import { Form } from "antd";
import React from "react";
import { RouteComponentProps } from "react-router";

import { useOnSubmit } from "../../hooks/useOnSubmit";
import DeviceForm from "./DeviceForm";

function DevicesCreate({ history }: RouteComponentProps) {
  const [form] = Form.useForm();
  const title = "Registrar un dispositivo nuevo";

  const onSuccess = () => {
    history.push(`/admin/devices`);
  };

  const { onSubmit, submitting, error } = useOnSubmit(
    `devices`,
    "v2",
    onSuccess
  );

  return (
    <DeviceForm
      error={error}
      form={form}
      onFinish={onSubmit}
      onCancel={() => history.goBack()}
      loading={submitting}
      title={title}
    />
  );
}

export default DevicesCreate;
