import { useReducer } from "react";

import { QueryState } from "../types";
import { ACTIONS, queryReducer } from "./queryReducer";

export const useQuery = (initialState: QueryState) => {
  const [state, dispatch] = useReducer(queryReducer, initialState);

  const onChange: (x: object) => void = (x: object) =>
    dispatch({ type: ACTIONS.mergeFilters, payload: x });

  const clearFilters = () => dispatch({ type: ACTIONS.clearFilters });

  const setPage: (page: number) => void = (page: number) =>
    dispatch({ type: ACTIONS.setPage, payload: page });

  const setSort: (sort: string) => void = (sort: string) =>
    dispatch({ type: ACTIONS.setSort, payload: sort });

  return { state, onChange, clearFilters, setPage, setSort };
};
