import moment from "moment";

import { newApi } from "./rest";

export async function download(endPoint: string, fileNamePrefix: string) {
  try {
    const response = await newApi().v3.get(endPoint, {
      responseType: "arraybuffer",
    });
    const data = response.data;
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `${fileNamePrefix}_${moment().format("YYYY-MM-DD")}.xlsx`
    );
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    alert("Error downloading");
  }
}
