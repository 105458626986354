import { useEffect, useReducer } from "react";
import axios from "axios";
import { dataServer } from "../constants/server";

const dataFetchReducer = (state, action) => {
  switch (action.type) {
    case "FETCH_INIT":
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case "FETCH_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload.data,
        count: action.payload.headers["x-total-count"],
      };

    case "ADD_ITEM":
      return {
        ...state,
        addedItems: [...state.addedItems, action.payload],
      };

    case "QUERY_CHANGED":
      return {
        ...state,
        query: { ...state.query, [action.payload.key]: action.payload.value },
        page: 1,
      };

    case "REMOVE_QUERY_KEY":
      const q = { ...state.query };
      delete q[action.payload.key];
      return {
        ...state,
        query: q,
        page: 1,
      };

    case "CLEAR_QUERY":
      return {
        ...state,
        query: {},
        page: 1,
      };

    case "SET_PAGE":
      return {
        ...state,
        page: action.payload,
      };

    case "SET_SORT":
      return {
        ...state,
        s: action.payload,
      };

    case "FETCH_FAILURE":
      return {
        ...state,
        isLoading: false,
        isError: true,
        asyncError: action.payload.error,
      };
    default:
      throw new Error();
  }
};

export const useFetchData = ({
  url,
  initialQuery,
  transform,
  initialSort,
  collectionName,
  dataServerPreferred,
}) => {
  const [state, dispatch] = useReducer(dataFetchReducer, {
    isLoading: true,
    isError: false,
    data: [],
    addedItems: [],
    page: 1,
    query:
      {
        ...JSON.parse(localStorage.getItem(collectionName)),
        ...initialQuery,
      } || {},
    s: initialSort,
  });

  const { query, page, s, addedItems } = state;

  const onChange = (key, value) => {
    if ((Array.isArray(value) && value.length === 0) || value === "") {
      dispatch({ type: "REMOVE_QUERY_KEY", payload: { key } });
    } else {
      dispatch({ type: "QUERY_CHANGED", payload: { key, value } });
    }
  };

  const clearQuery = () => {
    dispatch({ type: "CLEAR_QUERY" });
  };

  const addItem = (item) => {
    dispatch({ type: "ADD_ITEM", payload: item });
  };

  const setPage = (page) => {
    dispatch({ type: "SET_PAGE", payload: page });
  };

  const setSort = (sort) => {
    dispatch({ type: "SET_SORT", payload: sort });
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: "FETCH_INIT" });

      try {
        let data = transform ? transform(query) : query;

        localStorage.setItem(collectionName, JSON.stringify(data));

        const result = await axios.post(
          `${dataServerPreferred || dataServer}/${url}`,
          { ...data, page, offset: page, limit: 40, s, sort: s },
          {
            withCredentials: true,
          }
        );
        dispatch({ type: "FETCH_SUCCESS", payload: result });
      } catch (e) {
        if (e.response && e.response.status) {
          dispatch({
            type: "FETCH_FAILURE",
            payload: {
              error: {
                code: e.response.status,
                message: e.response.data,
              },
            },
          });
        } else {
          dispatch({
            type: "FETCH_FAILURE",
            payload: {
              error: { message: "Lo lamentamos mucho, algo salio mal." },
            },
          });
        }
      }
    };

    fetchData();
  }, [
    url,
    query,
    page,
    s,
    transform,
    addedItems,
    collectionName,
    dataServerPreferred,
  ]);

  return [state, onChange, addItem, clearQuery, setPage, setSort];
};
