import {
  Alert,
  Avatar,
  Button,
  Card,
  Form,
  Modal,
  Table,
  notification,
} from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import useSWR from "swr";

import { post } from "../../api";
import Breadcrumb from "../../components/Breadcrumb";
import { TextInput } from "../../components/Inputs";
import { FIELD_TYPES } from "../../constants";
import useForm from "../../hooks/useForm";
// import InviteUserForm from "./InviteUserForm";
import format from "../../utils/format";

const formFields = [
  {
    name: "firstName",
    label: "Nombre",
    required: true,
    placeholder: "Ingrese el nombre",
  },
  {
    name: "lastName",
    label: "Apellido",
    required: true,
    placeholder: "Ingrese el apellido",
  },
  {
    name: "email",
    label: "Correo",
    required: true,
    placeholder: "Ingrese el correo electrónico",
  },
];

function UserInvitationModal({ trigger }) {
  const [visible, setVisible] = useState(false);
  const { formState, handleSubmit, errors, onChange } = useForm(
    { firstName: "", lastName: "", email: "" },
    formFields
  );

  const { entity, submitted, submitting, asyncError } = formState;

  const onSubmit = async () => {
    await post("roles/account-email-invitation", entity);
    notification.success({
      message: `La invitación fue para ${entity.firstName} ${entity.lastName} fue enviada a ${entity.email}`,
    });
    setVisible(false);
  };

  const close = () => setVisible(false);

  return (
    <div>
      <div onClick={() => setVisible(true)}>{trigger}</div>
      <Modal
        visible={visible}
        title={"Invitar a un usuario nuevo"}
        footer={null}
        onCancel={close}
      >
        <Form layout={"vertical"}>
          {asyncError && <Alert message={asyncError.message} type="error" />}

          {formFields.map((x) => (
            <TextInput
              key={x.name}
              error={errors[x.name]}
              onChange={onChange}
              submitted={submitted}
              label={x.label}
              placeholder={x.placeholder}
              entity={entity}
              name={x.name}
            />
          ))}

          <div className={"login-form-buttons"}>
            <Button
              type="primary"
              disabled={submitting}
              loading={submitting}
              onClick={handleSubmit(onSubmit)}
            >
              INVITAR USUARIO
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
}

const getColumns = () => [
  {
    title: "Usuario",
    dataIndex: "license_plate",
    key: "license_plate",
    render: (text, record) => {
      let user = record.user;
      const fullName = user.firstName + " " + user.lastName;

      return (
        <div className="p-1">
          <Avatar className={"mr-3"}>
            {(user.firstName[0] + user.lastName[0]).toUpperCase()}
          </Avatar>
          <Link to={`/${record.accountId}/roles/${record.id}`}>{fullName}</Link>
        </div>
      );
    },
  },
  {
    title: "Rol",
    dataIndex: ["roleType", "name"],
    key: ["roleType", "name"],
  },
  {
    title: "Email",
    dataIndex: ["user", "email"],
    key: ["user", "email"],
  },
  {
    title: "Cuenta",
    dataIndex: ["account", "name"],
    key: ["account", "name"],
  },
  {
    title: "Última visita",
    dataIndex: ["user", "lastVisit"],
    key: ["user", "lastVisit"],
    render: (text) => format(text, FIELD_TYPES.RELATIVE_TIME),
  },
];

function useUsers() {
  return useSWR("roles", async () => await post("roles/search"), {
    initialData: {},
    revalidateOnMount: true,
  });
}

export default function PreferencesUsers() {
  const collection = useUsers();

  const routes = [
    { to: "/preferencias", label: "Preferencias" },
    { label: "Usuarios" },
  ];

  return (
    <Card
      title={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Breadcrumb routes={routes} />
          <UserInvitationModal
            trigger={<Button type={"primary"}>Adicionar Usuario</Button>}
          />
        </div>
      }
    >
      <Table
        dataSource={collection.data.rows}
        rowKey={(record) => record.id}
        columns={getColumns()}
        size={"small"}
        pagination={false}
        bordered
      />
    </Card>
  );
}
