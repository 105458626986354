import { DatabaseOutlined, EyeOutlined, MoreOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, Tooltip } from "antd";
import React from "react";
import { Link } from "react-router-dom";

import { filterTypes } from "../../../constants";
import { dashWhenEmpty } from "../../../helpers";
import { IGridColumn, Vehicle } from "../../../types";
import { formatNumber } from "../../../utils/format";
import { renderLastConnection } from "../../Devices/getColumns";
import { useStatuses } from "./goStatuses";

export const getColumns = (
  onDelete: (id: string, name: any, isArchival?: boolean) => void
): IGridColumn[] => [
  {
    dataIndex: "name",
    filterPlaceHolder: "Nombre del vehículo",
    filterType: filterTypes.text,
    title: "Nombre",
    sorter: true,
  },
  {
    dataIndex: "brand",
    filterType: filterTypes.text,
    filterPlaceHolder: "Marca del vehículo",
    title: "Marca",
    render: dashWhenEmpty,
    sorter: true,
  },
  {
    dataIndex: "model",
    filterType: filterTypes.text,
    filterPlaceHolder: "Modelo del vehículo",
    title: "Modelo",
    render: dashWhenEmpty,
    sorter: true,
  },
  {
    dataIndex: "license_plate",
    filterType: filterTypes.text,
    title: "Patente",
    filterPlaceHolder: "Patente del vehículo",
    render: dashWhenEmpty,
    sorter: true,
  },
  {
    dataIndex: "driver_name",
    title: "Conductor",
    filterPlaceHolder: "Conductor asignado",
    render: dashWhenEmpty,
    sorter: true,
  },
  {
    dataIndex: "status_id",
    title: "Estado",
    filterType: filterTypes.asyncTag,
    useFetcher: useStatuses,
    formatter: (x: any) => x.name,
    filterPlaceHolder: "Estado del vehículo",
    render: (text: string, record: any) => dashWhenEmpty(record.status_name),
    sorter: true,
  },
  {
    dataIndex: "lle_timestamp",
    filterType: filterTypes.text,
    title: "Ultima conexión",
    render: renderLastConnection,
    sorter: true,
  },
  {
    dataIndex: "odometer",
    title: "Odómetro",
    render: (x) => formatNumber(x, 0) + " km",
    align: "right",
    sorter: true,
  },
  {
    dataIndex: "id",
    title: "",
    render: (_, record: Vehicle) => {
      return (
        <Dropdown
          trigger={undefined}
          // @ts-ignore
          onClick={(e: any) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          overlay={
            <Menu>
              <Menu.Item key="0">
                <Link to={`/${record.account_id}/vehicles/${record.id}`}>
                  <Button type={"link"}>
                    <EyeOutlined style={{ color: "#AAAAAA" }} />
                    Ver
                  </Button>
                </Link>
              </Menu.Item>
              <Menu.Item key="1">
                <Button
                  onClick={() => onDelete(record.id, record.name, true)}
                  type={"link"}
                >
                  <DatabaseOutlined style={{ color: "#AAAAAA" }} /> Archivar
                  vehículo
                </Button>
              </Menu.Item>
            </Menu>
          }
        >
          <Tooltip title={"Acciones"} placement={"left"}>
            <Button icon={<MoreOutlined />} />
          </Tooltip>
        </Dropdown>
      );
    },
  },
];
