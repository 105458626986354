import React, { useContext } from "react";
import { Card, Form } from "antd";
import { AccountContext } from "../../hooks/useAccount";
import { useLegacyVehicles } from "../../hooks/useLegacyVehicles";
import { useOnSubmit } from "./useOnSubmit";
import { PlaceForm } from "./PlaceForm";
import { PlacesBreadcrumb } from "./PlacesBreadcrumb";
import { useHistory } from "react-router";

export default function CreateInsurance() {
  const vehicles = useLegacyVehicles();
  const history = useHistory();
  const account = useContext(AccountContext);
  const [form] = Form.useForm();

  const [onFinish, submitting, error] = useOnSubmit(`places`);

  return (
    <Card style={{ height: "100vh" }}>
      <PlacesBreadcrumb account={account} label={"Registrar"} />
      <PlaceForm
        error={error}
        form={form}
        loading={submitting}
        onCancel={() => history.goBack()}
        onFinish={onFinish}
        title={`Registrar un lugar nuevo`}
        vehicles={vehicles}
      />
    </Card>
  );
}
