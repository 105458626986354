import { useContext, useEffect, useState } from "react";
import { get, patch } from "../../helpers/v2api";
import { parseError } from "../../helpers";
import { useHistory } from "react-router";
import { AccountContext } from "../../hooks/useAccount";

export const useFetchAndSubmit = (endPoint, entityId, setFieldsValue) => {
  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const account = useContext(AccountContext);
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const axiosResponse = await get(`${endPoint}/${entityId}`);
        setFieldsValue(axiosResponse.data);
      } catch (e) {
        setError(parseError(e));
      }
    };
    fetchData();
  }, [entityId, setFieldsValue, endPoint]);

  const onFinish = async (values) => {
    try {
      setSubmitting(true);
      console.log("values", values);
      await patch(endPoint, values, entityId);

      history.push(`/${account.id}/lugares`);

      setSubmitting(false);
    } catch (err) {
      setSubmitting(false);
      setError(parseError(err));
    }
  };

  return [onFinish, submitting, error];
};
