import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
import { GOOGLE_MAPS_KEY, GEO_POLYGON_SET } from "../../constants";
const Marker = ({ children }) => children;

export default function PlaceMapView({ place, onChange }) {
  const [point, setPoint] = useState({ lat: -34.616655, lng: -58.4622398 });
  const onClickMap = (e) => {
    const { lat, lng } = e;
    setPoint({ lat, lng });
    onChange({ lat, lng });
  };

  return (
    <div style={{ height: "600px", width: "100%" }}>
      <GoogleMapReact
        onClick={onClickMap}
        bootstrapURLKeys={{ key: GOOGLE_MAPS_KEY }}
        defaultCenter={point}
        center={point}
        defaultZoom={11}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => {
          const polygonValue = place.getFieldValue("geo_polygon");
          if (polygonValue) {
            debugger;
            const polygon = polygonValue[0].map((coordinate) => ({
              lat: coordinate[0],
              lng: coordinate[1],
            }));
            map.setCenter({ lat: polygonValue[0][0], lng: polygonValue[0][1] });
            const poly = new window.google.maps.Polygon({
              paths: polygon,
              strokeColor: GEO_POLYGON_SET.POLYGON_BORDER_COLOR,
              strokeOpacity: 0.5,
              strokeWeight: 2,
              fillColor: GEO_POLYGON_SET.POLYGON_FILL_COLOR,
              fillOpacity: 0.35,
            });
            poly.setMap(map);
          }
          return null;
        }}
      >
        <Marker lat={point.lat} lng={point.lng} text="x.vehicleId">
          <img
            src={"https://static.inmatics.io/icons/icoGeo10.svg"}
            width={32}
            alt=""
          />
        </Marker>
      </GoogleMapReact>
    </div>
  );
}
