import React, { useRef, useState } from "react";
import { Card, Button, Form, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useDataApi } from "../hooks/useDataApi";
import { post } from "../api";
import PartsList from "./PartsList";

let endPoint = "part-types?sortBy=name+asc";

export default function PartsSelector({
  onSelect,
  entity,
  onRemove,
  onRowChange,
}) {
  const [name, setName] = useState("");
  const foo = useDataApi(endPoint);
  const state = foo[0];
  const addItem = foo[2];

  const inputRef = useRef(null);

  const onAddItemClick = async () => {
    const response = await post(endPoint, { name });
    addItem(response);
    setName("");
    onSelect(response.id);
    inputRef.current.blur();
  };

  const onSelectChange = (e) => {
    if (e) {
      onSelect(e);
    }
    inputRef.current.blur();
  };

  const onInputChange = (e) => setName(e);

  return (
    <Card title={"Repuestos"}>
      <Form.Item label={"Indique los repuestos utilizados"}>
        <Select
          allowClear
          showSearch
          notFoundContent={
            name.length > 3 ? (
              <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                <Button
                  style={{
                    flex: "none",
                    display: "block",
                    cursor: "pointer",
                  }}
                  type="primary"
                  onClick={onAddItemClick}
                >
                  <PlusOutlined /> {`Agregar ${name}`}
                </Button>
              </div>
            ) : (
              ""
            )
          }
          onChange={onSelectChange}
          onSearch={onInputChange}
          optionFilterProp={"label"}
          options={state.data.map((item) => ({
            label: item.name,
            value: item.id,
          }))}
          placeholder="Agregue las partes (Ej. Filtro de aceite de motor.)"
          ref={inputRef}
          value={null}
        />
      </Form.Item>
      <PartsList
        entity={entity}
        onRowChange={onRowChange}
        collection={state.data}
        onRemove={onRemove}
      />
    </Card>
  );
}
