import { Alert, Col, Row } from "antd";
import React from "react";
import { patch } from "src/api";
import QuickAddButton from "src/components/QuickAddButton";
import VehicleHeader from "src/components/VehicleHeader";
import { post } from "src/helpers/v2api";
import { IWithAccountId } from "src/types";
import { useSimpleSWRFetch } from "src/utils/rest";

import VehicleSidebar from "./VehicleSidebar";
import VehicleViewRoutes from "./VehicleViewRoutes";

const refreshInterval = 30000;

export default function VehicleView({ match, location }: IWithAccountId) {
  const { vehicleId, accountId } = match.params;
  const url = `vehicles/${vehicleId}/fullinfo`;

  const { data, error, mutate } = useSimpleSWRFetch(url, { refreshInterval });

  const onReadingSubmit = async (value: number, date: string) => {
    const vehicle_id = parseInt(vehicleId || "");
    await post(`meter-readings`, { vehicle_id, value, date });
    mutate();
  };

  const onStatusSubmit = async (statusId: string) => {
    await patch(`vehicles`, { status_id: statusId }, vehicleId);
    mutate();
  };

  if (error) {
    return <Alert message={error.message} type="error" />;
  }

  if (!data) {
    return null;
  }

  return (
    <div>
      <VehicleHeader
        entity={data}
        vehicle={data}
        accountId={accountId}
        pathname={location.pathname}
        onReadingSubmit={onReadingSubmit}
        onStatusSubmit={onStatusSubmit}
      />
      <Row className="vehicle-body">
        <Col md={4} className="vehicle-side-menu">
          <QuickAddButton vehicleId={vehicleId} accountId={accountId} />
          <VehicleSidebar
            url={match.url}
            entity={data}
            selectedKeys={[match.path]}
          />
        </Col>
        <Col md={20} className="vehicle-body-content">
          <VehicleViewRoutes
            url={match.url}
            path={match.path}
            vehicle={data}
            accountId={accountId}
            vehicleId={vehicleId || ""}
          />
        </Col>
      </Row>
    </div>
  );
}
