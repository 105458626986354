import { Form } from "antd";
import React from "react";

import { useFetchAndSubmit } from "../../hooks/useFetchAndSubmit";
import { IWithAccountId } from "../../types";
import DeviceForm from "./DeviceForm";

const endPoint = `admin/devices`;

function DevicesEdit({ history, match }: IWithAccountId) {
  const [form] = Form.useForm();
  const { setFieldsValue } = form;
  const entityId = match.params.entityId;
  const title = `Actualizar el dispositivo #${entityId}`;

  const onSuccess = () => history.push("admin/devices");

  const { onSubmit, submitting, error } = useFetchAndSubmit({
    apiVersion: "v3",
    endPoint,
    entityId: String(entityId),
    onSuccess,
    setFieldsValue,
  });

  return (
    <DeviceForm
      form={form}
      error={error}
      onFinish={onSubmit}
      loading={submitting}
      onCancel={() => history.goBack()}
      title={title}
    />
  );
}

export default DevicesEdit;
