import { FileTextOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
} from "antd";
import React from "react";

import { carBrands, defaultLayout } from "../../constants";
import { getLastNYears } from "../../helpers";
import { FormParams } from "../../types";

const fullYear = new Date().getFullYear();
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const YearSelect = () => {
  return (
    <Form.Item
      required
      label={"Año"}
      name="year"
      rules={[
        { required: true, message: "Por favor ingrese el Año del vehículo" },
      ]}
    >
      <Select
        showSearch
        optionFilterProp={"label"}
        options={getLastNYears(20, fullYear).map((x) => ({
          value: Number(x),
          label: x,
        }))}
        placeholder={"Ej: 2020"}
      />
    </Form.Item>
  );
};

const BrandSelect = () => {
  return (
    <Form.Item
      required
      label={"Marca"}
      name="brand"
      rules={[
        { required: true, message: "Por favor ingrese la Marca del Vehículo" },
      ]}
    >
      <Select
        showSearch
        optionFilterProp={"label"}
        options={carBrands.map((x) => ({ value: x, label: x }))}
        placeholder={"Ej: TOYOTA"}
      />
    </Form.Item>
  );
};

export function VehicleForm(props: FormParams) {
  const { error, form, loading, onCancel, onFinish, title } = props;
  const cancel = () => {
    onCancel();
  };

  return (
    <Row>
      <Col {...defaultLayout}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Card
            style={{ width: 1000 }}
            title={
              <>
                <FileTextOutlined className={"muted"} /> {title}
              </>
            }
          >
            {error && <Alert message={error} className={"mb-3"} type="error" />}
            <Form
              form={form}
              onFinish={onFinish}
              layout={"vertical"}
              style={{ minWidth: 800 }}
              {...layout}
            >
              {/*<Card title={title}>*/}

              <Row>
                <Col span={"12"}>
                  <YearSelect />
                </Col>
                <Col span={"12"}>
                  <BrandSelect />
                </Col>
                <Col span={"12"}>
                  <Form.Item
                    required
                    label="Modelo"
                    name="model"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingrese un Modelo de Vehículo",
                      },
                    ]}
                  >
                    <Input placeholder={"Ej: Corsa"} />
                  </Form.Item>
                </Col>
                <Col span={"12"}>
                  <Form.Item
                    required
                    label="Patente"
                    name="license_plate"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingrese la Patente del Vehículo",
                      },
                    ]}
                  >
                    <Input placeholder={"Ej: AS432XS"} />
                  </Form.Item>
                </Col>
                <Col span={"12"}>
                  <Form.Item
                    required
                    label="Nombre"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Por favor ingrese el Nombre del Vehículo",
                      },
                    ]}
                  >
                    <Input placeholder={"Ej: Movil 487"} />
                  </Form.Item>
                </Col>
                <Col span={"12"}>
                  <Form.Item label="Chasis" name="vin">
                    <Input placeholder={"Ej: 4Y1SL65848Z411439"} />
                  </Form.Item>
                </Col>
                <Col span={"12"}>
                  <Form.Item label="Titular" name="owner">
                    <Input placeholder={"Ej: ACME S.A"} />
                  </Form.Item>
                </Col>
                <Col span={"12"}>
                  <Form.Item label="CUIT" name="owner_cuit">
                    <Input placeholder={"Ej: 30716268090"} />
                  </Form.Item>
                </Col>
                <Col span={"12"}>
                  <Form.Item label="Email notificaciones" name="notify_to">
                    <Input placeholder={"Ej: notificaciones@tu-dominio.com"} />
                  </Form.Item>
                </Col>
                <Col span={"12"}>
                  <Form.Item
                    hidden={true}
                    label="Odómetro automático"
                    name="auto_km_updates"
                    valuePropName="checked"
                  >
                    <Checkbox />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item>
                <Button loading={loading} onClick={cancel} className={"mr-2"}>
                  CANCELAR
                </Button>
                <Button loading={loading} type={"primary"} htmlType={"submit"}>
                  GUARDAR
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </div>
      </Col>
    </Row>
  );
}
