import { Form } from "antd";
import React from "react";

import Breadcrumb from "../../components/Breadcrumb";
import { useOnSubmit } from "../../hooks/useOnSubmit";
import { IWithAccountId } from "../../types";
import { VehicleForm } from "./VehicleForm";

function VehiclesCreate({ history, match }: IWithAccountId) {
  const [form] = Form.useForm();
  const title = "Registrar un vehículo nuevo";

  const onSuccess = (data: any) => {
    history.push(`/${match.params.accountId}/vehicles/${data.id}`);
  };

  const { onSubmit, submitting, error } = useOnSubmit(
    `vehicles`,
    "v3",
    onSuccess
  );

  const routes = [
    { to: "/" + match.params.accountId + "/vehicles", label: "Vehículos" },
    { label: "Crear vehículo" },
  ];

  return (
    <div className={"mb-5"}>
      <div className="entity-header mb-3">
        <Breadcrumb routes={routes} />
      </div>
      <VehicleForm
        error={error}
        form={form}
        onFinish={(values) =>
          // @ts-ignore
          onSubmit({ ...values, year: Number(values.year) })
        }
        onCancel={() => history.goBack()}
        loading={submitting}
        title={title}
      />
    </div>
  );
}

export default VehiclesCreate;
