import { Menu } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

export default function VehicleSidebar(props: {
  url: string;
  entity: any;
  selectedKeys: any;
}) {
  const [selectedMenuKey, setSelectedMenuKey] = useState([""]);
  const location = useLocation<{ menuKey: string[] }>();
  const { url, entity } = props;

  useEffect(() => {
    const key = location.state?.menuKey?.[1];
    setSelectedMenuKey([key]);
  }, [location, setSelectedMenuKey]);

  return (
    <Menu selectedKeys={selectedMenuKey}>
      <Menu.Item key={"summary-vehicle"}>
        <Link
          to={{
            pathname: url + "/resumen",
            state: { menuKey: ["vehiculos", "summary-vehicle"] },
          }}
        >
          Resumen
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key={"readings-vehicle"} className={"vehicle-menu-item"}>
        <Link
          to={{
            pathname: url + "/readings",
            state: { menuKey: ["vehiculos", "readings-vehicle"] },
          }}
        >
          Lecturas de odómetro
        </Link>
        <small style={{ float: "right" }}>
          {entity["meter_entries_count"] || 0}
        </small>
      </Menu.Item>
      <Menu.Item key={"services-vehicle"}>
        <Link
          to={{
            pathname: url + "/services",
            state: { menuKey: ["vehiculos", "services-vehicle"] },
          }}
        >
          Servicios
        </Link>
        <small style={{ float: "right" }}>
          {entity["services_count"] || 0}
        </small>
      </Menu.Item>
      <Menu.Item key={"asignaciones-vehicle"}>
        <Link
          to={{
            pathname: url + "/asignaciones",
            state: { menuKey: ["vehiculos", "asignaciones-vehicle"] },
          }}
        >
          Asignaciones
        </Link>
        <small style={{ float: "right" }}>
          {entity["vehicle_assignment_count"] || 0}
        </small>
      </Menu.Item>
      <Menu.Item key={"seguros-vehicle"}>
        <Link
          to={{
            pathname: url + "/seguros",
            state: { menuKey: ["vehiculos", "seguros-vehicle"] },
          }}
        >
          Seguros
        </Link>
        <small style={{ float: "right" }}>
          {entity["insurance_policy_count"] || 0}
        </small>
      </Menu.Item>

      <Menu.Item key={"collections-vehicle"}>
        <Link
          to={{
            pathname: url + "/collections",
            state: { menuKey: ["vehiculos", "collections-vehicle"] },
          }}
        >
          Cobranzas
        </Link>
        <small style={{ float: "right" }}>
          {entity["transactions_count"] || 0}
        </small>
      </Menu.Item>
      {/*<Menu.Item key={url + "/viajes"} className={"vehicle-menu-item"}>*/}
      {/*  <Link to={url + "/viajes"}>Viajes</Link>*/}
      {/*  <small style={{ float: "right" }}>{entity["tripCount"] || 0}</small>*/}
      {/*</Menu.Item>*/}
    </Menu>
  );
}
