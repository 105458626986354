import AccountSettings from "./Account/AccountSettings";
import VehicleAssignmentEdit from "./Assignments/VehicleAssignmentEdit";
import BulkInsert from "./Bulk/BulkInsert";
import CollectionsCreate from "./Collections/CollectionsCreate";
import CollectionsEdit from "./Collections/CollectionsEdit";
import TransactionsList from "./Collections/TransactionsList";
import ContactCreate from "./Contacts/ContactCreate";
import ContactEdit from "./Contacts/ContactEdit";
import ContactList from "./Contacts/ContactList";
import ContactView from "./Contacts/ContactView";
import { DailyLocationsReport } from "./DailyLocationsReport";
import Dashboard from "./Dashboard/Dashboard";
import Devices from "./Devices/Devices";
import Insurances from "./Insurance/Insurances";
import { Invoices } from "./Invoices/Invoices";
import { LocationEntriesReport } from "./LocationEntries/LocationEntriesReport";
import Telematic from "./Maps/FleetView";
import Places from "./Places/Places";
import Preferences from "./Preferences/Preferences";
import AnnualReport from "./Reports/AnnualReport";
import AssignmentsReport from "./Reports/AssignmentsReport";
import Reports from "./Reports/ReportsIndex";
import ServicesSummary from "./Reports/ServicesSummary";
import WeeklylReport from "./Reports/WeeklylReport";
import RoleCreate from "./Roles/RoleCreate";
import RoleEdit from "./Roles/RoleEdit";
import RoleView from "./Roles/RoleView";
import GaliciaInsert from "./RussellCar/GaliciaInsert";
import ServiceCreate from "./Services/ServiceCreate";
import ServiceEdit from "./Services/ServiceEdit";
import ServicesList from "./Services/ServicesList";
import PremiumInsert from "./Taxis/PremiumInsert";
import { Trips } from "./Trips/Trips";
import VehicleCreate from "./Vehicles/VehicleCreate";
import VehicleEdit from "./Vehicles/VehicleEdit";
import VehiclesList from "./Vehicles/VehicleList/VehiclesList";
import VehicleView from "./Vehicles/VehicleView/VehicleView";
import { Vendors } from "./Vendors/Vendors";

let b = "/:accountId";

export const routes = [
  { path: b + "/vehicles/create", c: VehicleCreate, exact: true },
  { path: b + "/vehicles", c: VehiclesList, exact: true },
  { path: b + "/vehicles/:entityId/edit", c: VehicleEdit, exact: true },
  { path: b + "/vehicles/:vehicleId", c: VehicleView },
  { path: b + "/services", c: ServicesList, exact: true },
  { path: b + "/services/create", c: ServiceCreate, exact: true },
  { path: b + "/services/:serviceId/edit", c: ServiceEdit, exact: true },
  {
    path: b + "/asignaciones/:entityId/editar",
    c: VehicleAssignmentEdit,
    exact: true,
  },

  { path: b + "/lugares", c: Places },
  { path: b + "/ubicaciones", c: LocationEntriesReport },
  { path: b + "/seguros", c: Insurances },
  { path: b + "/dispositivos", c: Devices },
  { path: b + "/roles/create", c: RoleCreate, exact: true },
  { path: b + "/roles/:roleId", c: RoleView, exact: true },
  { path: b + "/roles/:entityId/edit", c: RoleEdit, exact: true },
  { path: b + "/contacts", c: ContactList, exact: true },
  { path: b + "/contacts/create", c: ContactCreate, exact: true },
  { path: b + "/contacts/:entityId/edit", exact: true, c: ContactEdit },
  { path: b + "/contacts/:entityId", c: ContactView },
  { path: b + "/collections/create", c: CollectionsCreate, exact: true },
  { path: b + "/cobranzas", c: TransactionsList, exact: true },
  { path: b + "/collections/:id/edit", exact: true, c: CollectionsEdit },

  { path: b + "/reports/collections-annual", c: AnnualReport, exact: true },
  { path: b + "/reports/services-summary", c: ServicesSummary, exact: true },
  { path: b + "/reports/collections-weekly", c: WeeklylReport, exact: true },
  { path: b + "/reports/asignaciones", c: AssignmentsReport, exact: true },
  {
    path: b + "/reports/ubicaciones-diario",
    c: DailyLocationsReport,
    exact: true,
  },
  { path: b + "/reports", c: Reports, exact: true },

  { path: b + "/proveedores", c: Vendors },
  { path: b + "/facturas", c: Invoices },
  { path: b + "/bulk-insert", c: BulkInsert, exact: true },
  { path: b + "/account", c: AccountSettings, exact: true },
  { path: b + "/telematic", c: Telematic, exact: true },
  { path: b + "/viajes", c: Trips, exact: true },
  { path: b + "/dashboard", c: Dashboard, exact: true },
  { path: b + "/preferencias", c: Preferences },
  { path: b + "/importacion-galicia", c: GaliciaInsert },
  { path: b + "/importacion-premium", c: PremiumInsert },
];
