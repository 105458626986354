import React from "react";
import { patch } from "../../api";
import { useEntity } from "../../hooks/useEntity";
import Breadcrumb from "../../components/Breadcrumb";
import { Alert, Button, DatePicker, Form, Layout } from "antd";
import { TextInput } from "../../components/Inputs";
import locale from "antd/es/date-picker/locale/es_ES";
import moment from "moment-timezone";
import ConceptSelector from "../../components/ConceptSelector";
import OdometerUpdateField from "../../components/OdometerUpdateField";
import ShiftPicker from "./ShiftPicker";

const text = { title: "Editar cobranza" };
const fontSize = `${30 / text.title.length}rem`;
const formFields = [];

export default function Entity({ match, history }) {
  const { id, accountId } = match.params;

  const { state, onChange, handleSubmit } = useEntity(
    id,
    `transactions`,
    formFields
  );

  const { entity, submitted, submitting, asyncError, fetching } = state;

  const onSubmit = async () => {
    await patch(`transactions`, entity, id);
    history.push(
      `/${accountId}/vehicles/${entity.vehicleId}/collections/${id}`
    );
  };

  if (fetching) {
    return <p>loading...</p>;
  }

  const routes = [
    { to: "/" + accountId + "/vehicles", label: "Cobranzas" },
    { label: "Editar cobranza" },
  ];

  return (
    <div className={"mb-5"}>
      <div className="entity-header mb-3">
        <Breadcrumb routes={routes} />
        <div className="entity-header__buttons">
          <Button
            className="mr-2"
            style={{ width: 150 }}
            type="secondary"
            onClick={() => history.goBack()}
          >
            CANCELAR
          </Button>
          <Button type="primary" onClick={onSubmit}>
            GUARDAR CAMBIOS
          </Button>
        </div>
      </div>
      <Layout className={"small-form-container"} id={"root"}>
        <h1 style={{ fontSize: fontSize }}>{text.title}</h1>
        <Form
          layout={"vertical"}
          className={"login-form card"}
          onFinish={handleSubmit(onSubmit)}
        >
          {asyncError && (
            <Alert
              className={"mb-4"}
              description={
                <ul>
                  <li>{asyncError.message}</li>
                </ul>
              }
              message={"Solucione los siguientes errores"}
              type="error"
              showIcon
            />
          )}

          <Form.Item label={"Fecha"}>
            <DatePicker
              locale={locale}
              style={{ width: "100%" }}
              format="DD/MM/YYYY"
              value={entity["date"] ? moment(entity["date"]) : undefined}
              onChange={(e) => onChange("date", e)}
            />
          </Form.Item>

          <ShiftPicker onChange={onChange} entity={entity} />

          <ConceptSelector onChange={onChange} entity={entity} />

          <TextInput
            onChange={onChange}
            placeholder={"Ingrese el monto percibido (Ej. $1.000)"}
            entity={entity}
            name={"amount"}
            submitted={submitted}
            label={"Monto"}
          />

          <OdometerUpdateField
            entity={entity}
            onChange={onChange}
            vehicleList={state.data}
          />

          <Button
            className="mr-2"
            style={{ width: 150 }}
            type="secondary"
            onClick={() => history.goBack()}
          >
            CANCELAR
          </Button>

          <Button
            type="primary"
            htmlType={"submit"}
            disabled={submitting}
            loading={submitting}
            onClick={handleSubmit(onSubmit)}
          >
            GUARDAR CAMBIOS
          </Button>
        </Form>
      </Layout>
    </div>
  );
}
