import React from "react";
import {Button, Card, Dropdown, Menu, message, Table} from "antd";
import { formatNumber } from "../utils/format";
import { calculateTimeSince, dateFormatter, formatDate } from "../utils";
import { Link } from "react-router-dom";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  // @ts-ignore
} from "recharts";
import ServiceEmtpyView from "./OdometerEmtpyView";
import {DeleteOutlined, MoreOutlined, StopOutlined } from "@ant-design/icons";
import {remove, useSWRFetch, patch} from "../helpers/v2api";
import {useQuery} from "../hooks/useQuery";
import {getURI} from "../helpers/GetURI";
import {ImeterReadingRecord} from "../types";

const isTheSameDay = (text: string) => {
  const then = new Date(text);
  const now = new Date();

  const msBetweenDates = Math.abs(then.getTime() - now.getTime());

  const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);

  return hoursBetweenDates < 24;
}

const endpoint = "meter-readings";

export default function MeterReadingsTable({ match }: any) {
  const { vehicleId, accountId } = match.params;

  const initialFilter = {};

  const { state, setSort } = useQuery({
    filters: initialFilter,
    page: 1,
    // @ts-ignore
    vehicleId: vehicleId
  });

  const { data, error, mutate } = useSWRFetch(getURI(endpoint, state));

  const maxNotVoidValue = () => {
    const notVoidValues = data ? data.filter((reading: { void: boolean; }) => !reading.void) : [];
    return notVoidValues ? Math.max(...notVoidValues.map((reading: { value: number; }) => reading.value )) : 0;
  }


  if (error) {
    console.log(error)
  }

  const onDelete = async (record: { id: string; }) => {
    try {
      await remove(endpoint, record.id);
      await mutate();
      message.success(`La lectura #${record.id} ha sido removida.`);
    } catch (err) {
      console.log(err);
    }
  };

  const onChangeVoid = async (record: ImeterReadingRecord) => {
      try {
        await patch(endpoint, {
          id: record.id,
          date: record.date,
          value: record.value,
          vehicle_id: record.vehicle_id,
          void: !record.void,
        }, record.id);
        await mutate();
        message.success("La lectura #" + record.id + " fue marcada como " + (record.void ? "no nula" : "nula"))
      } catch (err) {
        message.error("La lectura #" + record.id + " no puede ser marcado como no nula")
      }
  }

  const onTableChange = (sorter: any) => {
    if (sorter.order) {
      let sort = sorter.order === "ascend" ? `ASC` : "DESC";
      setSort(`${sorter.column.sortIndex || sorter.field}+${sort}`);
    } else {
      setSort("");
    }
  };

  const renderDate = (text: string) => {
    if (isTheSameDay(text)) {
      return <div><p>Hoy</p></div>
    }

    return (
        <div>
          <p>{formatDate(text)}</p>
          <p>
            {" "}
            <small>{calculateTimeSince(text)}</small>
          </p>
        </div>
    );
  };

  const renderMileage = (text: string, record: any) => {
    const voidOpacity = record.void ? 0.4 : 1;
    const diff = maxNotVoidValue() - record.value;

    const small = record.value < maxNotVoidValue() ? (
        <>Hace {formatNumber(diff, 0)} km</>
    ) : (
        "Valor actual"
    );
    return (
        <div style={{ opacity: voidOpacity }}>
          <p>{formatNumber(text, 0)} km</p>
          <p>
            <small>{small}</small>
          </p>
        </div>
    );
  };

  const renderSource = (text: string, record: ImeterReadingRecord) => {
    if (record.service_id) {
      return (
          <div>
            <Link
                to={`/${accountId}/vehicles/${record.vehicle_id}/services/${record.service_id}`}
            >
              Servicio
            </Link>
            <p>
              <small>#{record.service_id}</small>
            </p>
          </div>
      );
    } else if (record.vehicle_assignment_id) {
      return (
          <div>
            <Link
                to={`/${accountId}/vehicles/${record.vehicle_id}/asignaciones/${record.vehicle_assignment_id}`}
            >
              Asignación
            </Link>
            <p>
              <small>#{record.vehicle_assignment_id}</small>
            </p>
          </div>
      );
    } else {
      return <div>Manual</div>
    }
  };

  const renderDropDown = (text: string, record: ImeterReadingRecord) => {
    return (
        <Dropdown
            trigger={undefined}
            overlay={
              <Menu>
                <Menu.Item key="0">
                  <Button disabled={ record.service_id !== null || record.transaction_id !== null } onClick={() => onDelete(record)} type={"link"}>
                    <DeleteOutlined style={{ color: "#AAAAAA" }} /> Remover lectura
                  </Button>
                </Menu.Item>
                <Menu.Item key="1">
                  <Button onClick={() => onChangeVoid(record)} type={"link"}>
                    <StopOutlined style={{ color: "#AAAAAA" }} /> Marcar como {record.void ? "no-nulo": "nulo"}
                  </Button>
                </Menu.Item>
              </Menu>
            }
        >
          <Button icon={<MoreOutlined />} />
        </Dropdown>
    )
  }

  const columns = [
    {
      sorter: true,
      title: "Fecha",
      dataIndex: "date",
      key: "date",
      render: renderDate,
    },
    {
      sorter: true,
      title: "Valor",
      defaultSortOrder: "descend",
      dataIndex: "value",
      key: "value",
      render: renderMileage,
    },
    {
      title: "Fuente",
      dataIndex: "serviceId",
      key: "serviceId",
      render: renderSource,
    },
    {
      title: "",
      render: renderDropDown,
    },
  ];

  const DataFormater = (data: number) => {
    if (data > 1000) {
      return (data / 1000).toFixed(0) + "K";
    } else {
      return data.toString();
    }
  };

  let newVar = 0;

  if (data?.length > 0) {
    let value = data[data.length - 1].value;
    if (value > 100000) {
      newVar = value - 20000;
    } else if (value > 50000) {
      newVar = value - 10000;
    }
  }

  if (data?.length === 0) {
    return <ServiceEmtpyView accountId={accountId} />;
  }

  const dataForLineChart = data?.filter((x: { void: boolean; }) => !x.void)

  return (
    <div>
      <Card>
        <ResponsiveContainer height={300} width={"99%"}>
          <LineChart height={300} data={dataForLineChart}>
            <XAxis
              dataKey="date"
              tick={{ fill: "#859677" }}
              reversed
              tickFormatter={dateFormatter}
            />
            <Tooltip formatter={(value: number) => formatNumber(value, 0) + " km."} />
            <YAxis
              tick={{ fill: "#779695" }}
              domain={[newVar, "dataMax"]}
              tickFormatter={DataFormater}
            />
            <CartesianGrid strokeDasharray="3 3" />

            <Line
              dot={false}
              strokeWidth={2}
              type="monotone"
              dataKey="value"
              label={"dsfs"}
              stroke="#0099B0"
            />
          </LineChart>
        </ResponsiveContainer>
      </Card>
      <br />
      <Table
        dataSource={data}
        onChange={onTableChange}
          // @ts-ignore
        columns={columns}
        size={"small"}
        rowKey={(record) => record.id}
      />
    </div>
  );
}
