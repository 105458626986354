import React from "react";
import { patch } from "../../api";
import { useEntity } from "../../hooks/useEntity";
import Breadcrumb from "../../components/Breadcrumb";
import {
  Alert,
  Button,
  Card,
  Form,
  Layout,
  Row,
  Col,
  notification,
} from "antd";
import { TextInput } from "../../components/Inputs";

const END_POINT = `contacts`;

const formFields = [
  {
    name: "firstName",
    label: "Nombre",
    required: true,
    placeholder: "Ingrese el nombre",
  },
  {
    name: "lastName",
    label: "Apellido",
    placeholder: "Ingresa apellido",
    required: true,
  },
  {
    name: "documentNumber",
    label: "DNI",
    placeholder: "Ingrese el DNI",
  },
  {
    name: "phoneNumber",
    label: "Telefono",
    placeholder: "Ingrese el telefono",
  },
  {
    name: "email",
    label: "Correo electrónico",
    placeholder: "Ingrese el correo",
  },
];

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export default function Entity({ match, history }) {
  const { entityId, accountId } = match.params;

  const { state, errors, onChange, handleSubmit } = useEntity(
    entityId,
    END_POINT,
    formFields
  );

  const { entity, submitted, submitting, asyncError, fetching } = state;

  const onSubmit = async () => {
    await patch(END_POINT, entity, entityId);
    notification.success({
      message: "Los datos del conductor fueron modificados.",
    });
    history.push(`/${accountId}/${END_POINT}/${entityId}`);
  };

  if (fetching) {
    return <p>loading...</p>;
  }

  const routes = [
    { to: "/" + accountId + `/${END_POINT}`, label: "Conductores" },
    { label: "Editar conductor" },
  ];

  return (
    <div className={"mb-5"}>
      <div className="entity-header mb-3">
        <Breadcrumb routes={routes} />
        <div className="entity-header__buttons">
          <Button
            className="mr-2"
            style={{ width: 150 }}
            type="secondary"
            onClick={() => history.goBack()}
          >
            CANCELAR
          </Button>
          <Button type="primary" onClick={onSubmit}>
            GUARDAR CAMBIOS
          </Button>
        </div>
      </div>

      <Layout className={"medium-form-container"}>
        <Card title={"Editar vehículo"} style={{ width: 1000 }}>
          <Form {...layout} onFinish={handleSubmit(onSubmit)}>
            <Row>
              {formFields.map((x) => (
                <Col span={"12"}>
                  <TextInput
                    error={errors[x.name]}
                    onChange={onChange}
                    transform={x.transform}
                    style={x.style}
                    submitted={submitted}
                    label={x.label}
                    placeholder={x.placeholder}
                    entity={entity}
                    name={x.name}
                  />
                </Col>
              ))}
            </Row>

            {asyncError && <Alert message={asyncError.message} type="error" />}

            <div className={"medium-form-buttons"}>
              <Button type="secondary" onClick={() => history.goBack()}>
                CANCELAR
              </Button>

              <Button
                type="primary"
                className={"ml-3"}
                htmlType={"submit"}
                disabled={submitting}
                loading={submitting}
                onClick={handleSubmit(onSubmit)}
              >
                GUARDAR CAMBIOS
              </Button>
            </div>
          </Form>
        </Card>
      </Layout>
    </div>
  );
}
