import React from "react";
import { Select } from "antd";

export default function FilterTagSelector({
  entity,
  onChange,
  style,
  useFetcher,
  name,
  placeholder,
  options,
}) {
  return (
    <Select
      allowClear
      mode={"multiple"}
      onChange={(value) => onChange(name, value)}
      optionFilterProp={"label"}
      options={options}
      placeholder={placeholder}
      showSearch
      style={style}
      value={entity[name]}
    />
  );
}
