import { Card, Col, Row, Statistic } from "antd";
import React from "react";

import { useDataApi } from "../../hooks/useDataApi";
import { formatMoney } from "../../utils/format";

export default function Entity({ contactId }) {
  const [state] = useDataApi(`transactions/summary?contactId=${contactId}`);

  const { data } = state;

  return (
    <Col span={24} className={"p-1"}>
      <Card title={"Cobranzas"}>
        <Row>
          <Col span={6}>
            {" "}
            <Statistic
              title="Últimos 7 días"
              value={data["seven"]}
              formatter={formatMoney}
            />
          </Col>
          <Col span={6}>
            {" "}
            <Statistic
              title="Últimos 30 días"
              value={data["thirty"]}
              formatter={formatMoney}
            />
          </Col>
          <Col span={6}>
            {" "}
            <Statistic
              title="Ulitmos 90 días"
              value={data["ninety"]}
              formatter={formatMoney}
            />
          </Col>
          <Col span={6}>
            {" "}
            <Statistic
              title="Total"
              value={data["total"]}
              formatter={formatMoney}
            />
          </Col>
        </Row>
      </Card>
    </Col>
  );
}
