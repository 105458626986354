import { Table } from "antd";
import React from "react";
import { Link } from "react-router-dom";

import { useDataApi } from "../../../hooks/useDataApi";
import { renderTime } from "../../../utils";
import { formatMoney } from "../../../utils/format";

export default function CollectionsTable(props) {
  const { match } = props;
  const { vehicleId, accountId } = match.params;
  const [collectionsState] = useDataApi(`transactions?vehicleId=${vehicleId}`);

  return (
    <Table
      columns={[
        {
          title: "ID",
          dataIndex: "id",
          render: (text, record) => (
            <Link
              to={{
                pathname: `/${accountId}/vehicles/${record.vehicleId}/collections/${record.id}`,
                state: { menuKey: ["vehiculos", "collections-vehicle"] },
              }}
            >
              {text}
            </Link>
          ),
        },
        { title: "Fecha", key: "date", dataIndex: "date", render: renderTime },
        {
          title: "Tipo",
          key: ["transactionType", "name"],
          dataIndex: ["transactionType.name"],
        },
        {
          title: "Conductor",
          key: ["contact", "name"],
          dataIndex: ["contact.id"],
          render: (text, record) => {
            if (!record["contact.id"]) {
              return "-";
            }

            return (
              <Link to={`/${accountId}/contacts/${record["contact.id"]}`}>
                {record["contact.firstName"]} {record["contact.lastName"]}
              </Link>
            );
          },
        },
        {
          title: "Referencia",
          dataIndex: ["reference"],
          render: (text) => {
            return text || "-";
          },
        },
        {
          title: "Monto",
          key: "amount",
          dataIndex: "amount",
          align: "right",
          render: (text) => {
            return formatMoney(text, 2);
          },
        },
      ]}
      loading={collectionsState.isLoading}
      size={"small"}
      className={"reports"}
      dataSource={collectionsState.data}
      pagination={{ pageSize: 15 }}
    />
  );
}
