import { Avatar } from "antd";
import React from "react";

import { TelematicsEmptyView } from "../../organisms/TelematicsEmptyView";
import { useSimpleSWRFetch } from "../../utils/rest";
import { Map } from "./Fleet/FleetMap";

export default function FleetView() {
  const { error, data, isValidating } = useSimpleSWRFetch("locations/last");

  if (error) {
    return null;
  }

  if (data) {
    if (data.length === 0 && !isValidating) {
      return <TelematicsEmptyView />;
    }
  }

  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <div className="grid-header">
        <h1 style={{ color: "#397872" }}>
          <Avatar
            size={40}
            src={"https://static.inmatics.io/icons/marker.svg"}
            className={"mr-1"}
          />
          {"Mapa de flota"}
        </h1>
      </div>
      <div>
        <Map entries={data || []} />
      </div>
    </div>
  );
}
