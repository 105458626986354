import { Col, Row } from "antd";
import React from "react";
import CommentCard from "src/routes/Services/CommentCard";
import DocumentsCard from "src/routes/Services/DocumentsCard";
import FilesCard from "src/routes/Services/FilesCard";

import { Vehicle } from "../../../types";
import SummaryMap from "../SummaryMap/SummaryMap";
import VehicleDetails from "./VehicleDetails";
import VehicleSummaryCollections from "./VehicleSummaryCollections";

export default function VehicleSummary({ vehicle }: { vehicle: Vehicle }) {
  if (!vehicle) {
    return null;
  }

  //Only VADE SRL or  is using collections
  // const shouldDisplayCollections = vehicle.account_id === "1";

  return (
    <Row>
      <SummaryMap vehicle={vehicle} />

      <Col span={9} className={"pb-1 pr-1"}>
        <div className={"pb-1"}>
          <CommentCard
            commentableId={vehicle.id}
            commentableType={"vehicleId"}
          />
        </div>
        <div className={"pb-1"}>
          <DocumentsCard
            fillableId={vehicle.id || ""}
            fillableType={"vehicleId"}
          />
        </div>
        <FilesCard fillableId={vehicle.id || ""} fillableType={"vehicleId"} />
      </Col>
      <Col className={"pb-1 pr-1"}>
        <VehicleDetails vehicle={vehicle} />
      </Col>
      <Col className={"pb-1 pr-1"}>
        <VehicleSummaryCollections vehicleId={vehicle.id} />
      </Col>
    </Row>
  );
}
