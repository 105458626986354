import { DollarOutlined } from "@ant-design/icons";
import { Card } from "antd";
import React from "react";
import { Link } from "react-router-dom";

import { FIELD_TYPES } from "../../constants";
import format from "../../utils/format";
import RenderIgnition from "../Vehicles/RenderIgnition";
import {useSimpleSWRFetch} from "../../utils/rest";

export default function DashboardLasLocations() {
  const { error, data} = useSimpleSWRFetch("locations/last");

  let titleText = "Últimas ubicaciones";
  const title = (
    <div>
      <DollarOutlined className={"muted mr-2"} />
      {titleText}
    </div>
  );

  if (error) {
    return null;
  }

  if (!data) {
    return null;
  }


  return (
    <Card title={title} className={"mb-3"}>
      {data.map((x: any) => (
        <div
          style={{ display: "flex", justifyContent: "space-between" }}
          key={x.id}
        >
          <div style={{ display: "flex" }}>
            <div style={{ marginBottom: 16 }}>
              <p style={{ marginBottom: 0 }}>
                <Link
                  to={{
                    pathname: `/${x.account_id}/vehicles/${x.vehicle_id}/resumen`,
                    state: { menuKey: ["vehiculos"] },
                  }}
                  className={"mr-1"}
                >
                  {x.vehicle_name}
                </Link>
                <RenderIgnition status={x.ignition} />
                <small>{x.speed} km/h</small>
              </p>
              <small style={{ fontSize: "70%" }}>{x.address}</small>
            </div>
          </div>

          <div style={{ minWidth: 80 }}>
            <small>({format(x.timestamp, FIELD_TYPES.RELATIVE_TIME)})</small>
          </div>
        </div>
      ))}
    </Card>
  );
}
