import * as PropTypes from "prop-types";
import React from "react";

import Breadcrumb from "../../components/Breadcrumb";

export function InsuranceBreadcrumb(props: { accountID: string; label: any }) {
  return (
    <div className="entity-header mb-3">
      <Breadcrumb
        routes={[
          {
            to: "/" + props.accountID + "/seguros",
            label: "Pólizas de seguro",
          },
          { label: props.label },
        ]}
      />
    </div>
  );
}

InsuranceBreadcrumb.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.string,
  }),
  label: PropTypes.string,
};
