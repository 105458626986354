import useSWR from "swr";
import { get } from "../api";

export function useTasks() {
  return useSWR(
    "task-types?sortBy=name+asc",
    () => get("task-types?sortBy=name+asc"),
    {
      initialData: [],
      revalidateOnMount: true,
    }
  );
}
