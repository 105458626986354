import { EditOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";

import { Vehicle } from "../types";
import CarSVG from "./CarSVG";
import DriverSelector from "./DriverSelector/DriverSelector";
// import OdometerUpdate from "./OdometerUpdate";
import StatusSelector from "./StatusSelector";
import VehicleHeaderBreadcrumb from "./VehicleHeaderBreadcrumb";

type VehicleHeaderProps = {
  vehicle: Vehicle;
  pathname: string;
  accountId: string;
  entity: Vehicle;
  onStatusSubmit: (status: string) => void;
  onReadingSubmit: (value: number, date: string) => void;
};
export default ({
  vehicle,
  accountId,
  entity,
  pathname,
  onStatusSubmit,
}: // onReadingSubmit,
VehicleHeaderProps) => {
  return (
    <div>
      <div className="entity-header">
        <VehicleHeaderBreadcrumb
          entity={entity}
          pathname={pathname}
          accountId={accountId}
        />
        <div className="entity-header__buttons">
          <Link
            to={{
              pathname: `/${accountId}/vehicles/${vehicle.id}/edit`,
              state: { menuKey: ["vehiculos"] },
            }}
          >
            <Button type={"dashed"}>
              <EditOutlined /> EDITAR VEHÍCULO
            </Button>
          </Link>
        </div>
      </div>
      <div className="vehicle-header">
        <div style={{ marginRight: 20, display: "flex", alignItems: "center" }}>
          <CarSVG vehicle={vehicle} />
        </div>
        <div className={"vehicle-info"}>
          <h1>{vehicle.name}</h1>
          <h2>
            {vehicle.year} {vehicle.brand} {vehicle.model} · {vehicle.vin} ·{" "}
            {vehicle.license_plate}
          </h2>
          <div className={"vehicle-info-status"}>
            {/*<OdometerUpdate*/}
            {/*  vehicle={entity}*/}
            {/*  onReadingSubmit={onReadingSubmit}*/}
            {/*/>*/}
            <StatusSelector vehicle={entity} onStatusSubmit={onStatusSubmit} />
            <DriverSelector accountId={accountId} vehicle={entity} />
          </div>
        </div>
      </div>
    </div>
  );
};
