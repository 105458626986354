import React, { useState } from "react";
import { parse } from "papaparse";
import Breadcrumb from "../../components/Breadcrumb";
import { IWithAccountId } from "../../types";
import moment from "moment/moment";
import { post } from "../../api";
import { Table } from "antd";

const getFloat = function (str: string) {
  const parts = str.split(",");
  //Fix lint, no se si anda, igual los forros de Masor no estan usando lo de Premium
  const re = new RegExp(".");
  parts[0] = parts[0].replace(re, "");
  return parseFloat(parts.join("."));
};

function useRecursivePosts(onFinish?: () => void) {
  const [inserts, setInserts] = useState([]);

  async function postRecursively(data: any[]) {
    let item = data[0];
    if (!item) {
      onFinish && onFinish();
      return;
    }

    try {
      const response = await post("vehicles/search", {
        name: item.movil,
      });

      if (response.count === 1) {
        let vehicle = response.rows[0];

        await post("transactions", {
          vehicleId: vehicle.id,
          contactId: vehicle.driverId,
          amount: item.amount,
          reference: item.reference,
          date: item.date,
          //Si el tipo de pago es tarjeta o cuenta corriente.
          transactionTypeId: item.transactionTypeId,
        });
        // @ts-ignore
        setInserts((prevState: any[]) => [
          ...prevState,
          { result: "OK", ...item },
        ]);
      } else {
        // @ts-ignore
        setInserts((prevState: any[]) => [
          ...prevState,
          { result: "CONDUCTOR-NO-ENCONTRADO", ...item },
        ]);
      }
    } catch (e) {
      // @ts-ignore
      setInserts((prevState) => [...prevState, { result: "ERROR", ...item }]);
    } finally {
      data.shift();
      await postRecursively(data);
    }
  }

  return { inserts, postRecursively };
}

const transform = (rows: any[]) => {
  return rows.map((payment: any) => {
    let importe = payment["Importe"];
    let fecha = payment["Fecha"];
    let voucher = payment["Numero Voucher"];

    let esTarjeta =
      payment["Nombre de Cliente"] &&
      payment["Nombre de Cliente"].includes("TARJETA DE CREDITO - PAYU");
    console.log(payment["Nombre de Cliente"]);
    console.log(esTarjeta);

    return {
      amount: importe && getFloat(importe),
      date: new Date(moment(fecha, "DD/MM/YYYY").format("YYYY-MM-DD")),
      fecha,
      reference: voucher,
      movil: payment.Movil,
      transactionTypeId: esTarjeta ? 95 : 96,
    };
  });
};

function PremiumInsert(props: IWithAccountId) {
  const { match } = props;
  const { accountId } = match.params;

  const { postRecursively, inserts } = useRecursivePosts();

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    let reader = new FileReader();
    const files = e.target.files;
    if (files && files.length > 0) {
      reader.onload = (_event) => {
        // @ts-ignore
        let newVar = parse(reader.result, { header: true });

        let data = transform(newVar.data);
        // @ts-ignore

        postRecursively(data);
      };

      reader.readAsText(files[0], "UTF-8");
    }
  };

  const routes = [
    { to: "/" + accountId + "imports", label: "Importaciones" },
    { label: "Importar datos" },
  ];

  const columns = [
    { title: "result", dataIndex: "result" },
    { title: "Monto", dataIndex: "amount" },
    { title: "Chofer", dataIndex: "driverName" },
    { title: "Referencia", dataIndex: "reference" },
    { title: "DNI", dataIndex: "driverDNI" },
    { title: "Fecha", dataIndex: "fecha" },
  ];

  return (
    <div>
      <div className="p-3">
        <Breadcrumb routes={routes} />
      </div>
      <div className={"p-3"}>
        <h1>Paso 1: Seleccione un archivo para importar</h1>
        <div>
          <input
            accept=".csv, text/csv"
            type="file"
            style={{ marginBottom: 20 }}
            onChange={handleChangeFile}
          />
        </div>

        <p>
          {inserts.length > 0 &&
            "Registros insertados: " +
              inserts.filter((x: any) => x.result === "OK").length}
        </p>
        <p>
          {inserts.length > 0 &&
            "Registros fallados: " +
              inserts.filter((x: any) => x.result !== "OK").length}
        </p>
        <Table
          columns={columns}
          size={"small"}
          pagination={false}
          dataSource={inserts}
        />
      </div>
    </div>
  );
}

export default PremiumInsert;
