import React from "react";
import format from "../../utils/format";
import { FIELD_TYPES } from "../../constants";
import Duration from "../../components/Duration";
import { Descriptions } from "antd";

export default function TripDetails({ entity }: any) {
  let locationEntries = entity?.location_entries;
  const lastLocation = locationEntries[0];
  const firstLocationEntry = locationEntries[locationEntries.length - 1];
  let createdAt = firstLocationEntry.created_at;
  let finishedAt = lastLocation?.created_at;
  return (
    <div>
      <Descriptions>
        <Descriptions.Item label="Hora inicio">
          <b>{format(createdAt, FIELD_TYPES.TIME)}</b>
        </Descriptions.Item>
        <Descriptions.Item label="Hora fin">
          <b>
            {lastLocation.ignition === 0
              ? format(finishedAt, FIELD_TYPES.HOUR)
              : "-"}
          </b>
        </Descriptions.Item>
        <Descriptions.Item label="Duración">
          <b>
            <Duration startDate={createdAt} finishDate={finishedAt} />
          </b>
        </Descriptions.Item>
      </Descriptions>

      <Descriptions>
        <Descriptions.Item label="Dirección inicial">
          <b>{firstLocationEntry.address}</b>
        </Descriptions.Item>
      </Descriptions>

      <Descriptions>
        <Descriptions.Item label="Dirección final">
          <b>{lastLocation.address}</b>
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
}
