import React, { useState } from "react";
import { parse } from "papaparse";
import Breadcrumb from "../../components/Breadcrumb";
import { IWithAccountId } from "../../types";
import moment from "moment/moment";
import { post } from "../../api";
import { Table } from "antd";

function useRecursivePosts(onFinish?: () => void) {
  const [inserts, setInserts] = useState([]);

  async function postRecursively(data: any[]) {
    let item = data[0];
    if (!item) {
      onFinish && onFinish();
      return;
    }

    try {
      const response = await post("contacts/search", {
        documentNumber: item.driverDNI,
      });

      if (response.count === 1) {
        let contact = response.rows[0];

        await post("transactions", {
          contactId: contact.id,
          vehicleId: contact.vehicleId,
          amount: item.amount,
          reference: item.reference,
          date: item.date,
          transactionTypeId: 45,
        });
        // @ts-ignore
        setInserts((prevState: any[]) => [
          ...prevState,
          { result: "OK", ...item },
        ]);
      } else {
        // @ts-ignore
        setInserts((prevState: any[]) => [
          ...prevState,
          { result: "CONDUCTOR-NO-ENCONTRADO", ...item },
        ]);
      }
    } catch (e) {
      // @ts-ignore
      setInserts((prevState) => [...prevState, { result: "ERROR", ...item }]);
    } finally {
      data.shift();
      await postRecursively(data);
    }
  }

  return { inserts, postRecursively };
}

const transform = (rows: any[]) => {
  return rows.map((payment: any) => {
    //Va con acento
    let identificacion = payment["Identificacion del cliente"];
    let importe = payment["Importe"];
    //Va con acento
    let fecha = payment["Deposito"];
    return {
      amount: Number(
        importe.substring(0, importe.indexOf(",")).replace(".", "")
      ),
      date: new Date(moment(fecha, "DD/MM/YYYY").format("YYYY-MM-DD")),
      fecha,
      galiciaBranch: payment["Sucursal"].trim(),
      driverDNI: identificacion.slice(0, 8),
      driverName: identificacion.slice(9, identificacion.length),
      reference: payment["Id. pago"],
    };
  });
};

function GaliciaInsert(props: IWithAccountId) {
  const { match } = props;
  const { accountId } = match.params;

  const { postRecursively, inserts } = useRecursivePosts();

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    let reader = new FileReader();
    const files = e.target.files;
    if (files && files.length > 0) {
      reader.onload = (_event) => {
        // @ts-ignore
        let newVar = parse(reader.result, { header: true });

        let data = transform(newVar.data);
        // @ts-ignore

        postRecursively(data);
      };

      reader.readAsText(files[0], "UTF-8");
    }
  };

  const routes = [
    { to: "/" + accountId + "imports", label: "Importaciones" },
    { label: "Importar datos" },
  ];

  const columns = [
    { title: "result", dataIndex: "result" },
    { title: "Monto", dataIndex: "amount" },
    { title: "Chofer", dataIndex: "driverName" },
    { title: "Referencia", dataIndex: "reference" },
    { title: "DNI", dataIndex: "driverDNI" },
    { title: "Fecha", dataIndex: "fecha" },
  ];

  return (
    <div>
      <div className="p-3">
        <Breadcrumb routes={routes} />
      </div>
      <div className={"p-3"}>
        <h1>Paso 1: Seleccione un archivo para importar</h1>
        <div>
          <input
            accept=".csv, text/csv"
            type="file"
            style={{ marginBottom: 20 }}
            onChange={handleChangeFile}
          />
        </div>

        <p>
          {inserts.length > 0 &&
            "Registros insertados: " +
              inserts.filter((x: any) => x.result === "OK").length}
        </p>
        <p>
          {inserts.length > 0 &&
            "Registros fallados: " +
              inserts.filter((x: any) => x.result !== "OK").length}
        </p>
        <Table
          columns={columns}
          size={"small"}
          pagination={false}
          dataSource={inserts}
        />
      </div>
    </div>
  );
}

export default GaliciaInsert;
