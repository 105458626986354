import { EditOutlined, WhatsAppOutlined } from "@ant-design/icons";
import { Alert, Avatar, Button, Col, Menu, Row } from "antd";
import React from "react";
import { Link, Redirect, Route, Switch } from "react-router-dom";

import Breadcrumb from "../../components/Breadcrumb";
import { useEntity } from "../../hooks/useEntity";
import { IWithAccountId } from "../../types";
import AssignmentsTable from "./AssignmentsTable";
import CollectionsTable from "./CollectionsTable";

export default function ContactView(props: IWithAccountId) {
  const { match, location } = props;
  const { entityId, accountId } = match.params;
  const { state } = useEntity(entityId, `contacts`);

  const { fetching, entity, asyncError } = state;

  if (fetching) {
    return null;
  }

  if (asyncError) {
    return (
      <Alert
        className={"mb-4"}
        description={
          <ul>
            <li>{asyncError.message}</li>
          </ul>
        }
        message={"Solucione los siguientes errores"}
        type="error"
        showIcon
      />
    );
  }

  const fullName = entity.firstName + " " + entity.lastName;

  const routes = [
    { to: `/${accountId}/contacts`, label: "Conductores" },
    { label: fullName },
  ];

  const vehicleId = entity.vehicleAssignments[0]?.vehicle?.id;
  return (
    <div>
      <div className="entity-header">
        <Breadcrumb routes={routes} />
        <div className="entity-header__buttons">
          <Link
            to={{
              pathname: `/${accountId}/contacts/${entity.id}/edit`,
              state: { menuKey: ["conductores"] },
            }}
          >
            <Button type={"dashed"}>
              <EditOutlined /> EDITAR CONDUCTOR
            </Button>
          </Link>
        </div>
      </div>
      <div className="vehicle-header">
        <div style={{ marginRight: 20, display: "flex", alignItems: "center" }}>
          <Avatar size={90} src={entity.profileImageUrl}>
            {(entity.firstName[0] + entity.lastName[0]).toUpperCase()}
          </Avatar>
        </div>
        <div className={"vehicle-info"}>
          <h1>{fullName}</h1>
          <div className={"vehicle-info-status"}>
            <div>
              {entity.phoneNumber && (
                <p>
                  {(
                    <span>
                      <a
                        target={"_blank"}
                        rel="noopener noreferrer"
                        href={`https://api.whatsapp.com/send?phone=${entity.phoneNumber}`}
                      >
                        <WhatsAppOutlined /> {entity.phoneNumber}
                      </a>
                    </span>
                  ) || "-"}
                </p>
              )}
              <p>DNI: {entity.documentNumber || "-"}</p>
            </div>
            {entity.vehicleAssignments.length > 0 &&
              entity.vehicleAssignments.map((x: any) => (
                <div className={"ml-3"}>
                  Vehículo asignado:{" "}
                  <Link to={`/${accountId}/vehicles/${x.vehicle.id}`}>
                    {x.vehicle.name}
                  </Link>{" "}
                  <span style={{ color: x.vehicle.status.color }}>
                    &#9673;{" "}
                  </span>{" "}
                  {x.vehicle.status.name}
                </div>
              ))}
          </div>
        </div>
      </div>
      <Row className="vehicle-body">
        <Col md={4} className="vehicle-side-menu">
          {
            <Link
              to={`/${accountId}/collections/create?vehicleId=${vehicleId}`}
            >
              <Button
                style={{ width: "100%", marginBottom: "10px" }}
                type="primary"
              >
                Cargar cobranza
              </Button>
            </Link>
          }
          <Menu selectedKeys={[location.pathname]}>
            <Menu.Divider />
            <Menu.Item key={match.url + "/collections"}>
              <Link
                to={{
                  pathname: match.url + "/collections",
                  state: { menuKey: ["conductores"] },
                }}
              >
                Cobranzas
              </Link>
              <small style={{ float: "right" }}>
                {entity["transactionCount"]}
              </small>
            </Menu.Item>
            <Menu.Item key={match.url + "/asignaciones"}>
              <Link
                to={{
                  pathname: match.url + "/asignaciones",
                  state: { menuKey: ["conductores"] },
                }}
              >
                Asignaciones
              </Link>
              <small style={{ float: "right" }}>
                {entity["transactionCount"]}
              </small>
            </Menu.Item>
          </Menu>
        </Col>
        <Col span={20}>
          <Switch>
            <Route
              component={CollectionsTable}
              path={match.path + "/collections"}
              exact
            />
            <Route
              component={AssignmentsTable}
              path={match.path + "/asignaciones"}
            />
            <Redirect
              to={{
                pathname: match.url + "/collections",
                state: { menuKey: ["conductores", "collections-vehicle"] },
              }}
            />
          </Switch>
        </Col>
      </Row>
    </div>
  );
}
