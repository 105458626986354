import { Form } from "antd";
import React, { useContext } from "react";

import { AccountContext } from "../../hooks/useAccount";
import { useLegacyVehicles } from "../../hooks/useLegacyVehicles";
import { useOnSubmit } from "../../hooks/useOnSubmit";
import { IAccount, IInsurance, IWithAccountId } from "../../types";
import { InsuranceBreadcrumb } from "./InsuranceBreadcrumb";
import { InsuranceForm } from "./InsuranceForm";

export default function CreateInsurance({ history }: IWithAccountId) {
  const vehicles = useLegacyVehicles();
  const account: IAccount = useContext(AccountContext);
  const [form] = Form.useForm();

  const onSuccess = (data: IInsurance) => {
    history.push(
      `/${account.id}/vehicles/${data.vehicle_id}/seguros/${data.id}`
    );
  };

  const { onSubmit, submitting, error } = useOnSubmit(
    `insurances`,
    "v3",
    onSuccess
  );

  return (
    <div style={{ height: "100vh" }}>
      <div className="entity-header mb-3">
        <InsuranceBreadcrumb label={"Registrar"} accountID={account.id || ""} />
      </div>

      <InsuranceForm
        error={error}
        form={form}
        loading={Boolean(submitting)}
        onCancel={() => history.goBack()}
        onFinish={onSubmit}
        title={`Registrar una póliza de seguro nueva`}
        vehicles={vehicles}
      />
    </div>
  );
}
