import React from "react";
import { Button, Card, Col, Row } from "antd";
import { Link } from "react-router-dom";

export default function EmptyView(props) {
  const {vehicleId, accountId} = props

  let style = {
    minHeight: 300,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  };

  let p = `Registre rápidamente el mantenimiento preventivo y las reparaciones. Mantenga registros de servicio detallados por vehículo.`;

  let to = vehicleId ? `/${(accountId)}/services/create?vehicleId=${vehicleId}` : `/${(accountId)}/services/create`;
  return (
    <Row style={{ minHeight: 1000, paddingTop: 150 }}>
      <Col span={12} offset={6}>
        <Card>
          <div style={style}>
            <img
              src="https://static.inmatics.io/icons/service-history.png"
              alt="Empty service icon"
              height={100}
              width={100}
            />
            <h2>Historial de servicios</h2>
            <p style={{ maxWidth: "80%" }}>{p}</p>
            <div style={{ display: "inline" }}>
              <Link to={to}>
                {" "}
                <Button type={"primary"}>Crear un servicio nuevo</Button>
              </Link>
            </div>
          </div>
        </Card>
      </Col>
    </Row>
  );
}
