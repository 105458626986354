import { DeleteOutlined, EditOutlined, MoreOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, Tooltip } from "antd";
import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { AccountContext } from "../../hooks/useAccount";
import { IAccount } from "../../types";

export function ActionsMenu(props: {
  record: any;
  onDelete: (id: string, name: any) => void;
}) {
  const account: IAccount = useContext(AccountContext);

  const { record, onDelete } = props;
  const stopPropagation = (e: any) => {
    e.stopPropagation();
  };
  const onClick = (e: any) => {
    onDelete(record.id, record.id);
    e.stopPropagation();
  };
  return (
    <Dropdown
      trigger={["click"]}
      // @ts-ignore
      onClick={stopPropagation}
      overlay={
        <Menu>
          <Menu.Item key="0">
            <Link
              to={`/${account.id}/seguros/${record.id}`}
              onClick={stopPropagation}
            >
              <Button type={"link"}>
                <EditOutlined style={{ color: "#AAAAAA" }} /> Editar
              </Button>
            </Link>
          </Menu.Item>
          <Menu.Item onClick={stopPropagation}>
            <Button type={"link"} onClick={onClick}>
              <DeleteOutlined style={{ color: "#AAAAAA" }} /> Remover
            </Button>
          </Menu.Item>
        </Menu>
      }
    >
      <Tooltip title={"Acciones"} placement={"left"}>
        <Button
          onClick={(e) => {
            e.stopPropagation();
          }}
          icon={<MoreOutlined />}
        />
      </Tooltip>
    </Dropdown>
  );
}
