import { useContext, useState } from "react";
import { post } from "../../helpers/v2api";
import { parseError } from "../../helpers";
import { useHistory } from "react-router";
import { AccountContext } from "../../hooks/useAccount";

export const useOnSubmit = (endPoint) => {
  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const account = useContext(AccountContext);
  const history = useHistory();

  const onFinish = async (values) => {
    try {
      setSubmitting(true);
      await post(endPoint, values);

      history.push(`/${account.id}/lugares`);
      setSubmitting(false);
    } catch (err) {
      setSubmitting(false);
      setError(parseError(err));
    }
  };

  return [onFinish, submitting, error];
};
