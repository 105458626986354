import React, { useState } from "react";
import {
  Button,
  Card,
  Dropdown,
  Menu,
  message,
  Modal,
  Table,
  Tooltip,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import Breadcrumb from "../../components/Breadcrumb";
import { get, remove } from "../../api";
import useSWR from "swr";
import TransactionTypeForm from "./TransactionTypeForm";
import { Link } from "react-router-dom";

function StatusModal({ trigger, entityId, mutate, category }) {
  const [visible, setVisible] = useState(false);
  const title = entityId
    ? "Editar tipo de gasto."
    : "Agregar un tipo de gasto nuevo.";

  const close = () => {
    mutate();
    setVisible(false);
  };

  return (
    <div>
      <div onClick={() => setVisible(true)}>{trigger}</div>
      <Modal
        visible={visible}
        title={title}
        forceRender
        footer={null}
        onCancel={close}
      >
        <TransactionTypeForm
          onSuccess={close}
          category={category}
          entityId={entityId}
        />
      </Modal>
    </div>
  );
}

const useSWRWithGet = (url) =>
  useSWR(url, () => get(url), { initialData: [], revalidateOnMount: true });

function PreferencesTransactionsTypes({ match }) {
  const title = "Tipo de cobranzas";
  const deleteWarning =
    "Para poder borrar este tipo de cobranza, no puede haber ninguna cobranza de este tipo.";
  const addButtonLabel = "Agregar un tipo de cobranza nueva";

  let label = title;
  const endPoint = "transaction-types";
  const { data, error, mutate } = useSWRWithGet(
    `transaction-types?sort=name+asc`
  );

  const confirm = async (record) => {
    try {
      await remove(endPoint, record.id);
      mutate();
      message.success(`${record.name} removido`);
    } catch (err) {
      console.log(err);
    }
  };

  const menu = (record) => (
    <Menu>
      <Menu.Item>
        <StatusModal
          entityId={record.id}
          mutate={mutate}
          trigger={
            <Button
              type={"link"}
              icon={<EditOutlined style={{ color: "#AAAAAA" }} />}
            >
              Editar
            </Button>
          }
        />
      </Menu.Item>
      <Menu.Item>
        {record.transactionCount !== 0 ? (
          <Tooltip title={deleteWarning}>
            <Button type={"link"} disabled>
              <DeleteOutlined style={{ color: "#AAAAAA" }} /> Remover
            </Button>
          </Tooltip>
        ) : (
          <Button type={"link"} onClick={() => confirm(record)}>
            <DeleteOutlined style={{ color: "#AAAAAA" }} /> Remover
          </Button>
        )}
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: "Nombre",
      dataIndex: "name",
    },
    {
      title: "Uso",
      dataIndex: "transactionCount",
      render: (text, record) => (
        <Link
          to={`/${match.params.accountId}/cobranzas?transactionTypeId=${record.id}`}
        >
          {text} cobranzas
        </Link>
      ),
    },
    {
      title: "Acciones",
      dataIndex: "id",
      width: 90,
      render: (id, record) => (
        <div className="justify-center" key={id}>
          <Tooltip title="Acciones" placement="left">
            <Dropdown
              trigger={"click"}
              overlay={menu(record)}
              placement="bottomLeft"
            >
              <Button
                icon={
                  <EllipsisOutlined style={{ transform: "rotate(90deg)" }} />
                }
              />
            </Dropdown>
          </Tooltip>
        </div>
      ),
    },
  ];

  const routes = [
    { to: "/preferencias", label: "Preferencias" },
    { label: label },
  ];

  return (
    <Card
      title={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Breadcrumb routes={routes} />
          <StatusModal
            mutate={mutate}
            trigger={
              <Button icon={<PlusOutlined />} type={"primary"}>
                {addButtonLabel}
              </Button>
            }
          />
        </div>
      }
    >
      {JSON.stringify(error)}
      <Table
        dataSource={data}
        rowKey={(record) => record.id}
        columns={columns}
        pagination={false}
        size={"small"}
        bordered
      />
    </Card>
  );
}

export default PreferencesTransactionsTypes;
