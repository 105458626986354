import { ArrowDownOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Avatar, Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";

type HeaderProps = {
  onDownload: () => Promise<void>;
  iconURL: string;
  title: string;
  addLink: any;
  addText: string;
};

export function TableHeader(props: HeaderProps) {
  const { iconURL, title, addLink, onDownload, addText } = props;

  return (
    <div
      style={{
        background: "#FAFAFA",
        color: "#397872",
      }}
      className={"justify-flex-between p-3"}
    >
      <div>
        {" "}
        <Avatar size={32} src={iconURL} />{" "}
        <span style={{ paddingLeft: 8, fontSize: 20 }}>{title}</span>
      </div>
      <div>
        <Button onClick={onDownload} icon={<ArrowDownOutlined />}>
          Descargar
        </Button>
        <Link to={addLink}>
          <Button
            type={"primary"}
            className={"ml-1"}
            icon={<PlusCircleOutlined />}
          >
            {addText}
          </Button>
        </Link>
      </div>
    </div>
  );
}
