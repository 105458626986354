import React, {useEffect, useState} from "react"
import {get} from "../helpers/v2api";
import {Button, Table} from "antd";
import {formatNumber} from "../utils/format";
import moment from "moment";
import {ArrowDownOutlined} from "@ant-design/icons";
import {newApi, useSimpleSWRFetch} from "../utils/rest";

const columns = [
// @ts-ignore
    {dataIndex: "vehicle_id", title: "Vehículo", render: (_, record) => record.vehicle_name},

    {
// @ts-ignore
        dataIndex: "date", title: "Fecha", render: x => x.substring(0,10)},
    {
        dataIndex: "distance",
        title: "Distancia",
// @ts-ignore
        render: x => formatNumber(x/1000, 1) + "km."
    },
// @ts-ignore
    {dataIndex: "speed_max", title: "Velocidad máxima", render: x => formatNumber(x, 0) + "km/h"},
// @ts-ignore
    {dataIndex: "speed_avg", title: "Velocidad promedio", render: x => formatNumber(x, 0) + "km/h"},
];

async function download() {
    try {
        let response = await newApi().v3.get("reports/locations/excel", {
            responseType: "arraybuffer",
        });
        const data = response.data;

        const fileName = `${"daily_report"}_${moment().format("YYYY-MM-DD")}.xlsx`;

        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
    } catch (e) {
        alert("Error downloading");
    }
}


export function DailyLocationsReport() {
    const { data, error } = useSimpleSWRFetch("reports/locations");

    if (error) return null

    if (data) {
        return <div className={"p-3"}>
            <div className={"p-3"}>
                <div className={"mb-3"}>
                    <Button type={"default"} icon={<ArrowDownOutlined />} onClick={download}>Descargar</Button>
                </div>

                <Table
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                     />
            </div>

        </div>
    }

    return <div>Reporte de ubicaciones</div>;
}