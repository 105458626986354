import { Badge, Card } from "antd";
import React from "react";

import { post } from "../../api";
import AddComment from "../../components/AddComment";
import Comment from "../../components/CommentView";
import { useDataApi } from "../../hooks/useDataApi";

export default function CommentsCard({ commentableId, commentableType }) {
  const [state] = useDataApi(`comments?${commentableType}=${commentableId}`);
  const { isLoading, data } = state;

  const addComment = async (text) => {
    await post(`comments`, { text, [commentableType]: commentableId });
    window.location.reload();
  };

  if (isLoading) {
    return <p>loading...</p>;
  }

  return (
    <Card
      title={
        <div className="card-title">
          <div>
            Comentarios
            <Badge showZero count={data.length} />
          </div>
          <div>
            <AddComment onModalSubmit={addComment} />
          </div>
        </div>
      }
    >
      <div>
        {data.map((c) => (
          <Comment key={c.id} comment={c} />
        ))}
      </div>
    </Card>
  );
}
