import { CarOutlined } from "@ant-design/icons";
import { Table } from "antd";
import React from "react";
import EmtpyView from "src/components/WelcomeView";
import {
  getOnDelete,
  getOnTableChange,
  shouldRenderEmptyView,
} from "src/helpers";
import { getURI } from "src/helpers/GetURI";
import { IWithAccountId, QueryState } from "src/types";
import { download } from "src/utils/download";
import { useSWRFetch } from "src/utils/rest";

import { getQueryFromURL } from "../../../utils/utils";
import { TableFilters } from "./VehiclesTableFilters";
import { TableHeader } from "./VehiclesTableHeader";
import { getColumns } from "./getColumns";

const endPoint = "vehicles";
const emptyViewDescription = `Para comenzar a administrar el mantenimiento preventivo, las reparaciones y los registros de servicio por cada unidad, ingresá los datos de sus vehículos.`;
const emptyViewHeader = "Tu flota no tiene vehículos";
const callToActionText = "Crear un vehículo nuevo";

export default function VehiclesList(props: IWithAccountId) {
  const { history, match } = props;
  const { params } = match;

  const urlState = getQueryFromURL(props.location.search);

  const updateURLState = (newState: QueryState) => {
    history.push({
      pathname: props.location.pathname,
      search: getURI("", newState),
      state: { menuKey: ["vehiculos"] },
    });
  };

  const onChange = (payload: object) => {
    updateURLState({
      ...urlState,
      filters: { ...urlState.filters, ...payload },
    });
  };

  const clearFilters = () => {
    updateURLState({ ...urlState, filters: {} });
  };

  const setPage = (page: number) => {
    updateURLState({ ...urlState, page });
  };

  const setSort = (sort: string) => {
    updateURLState({ ...urlState, sort });
  };

  const { isValidating, error, data, mutate } = useSWRFetch(
    getURI(endPoint, urlState)
  );

  const accountID = params.accountId;
  const columns = getColumns(getOnDelete(endPoint, mutate));

  if (error) {
    return <div>{JSON.stringify(error)}</div>;
  }

  if (shouldRenderEmptyView(data, isValidating, urlState.filters)) {
    return (
      <EmtpyView
        accountId={accountID}
        entity={"vehicles"}
        description={emptyViewDescription}
        h2Text={emptyViewHeader}
        buttonText={callToActionText}
        icon={<CarOutlined style={{ fontSize: 100, width: 100 }} />}
      />
    );
  }

  const onRow = (record: any) => {
    return {
      onClick: () =>
        history.push({
          pathname: `/${record.account_id}/vehicles/${record.id}/resumen`,
          state: { menuKey: ["vehiculos", "summary-vehicle"] },
        }),
    };
  };

  return (
    <div>
      <TableHeader
        addText={"Nuevo vehículo"}
        iconURL={"https://static.inmatics.io/icons/marker.svg"}
        onDownload={() => download("vehicles/download", `listado_vehiculos`)}
        title={"Listado de vehículos"}
        addLink={{
          pathname: `/${accountID}/vehicles/create`,
          state: { menuKey: ["vehiculos"] },
        }}
      />
      <TableFilters
        clearFilters={clearFilters}
        columns={columns}
        onChange={onChange}
        queryState={urlState}
        setPage={setPage}
        totalCount={data?.count}
      />
      <Table
        onRow={onRow}
        dataSource={data?.data}
        rowClassName={"show-pointer"}
        onChange={getOnTableChange(setSort)}
        columns={columns}
        pagination={false}
        size={"small"}
        rowKey={(record) => record.id}
      />
    </div>
  );
}
