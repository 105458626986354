//npm
import { Button, Col, Form, Row, notification } from "antd";
import React from "react";

import { patch } from "../../api";
//inmatics
import Breadcrumb from "../../components/Breadcrumb";
import PartsSelector from "../../components/PartsSelector";
import ServiceDetails from "../../components/ServiceDetails";
import TaskSelector from "../../components/TaskSelector";
import { defaultLayout } from "../../constants";
import { useEntity } from "../../hooks/useEntity";

export default function Entity({ match, history }) {
  const { serviceId, accountId } = match.params;
  const { onChange, state } = useEntity(serviceId, `services`);
  const { fetching, entity } = state;

  const onSubmit = async () => {
    await patch(`services`, entity, serviceId);
    history.push({
      pathname: `/${accountId}/vehicles/${entity.vehicleId}/services/${serviceId}`,
      state: { menuKey: ["vehiculos", "services-vehicle"] },
    });
  };

  const onAddTask = (e) => {
    if (entity.tasks.find((task) => task.taskTypeId === e)) {
      notification.error({ message: "Tarea repetida, no se agrego." });
      return;
    }

    onChange("tasks", [...entity.tasks, { taskTypeId: e }]);
    notification.success({ message: "Se agrego una tarea al servicio." });
  };

  const onRemoveTask = (e) => {
    onChange("tasks", [...entity.tasks.filter((x) => e !== x)]);
    notification.success({ message: "Se elimino la tarea" });
  };

  const onAddPart = (e) => {
    const message = "Autoparte repetida, no se agrego.";
    const message2 = "Se agrego una tarea al servicio.";

    if (entity.parts.find((part) => part["partTypeId"] === e)) {
      notification.error({ message: message });
      return;
    }

    onChange("parts", [...entity["parts"], { partTypeId: e, qty: 1 }]);
    notification.success({ message: message2 });
  };

  const onRowChange = (key, value, record) => {
    const id = "partTypeId";

    onChange("parts", [
      ...entity["parts"].filter((x) => x[id] !== record[id]),
      { [id]: record[id], [key]: value },
    ]);
  };

  const onRemovePart = (e) => {
    onChange("parts", [...entity.parts.filter((x) => e !== x)]);
    notification.success({ message: "Se elimino la autoparte" });
  };

  if (fetching) {
    return <p>loading...</p>;
  }

  return (
    <div className={"mb-5"}>
      <div className="entity-header mb-3">
        <Breadcrumb
          routes={[
            { to: "/" + accountId + "/services", label: "Servicios" },
            { label: "Editar servicio" },
          ]}
        />
        <div className="entity-header__buttons">
          <Button
            className="mr-2"
            style={{ width: 150 }}
            type="secondary"
            onClick={() => history.goBack()}
          >
            CANCELAR
          </Button>
          <Button type="primary" onClick={onSubmit}>
            GUARDAR SERVICIO
          </Button>
        </div>
      </div>
      <Row>
        <Col {...defaultLayout}>
          <Form layout={"vertical"} onFinish={onSubmit}>
            <ServiceDetails onChange={onChange} entity={entity} />
            <TaskSelector
              onSelect={onAddTask}
              entity={entity}
              onRemoveTask={onRemoveTask}
            />
            <PartsSelector
              onSelect={onAddPart}
              entity={entity}
              onRowChange={onRowChange}
              onRemove={onRemovePart}
            />
          </Form>
        </Col>
      </Row>
    </div>
  );
}
