import React from "react";
import { Input } from "antd";
import { CommentOutlined } from "@ant-design/icons";

export default function Comment({ onChange, entity }) {
  return (
    <>
      <h2 className={"section-title"}>
        <span>
          <CommentOutlined />
        </span>{" "}
        Comentarios
      </h2>

      <div className={"section-container"}>
        <Input.TextArea
          placeholder={"Notas adicionales que se quieran agregar"}
          rows={4}
          onChange={(e) => onChange("comment", e.target.value)}
          value={entity["comment"]}
        />
      </div>
    </>
  );
}
