import { Link } from "react-router-dom";
import { Button } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import * as PropTypes from "prop-types";
import React from "react";

function CreateLink(props) {
  return (
    <Link to={props.to}>
      <Button type={"primary"} className={"ml-1"} icon={<PlusCircleOutlined />}>
        {props.label}
      </Button>
    </Link>
  );
}

CreateLink.propTypes = {
  to: PropTypes.string,
  label: PropTypes.any,
};

export default CreateLink;
