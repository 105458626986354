import React, { useContext } from "react";
import {
  ArrowDownOutlined,
  DeleteOutlined,
  EditOutlined,
  MoreOutlined,
  PlusCircleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Menu, message, Table, Tooltip, Input } from "antd";
import { Link } from "react-router-dom";
import { get, remove } from "../../helpers/v2api";
import moment from "moment";
import { AccountContext } from "../../hooks/useAccount";
import { useHistory } from "react-router";
import * as PropTypes from "prop-types";
import Paginator from "../../components/Paginator";
import { useFetchData } from "../../hooks/useFetchDataBis";
import FilterTagSelector from "../../components/AsyncMultipleSelect";
import { v2dataServer } from "../../constants/server";

const beEndPoint = "places";
const feModule = `lugares`;
const addButtonContent = "Registrar un lugar nuevo";
const pageSize = 50;

function ActionsMenu({ record, onDelete }) {
  function onMouseEnter(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  return (
    <Dropdown
      trigger={"click"}
      onClick={onMouseEnter}
      overlay={
        <Menu>
          <Menu.Item key="0">
            <Link to={`/${record.account_id}/${feModule}/${record.id}`}>
              <Button type={"link"}>
                <EditOutlined style={{ color: "#AAAAAA" }} /> Editar
              </Button>
            </Link>
          </Menu.Item>
          <Menu.Item>
            <Button
              type={"link"}
              onClick={(e) => {
                e.stopPropagation();
                onDelete(record);
              }}
            >
              <DeleteOutlined style={{ color: "#AAAAAA" }} /> Remover
            </Button>
          </Menu.Item>
        </Menu>
      }
    >
      <Tooltip title={"Acciones"} placement={"left"}>
        <Button icon={<MoreOutlined />} />
      </Tooltip>
    </Dropdown>
  );
}

const getColumns = (onDelete) => [
  {
    title: "Nombre",
    dataIndex: "name",
  },
  {
    title: "Dirección",
    dataIndex: "formatted_address",
  },
  {
    title: "Descripcion",
    dataIndex: "description",
  },
  {
    title: "Taller",
    dataIndex: "is_mechanic_shop",
    render: (text) => (text ? "Sí" : "No"),
  },
  {
    title: "Almacén",
    dataIndex: "is_warehouse",
    render: (text) => (text ? "Sí" : "No"),
  },
  {
    title: "",
    dataIndex: "",
    align: "right",
    render: (text, record) => (
      <ActionsMenu record={record} onDelete={onDelete} />
    ),
  },
];

function AddButtonLink(props) {
  return (
    <Tooltip
      mouseEnterDelay={1}
      title={
        <div>
          <QuestionCircleOutlined /> <a href={"/ayuda"}>Ayuda</a>
        </div>
      }
      placement={"top"}
      trigger={"hover"}
    >
      <Link to={props.to}>
        <Button type={"primary"} icon={<PlusCircleOutlined />}>
          {props.addButtonContent}
        </Button>
      </Link>
    </Tooltip>
  );
}

AddButtonLink.propTypes = {
  to: PropTypes.string,
  addButtonContent: PropTypes.any,
};

const sortOptions = [
  { label: "Nombre - Ascendente", value: "name ASC" },
  { label: "Nombre - Descendente", value: "name DESC" },
];
const filters = [
  {
    label: "Nombre",
    name: "q",
  },
];
export default function ListPlaces(props) {
  const history = useHistory();

  const account = useContext(AccountContext);

  const [state, onChange, clearQuery, setPage] = useFetchData({
    url: "places-search",
    initialSort: sortOptions[0].value,
    initialQuery: {},
    collectionName: "places",
    dataServerPreferred: v2dataServer,
  });
  const { data, query, count, page } = state;

  const onDelete = async (record) => {
    try {
      await remove(beEndPoint, record.id);
      message.success(`El lugar ${record.name} ha sido removido.`);
    } catch (err) {
      console.log(err);
    }
  };

  const columns = getColumns(onDelete);

  async function download() {
    try {
      let response = await get(beEndPoint + "/download");
      const data = response.data;

      const fileName = `${feModule}_${moment().format("YYYY-MM-DD")}.csv`;

      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      alert("Error downloading");
    }
  }

  const length = Object.keys(query).length;

  const onRow = (record) => {
    return {
      onClick: () =>
        history.push(`/${record.account_id}/${feModule}/${record.id}`),
    };
  };

  return (
    <>
      <div>
        <div className="grid-header">
          <h1>{"Listado de lugares"}</h1>
          <div className={"justify-flex-end"}>
            <Button onClick={download} icon={<ArrowDownOutlined />}>
              Descargar archivo CSV
            </Button>
            <div className={"ml-2"}>
              <AddButtonLink
                to={"/" + account.id + `/${feModule}/crear`}
                addButtonContent={addButtonContent}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={"grid-filters"}>
        <Input.Search
          placeholder="Buscar por nombre"
          allowClear
          onSearch={(value) => onChange("q", value)}
          style={{ width: 300, marginRight: 10 }}
        />
        {filters
          .filter((x) => x.type === "tag")
          .map((x, idx) => (
            <FilterTagSelector
              entity={query}
              name={x.name}
              formatter={x.formatter}
              onChange={onChange}
              placeholder={x.placeholder}
              style={{ width: 200, marginRight: 10 }}
              useFetcher={x.useFetcher}
              key={idx}
            />
          ))}
        {length > 0 && (
          <Button type={"link"} onClick={clearQuery}>
            {" "}
            Borrar filtros
          </Button>
        )}
      </div>
      <div>
        {data && (
          <div>
            <Paginator
              current={page}
              total={count}
              onChange={setPage}
              pageSize={pageSize}
            />
          </div>
        )}
        <Table
          columns={columns}
          rowClassName={"show-pointer"}
          size={"small"}
          onRow={onRow}
          pagination={false}
          className="table-striped-rows"
          dataSource={data}
          rowKey={(record) => record.id}
        />
      </div>
    </>
  );
}
