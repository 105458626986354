import React, { useEffect, useState } from "react";
import { Form, Button, Input, Alert } from "antd";
import { get, post, patch } from "../../api";

function TransactionTypeForm({ entityId, onSuccess, category }) {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const { setFieldsValue } = form;
  const endPoint = `transaction-types`;

  const onFinish = async (values) => {
    try {
      setSubmitting(true);
      if (entityId) {
        await patch(endPoint, { name: values.name }, entityId);
      } else {
        await post(endPoint, { name: values.name, category });
        setFieldsValue({ name: "" });
      }
      onSuccess();
      setSubmitting(false);
    } catch (err) {
      setSubmitting(false);
      setError(JSON.stringify(err.message));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (entityId) {
        const res = await get(`${endPoint}/${entityId}`);
        setFieldsValue(res);
      } else {
        setFieldsValue({});
      }
    };
    fetchData();
  }, [entityId, setFieldsValue, endPoint]);

  return (
    <Form layout={"vertical"} form={form} onFinish={onFinish}>
      {error && <Alert message={error} type="error" />}
      <Form.Item
        label="Nombre del tipo de gasto"
        name="name"
        rules={[{ required: true, message: "Por favor ingrese el nombre" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item style={{ display: "flex" }}>
        <Button
          type="primary"
          htmlType="submit"
          disabled={submitting}
          loading={submitting}
        >
          GUARDAR
        </Button>
      </Form.Item>
    </Form>
  );
}

export default TransactionTypeForm;
