import { Form, Select } from "antd";
import React, { useState } from "react";
import useSWR from "swr";
import { get } from "../helpers/v2api";

const useSWRFetch = (url: string) => {
  const fetcher = async () => {
    const axiosResponse = await get(url);

    return axiosResponse.data;
  };

  return useSWR(url, fetcher, {
    initialData: null,
    revalidateOnMount: true,
  });
};

export function VehicleSelectByAccount(x: { form: any }) {
  const entity = x.form.getFieldsValue();
  const [name, setName] = useState("");

  const { data } = useSWRFetch(
    `admin/vehicles/all?filters[name_substring]=${name}&sort=name+asc`
  );

  function onSearch(value: string) {
    if (value.length > 1 || value.length === 0) {
      setName(value);
    }
  }

  let newVar = data || [];
  return (
    <Form.Item label={"Vehículo"} name="vehicle_id">
      <Select
        onSearch={(value: string) => onSearch(value)}
        optionFilterProp={"label"}
        options={[
          ...newVar,
          { value: entity.vehicle_id, label: entity.vehicle_name },
        ].map((item: any) => ({
          label: item.name,
          value: item.id,
        }))}
        placeholder={"Busque el vehículo"}
        allowClear
        showSearch
      />
    </Form.Item>
  );
}
