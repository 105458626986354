import {
  BarChartOutlined,
  BugOutlined,
  CarOutlined,
  DollarOutlined,
  FileDoneOutlined,
  PushpinOutlined,
  TeamOutlined,
  ToolOutlined,
  WindowsOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const { Sider } = Layout;
const { SubMenu } = Menu;

type MenuItem = {
  children?: any;
  icon: JSX.Element;
  to?: { state: { menuKey: string[] }; pathname: string; search?: string };
  label: string;
};
export default function Sidebar({ accountId }: { accountId: string }) {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation<{ menuKey: string[] }>();
  const [selectedMenuKey, setSelectedMenuKey] = useState([""]);
  const onCollapse = (collapsed: boolean) => setCollapsed(collapsed);

  let s = { fontSize: 16 };
  const modules: MenuItem[] = [
    {
      to: {
        pathname: `/${accountId}/telematic`,
        state: { menuKey: ["telematic"] },
      },
      label: "Mapa general",
      icon: <PushpinOutlined />,
    },
    {
      to: {
        pathname: `/${accountId}/vehicles`,
        state: { menuKey: ["vehiculos"] },
      },
      label: "Vehículos",
      icon: <CarOutlined />,
    },
    {
      to: {
        pathname: `/${accountId}/services`,
        state: { menuKey: ["mantenimiento"] },
      },
      label: "Mantenimiento",
      icon: <ToolOutlined />,
    },
    {
      to: {
        pathname: `/${accountId}/seguros`,
        state: { menuKey: ["seguros"] },
      },
      label: "Seguros",
      icon: <FileDoneOutlined />,
    },
    {
      to: {
        pathname: `/${accountId}/contacts`,
        state: { menuKey: ["conductores"] },
      },
      label: "Conductores",
      icon: <TeamOutlined />,
    },
    {
      to: {
        pathname: `/${accountId}/reports`,
        state: { menuKey: ["reportes"] },
      },
      label: "Reportes",
      icon: <BarChartOutlined />,
      children: [
        {
          to: {
            pathname: `/${accountId}/reports/asignaciones`,
            state: { menuKey: ["reportes", "asignaciones"] },
          },
          label: "Asignaciones",
        },
        {
          to: {
            pathname: `/${accountId}/ubicaciones`,
            state: { menuKey: ["reportes", "ubicaciones"] },
          },
          label: "Ubicaciones",
        },
        {
          to: {
            pathname: `/${accountId}/reports/collections-weekly`,
            state: { menuKey: ["reportes", "collections-weekly"] },
          },
          label: "Cobranzas Semanal",
        },
        {
          to: {
            pathname: `/${accountId}/reports/collections-annual`,
            state: { menuKey: ["reportes", "collections-annual"] },
          },
          label: "Cobranzas anual",
        },
        {
          to: {
            pathname: `/${accountId}/reports/ubicaciones-diario`,
            state: { menuKey: ["reportes", "ubicaciones-diario"] },
          },
          label: "Ubicaciones diario",
        },
        // {
        //   to: `/${accountId}/viajes`,
        //   label: "Viajes",
        // },
      ],
    },
    {
      label: "Finanzas",
      icon: <DollarOutlined />,
      children: [
        {
          to: {
            pathname: `/${accountId}/cobranzas`,
            state: { menuKey: ["finanzas", "cobranzas"] },
          },
          label: "Cobranzas",
        },
        // {
        //   to: `/${accountId}/facturas`,
        //   label: "Facturas",
        // },
      ],
    },
    {
      to: {
        pathname: `/${accountId}/dispositivos`,
        state: { menuKey: ["dispositivos"] },
      },
      label: "Dispositivos",
      icon: <BugOutlined />,
    },
  ];
  // {
  //   to: `/${accountId}/proveedores`,
  //   label: "Proveedores",
  //   icon: <BankOutlined />,
  // },

  useEffect(() => {
    const key = location.state?.menuKey;
    setSelectedMenuKey(key);
  }, [location, setSelectedMenuKey]);

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      width={220}
      onCollapse={onCollapse}
      className={"sidebar"}
    >
      <Menu theme="dark" mode="inline" selectedKeys={selectedMenuKey}>
        <Menu.Item icon={<WindowsOutlined />} key={"inicio"}>
          <Link
            style={s}
            to={{
              pathname: `/${accountId}/dashboard`,
              state: { menuKey: ["inicio"] },
            }}
          >
            {"Inicio"}
          </Link>
        </Menu.Item>
        {modules.map((menu: MenuItem) => {
          if (menu.children) {
            return (
              <SubMenu
                style={s}
                key={menu.to?.state?.menuKey?.[0]}
                icon={menu.icon}
                title={menu.label}
              >
                {menu.children.map((menuChild: any) => {
                  return (
                    <Menu.Item key={menuChild.to.state?.menuKey?.[1]}>
                      <Link to={menuChild.to}>{menuChild.label}</Link>
                    </Menu.Item>
                  );
                })}
              </SubMenu>
            );
          }
          return (
            <Menu.Item icon={menu.icon} key={menu.to?.state?.menuKey[0]}>
              <Link to={{ ...menu.to }} style={s}>
                {menu.label}
              </Link>
            </Menu.Item>
          );
        })}
      </Menu>
    </Sider>
  );
}
