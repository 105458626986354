import React from "react";
import { Col, Form, Radio, Row, Select } from "antd";
import { TextInput } from "../Inputs";
import { useDataApi } from "../../hooks/useDataApi";
import ContactSelect from "./ContactSelect";

export default function ContactOrCreate({
  entity,
  onChange,
  submitted,
  errors,
  addNew,
  dispatch,
}) {
  const { contactId } = entity;
  const [state] = useDataApi("contacts");

  let content;

  if (addNew === "createNew") {
    content = (
      <div>
        <Row>
          <Col span={10}>
            <TextInput
              error={errors["firstName"]}
              onChange={onChange}
              submitted={submitted}
              label={"Nombre"}
              placeholder={"Ingrese el nombre"}
              entity={entity}
              name={"firstName"}
            />
          </Col>
          <Col span={10} offset={2}>
            <TextInput
              error={errors["lastName"]}
              onChange={onChange}
              submitted={submitted}
              label={"Apellido"}
              placeholder={"Ingrese el apellido"}
              entity={entity}
              name={"lastName"}
            />
          </Col>
        </Row>
      </div>
    );
  } else {
    content = (
      <ContactSelect
        onChange={onChange}
        entity={entity}
        errors={errors}
        submitted={submitted}
      />
    );

    content = (
      <Form.Item
        label={"Seleccione un conductor"}
        validateStatus={submitted && errors["contactId"] ? "error" : "success"}
        hasFeedback={submitted}
        help={submitted && errors["contactId"]}
      >
        <Select
          allowClear
          autoFocus
          onChange={(value) => onChange("contactId", value)}
          optionFilterProp={"label"}
          options={state.data.map((item) => ({
            label: `${item.firstName} ${item.lastName}`,
            value: item.id.toString(),
          }))}
          placeholder="Buscar conductor"
          showSearch
          value={contactId}
        />
      </Form.Item>
    );
  }

  return (
    <div style={{ minHeight: 165 }}>
      <Form.Item label={"Conductor"}>
        <Radio.Group
          value={addNew}
          onChange={(e) =>
            dispatch({ type: "ADD_NEW_CHANGED", payload: e.target.value })
          }
          style={{ marginBottom: 16 }}
        >
          <Radio value="selectExisting">Seleccionar existente</Radio>
          <Radio value="createNew">Crear uno nuevo</Radio>
        </Radio.Group>

        {content}
      </Form.Item>
    </div>
  );
}
