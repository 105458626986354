import React from "react";

import { Vehicle } from "../types";
import Breadcrumb from "./Breadcrumb";

type Props = {
  pathname: string;
  accountId: string;
  entity: Vehicle;
};
export default function VehicleHeaderBreadcrumb({
  accountId,
  entity,
  pathname,
}: Props) {
  let routes;

  const length = pathname.split("/").length;
  const nElement = pathname.split("/")[length - 1];
  const nMinus1Element = pathname.split("/")[length - 2];

  if (nElement === "readings") {
    routes = routes = [
      { to: `/${accountId}/vehicles`, label: "Vehículos" },
      { to: `/${accountId}/vehicles/${entity.id}/`, label: entity.name },
      { label: "Historial odómetro" },
    ];
  } else if (nElement === "services") {
    routes = [
      { to: `/${accountId}/vehicles`, label: "Vehículos" },
      { to: `/${accountId}/vehicles/${entity.id}/`, label: entity.name },
      { label: "Historial servicios" },
    ];
  } else if (nMinus1Element === "services" && length === 6) {
    routes = [
      { to: `/${accountId}/vehicles`, label: "Vehículos" },
      { to: `/${accountId}/vehicles/${entity.id}/`, label: entity.name },
      {
        to: `/${accountId}/vehicles/${entity.id}/services`,
        label: "Historial servicios",
      },
      { label: `Servicio #${nElement}` },
    ];
  } else if (nMinus1Element === "collections" && length === 6) {
    routes = [
      { to: `/${accountId}/vehicles`, label: "Vehículos" },
      { to: `/${accountId}/vehicles/${entity.id}/`, label: entity.name },
      {
        to: `/${accountId}/vehicles/${entity.id}/collections`,
        label: "Historial de cobranzas",
      },
      { label: `Cobranza #${nElement}` },
    ];
  } else if (nMinus1Element === "gastos" && length === 6) {
    routes = [
      { to: `/${accountId}/vehicles`, label: "Vehículos" },
      { to: `/${accountId}/vehicles/${entity.id}/`, label: entity.name },
      {
        to: `/${accountId}/vehicles/${entity.id}/gastos`,
        label: "Historial de gastos",
      },
      { label: `Gasto #${nElement}` },
    ];
  } else {
    routes = [
      { to: `/${accountId}/vehicles`, label: "Vehículos" },
      { label: entity.name },
    ];
  }

  return <Breadcrumb routes={routes} />;
}
