import {Card, Col, Row} from "antd";
import React from "react";
import {QuestionOutlined} from "@ant-design/icons";

function NoLocationEntryView() {
    let style = {
        minHeight: 300,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
    };

    return (
        <div style={{ minHeight: 1000, paddingTop: 150 }}>
            <Row>
                <Col span={12} offset={6}>
            <Card>
                {/* @ts-ignore*/}
                <div style={style}>
                    <QuestionOutlined style={{ color: "#AAAAAA", fontSize: '72px', margin: 20 }} />
            <h2>{"No se encontró ninguna ubicación"}</h2>
            <p style={{ maxWidth: "80%" }}>
            {
                "Por favor cambie o amplíe el rango de fechas"
            }
            </p>
        </div>
            </Card>
                </Col>
            </Row>
        </div>
);
}

export default NoLocationEntryView;
