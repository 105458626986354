import React, {useContext} from "react";
import {Layout} from "antd";
import Header from "../../components/Header";
import {AuthContext} from "../../hooks/useAuth";
import {User} from "../../types";


const DisabledAccount = () => {
    const user = useContext<User>(AuthContext);


    return (
        <div>
            <Header user={user}/>
            <Layout className={"login-container p-3"}>
                <h1>Cuenta Deshabilitada</h1>
                <h3>Por favor, comuniquese con el equipo de administración para regularizar su situación.</h3>
            </Layout>
        </div>
    );
};
export default DisabledAccount;
