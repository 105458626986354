import axios from "axios";
import { dataServer } from "../constants/server";

export const get = (endPoint: string) =>
  axios
    .get(`${dataServer}/${endPoint}`, { withCredentials: true })
    .then((response) => response);

export const post = (endPoint: string, payload: object, config?: object) =>
  axios
    .post(`${dataServer}/${endPoint}`, payload, {
      ...config,
      withCredentials: true,
    })
    .then((response) => response);

export const patch = (endPoint: string, payload = {}, id: string) =>
  axios
    .patch(`${dataServer}/${endPoint}/${id}`, payload, {
      withCredentials: true,
    })
    .then((response) => response);

export const remove = (endPoint: string, id: string) =>
  axios
    .delete(`${dataServer}/${endPoint}/${id}`, { withCredentials: true })
    .then((response) => response.data);
