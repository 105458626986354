import React, { useEffect, useState } from "react";
import { Checkbox, DatePicker, Divider, Form } from "antd";
import { ClockCircleOutlined, FileTextOutlined } from "@ant-design/icons";
import { useDataApi } from "../hooks/useDataApi";
import { Interval } from "luxon";
import locale from "antd/es/date-picker/locale/es_ES";
import moment from "moment";
import OdometerUpdateField from "./OdometerUpdateField";
import { getDateTime } from "../utils";
import VehicleSelect from "../Views/VehicleSelect";

function Duration(startDate, finishDate) {
  let duration;

  if (startDate && startDate < finishDate) {
    const i = Interval.fromDateTimes(
      getDateTime(startDate),
      getDateTime(finishDate),
      { locale: "es" }
    );

    if (!i.invalid) {
      const { days, hours, minutes } = i.toDuration([
        "days",
        "hours",
        "minutes",
      ]).values;
      duration = `Duración: ${days ? days + " días " : ""} ${
        hours ? hours + " horas" : ""
      } ${minutes ? minutes + " minutos." : ""}`;
    } else {
      duration =
        "Error: La fecha y hora de inicio no puede ser posterior a la de inicio. Por favor, corrija la fecha de inicio.";
    }
  }

  return (
    duration && (
      <small>
        <ClockCircleOutlined /> {duration}
      </small>
    )
  );
}

export default function ServiceDetails({ entity, onChange }) {
  const [state] = useDataApi("vehicles");
  const [showStart, setShowStart] = useState(false);

  const disabledDate = (current) => current && current > moment();

  const disabledDateStart = (current) =>
    current && current >= moment(entity.finishDate).endOf("day");

  useEffect(() => setShowStart(Boolean(entity.startDate)), [entity]);

  if (state.isLoading) return <p>loading</p>;

  let duration = Duration(entity["startDate"], entity["finishDate"]);

  let getDatePicker = (name, placeholder) => (
    <DatePicker
      showTime
      disabledDate={name === "startDate" ? disabledDateStart : disabledDate}
      locale={locale}
      style={{ width: "100%" }}
      placeholder={placeholder}
      format="DD/MM/YYYY HH:mm"
      value={entity[name] ? moment(entity[name]) : undefined}
      onChange={(e) => {
        onChange(name, e ? e.format("YYYY-MM-DD HH:mm") : "");
      }}
    />
  );
  return (
    <>
      <h2 className={"section-title"}>
        <span>
          <FileTextOutlined />
        </span>{" "}
        Detalles
      </h2>

      <div className={"section-container"}>
        <VehicleSelect entity={entity} onChange={onChange} />
        <Divider />
        <OdometerUpdateField
          entity={entity}
          onChange={onChange}
          vehicleList={state.data}
        />
        <Divider />

        <Form.Item label={"Fecha y hora de finalización"}>
          {getDatePicker("finishDate", "Indique fecha y hora de finalización.")}
          <Checkbox
            checked={showStart}
            onChange={(e) => setShowStart(e.target.checked)}
          >
            Indicar hora de inicio
          </Checkbox>
        </Form.Item>

        {showStart && (
          <div>
            <Form.Item label={"Fecha y hora de Inicio"}>
              {getDatePicker(
                "startDate",
                "Indique fecha y hora de inicio del servicio"
              )}
            </Form.Item>

            {duration}
          </div>
        )}
      </div>
    </>
  );
}
