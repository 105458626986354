import {
  CalendarOutlined,
  DashboardFilled,
  EditOutlined,
  FileTextOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Col, Divider, Row } from "antd";
import { DateTime } from "luxon";
import * as PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

import { FIELD_TYPES } from "../../constants";
import { useEntity } from "../../hooks/useEntity";
import { calculateDurationBis } from "../../utils";
import format, { formatNumber } from "../../utils/format";
import CommentCard from "./CommentCard";
import DocumentsCard from "./DocumentsCard";
import FilesCard from "./FilesCard";

function Header(props) {
  return (
    <div className={"vehicle-assignment-view-header"}>
      <div className={"vehicle-assignment-view-header-item"}>
        <div>
          <UserOutlined />
        </div>
        <div className={" ml-2"}>
          <div className={"muted"}> CONDUCTOR</div>
          <div>
            <a
              rel="noopener noreferrer"
              target={"_blank"}
              href={`/${props.entity.vehicle.accountId}/contacts/${props.entity.contactId}`}
            >
              {props.entity.contact.firstName} {props.entity.contact.lastName}
            </a>
          </div>
        </div>
      </div>
      <div className={"vehicle-assignment-view-header-item"}>
        <div>
          <CalendarOutlined />
        </div>
        <div className={" ml-2"}>
          <div className={"muted"}> DURACIÓN</div>
          <b>{props.duration ? props.duration : "N/A"}</b>
        </div>
      </div>

      <div className={"vehicle-assignment-view-header-item"}>
        <div>
          <DashboardFilled />
        </div>
        <div className={" ml-2"}>
          <div className={"muted"}> DISTANCIA</div>
          <div>
            <div>
              {props.distance ? (
                <span>
                  <b>{formatNumber(props.distance, 0)}</b> <small>KM</small>
                </span>
              ) : (
                "N/A"
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Header.propTypes = { entity: PropTypes.any };
export default function VehicleAssignmentView({ match }) {
  const { vehicleAssignmentId } = match.params;
  const { state } = useEntity(vehicleAssignmentId, `vehicle-assignments`);
  const { fetching, entity } = state;
  let distance;

  if (fetching) return <p>loading...</p>;

  const duration = calculateDurationBis(
    entity["startDate"],
    entity["finishDate"],
    true
  );

  const endingMeterEntryValue =
    entity["endingMeterEntry"] && entity["endingMeterEntry"].value;
  const startingMeterEntryValue =
    entity["startingMeterEntry"] && entity["startingMeterEntry"].value;

  if (startingMeterEntryValue) {
    distance =
      endingMeterEntryValue ||
      entity.vehicle.currentMeterValue - startingMeterEntryValue;
  }

  const user = state.entity.role.user;

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className={"section-title-bis"}>
          <FileTextOutlined />
          Detalles
        </div>

        <Link
          to={{
            pathname: `/${entity.vehicle.accountId}/vehicles/${entity.vehicle.id}/asignaciones/${entity.id}/editar`,
            state: { menuKey: ["vehiculos", "asignaciones-vehicle"] },
          }}
        >
          <Button type={"dashed"}>
            <EditOutlined /> EDITAR ASIGNACIÓN VEHÍCULO
          </Button>
        </Link>
      </div>

      <div className={"section-container"}>
        <Header entity={entity} duration={duration} distance={distance} />
        <Divider />
        <table className="service-view-table">
          <tbody>
            <tr>
              <td align={"right"}>
                <b>Fecha inicio:</b>
              </td>
              <td>
                {entity.startDate
                  ? DateTime.fromISO(entity.startDate).toLocaleString({
                      month: "short",
                      day: "numeric",
                      weekday: "long",
                      year: "numeric",
                      locale: "es",
                    })
                  : "-"}
              </td>
            </tr>
            <tr>
              <td align={"right"}>
                <b>Fecha fin:</b>
              </td>
              <td>
                {entity.finishDate
                  ? DateTime.fromISO(entity.finishDate).toLocaleString({
                      month: "short",
                      day: "numeric",
                      weekday: "long",
                      year: "numeric",
                      locale: "es",
                      minute: "2-digit",
                      hour: "numeric",
                    })
                  : "-"}
              </td>
            </tr>
            <tr>
              <td align={"right"}>
                <b>Odómetro al inicio:</b>
              </td>

              <td>
                {entity.startingMeterEntry ? (
                  <span>
                    {formatNumber(entity.startingMeterEntry.value, 0)}{" "}
                    <small>KM</small>
                  </span>
                ) : (
                  <span>No registra kilometraje inicial</span>
                )}
              </td>
            </tr>
            <tr>
              <td align={"right"}>
                <b>Odómetro al finalizar:</b>
              </td>

              <td>
                {entity.endingMeterEntry ? (
                  <span>
                    {formatNumber(entity.endingMeterEntry.value, 0)}{" "}
                    <small>KM</small>
                  </span>
                ) : (
                  <span>No registra kilometraje final</span>
                )}
              </td>
            </tr>
          </tbody>
        </table>
        <small className={"muted"}>
          Creado por {user.firstName} {user.lastName} hace{" "}
          {format(entity.createdAt, FIELD_TYPES.RELATIVE_TIME)}.
        </small>
      </div>
      <Divider />
      <Row>
        <Col span={12} className={"pr-1"}>
          <CommentCard
            commentableId={vehicleAssignmentId}
            commentableType={"vehicleAssignmentId"}
          />
        </Col>

        <Col span={12} className={"pl-1"}>
          <FilesCard
            fillableId={vehicleAssignmentId}
            fillableType={"vehicleAssignmentId"}
          />
        </Col>

        <Col span={12} className={"pl-1"}>
          <DocumentsCard
            fillableId={vehicleAssignmentId}
            fillableType={"vehicleAssignmentId"}
          />
        </Col>
      </Row>
    </div>
  );
}
