import { Table } from "antd";
import React from "react";

import { FIELD_TYPES } from "../constants";
import { Map } from "../routes/Maps/Fleet/FleetMap";
import format from "../utils/format";
import { useSimpleSWRFetch } from "../utils/rest";

const columns = [
  {
    title: "Vehículo",
    dataIndex: "vehicle_name",
  },
  {
    title: "Cuenta",
    dataIndex: "account_id",
  },
  {
    title: "Timestamp",
    dataIndex: "timestamp",
    render: (x: any) => (x ? format(x, FIELD_TYPES.RELATIVE_TIME) : "N/A"),
  },
  {
    title: "Odómetro",
    dataIndex: "odometer",
    render: (x: any) => format(x / 1000, FIELD_TYPES.INTEGER),
  },
  {
    title: "Ignición",
    dataIndex: "ignition",
  },
];

export default function LocationsList() {
  const { error, data } = useSimpleSWRFetch("admin/locations/last");

  if (error) return <div>{JSON.stringify(error)}</div>;

  return (
    <div>
      <Map entries={data || []} />
      <Table
        dataSource={data}
        rowClassName={"show-pointer"}
        columns={columns}
        pagination={false}
        size={"small"}
        rowKey={(record) => record.id}
      />
    </div>
  );
}
