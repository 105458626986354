import React from "react";

import { useDataApi } from "../../hooks/useDataApi";
import Assignment from "./Assignment";
import Deassignment from "./Deassignment";

export default ({ vehicle, accountId }) => {
  const [state] = useDataApi(`vehicles/${vehicle.id}/current-operator`);
  const { data, isLoading } = state;
  if (isLoading) {
    return null;
  }

  if (data[0]) {
    return (
      <div>
        <Deassignment
          accountId={accountId}
          assignment={data[0]}
          vehicle={vehicle}
        />
      </div>
    );
  } else {
    return <Assignment vehicle={vehicle} />;
  }
};
