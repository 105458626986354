import { Form, Select } from "antd";
import React from "react";
import useSWR from "swr";

import { newApi } from "../utils/rest";

const useSWRFetch = (url: string) => {
  const fetcher = async () => {
    const axiosResponse = await newApi().v3.get(url);

    return axiosResponse.data;
  };

  return useSWR(url, fetcher, {
    initialData: null,
    revalidateOnMount: true,
  });
};

export function AccountSelect(x: { form: any }) {
  const entity = x.form.getFieldsValue();

  const { data } = useSWRFetch(`admin/accounts`);

  let newVar = data || [];
  return (
    <Form.Item label={"Cuenta"} name="account_id">
      <Select
        optionFilterProp={"label"}
        options={[...newVar, { value: entity.id, label: entity.name }].map(
          (item: any) => ({
            label: item.name,
            value: item.id,
          })
        )}
        placeholder={"Busque el vehículo"}
        allowClear
        showSearch
      />
    </Form.Item>
  );
}
