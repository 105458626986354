import { Tooltip } from "antd";
import React from "react";

const Green = "#50b167";
const Grey = "#d9d9d9";
const Red = "#bf3b00";

function IgnitionStatus(props: { title: string; color: string }) {
  return (
    <Tooltip title={props.title}>
      <span className={"mr-2"} style={{ color: props.color }}>
        ◉
      </span>
    </Tooltip>
  );
}

export default (props: { status: number }) => {
  const { status } = props;

  if (status === 1) {
    return <IgnitionStatus title={"Motor encendido"} color={Green} />;
  } else if (status === 0) {
    return <IgnitionStatus title={"Motor apagado"} color={Grey} />;
  } else {
    return (
      <IgnitionStatus title={"Rastreo satelital sin reportar"} color={Red} />
    );
  }
};
