import { CommentOutlined } from "@ant-design/icons";
import { Badge, Table, Tag, message } from "antd";
import dottie from "dottie";
import React from "react";
// import { useSWRFetch } from "../../helpers";
import { Link } from "react-router-dom";
import useSWR from "swr";

import { get, remove } from "../../api";
import ActionsMenu from "../../components/ActionsMenu";
import CarSVG from "../../components/CarSVG";
import { renderDateRangeAndDuration, renderDistance } from "../../utils";

export const useSWRFetch = (url) =>
  useSWR(url, () => get(url), { initialData: null, revalidateOnMount: true });

const renderContact = (text, record) =>
  record.contactId ? (
    <div>
      <Link to={`/${record.contact.accountId}/contacts/${record.contactId}`}>
        {record.contact.firstName + " " + record.contact.lastName}
      </Link>{" "}
      {!record.finishDate && <Tag color="#87d068">Conductor Actual</Tag>}
    </div>
  ) : (
    "-"
  );

const renderVehicle = (text, record) => {
  const vehicle = dottie.transform(record).vehicle;
  const noPropagation = (e) => e.stopPropagation();

  return (
    <div style={{ display: "flex", alignItems: "center", paddingLeft: "10" }}>
      <CarSVG type={"Auto"} width={40} vehicle={vehicle} />
      <div className={"ml-1"}>
        <Link
          onClick={noPropagation}
          to={`/${record.vehicle.accountId}/vehicles/${record.vehicleId}`}
        >
          <b>{vehicle.name}</b>
        </Link>
        <p>
          <small>
            <Badge style={{ marginRight: -5 }} color={vehicle.status.color} />
            {vehicle.status.name} • {vehicle.year} {vehicle.brand}{" "}
            {vehicle.model}
          </small>
        </p>
      </div>
    </div>
  );
};

export default function AssignmentsReport({ history }) {
  const { data, mutate } = useSWRFetch("vehicle-assignments");

  const onDelete = async (record) => {
    try {
      await remove("vehicle-assignments", record.id);
      mutate();
      message.success(`Registro removido`);
    } catch (err) {
      console.log(err);
    }
  };

  if (!data) {
    return null;
  }

  const columns = [
    { title: "", dataIndex: "vehicle", render: renderVehicle },
    {
      title: "",
      dataIndex: "startDate",
      render: renderDistance,
    },
    {
      title: "",
      dataIndex: "startDate",
      render: renderDateRangeAndDuration,
    },
    { title: "", dataIndex: "contact", render: renderContact },
    {
      title: "",
      dataIndex: "comm",
      render: (text, record) => (
        <span>
          <CommentOutlined /> {record.comments && record.comments.length}{" "}
          comentarios
        </span>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      render: (text, record) => (
        <ActionsMenu record={record} onDelete={onDelete} />
      ),
    },
  ];

  const onRow = (record) => {
    return {
      onClick: () => {
        console.log(record.id);
        history.push({
          pathname: `/${record.vehicle.accountId}/vehicles/${record.vehicle.id}/asignaciones/${record.id}`,
          state: { menuKey: ["vehiculos", "asignaciones-vehicle"] },
        });
      },
    };
  };
  return (
    <Table
      rowClassName={"show-pointer"}
      onRow={onRow}
      rowKey={(record) => record.id}
      dataSource={data}
      columns={columns}
      pagination={false}
    />
  );
}
