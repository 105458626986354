import React, { useRef, useState } from "react";
import { Button, Form, Select } from "antd";
import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useDataApi } from "../hooks/useDataApi";
import { post } from "../api";
import TaskList from "./TaskList";

let endPoint = "task-types?sortBy=name+asc";

export default function TaskSelector({ onSelect, entity, onRemoveTask }) {
  const [name, setName] = useState("");
  const foo = useDataApi(endPoint);
  const state = foo[0];
  const addItem = foo[2];

  const inputRef = useRef(null);

  const onAddItemClick = async () => {
    const response = await post(endPoint, { name });
    addItem(response);
    setName("");
    onSelect(response.id);
    inputRef.current.blur();
  };

  const onSelectChange = (e) => {
    if (e) {
      onSelect(e);
    }

    inputRef.current.blur();
  };

  const onInputChange = (e) => setName(e);

  return (
    <>
      <h2 className={"section-title"}>
        <span>
          <InfoCircleOutlined />
        </span>{" "}
        Trabajos
      </h2>

      <div className={"section-container"}>
        <Form.Item label={"Indique las tareas realizadas"}>
          <Select
            allowClear
            showSearch
            notFoundContent={
              name.length > 3 ? (
                <div
                  style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}
                >
                  <Button
                    style={{
                      flex: "none",
                      display: "block",
                      cursor: "pointer",
                    }}
                    type="primary"
                    onClick={onAddItemClick}
                  >
                    <PlusOutlined /> {`Agregar ${name}`}
                  </Button>
                </div>
              ) : (
                ""
              )
            }
            onChange={onSelectChange}
            onSearch={onInputChange}
            optionFilterProp={"label"}
            options={state.data.map((item) => ({
              label: item.name,
              value: item.id,
            }))}
            placeholder="Agregue tareas (Ej. Cambio de aceite de motor y reemplazo de filtros)"
            ref={inputRef}
            value={null}
          />
        </Form.Item>
        <TaskList
          entity={entity}
          taskList={state.data}
          onRemoveTask={onRemoveTask}
        />
      </div>
    </>
  );
}
