import useSWR from "swr";
import { get } from "../api";

export function usePartTypes() {
  return useSWR(
    "part-types?sortBy=name+asc",
    () => get("part-types?sortBy=name+asc"),
    {
      initialData: [],
      revalidateOnMount: true,
    }
  );
}
