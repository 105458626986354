import { Button } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import React from "react";
import * as PropTypes from "prop-types";

interface OnChanger {
  (x: number): void;
}

type PaginatorParams = {
  current: number;
  onChange: OnChanger;
  total: number;
  pageSize: number;
};

function Paginator(props: PaginatorParams) {
  const { current, onChange, total, pageSize } = props;

  if (!(total > 0)) return null;

  return (
    <div className={"p-1"}>
      <span className={"mr-2"}>{`${(current - 1) * pageSize + 1}-${Math.min(
        pageSize * current,
        total
      )} de ${total} registros`}</span>
      <Button
        disabled={current === 1}
        icon={<LeftOutlined />}
        size={"small"}
        onClick={() => onChange(Math.max(current - 1, 1))}
      />
      <Button
        disabled={current * pageSize > total}
        icon={<RightOutlined />}
        size={"small"}
        onClick={() => onChange(current + 1)}
      />
    </div>
  );
}

Paginator.propTypes = {
  current: PropTypes.any,
  onChange: PropTypes.func,
  total: PropTypes.any,
};

export default Paginator;
