import React from "react";
import { Checkbox, Form, Select } from "antd";
import { useDataApi } from "../../hooks/useDataApi";

const getLabel = (item) =>
  `${item.name} / ${item["vehicleAssignments.contact.firstName"]} ${item["vehicleAssignments.contact.lastName"]}`;

export default function ShiftPicker({
  onChange,
  entity,
  autoFocus,
  pickDriver,
  setPickDriver,
  error,
  submitted,
}) {
  const [state] = useDataApi("vehicles");
  const vehicles = state.data;

  const options = vehicles
    .filter((x) => x["vehicleAssignments.contact.firstName"])
    .map((item) => ({
      label: getLabel(item),
      value: item.id,
    }));

  return (
    <Form.Item
      help={submitted && error}
      validateStatus={error && submitted ? "error" : "success"}
      label={"Vehículo / Conductor"}
    >
      <Select
        allowClear
        autoFocus={autoFocus}
        onChange={(value) => onChange("vehicleId", value)}
        optionFilterProp={"label"}
        options={options}
        placeholder="Elija el vehículo"
        showSearch
        value={entity["vehicleId"]}
      />
      <Checkbox
        checked={pickDriver}
        onChange={(e) => setPickDriver(e.target.checked)}
      >
        Elegir conductor diferente al asignado.
      </Checkbox>
    </Form.Item>
  );
}
