import React from "react";

import { filterTypes } from "../../constants";
import { useLegacyVehicles } from "../../hooks/useLegacyVehicles";
import { IGridColumn } from "../../types";
import { formatDate } from "../../utils";
import { formatMoney } from "../../utils/format";
import { ActionsMenu } from "./ActionsMenu";

export const getColumns = (onDelete: any): IGridColumn[] => [
  {
    title: "Vehículo",
    dataIndex: "vehicle_id",
    filterType: filterTypes.asyncTag,
    filterPlaceHolder: "Filtrar vehículos",
    useFetcher: useLegacyVehicles,
    render: (text, record) => record.vehicle_name,
    sorter: true,
  },
  {
    title: "Válido desde",
    dataIndex: "valid_from",
    render: (text) => formatDate(text),
    sorter: true,
  },
  {
    title: "Válido hasta",
    dataIndex: "valid_to",
    render: (text) => formatDate(text),
    sorter: true,
  },
  {
    title: "Tipo de cobertura",
    dataIndex: "type",
    sorter: true,
  },
  {
    title: "Compañia aseguradora",
    dataIndex: "vendor_name",
    sorter: true,
  },
  {
    title: "Costo",
    dataIndex: "cost",
    render: (x) => formatMoney(x),
    sorter: true,
  },
  {
    title: "",
    dataIndex: "",
    align: "right",
    render: (text, record) => (
      <ActionsMenu record={record} onDelete={onDelete} />
    ),
  },
];
