import { SuperClusterAlgorithm } from "@googlemaps/markerclusterer";

import { hoursSinceLastPosition } from "../../components/Marker_helpers";
import { FIELD_TYPES, STATIC_SERVER } from "../../constants";
import { ILocation } from "../../types";
import format from "../../utils/format";

export const getCenter = (locationEntries: ILocation[]) => {
  const bound = new google.maps.LatLngBounds();
  for (let i = 0; i < locationEntries.length; i++) {
    bound.extend(
      new google.maps.LatLng(locationEntries[i].lat, locationEntries[i].lng)
    );
  }

  const boundCenter = bound.getCenter();
  return { bound, boundCenter };
};

export const getInfoWindow = (position: ILocation) => {
  const link1 = `"/${position.account_id}/vehicles/${position.vehicle_id}"`;
  return `
    <div class="vehicle-info-window" style="font-size: 12px"> 
        <div style="font-size: 14px">  <a href=${link1}>${
    position.vehicle_name
  }</a></div>
        <hr />
        <div> <strong>${position.driver_name || "-"}</strong></div>
        <div> <strong>${position.speed}</strong> <small>KM/h</small></div>
        <div class="details">
        <hr />
        <div> <strong>Fecha:</strong> ${format(
          position.timestamp,
          FIELD_TYPES.DATE
        )}</div>
        <div> <strong>Hora:</strong> ${format(
          position.timestamp,
          FIELD_TYPES.TIME
        )}</div>
        <div> <strong>Odómetro:</strong> ${format(
          position.odometer / 1000,
          FIELD_TYPES.MILEAGE
        )}</div>
        </div>
  </div>
`;
};

function setGlyphImgSrc(glyphImg: HTMLImageElement) {
  glyphImg.src = STATIC_SERVER + "/icons/cars/car-glyph.svg";
}

function getGlyph() {
  const glyphImg = document.createElement("img");
  setGlyphImgSrc(glyphImg);
  return glyphImg;
}

function getFillColor(position: ILocation) {
  const GREEN = "#15ad5b";
  const RED = "#ec7184";
  const GREY = "#cbc6b8";
  const YELLOW = "#e7d092";

  if (hoursSinceLastPosition(position.timestamp) > 12) {
    return RED;
  } else if (hoursSinceLastPosition(position.timestamp) > 6) {
    return YELLOW;
  } else if (position.ignition === 0) {
    return GREY;
  } else {
    return GREEN;
  }
}

export function getPin(location: ILocation) {
  // @ts-ignore
  return new google.maps.marker.PinElement({
    scale: 1,
    background: getFillColor(location),
    // background: location.ignition === 1 ? "#15ad5b" : "#cbc6b8",
    borderColor: "#3d3d4d",
    glyph: getGlyph(),
  });
}

export class BoundingSuperClusterAlgorithm extends SuperClusterAlgorithm {
  cluster({ map }: any) {
    const bounds = map.getBounds().toJSON();
    const boundingBox = [bounds.west, bounds.south, bounds.east, bounds.north];

    return this.superCluster
      .getClusters(boundingBox, Math.round(map.getZoom()))
      .map(this.transformCluster.bind(this));
  }
}
