import { Button, Tooltip } from "antd";
import React from "react";

import { getByKey } from "../helpers";
import { IGridColumn } from "../types";
import { rangeToString } from "../utils";
import ContactsFiltersInfo from "./DriverSelector/ContactsFiltersInfo";
import PartTypesFilterInfo from "./DriverSelector/PartTypesFiltersInfo";
import StatusFilterInfo from "./DriverSelector/StatusFilterInfo";
import TransactionTypesFiltersInfo from "./DriverSelector/TransactionTypesFiltersInfo";
import VehicleFiltersInfo from "./DriverSelector/VehicleFiltersInfo";

interface FiltersInfoParams {
  filters: { [index: string]: any };
  columns: IGridColumn[];
  clearFilters: () => void;
}

export default function FiltersInfo(props: FiltersInfoParams) {
  const { filters, columns } = props;

  //Keep only the filters that actually contain a value.
  const keys = Object.keys(filters).filter((key) => filters[key]);
  const length = keys.length;

  const overlay = (
    <div style={{ color: "grey" }}>
      {keys.map((key) => {
        const f = getByKey(columns, key, "dataIndex")?.title;
        const value = filters[key];

        let s: any = value?.operand
          ? JSON.stringify(value.value)
          : JSON.stringify(value);

        switch (key) {
          case "date":
            s = rangeToString(value);
            break;
          case "contactId":
            s = <ContactsFiltersInfo values={value} />;
            break;
          case "partTypeId":
            s = <PartTypesFilterInfo values={value} />;
            break;
          case "vehicleId":
          case "vehicle_id":
            s = <VehicleFiltersInfo values={value} />;
            break;
          case "transactionTypeId":
            s = <TransactionTypesFiltersInfo values={value} />;
            break;
          case "statusId":
          case "status_id":
            s = <StatusFilterInfo values={value} />;
            break;
          default:
        }

        return (
          <p key={key}>
            <b>{f}:</b> <small>{s}</small>
          </p>
        );
      })}
    </div>
  );

  return (
    <Tooltip overlay={length > 0 ? overlay : null} color={"white"}>
      <div>
        <small>
          <span style={{ color: "#0098b0" }}>{length} filtros</span>{" "}
          {length === 1 ? "aplicado" : "aplicados"}
        </small>
        {length > 0 && (
          <Button type={"link"} onClick={props.clearFilters}>
            {" "}
            Borrar filtros
          </Button>
        )}
      </div>
    </Tooltip>
  );
}
