import { Menu } from "antd";
import React from 'react';
import {
    SettingOutlined,
    CarOutlined, DollarCircleOutlined,
    FileOutlined, FileTextOutlined, GlobalOutlined,
    IdcardOutlined,
    ToolOutlined, WindowsOutlined, BankOutlined
} from "@ant-design/icons";
import Vehiculos from "./Vehiculos";
import Inicio from './Inicio';
import MapaGeneral from "./MapaGeneral";
import Mantenimiento from "./Mantenimiento";
import Seguros from "./Seguros";
import Conductores from "./Conductores";
import {Reportes} from "./Reportes";
import Finanzas from "./Finanzas";
import Ajustes from "./Ajustes";
import "./Help.css";
import Proveedores from "./Proveedores";

export default function Help() {
  return (
      <div style={{ display: "flex" }}>
          <Menu
              style={{ width: "100vw", position: "fixed", textAlign: "center", zIndex: 1 }}
              defaultSelectedKeys={['dashboard']}
              mode="horizontal"
              theme="dark"
          >
                      <Menu.Item key="dashboard"><a href={"#inicio"}><WindowsOutlined />Inicio</a></Menu.Item>
                      <Menu.Item key="map"><a href={"#mapa-general"}><GlobalOutlined />Mapa</a></Menu.Item>
                      <Menu.Item key="vehicle"><a href={"#vehiculos"}><CarOutlined />Vehiculos</a></Menu.Item>
                      <Menu.Item key="maintenance"><a href={"#mantenimiento"}><ToolOutlined />Mantenimiento</a></Menu.Item>
                      <Menu.Item key="insurance"><a href={"#seguros"}><FileOutlined />Seguros</a></Menu.Item>
                      <Menu.Item key="drivers"><a href={"#conductores"}><IdcardOutlined />Conductores</a></Menu.Item>
                      <Menu.Item key="reports"><a href={"#reportes"}><FileTextOutlined />Reportes</a></Menu.Item>
                      <Menu.Item key="finances"><a href={"#finanzas"}><DollarCircleOutlined />Finanzas</a></Menu.Item>
                      <Menu.Item key="proveedores"><a href={"#proveedores"}><BankOutlined />Proveedores</a></Menu.Item>
                      <Menu.Item key="settings"><a href={"#ajustes"}><SettingOutlined />Ajustes</a></Menu.Item>
          </Menu>

          <div style={{ display: "flex", flexDirection: "column", width: "40%", margin: "40px auto" }}>
              <Inicio />
              <MapaGeneral />
              <Vehiculos />
              <Mantenimiento />
              <Seguros />
              <Conductores />
              <Reportes />
              <Finanzas />
              <Proveedores />
              <Ajustes />
          </div>
      </div>
      )
}
