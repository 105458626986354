import { FIELD_TYPES } from "../constants";
import { ILocation } from "../types";
import format from "../utils/format";

export function getOrientationCode(degrees: number): string {
  const directions = ["N", "NE", "E", "SE", "S", "SO", "O", "NO"];
  const index = Math.round(degrees / 45) % 8;
  return directions[index];
}

export const hoursSinceLastPosition = (date: any) => {
  return Math.round(
    (new Date().getTime() - new Date(date).getTime()) / 1000 / 60 / 60
  );
};

export function getFillColor(position: ILocation, mapType: string | undefined) {
  if (mapType === "fleet") {
    if (hoursSinceLastPosition(position.timestamp) > 12) {
      return "red";
    } else if (hoursSinceLastPosition(position.timestamp) > 6) {
      return "yellow";
    } else if (position.ignition === 0) {
      return "blue";
    } else {
      return "green";
    }
  }

  if (position.ignition === 0) {
    return "blue";
  } else {
    return "green";
  }
}

export const getInfoWindow = (position: ILocation) => {
  return `
    <div> 
        <h3 style="text-align: center; font-size: 18px;">  <a href="/${
          position.account_id
        }/vehicles/${position.vehicle_id}">${position.vehicle_name}</a></h3>
        <p> <strong>Conductor:</strong><a href="/${
          position.account_id
        }/contacts/${position.driver_id}"> ${
    position.driver_name || "-"
  }</a></p>
        <p> <strong>Batería:</strong> ${position.battery} Volts</p>
        <p> <strong>Velocidad:</strong> ${position.speed} KM/h</p>
        <p> <strong>Fecha:</strong> ${format(
          position.timestamp,
          FIELD_TYPES.DATE
        )}</p>
        <p> <strong>Hora:</strong> ${format(
          position.timestamp,
          FIELD_TYPES.TIME
        )}</p>
        <p> <strong>Odómetro:</strong> ${format(
          position.odometer / 1000,
          FIELD_TYPES.MILEAGE
        )}</p>
    </div>
`;
};

export function getPath(position: ILocation) {
  if (position.speed > 0) {
    return window.google.maps.SymbolPath.BACKWARD_CLOSED_ARROW;
  } else {
    return window.google.maps.SymbolPath.CIRCLE;
  }
}

export function getMarkerOptions(
  position: ILocation,
  mapType: string | undefined,
  zoom: number
) {
  let scale: number;
  const path = getPath(position);

  if (mapType === "fleet") {
    scale = zoom > 13 ? 6 : 5;
  } else {
    scale = 3;
  }

  return {
    icon: {
      // anchor: new window.google.maps.Point(0, -3),
      fillColor: getFillColor(position, mapType),
      fillOpacity: 1,
      path,
      rotation: position.direction - 180,
      scale: scale,
      strokeWeight: 1,
    },
  };
}
