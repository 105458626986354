import useSWR from "swr";
import { QueryState } from "../types";
import { post } from "../helpers/api";

export const useSWRSearch = (query: QueryState, url: string) => {
  let fetcher = async () => {
    return (await post(url, query)).data;
  };

  return useSWR(url + JSON.stringify(query), fetcher, {
    initialData: {},
    revalidateOnMount: true,
  });
};
