import { useState } from "react";

import { parseError } from "../helpers";
import { newApi } from "../utils/rest";

export const useOnSubmit = (
  endPoint: string,
  apiVersion: string,
  onSuccess: (arg0: any) => void
) => {
  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const api = newApi()[apiVersion];

  const onSubmit = (values: object) => {
    setSubmitting(true);
    api
      .post(endPoint, values)
      .then((response) => {
        onSuccess && onSuccess(response.data);
      })
      .catch((err) => setError(parseError(err)));

    setSubmitting(false);
  };

  return { onSubmit, submitting, error };
};
