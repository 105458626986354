import React from "react";
import { Table } from "antd";
import { useHistory } from "react-router";
import { useSWRFetch } from "./useSWRFetch";

export function VehiclesTable(props: { placeId: string }) {
  const { data } = useSWRFetch(`places/${props.placeId}/nearby-vehicles`);
  const history = useHistory();

  if (!data) {
    return null;
  }

  const columns = [{ title: "Nombre", dataIndex: "name" }];

  const onRow = (record: any) => {
    return {
      onClick: () =>
        history.push(`/${record.account_id}/vehicles/${record.id}`),
    };
  };

  return (
    <Table
      className="table-striped-rows"
      columns={columns}
      dataSource={data}
      onRow={onRow}
      pagination={false}
      rowClassName={"show-pointer"}
      rowKey={(record) => record.id}
      size={"small"}
    />
  );
}
