import { formatNumber } from "accounting";
import { FIELD_TYPES } from "../constants";
import _ from "lodash";

export const validateDate = (dateString: string): boolean => {
  const regEx = /^\d{4}-\d{2}-\d{2}$/;
  if (!dateString.match(regEx)) return false;
  const d = new Date(dateString);
  const dNum = d.getTime();
  if (!dNum && dNum !== 0) return false;
  return d.toISOString().slice(0, 10) === dateString;
};

export const validateInteger = (number: string): boolean =>
    Number.isInteger(Number(number));

export const validateEmail = (email: string): boolean =>
    /\S+@\S+\.\S+/.test(email);

export const validateText = (text: string): boolean =>
    /^[ÁáÉéÍíÓóÚúÑñA-Za-z _]*[ÁáÉéÍíÓóÚúÑñA-Za-z][ñA-Za-z _]*$/.test(text);

const getRequiredFields = (fields: any[]) =>
    fields.filter((field) => field.required);

const getEmailFields = (fields: any[]) =>
    fields.filter((field) => field.type === FIELD_TYPES.EMAIL);

const getDateFields = (fields: any[]) =>
    fields.filter((field) => field.type === FIELD_TYPES.DATE);

const getNumberFields = (fields: any[]) =>
    fields.filter(
        (field) => field.type === "number" || field.type === "currency"
    );

const getIntegerFields = (fields: any[]) =>
    fields.filter((field) => field.type === FIELD_TYPES.INTEGER);

const getTextFields = (fields: any[]) =>
    fields.filter(
        (field) =>
            field.type === undefined ||
            field.type === "text" ||
            field.type === "password"
    );

const format = (value: number): string => formatNumber(value, 0, ".");

interface ValidationError {
  [key: string]: string;
}

interface Entity {
  [key: string]: any;
}

export default (entity: Entity, fields: any[]): ValidationError => {
  const errors: ValidationError = {};

  getRequiredFields(fields).forEach((field) => {
    if (_.isNil(entity[field.name]) || entity[field.name] === "") {
      errors[field.name] = field.error || "El campo es obligatorio";
    }
  });

  getEmailFields(fields).forEach((field) => {
    if (entity[field.name] && !validateEmail(entity[field.name])) {
      errors[field.name] = "Formato Incorrecto";
    }
  });

  getIntegerFields(fields).forEach((field) => {
    if (entity[field.name] && !validateInteger(entity[field.name])) {
      errors[field.name] = "El valor tiene que ser un número entero";
    }
  });

  getNumberFields(fields).forEach((field) => {
    if (field.max !== undefined && entity[field.name] > field.max) {
      errors[field.name] = `Valor máximo ${format(field.max)}`;
    } else if (field.min !== undefined && entity[field.name] < field.min) {
      errors[field.name] = `Valor mínimo ${format(field.min)}`;
    }
  });

  getDateFields(fields).forEach((field) => {
    if (entity[field.name] && !validateDate(entity[field.name])) {
      errors[field.name] = "Formato Incorrecto";
    }
  });

  getTextFields(fields).forEach((field) => {
    if (entity[field.name]) {
      if (field.letterOnly && !validateText(entity[field.name])) {
        errors[field.name] = "Solo se permiten letras";
      } else if (field.max !== undefined && entity[field.name].length > field.max) {
        errors[field.name] = `Máximo ${field.max} caracteres`;
      } else if (field.min !== undefined && entity[field.name].length < field.min) {
        errors[field.name] = `Mínimo ${field.min} caracteres`;
      } else if (field.noSpaces && entity[field.name].includes(" ")) {
        errors[field.name] = "No se permiten espacios";
      }
    }
  });

  return errors;
};
