import { Button, Form, Input } from "antd";
import React from "react";
import FilterTagSelector from "./FilterTagSelector";
import { filterTypes, OPERANDS } from "../constants";
import { IGridColumn } from "../types";
import { DateRange } from "../organisms/Filters/DateRange";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

interface FiltersFormParams {
  columns: IGridColumn[];
  entity: { [index: string]: any };
  onChange: (key: string, value: any) => void;
  onSubmit: (arg0: {}) => void;
}

export function FiltersForm(props: FiltersFormParams) {
  const { onSubmit, entity, columns } = props;

  return (
    <Form onFinish={onSubmit} style={{ marginTop: 16 }} {...layout}>
      {columns
        .filter((x: any) => x.title && x.filterType)
        .sort((a: any, b: any) => ("" + a.title).localeCompare(b.title))
        .map((x: IGridColumn) => {
          const onChange = (value: any) => props.onChange(x.dataIndex, value);

          const onChangeTags = (value: any) =>
            props.onChange(x.dataIndex, {
              operand: OPERANDS.in,
              value: value,
            });

          const onChangeDateRange = (value: any) => {
            props.onChange(x.dataIndex, {
              operand: OPERANDS.range,
              value: value,
            });
            // props.onChange(x.dataIndex, value);
          };

          const onChangeText = (value: any) =>
            props.onChange(x.dataIndex, {
              operand: OPERANDS.substring,
              value: value,
            });

          switch (x.filterType) {
            case filterTypes.asyncTag:
              return (
                <Form.Item label={x.title} key={x.dataIndex + x.title}>
                  <FilterTagSelector
                    entity={entity}
                    onChange={onChangeTags}
                    column={x}
                  />
                </Form.Item>
              );

            case filterTypes.asyncTagLegacy:
              return (
                <Form.Item label={x.title} key={x.dataIndex + x.title}>
                  <FilterTagSelector
                    entity={entity}
                    onChange={onChange}
                    column={x}
                  />
                </Form.Item>
              );

            case filterTypes.dateRange:
              return (
                <DateRange
                  column={x}
                  onChange={onChangeDateRange}
                  entity={entity}
                />
              );

            default:
              return (
                <Form.Item label={x.title} key={x.dataIndex + x.title}>
                  <Input
                    allowClear
                    placeholder={x.filterPlaceHolder}
                    value={
                      (entity[x.dataIndex] && entity[x.dataIndex].value) || ""
                    }
                    onChange={(e) => onChangeText(e.target.value)}
                  />
                </Form.Item>
              );
          }
        })}
      <div className={"justify-flex-end"}>
        <Button
          type={"primary"}
          style={{ width: "200px", marginBottom: 16 }}
          htmlType={"submit"}
        >
          Filtrar
        </Button>
      </div>
    </Form>
  );
}
