import React from "react";
import { Form, Select } from "antd";
import { useLegacyVehicles } from "../hooks/useLegacyVehicles";

export default function VehicleSelect({
  entity,
  onChange,
  fieldKey = "vehicleId",
}) {
  const { data } = useLegacyVehicles();
  const vehicleId = fieldKey || "vehicleId";
  const a = entity[vehicleId];

  return (
    <Form.Item label={"Vehículo"}>
      <Select
        allowClear
        onChange={(value) => onChange(vehicleId, value)}
        optionFilterProp={"label"}
        options={data.map((item) => ({
          label: item.name,
          value: item.id.toString(),
        }))}
        placeholder="Elija el vehículo"
        showSearch
        value={a && a.toString()}
      />
    </Form.Item>
  );
}
