import { useEffect, useState } from "react";

import { parseError } from "../helpers";
import { newApi } from "../utils/rest";

interface IFetchAndSubmit {
  endPoint: string;
  entityId: string;
  onSuccess: any;
  transform?: any;
  apiVersion: string;
  setFieldsValue: (value: any) => void;
}

type Submitter = {
  submitting: boolean;
  onSubmit: (values: object) => void;
  error: string;
};

export const useFetchAndSubmit = (props: IFetchAndSubmit) => {
  const { endPoint, entityId, onSuccess, setFieldsValue, transform } = props;
  const [error, setError] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);
  const api = newApi()[props.apiVersion];

  //Fetch data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const axiosResponse = await newApi()[props.apiVersion].get(
          `${endPoint}/${entityId}`
        );
        const data = axiosResponse.data;
        setFieldsValue(transform ? transform(data) : data);
      } catch (e) {
        setError(parseError(e));
      }
    };
    fetchData();
  }, [entityId, setFieldsValue, endPoint, transform, props.apiVersion]);

  const onSubmit: (values: object) => void = (values: object) => {
    setSubmitting(true);
    api
      .patch(`${endPoint}/${entityId}`, values)
      .then((response) => {
        onSuccess && onSuccess(response.data);
      })
      .catch((err) => setError(parseError(err)));

    setSubmitting(false);
  };

  const newVar: Submitter = { onSubmit, submitting, error };
  return newVar;
};
