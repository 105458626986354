import { Form } from "antd";
import React, { useContext } from "react";

import { AccountContext } from "../../hooks/useAccount";
import { useOnSubmit } from "../../hooks/useOnSubmit";
import { IAccount, IWithAccountId } from "../../types";
import { VendorBreadcrumb } from "./VendorsBreadcrumb";
import { VendorsForm } from "./VendorsForm";

const path = `proveedores`;

export default function VendorsCreate({ history }: IWithAccountId) {
  const account = useContext<IAccount>(AccountContext);
  const [form] = Form.useForm();

  const onSuccess = () => {
    history.push(`/${account.id}/${path}`);
  };

  const { onSubmit, submitting, error } = useOnSubmit(
    `vendors`,
    "v2",
    onSuccess
  );

  return (
    <div style={{ height: "100vh" }}>
      <div className="entity-header mb-3">
        <VendorBreadcrumb account={account} label={"Registrar"} />
      </div>

      <VendorsForm
        error={error}
        form={form}
        loading={submitting}
        onCancel={() => history.goBack()}
        onSubmit={onSubmit}
        title={`Registrar un proveedor nuevo`}
      />
    </div>
  );
}
