import React from "react";

export default function Vehiculos() {
    return (
        <div id="vehiculos" style={{ borderBottom: "1px solid grey" }}>
            <div style={{ display: "flex" }}>
                <h1 style={{ fontSize: 40, color: "#0066b3" }}>Vehículos</h1>
            </div>

            <div>
                <p style={{ fontSize: 16, marginBottom: 15 }}>
                    Los vehículos son el ente central de la flota y por ello tienen un rol protagónico.
                    Todos el resto de los elementos del sistema tiene una referencia directa o indirecta hacia un vehículo específico.
                </p>
            </div>

            <div style={{ padding: 10 }}>
                <h2>Un vehículo va disponer de las siguientes entidades asociadas:</h2>
                <ul style={{ display: "flex", fontSize: 16, alignItems: "flex-start" }}>
                    <div style={{ width: "50%" }}>
                        <li style={{ flex: 1, margin: 10 }}>Servicios realizados</li>
                        <li style={{ flex: 1, margin: 10 }}>Pólizas de seguros</li>
                        <li style={{ flex: 1, margin: 10 }}>Conductores asignados</li>
                        <li style={{ flex: 1, margin: 10 }}>Ubicaciones en el mapa</li>
                        <li style={{ flex: 1, margin: 10 }}>Lecturas de odómetro</li>
                    </div>
                    <div style={{ width: "50%" }}>
                        <li style={{ flex: 1, margin: 10 }}>Asignación de conductores</li>
                        <li style={{ flex: 1, margin: 10 }}>Registro de póliza de seguro nueva</li>
                        <li style={{ flex: 1, margin: 10 }}>Actualización de póliza de seguro existente</li>
                        <li style={{ flex: 1, margin: 10 }}>Lista de pólizas y pólizas individuales</li>
                        <li style={{ flex: 1, margin: 10 }}>Lista de pólizas pertenecientes a un vehículo</li>
                    </div>
                </ul>
            </div>
        </div>
    )
}
