import React from 'react';

export default function Proveedores() {

    return (
        <div id="proveedores" style={{ borderBottom: "1px solid grey", }}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                <h1 style={{ fontSize: 40, color: "#0066b3" }}>Proveedores</h1>
            </div>

            <div>
                <h3>Morbi rhoncus lacinia felis vitae bibendum. Integer non feugiat tortor</h3>
                <p style={{ fontSize: 16 }}>
                    Duis aliquet et augue vitae rutrum. Phasellus tempor efficitur ante, id finibus arcu gravida eu.
                    Quisque convallis urna magna. Cras interdum tortor ut eros tincidunt porttitor.
                    Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
                </p>
                <p style={{ fontSize: 16 }}>
                    Maecenas fringilla quis augue et aliquam. Quisque at vehicula enim.
                    Mauris magna velit, bibendum et urna non, maximus rhoncus augue.
                </p>
            </div>
        </div>
    )
}
