import React from "react";
import { formatMoney, formatNumber } from "../../utils/format";
import { Link } from "react-router-dom";
import { HighlightedText } from "../../components/HighlightedText";

const compareFn = (key) => (a, b) => {
  if (a[key] < b[key]) return -1;
  if (a[key] > b[key]) return 1;
  return 0;
};

export const getColumns = (accountId, query, iterator, page) => {
  const { search, groupBy } = query;
  let render;

  if (groupBy === "both") {
    render = (text, record) => (
      <div>
        <Link to={`/${accountId}/vehicles/${record.vehicleId}`}>
          <HighlightedText needle={search} haystack={record.name} />
        </Link>{" "}
        /{" "}
        <Link to={`/${accountId}/vehicles/${record.contactId}`}>
          <HighlightedText needle={search} haystack={record.contact} />
        </Link>
      </div>
    );
  } else if (groupBy === "vehicleId") {
    render = (text, record) => (
      <Link to={`/${accountId}/vehicles/${record.id}`}>
        <HighlightedText needle={search} haystack={record.name} />
      </Link>
    );
  } else {
    render = (text, record) => (
      <Link to={`/${accountId}/contacts/${record.id}`}>
        <HighlightedText needle={search} haystack={record.contact} />
      </Link>
    );
  }

  return [
    {
      title: "",
      dataIndex: "id",
      key: "id",
      render: (value, item, index) => (page - 1) * 15 + index + 1,
    },
    {
      title: groupBy === "vehicleId" ? "Vehículo" : "Conductor",
      dataIndex: "id",
      sorter: compareFn(groupBy === "vehicleId" ? "name" : "contact"),
      key: "id",
      render: render,
    },
  ].concat(
    iterator.map((x) => ({
      title: x,
      dataIndex: x,
      key: x,
      sorter: (a, b) => a[x] - b[x],
      align: "right",
      render: (text) => {
        if (x !== "Total Mes") {
          return formatNumber(text, 0);
        } else {
          return <small>{formatNumber(text, 0)}</small>;
        }
      },
    })),
    [
      {
        title: "Total",
        dataIndex: "Total",
        align: "right",
        key: "Total",
        sorter: (a, b) => a.Total - b.Total,
        render: (text) => formatMoney(text),
      },
    ]
  );
};
