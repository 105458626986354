import { MinusCircleOutlined } from "@ant-design/icons";
import { Alert, Avatar, DatePicker, Form, Input, Modal } from "antd";
import locale from "antd/es/date-picker/locale/es_ES";
import moment from "moment-timezone";
import React, { useState } from "react";
import { Link } from "react-router-dom";

import { patch } from "../../api";
import { FIELD_TYPES } from "../../constants";
import useForm from "../../hooks/useForm";
import { formatDate, odomoterUpdateHelp } from "../../utils";

export default ({ vehicle, assignment, accountId }) => {
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const fullName = assignment.firstName + " " + assignment.lastName;

  const { formState, handleSubmit, onChange } = useForm(
    {
      contactId: assignment.contactId,
      startDate: assignment.startDate,
      finishDate: moment(),
      vehicleId: vehicle.id,
    },
    [
      {
        name: "finishDate",
        required: true,
      },
      {
        name: "meterEntryValue",
        type: FIELD_TYPES.INTEGER,
      },
    ]
  );

  const { entity, submitting, asyncError } = formState;

  const onSubmit = async () => {
    await patch(
      "vehicle-assignments",
      { ...entity, endingMeterEntry: { value: entity.meterEntryValue } },
      assignment.id
    );
    document.body.style.overflow = "initial";
    window.location.reload();
  };

  let showModal = () => {
    document.body.style.overflow = "hidden";
    setModalIsVisible(true);
  };

  let closeModal = () => {
    document.body.style.overflow = "initial ";
    setModalIsVisible(false);
  };

  let modal = (
    <Modal
      title={`Desasignar ${vehicle.name}`}
      visible={modalIsVisible}
      onOk={handleSubmit(onSubmit)}
      okText={"Desasignar"}
      cancelText={"Cancelar"}
      confirmLoading={submitting}
      onCancel={closeModal}
    >
      <Form layout={"vertical"}>
        {asyncError && <Alert message={asyncError.message} type="error" />}

        <div>
          Actualmente asignado a:
          <div
            style={{
              display: "flex",
              alignItems: "center",
              background: "#dbe5ee",
              padding: "0px 10px",
            }}
          >
            <Avatar>
              {(assignment.firstName[0] + assignment.lastName[0]).toUpperCase()}
            </Avatar>
            <div style={{ paddingLeft: 20 }}>
              <p style={{ marginBottom: 0, marginTop: 5, fontWeight: 800 }}>
                {fullName}
              </p>
              <p>{formatDate(assignment.startDate)}</p>
            </div>
          </div>
        </div>
        <br />

        <Form.Item label={"Fecha y hora de finalización"}>
          <DatePicker
            showTime
            locale={locale}
            style={{ width: "100%" }}
            placeholder={"Fecha finalización de la asignación."}
            format="DD/MM/YYYY HH:mm"
            value={
              entity["finishDate"] ? moment(entity["finishDate"]) : undefined
            }
            onChange={(e) => onChange("finishDate", e)}
          />
        </Form.Item>
        <Form.Item
          label={
            <div>
              Kilometraje <small>(opcional)</small>
            </div>
          }
          help={odomoterUpdateHelp(vehicle)}
        >
          <Input
            placeholder={"Ingresa el kilometraje"}
            onChange={(e) =>
              onChange(
                "meterEntryValue",
                e.target.value.replace(/\D/g, "").replace(/\./gi, "")
              )
            }
            value={entity["meterEntryValue"]}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
  return (
    <div>
      {modal}
      <Link
        to={{
          pathname: `/${accountId}/contacts/${assignment.contactId}`,
          state: { menuKey: ["conductores"] },
        }}
      >
        {fullName}
      </Link>

      {<MinusCircleOutlined className={"ml-1"} onClick={showModal} />}
    </div>
  );
};
