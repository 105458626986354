import React from "react";
import { RightOutlined } from "@ant-design/icons";
import { List } from "antd";

export default function Conductores() {
    const data = [
        {
            title: 'Fecha de inicio',
        },
        {
            title: 'Valor del odómetro al inicio',
        },
        {
            title: 'Observaciones',
        },
        {
            title: 'Registro de imágenes',
        },
        {
            title: 'Registro de documentación firmada',
        },
        {
            title: 'Valor del odómetro al finalizar',
        },
        {
            title: 'Fecha de finalización',
        }
    ];

    return (
        <div id="conductores" style={{ borderBottom: "1px solid grey" }}>
            <div style={{ display: "flex" }}>
                <h1 style={{ fontSize: 40, color: "#0066b3" }}>Conductores</h1>
            </div>

            <div style={{ padding: 10 }}>
                <p style={{ fontSize: 18 }}>
                    Inmatics permite tener un directorio de conductores habilitados.
                    Los conductores pueden ser asignados a un vehículo.
                </p>
                <h3>
                    Cada vez que se realiza una asignación se guardan los siguientes datos:
                </h3>
            </div>

            <div style={{ padding: 10 }}>
                <List
                    itemLayout="horizontal"
                    dataSource={data}
                    renderItem={(item) => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={<RightOutlined />}
                                title={item.title}
                            />
                        </List.Item>
                    )}
                />
            </div>
        </div>
    )
}
