import React from "react";
import { Card, Col, Row, Statistic } from "antd";
import { useDataApi } from "../../hooks/useDataApi";
import { nFormatter } from "../../utils";
import { DollarOutlined } from "@ant-design/icons";

export default function DashboardTransactions() {
  const [state] = useDataApi(`transactions/summary`);
  const { data } = state;

  const title = (
    <div>
      <DollarOutlined className={"muted mr-2"} />
      Cobranzas
    </div>
  );

  return (
    <Card title={title} className={"mb-3"}>
      <Row>
        {[
          { title: "Últimos 7 días", key: "seven" },
          { title: "Últimos 30 días", key: "thirty" },
          { title: "Últimos 90 días", key: "ninety" },
        ].map((x) => (
          <Col key={x.key} span={8}>
            {" "}
            <Statistic
              title={x.title}
              value={data[x.key]}
              decimalSeparator={","}
              formatter={(value) => nFormatter(Math.abs(value), 2)}
            />
          </Col>
        ))}
      </Row>
    </Card>
  );
}
