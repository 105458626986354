import useSWR from "swr";
import {newApi} from "../utils/rest";

export function useStatuses() {
  const endpoint = "statuses";

  return useSWR(endpoint, () => newApi().v3.get(endpoint), {
    initialData: null,
    revalidateOnMount: true,
  });
}