import { useEffect, useState } from "react";
import { GEO_POLYGON_SET } from "../constants";

export const Polygon = (options) => {
  const [polygon, setMarker] = useState();
  const [infoWindow, setInfoWindow] = useState();

  const polygonData = (options.coordinates || []).map((coordinate) => ({
    lat: coordinate[0],
    lng: coordinate[1],
  }));

  const infoWindowStyle = "font-size: 14px";

  const infoWindowData = `
    <div>
        <h1 style='${infoWindowStyle}'> <strong>Nombre:</strong> ${options.name}</h1>
    </div>
`;

  //Create point
  useEffect(() => {
    if (!polygon) {
      setMarker(
        new window.google.maps.Polygon({
          paths: polygonData,
          strokeColor: GEO_POLYGON_SET.POLYGON_BORDER_COLOR,
          strokeOpacity: 0.5,
          strokeWeight: 2,
          fillColor: GEO_POLYGON_SET.POLYGON_FILL_COLOR,
          fillOpacity: 0.35,
        })
      );
    }

    // remove polygon from map on unmount
    return () => {
      if (polygon) {
        polygon.setMap(null);
      }
    };
  }, [polygon, polygonData]);

  // Set polygon options
  useEffect(() => {
    if (polygon) {
      polygon.setOptions({ ...options, paths: polygonData });
    }
  }, [polygon, options, polygonData]);

  // Create Info Window
  useEffect(() => {
    if (!infoWindow) {
      setInfoWindow(
        new window.google.maps.InfoWindow({
          content: infoWindowData,
        })
      );
    }
  }, [infoWindow, infoWindowData]);

  // Render Info Window onCLick
  useEffect(() => {
    if (polygon && infoWindow) {
      polygon.addListener("click", () => {
        infoWindow.open({
          anchor: polygon,
          shouldFocus: false,
        });
      });
    }
  }, [polygon, infoWindow]);

  return null;
};
