import React, { useContext, useEffect, useState } from "react";
import moment from "moment-timezone";
import {
  Alert,
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
} from "antd";
import { DollarOutlined, PlusCircleOutlined } from "@ant-design/icons";
import locale from "antd/es/date-picker/locale/es_ES";
import useForm from "../../hooks/useForm";
import { post } from "../../api";
import ConceptSelector from "../../components/ConceptSelector";
import { TextInput } from "../../components/Inputs";
import { useDataApi } from "../../hooks/useDataApi";
import OdometerUpdateField from "../../components/OdometerUpdateField";
import ShiftPicker from "./ShiftPicker";
import { AccountContext } from "../../hooks/useAccount";
import ContactSelect from "../../components/DriverSelector/ContactSelect";
import { pick } from "lodash";
import { showNotification } from "../../utils";

export default function ({ mutate, accountId }) {
  const [pickDriver, setPickDriver] = useState(false);
  const [details, setDetails] = useState(false);
  const [state] = useDataApi("vehicles");
  const account = useContext(AccountContext);
  const [showModal, setShowModal] = useState(false);

  const vehicles = state.data;

  const { formState, onChange, handleSubmit, errors, setEntity } = useForm(
    {
      transactionTypeId: account.transactionTypeId,
      date: moment(),
    },
    [
      { name: "amount", required: true },
      { name: "vehicleId", required: true },
    ]
  );

  useEffect(() => {
    onChange(
      "transactionTypeId",
      account.transactionTypeId && account.transactionTypeId.toString()
    );
  }, [account, onChange]);

  const { entity, asyncError, submitted, submitting } = formState;

  const onSubmit = async () => {
    //Prevent double submission
    if (submitting) {
      return;
    }

    let contactId = vehicles.find(
      (x) => x.id.toString() === entity.vehicleId.toString()
    )["vehicleAssignments.contact.id"];

    const res = await post("transactions", {
      ...entity,
      date: entity.date.format("YYYY-MM-DD"),
      ...(pickDriver ? {} : { contactId }),
    });

    mutate();

    setEntity(pick(entity, ["date", "transactionTypeId"]));

    showNotification(accountId, res);
  };

  return (
    <div>
      <Modal
        visible={showModal}
        footer={
          <div>
            <Button type={"secondary"} onClick={() => setShowModal(false)}>
              Salir
            </Button>
            <Button
              onClick={handleSubmit(onSubmit)}
              loading={submitting}
              type={"primary"}
            >
              Guardar
            </Button>
          </div>
        }
        onCancel={() => setShowModal(false)}
        title={
          <div className="card-title">
            <div>
              <DollarOutlined style={{ color: "#AAAAAA" }} />
              {" Cargar cobranza"}
            </div>
          </div>
        }
        className={"mb-4"}
      >
        <Form layout={"vertical"} onFinish={handleSubmit(onSubmit)}>
          {asyncError && (
            <Alert
              className={"mb-4"}
              description={
                <ul>
                  <li>{asyncError.message}</li>
                </ul>
              }
              message={"Solucione los siguientes errores"}
              type="error"
              showIcon
            />
          )}

          <Form.Item label={"Fecha"}>
            <DatePicker
              locale={locale}
              autoFocus
              format="DD/MM/YYYY"
              onChange={(e) => {
                onChange("date", e);
              }}
              value={entity["date"] ? moment(entity["date"]) : undefined}
            />
          </Form.Item>

          <ShiftPicker
            submitted={submitted}
            error={errors["vehicleId"]}
            onChange={onChange}
            setPickDriver={setPickDriver}
            pickDriver={pickDriver}
            entity={entity}
          />

          {pickDriver && (
            <ContactSelect
              submitted={submitted}
              entity={entity}
              onChange={onChange}
              errors={errors}
            />
          )}

          <ConceptSelector
            onChange={(key, value) => {
              onChange(key, value);
            }}
            entity={entity}
          />

          <TextInput
            onChange={onChange}
            placeholder={"Ingrese el monto percibido (Ej. $1.000)"}
            entity={entity}
            size={"large"}
            error={errors["amount"]}
            name={"amount"}
            submitted={submitted}
            label={"Monto"}
          />

          <Divider />
          <Checkbox
            checked={details}
            onChange={(e) => setDetails(e.target.checked)}
          >
            Más
          </Checkbox>

          {details && (
            <div>
              <OdometerUpdateField
                entity={entity}
                onChange={onChange}
                optional
                vehicleList={state.data}
              />
              <Input.TextArea
                placeholder={"Notas adicionales que se quieran agregar"}
                rows={2}
                onChange={(e) => onChange("comment", e.target.value)}
                value={entity["comment"]}
              />
            </div>
          )}

          <Button
            style={{ display: "none" }}
            type={"primary"}
            htmlType={"submit"}
            disabled={submitting}
          >
            GUARDAR
          </Button>
        </Form>
      </Modal>
      <Button
        type={"primary"}
        className={"ml-1"}
        onClick={() => setShowModal(true)}
        icon={<PlusCircleOutlined />}
      >
        Cargar cobranza
      </Button>
    </div>
  );
}
