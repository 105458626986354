import { PieChartOutlined } from "@ant-design/icons";
import { Badge, Card, Spin } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { useSimpleSWRFetch } from "src/utils/rest";

import { getURI } from "../../helpers/GetURI";

interface Status {
  id: number;
  name: string;
  color: string;
  count: number;
}

// Define the props for the DashboardVehicleStatus component
interface DashboardVehicleStatusProps {
  accountId: number;
}

const DashboardVehicleStatus: React.FC<DashboardVehicleStatusProps> = ({
  accountId,
}) => {
  const { error, data } = useSimpleSWRFetch("reports?sort=count+desc");

  if (error) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <p className="error-message">
          Error fetching data. Please try again later.
        </p>
      </div>
    );
  }

  if (!data) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <Spin size="large" />
      </div>
    );
  }

  const title = (
    <div>
      <PieChartOutlined className="muted mr-2" />
      Cantidad de vehículos por estado.
    </div>
  );
  const linkToVehicle = (statusId: number) => {
    const url = getURI(`/${accountId}/vehicles`, {
      page: 0,
      filters: { status_id: { operand: "in", value: statusId } },
    }).split("?");

    return {
      pathname: url[0],
      search: "?" + url[1],
      state: { menuKey: ["vehiculos"] },
    };
  };

  return (
    <Card title={title} bodyStyle={{ maxHeight: 300, overflowY: "auto" }}>
      {data.map((status: Status) => (
        <Link to={linkToVehicle(status.id)} key={status.id}>
          <div
            style={{
              display: "flex",
              borderBottom: "1px dashed rgb(228, 228, 228)",
              justifyContent: "space-between",
              marginBottom: "5px",
            }}
          >
            <div style={{ width: "80%", minWidth: "80%", marginBottom: 10 }}>
              {status.name}
            </div>
            <Badge
              overflowCount={999}
              showZero
              count={status.count}
              style={{ backgroundColor: status.color, minWidth: "32px" }}
            />
          </div>
        </Link>
      ))}
    </Card>
  );
};

export default DashboardVehicleStatus;
