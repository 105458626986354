import { Table, Tooltip } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import useSWR from "swr";

import { FIELD_TYPES, filterTypes } from "../../constants";
import { getURI } from "../../helpers/GetURI";
import { get } from "../../helpers/v2api";
import format from "../../utils/format";

const HOURS_IN_A_DAY = 24;

function fancyTimeFormat(duration: any) {
  // Hours, minutes and seconds
  let days = 0;
  let hrs = Math.floor(duration / 3600);
  const mins = Math.floor((duration % 3600) / 60);
  const secs = Math.floor(duration % 60);

  // Output like "1:01" or "4:03:59" or "123:03:59"
  let ret = "";

  // Output like "1 dia/s 1:01" or "2 dia/s 4:03:59"
  if (hrs >= HOURS_IN_A_DAY) {
    days = howManyHours(hrs);
    hrs -= days * HOURS_IN_A_DAY;
    ret += days + "dia/s ";
  }

  if (hrs > 0) ret += "" + hrs + ":" + (mins < 10 ? "0" : "");

  ret += "" + mins + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;
  return ret;
}

const howManyHours = (hrs: number) => {
  let days = 0;

  while (hrs >= HOURS_IN_A_DAY) {
    days++;
    hrs -= HOURS_IN_A_DAY;
  }

  return days;
};

const columns = [
  {
    title: "Vehículo",
    dataIndex: "name",
    render: (text: string, record: any) => (
      <Link
        to={`/${record.account_id}/vehicles/${record.vehicle_id}/viajes/${record.id}`}
      >
        {record.name}
      </Link>
    ),
  },
  {
    title: "Inicio",
    dataIndex: "started_at",
    filterType: filterTypes.dateRange,
    render: (text: string) => (
      <Tooltip
        placement="topLeft"
        title={format(text, FIELD_TYPES.RELATIVE_TIME)}
      >
        {format(text, FIELD_TYPES.DATETIME)}
      </Tooltip>
    ),
  },
  {
    title: "Fin",
    dataIndex: "ended_at",
    render: (text: string) => format(text, FIELD_TYPES.DATETIME),
  },
  {
    title: "Velocidad máxima",
    dataIndex: "max_speed",
    align: "right",
  },
  {
    title: "Duración",
    dataIndex: "elapsed_seconds",
    align: "right",
    render: (text: string) => {
      return fancyTimeFormat(text);
    },
  },
];

const useSWRFetch = (url: string) => {
  const fetcher = async () => {
    const axiosResponse = await get(url);

    return {
      data: axiosResponse.data,
      count: axiosResponse.headers["X-Total-Count"],
    };
  };

  return useSWR(url, fetcher, {
    initialData: null,
    revalidateOnMount: true,
  });
};

export function TripsList(props: { vehicleId: any }) {
  const { vehicleId } = props;

  const { data } = useSWRFetch(
    getURI("trips", {
      filters: { vehicle_id: vehicleId },
      page: 1,
    })
  );

  return (
    <div>
      <Table
        dataSource={data?.data}
        rowClassName={"show-pointer"}
        // @ts-ignore
        columns={columns}
        pagination={false}
        size={"small"}
        rowKey={(record) => record.id}
      />
    </div>
  );
}
