import { Table } from "antd";
import { formatMoney } from "../utils/format";
import React from "react";

export default ({ iterator, row }) => {
  return (
    <>
      <Table.Summary.Row>
        <Table.Summary.Cell colSpan={2} />
        {iterator.map((item) => (
          <Table.Summary.Cell key={item} className="text-right">
            <b>{formatMoney(row[item])}</b>
          </Table.Summary.Cell>
        ))}
        <Table.Summary.Cell className="text-right">
          <b>{formatMoney(row["Total"])}</b>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    </>
  );
};
