import useSWR from "swr";

import { newApi } from "../../../utils/rest";

export function useStatuses() {
  return useSWR(
    "vehicles",
    () =>
      newApi()
        .v3.get(`statuses`)
        .then((x) => x.data),
    {
      initialData: [],
      revalidateOnMount: true,
    }
  );
}
