import {DownOutlined} from "@ant-design/icons";
import {Dropdown, Menu} from "antd";
import React from "react";

import {Vehicle} from "../types";
import {useSimpleSWRFetch} from "../utils/rest";

interface StatusSelectorProps {
    vehicle: Vehicle;
    onStatusSubmit: any;
}

export default ({vehicle, onStatusSubmit}: StatusSelectorProps) => {

    const {error, data} = useSimpleSWRFetch("reports?sort=count+desc");

    if (error) {
        return null;
    }

    if (!data ) {
        return null
    }

    const find = data.find(
        (x: { id: any }) => Number(x.id) === Number(vehicle.status_id)
    );

    return (
        <div className={"ml-3 mr-3"}>
            <span style={{color: find && find.color}}>&#9673; </span>

            <Dropdown
                overlay={
                    <Menu onClick={(a) => onStatusSubmit(a.key)}>
                        {data.map((item: any) => (
                            <Menu.Item key={item.id}>{item.name}</Menu.Item>
                        ))}
                    </Menu>
                }
                trigger={["click"]}
            >
        <span className="ant-dropdown-link">
          {find && find.name} <DownOutlined/>
        </span>
            </Dropdown>
        </div>
    );
};
