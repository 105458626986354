import { Col, Row } from "antd";
import React, { useState } from "react";

import { OPERANDS } from "../../constants";
import { getURI } from "../../helpers/GetURI";
import { get } from "../../helpers/v2api";
import useForm from "../../hooks/useForm";
import { QueryState } from "../../types";
import { Query } from "./Query";
import { Results } from "./Results";

const endPoint = "reports/location-entries";

export function LocationEntriesReport() {
  const [data, setData] = useState();
  const fields = [
    { name: "vehicle_id", required: true },
    { name: "timestamp", required: true },
  ];
  const { formState, onChange, handleSubmit, errors } = useForm({}, fields);
  const { entity, submitted } = formState;

  const onSubmit = async () => {
    let q: QueryState = {
      filters: {},
      page: 1,
    };

    q.filters["vehicle_id"] = {
      operand: OPERANDS.eq,
      value: entity["vehicle_id"],
    };

    q.filters["timestamp"] = {
      operand: OPERANDS.range,
      value: entity["timestamp"],
    };

    const uri = getURI(endPoint, q);
    const axiosResponse = await get(uri);
    setData(axiosResponse.data);
  };

  return (
    <Row>
      <Col span={4}>
        <Query
          errors={errors}
          submitted={submitted}
          entity={entity}
          onChange={onChange}
          onSubmit={handleSubmit(onSubmit)}
        />
      </Col>
      <Col span={20}>
        <Results data={data} />
      </Col>
    </Row>
  );
}
