import { Button, Card, Col, Row } from "antd";
import { upperFirst } from "lodash";
import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { AuthContext } from "../hooks/useAuth";
import { User } from "../types";

type EmptyViewParams = {
  icon: React.ReactNode;
  h2Text: React.ReactNode;
  description: React.ReactNode;
  accountId: any;
  entity: any;
  buttonText: React.ReactNode;
};

export default function WelcomeView(props: EmptyViewParams) {
  const user = useContext<User>(AuthContext);

  return (
    <div style={{ minHeight: 1000, paddingTop: 150 }}>
      <Row>
        <Col span={12} offset={6}>
          <h1>
            {upperFirst(user.first_name?.toLowerCase())}, te damos la bienvenida
            a Inmatics.
          </h1>

          <Card>
            <div
              style={{
                minHeight: 300,
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {props.icon}
              <h2>{props.h2Text}</h2>
              <p style={{ maxWidth: "80%" }}>{props.description}</p>
              <div style={{ display: "inline" }}>
                <Link to={`/${props.accountId}/${props.entity}/create`}>
                  {" "}
                  <Button type={"primary"}>{props.buttonText}</Button>
                </Link>
                <span>{" o "}</span>
                <Link to={`/${props.accountId}/bulk-insert`}>
                  {" "}
                  <Button type={"dashed"}>Importar desde un archivo</Button>
                </Link>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
