import React from "react";
import { List } from "antd";
import { RightOutlined } from "@ant-design/icons";

export function Reportes() {
    const data = [
        {
            title: 'Asignaciones',
            description: 'Historial de movimientos entre las asignaciones de conductores a los vehículos de la flota'
        },
        {
            title: 'Ubicaciones',
            description: 'Registros de ubicaciones y recorridos de los vehículos, filtrado por el rango de fecha seleccionado'
        },
        {
            title: 'Cobranzas semanales',
            description: 'Registros de cobranzas organizado por año'
        },
        {
            title: 'Cobranzas anuales',
            description: 'Registros de cobranzas organizado por año'
        },
        {
            title: 'Ubicaciones diarias',
            description: 'Registros de ubicaciones organizado por día'
        }
    ]

    return (
        <div id="reportes" style={{ borderBottom: "1px solid grey" }}>
            <div style={{ display: "flex" }}>
                <h1 style={{ fontSize: 40, color: "#0066b3" }}>Reportes</h1>
            </div>

            <div>
                <p style={{ fontSize: 18 }}>
                    Confeccionamos reportes personalizados para interpretar y analizar los datos de cada vehículo.
                    Esto permite tomar decisiones de negocio inteligentes basadas en información precisa y confiable.
                </p>
            </div>

            <div style={{ padding: 10 }}>
                <List
                    itemLayout="horizontal"
                    dataSource={data}
                    renderItem={(item) => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={<RightOutlined />}
                                title={item.title}
                                description={item.description}
                            />
                        </List.Item>
                    )}
                />
            </div>
        </div>
    )
}
