import { Table } from "antd";
import React from "react";
import { Link } from "react-router-dom";

import { useSWRSearch } from "../../../hooks/useSWRSearch";
import { IWithAccountId } from "../../../types";
import { renderTime } from "../../../utils";
import { formatMoney } from "../../../utils/format";

export default function ExpensesTable(props: IWithAccountId) {
  const { match } = props;
  const { vehicleId, accountId } = match.params;
  const { data } = useSWRSearch(
    { filters: { vehicleId: [vehicleId] }, page: 1 },
    `expenses/search`
  );

  if (!data) {
    return null;
  }

  return (
    <Table
      columns={[
        {
          title: "ID",
          dataIndex: "id",
          render: (text, record) => (
            <Link
              to={`/${accountId}/vehicles/${record.vehicleId}/gastos/${record.id}`}
            >
              {text}
            </Link>
          ),
        },
        { title: "Fecha", key: "date", dataIndex: "date", render: renderTime },
        {
          title: "Monto",
          key: "amount",
          dataIndex: "amount",
          align: "right",
          render: (text) => {
            return `${formatMoney(text)}`;
          },
        },
      ]}
      size={"small"}
      className={"reports"}
      dataSource={data.rows}
      pagination={{ pageSize: 15 }}
    />
  );
}
