import React from "react";
import { getByKey } from "../../utils";
import { useTransactionTypes } from "../../hooks/useTransactionTypes";

export default function FiltersInfo({ values }) {
  const ve = useTransactionTypes();

  return (
    <>
      {values
        .map((y) => {
          let byKey = getByKey(ve.data, y);
          return (byKey || "").name;
        })
        .join(", ")}
    </>
  );
}
