import { formatNumber as accountingFormatNumber } from "accounting";
import { DateTime } from "luxon";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";

import { FIELD_TYPES } from "../constants/index";

export const formatMileage = (value) =>
  value !== ""
    ? accountingFormatNumber(value, 0, ".", ",") + " km"
    : "Sin informar";

export const formatMoney = (value, decimals = 0) =>
  value !== "" ? "$" + accountingFormatNumber(value, decimals, ".", ",") : "$0";

export const formatNumber = (value, decimals = 2) =>
  value !== "" ? accountingFormatNumber(value, decimals, ".", ",") : "";

export const getFormattedDate = (date) =>
  moment(date).isValid() ? moment(date, "YYYY-MM-DD").format("DD-MM-YYYY") : "";

export const formatPercentage = (value) =>
  accountingFormatNumber(value, 2, ".", ",") + "%";

export const getAge = (birthDate) =>
  moment(birthDate).isValid() && birthDate !== undefined
    ? moment().diff(moment(birthDate), "years")
    : "";

export default (value, type, options) => {
  switch (type) {
    case FIELD_TYPES.CHECKBOX:
      return value ? "SI" : "NO";

    case FIELD_TYPES.CURRENCY:
      return formatMoney(value, 2);

    case FIELD_TYPES.RELATIVE_TIME:
      return DateTime.fromISO(value).toRelative({ locale: "es" });

    case FIELD_TYPES.PERCENTAGE:
      return formatPercentage(value);

    case FIELD_TYPES.MILEAGE:
      return formatMileage(value);

    case FIELD_TYPES.TIME:
      return value
        ? DateTime.fromSQL(value.replace("T", " ")).toFormat("HH:mm:ss")
        : "";

    case FIELD_TYPES.DATETIME:
      return value
        ? DateTime.fromSQL(value.replace("T", " ")).toLocaleString(
            DateTime.DATETIME_SHORT
          )
        : "";

    case FIELD_TYPES.DATE:
      return value
        ? DateTime.fromSQL(value.replace("T", " ")).toLocaleString(
            DateTime.DATE_MED
          )
        : "";

    case FIELD_TYPES.INTEGER:
      return value ? formatNumber(value, 0) : "0";

    case "link":
      return <Link to={`/${options.route}/${value}`}>{value}</Link>;

    default:
      return value || value === 0 ? value : "-";
  }
};
