import { useState } from "react";

export function useRecursivePosts(onFinish, createFunction) {
  const [inserts, setInserts] = useState([]);
  const [errors, setErrors] = useState([]);

  async function postRecursively(data, mapping) {
    const item = data[0];

    if (!item) {
      onFinish && onFinish();
      return;
    }

    try {
      let payload = {};

      for (const key in mapping) {
        if (mapping.hasOwnProperty(key) && mapping[key]) {
          payload[mapping[key]] = item[key];
        }
      }

      const result = await createFunction(payload);
      setInserts((prevState) => [...prevState, result]);
    } catch (e) {
      setErrors((prevState) => [...prevState, e]);
    } finally {
      data.shift();
      await postRecursively(data, mapping);
    }
  }

  return { inserts, postRecursively, errors };
}
