import React from "react";
import {Card, Statistic} from "antd";
import {ThunderboltOutlined} from "@ant-design/icons";
import {useSimpleSWRFetch} from "../../utils/rest";
import {LocationEntry} from "../../types";

export default function DashboardVehicleIgnition() {
    // @ts-ignore
    const {data} = useSimpleSWRFetch<LocationEntry[]>("locations/last");

    // Early return if data is not available
    if (!data) return null;

    // Count vehicles with ignition on
    // @ts-ignore
    const ignitionCount = data.reduce((count, entry) => count + (entry.ignition === 1 ? 1 : 0), 0);

    const title = (
        <div>
            <ThunderboltOutlined className="muted mr-2"/>
            Vehículos encendidos
        </div>
    );

    return (
        <Card title={title} bodyStyle={{maxHeight: 300, overflowY: "auto"}}>
            <div style={{display: "flex", justifyContent: "center"}}>
                <Statistic
                    title="Vehículos encendidos"
                    value={ignitionCount}
                    valueStyle={{color: "#3f8600"}}
                    style={{textAlign: "center"}}
                />
            </div>
        </Card>
    );
}
