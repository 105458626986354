import React from "react";
import { FileTextOutlined } from "@ant-design/icons";

export function PlacesBreadcrumb() {
  return (
    <div>
      <h3>
        <FileTextOutlined className={"muted"} /> Lugares{" "}
        <small>/ Crear uno nuevo lugar</small>
      </h3>
    </div>
  );
}
