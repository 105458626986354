import { Alert, Button, Card, Checkbox, Col, Form, Input, Row } from "antd";
import React from "react";
import * as PropTypes from "prop-types";
import PlaceMapView from "./PlaceMapView";
import { post } from "../../helpers/v2api";

export function PlaceForm({ form, error, onCancel, onFinish, loading }) {
  const cancel = () => {
    onCancel();
  };

  const onMapChange = (x) => {
    form.setFieldsValue(x);
    post("maps", x).then((x) => {
      form.setFieldsValue({
        formatted_address: x.data[0]["formatted_address"],
      });
    });
  };

  return (
    <Card>
      <Row>
        <Col span={12} className={"p-2"}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Form
              form={form}
              onFinish={onFinish}
              layout={"vertical"}
              // style={{ minWidth: 800 }}
            >
              {error && (
                <Alert message={error} className={"mb-3"} type="error" />
              )}
              <Form.Item
                label="Nombre"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese el nombre del lugar.",
                  },
                ]}
              >
                <Input placeholder={"Nombre del lugar"} autoFocus />
              </Form.Item>
              <Form.Item label="Dirección" name="formatted_address">
                <Input
                  placeholder={"Escriba la dirección Ej. (Cerrito 484,  CABA)"}
                />
              </Form.Item>
              <Form.Item
                label="Descripcion del lugar"
                name="description"
                rules={[]}
              >
                <Input placeholder={"Breve descripción del lugar."} />
              </Form.Item>
              <Form.Item style={{ display: "none" }} label="Latitud" name="lat">
                <Input disabled />
              </Form.Item>
              <Form.Item
                style={{ display: "none" }}
                label="Longitud"
                name="lng"
              >
                <Input disabled />
              </Form.Item>

              <h3>Tags</h3>
              <Row>
                <Form.Item
                  label="Taller"
                  name="is_mechanic_shop"
                  valuePropName="checked"
                >
                  <Checkbox />
                </Form.Item>
                <Form.Item
                  label="Almacén"
                  className={"ml-5"}
                  name="is_warehouse"
                  valuePropName="checked"
                >
                  <Checkbox />
                </Form.Item>
              </Row>
              <Form.Item>
                <Button loading={loading} onClick={cancel} className={"mr-2"}>
                  Cancelar
                </Button>
                <Button loading={loading} type={"primary"} htmlType={"submit"}>
                  Guardar
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Col>
        <Col span={12} className={"p-2"}>
          <PlaceMapView place={form} onChange={onMapChange} />
        </Col>
      </Row>
    </Card>
  );
}

PlaceForm.propTypes = {
  form: Form,
  onFinish: PropTypes.func,
  error: PropTypes.string,
  vehicles: PropTypes.any,
  loading: PropTypes.bool,
};
