import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Alert, Button, Card, Popconfirm, Tooltip, notification } from "antd";
import React from "react";
import { Link } from "react-router-dom";

import { remove } from "../../api";
import { useEntity } from "../../hooks/useEntity";
import { formatMoney } from "../../utils/format";
import CommentCard from "../Services/CommentCard";

export default function CollectionView({ match, history }) {
  const { id, accountId } = match.params;
  const { state } = useEntity(id, `transactions`);
  const { fetching, entity, asyncError } = state;

  if (asyncError) {
    return <Alert message={asyncError.message} type="error" />;
  }

  if (fetching) {
    return null;
  }

  const destroy = async () => {
    await remove("transactions", id);
    history.push(`/${accountId}/vehicles/${entity.vehicleId}`);
    notification.info({ message: `Se elimino la transaccion #${entity.id}` });
  };

  let title = (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div>Detalles</div>
      <div>
        <Tooltip placement={"bottom"} title={"Eliminar cobranza"}>
          <Popconfirm
            title="¿Esta seguro que desea eliminar esta cobranza"
            onConfirm={destroy}
            okText="Sí"
            cancelText="No"
          >
            <Button icon={<DeleteOutlined />} type={"link"} danger />
          </Popconfirm>
        </Tooltip>

        <Link
          to={{
            pathname: `/${accountId}/collections/${id}/edit`,
            state: { menuKey: ["finanzas", "cobranzas"] },
          }}
        >
          <Button type={"dashed"} size={"small"}>
            <EditOutlined />
            Editar cobranza
          </Button>
        </Link>
      </div>{" "}
    </div>
  );
  return (
    <div className="entity-view">
      <Card title={title} style={{ minHeight: 320, marginBottom: 20 }}>
        <table className="service-view-table">
          <tbody>
            <tr>
              <td align={"right"}>
                <b>Fecha</b>
              </td>
              <td>{entity.date}</td>
            </tr>
            <tr>
              <td align={"right"}>
                <b>Vehículo</b>
              </td>
              <td>
                <Link
                  to={{
                    pathname: `/${accountId}/vehicles/${entity.vehicleId}`,
                    state: { menuKey: ["vehiculos", "summary-vehicle"] },
                  }}
                >
                  {entity.vehicle.name}
                </Link>
              </td>
            </tr>
            <tr>
              <td align={"right"}>
                <b>Conductor</b>
              </td>
              <td>
                {entity.contactId ? (
                  <Link
                    to={{
                      pathname: `/${accountId}/contacts/${entity.contactId}`,
                      state: { menuKey: ["conductores"] },
                    }}
                  >
                    {entity.contact.firstName} {entity.contact.lastName}
                  </Link>
                ) : (
                  "-"
                )}
              </td>
            </tr>

            <tr>
              <td align={"right"}>
                <b>Monto</b>
              </td>
              <td>{formatMoney(entity.amount, 2)}</td>
            </tr>
            <tr>
              <td align={"right"}>
                <b>Tipo:</b>
              </td>
              <td>{entity.transactionType.name}</td>
            </tr>
            <tr>
              <td align={"right"}>
                <b>Referencia:</b>
              </td>
              <td>{entity.reference}</td>
            </tr>
          </tbody>
        </table>
      </Card>
      <CommentCard commentableId={id} commentableType={"transactionId"} />
    </div>
  );
}
