import {
  AimOutlined,
  BankOutlined,
  CarOutlined,
  TagOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import React, { useContext, useState } from "react";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import Header from "src/components/Header";
import { AuthContext } from "src/hooks/useAuth";
import { User } from "src/types";
import styled from "styled-components";

import AccountsList from "./AccountsList";
import { Devices } from "./Devices/Devices";
import LocationsList from "./LocationsList";
import Mqtt from "./Mqtt";
import SendSMS from "./SendSMS";
import UsersList from "./UsersList";
import VehiclesList from "./VehiclesList";

const { Content, Sider } = Layout;

const Wrapper = styled(Layout)`
  margin-top: 64px;
`;

const routes = [
  {
    path: "/admin/accounts",
    label: "Cuentas",
    icon: <BankOutlined />,
    component: AccountsList,
    exact: true,
  },
  {
    path: "/admin/devices",
    label: "Dispositivos",
    icon: <TagOutlined />,
    component: Devices,
  },
  {
    path: "/admin/vehicles",
    label: "Vehiculos",
    icon: <CarOutlined />,
    component: VehiclesList,
    exact: true,
  },
  {
    path: "/admin/locations",
    label: "Ubicaciones",
    icon: <AimOutlined />,
    component: LocationsList,
    exact: true,
  },
  {
    path: "/admin/users",
    label: "Usuarios",
    icon: <UserOutlined />,
    component: UsersList,
    exact: true,
  },
  {
    path: "/admin/send-sms",
    label: "Enviar SMS",
    icon: <TagOutlined />,
    component: SendSMS,
    exact: true,
  },
  {
    path: "/admin/mqtt",
    label: "mqtt",
    icon: <UserOutlined />,
    component: Mqtt,
    exact: true,
  },
];

export default function Admin() {
  const user: User = useContext(AuthContext);
  const [collapsed, setCollapsed] = useState(false);

  const onCollapse = (collapsed: boolean) => setCollapsed(collapsed);

  if (!user.platform_admin) return null;

  return (
    <Layout id={"root"}>
      <Header user={user} accountId={undefined} />
      <Wrapper>
        <Sider
          collapsible
          collapsed={collapsed}
          width={220}
          onCollapse={onCollapse}
          className={"sidebar"}
        >
          <Menu theme="dark" mode="inline">
            {routes.map((r) => (
              <Menu.Item icon={r.icon} key={r.path}>
                <Link to={r.path}>{r.label}</Link>
              </Menu.Item>
            ))}
          </Menu>
        </Sider>
        <Content className="main-content">
          <Switch>
            {routes.map((r) => (
              <Route path={r.path} component={r.component} exact={r.exact} />
            ))}
            <Redirect to={"/admin/devices"} />
          </Switch>
        </Content>
      </Wrapper>
    </Layout>
  );
}
