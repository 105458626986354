import {
  EditOutlined,
  FileTextOutlined,
  InfoCircleOutlined,
  ProfileOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Badge, Button, Card, Col, Divider, Row } from "antd";
import { DateTime } from "luxon";
import React from "react";
import { Link } from "react-router-dom";

import { useEntity } from "../../hooks/useEntity";
import { calculateDuration, formatDate } from "../../utils";
import { formatNumber } from "../../utils/format";
import CommentCard from "./CommentCard";
import DocumentsCard from "./DocumentsCard";
import FilesCard from "./FilesCard";

export default function ServiceView({ match }) {
  const { serviceId, accountId } = match.params;
  const { state } = useEntity(serviceId, `services`);
  const { fetching, entity } = state;

  if (fetching) return <p>loading...</p>;

  const duration = calculateDuration(entity.startDate, entity.finishDate);

  let user = state.entity.role.user;
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className={"section-title-bis"}>
          <FileTextOutlined />
          Detalles
        </div>

        <Link
          to={{
            pathname: `/${accountId}/services/${serviceId}/edit`,
            state: { menuKey: ["vehiculos"] },
          }}
        >
          <Button type={"dashed"}>
            <EditOutlined /> EDITAR SERVICIO
          </Button>
        </Link>
      </div>

      <div className={"section-container"}>
        <table className="service-view-table">
          <tbody>
            <tr>
              <td align={"right"}>
                <b>Fecha inicio:</b>
              </td>
              <td>
                {entity.startDate
                  ? DateTime.fromISO(entity.startDate).toLocaleString({
                      month: "short",
                      day: "numeric",
                      weekday: "long",
                      year: "numeric",
                      locale: "es",
                    })
                  : "-"}
              </td>
            </tr>
            <tr>
              <td align={"right"}>
                <b>Fecha fin:</b>
              </td>
              <td>
                {entity.finishDate
                  ? DateTime.fromISO(entity.finishDate).toLocaleString({
                      month: "short",
                      day: "numeric",
                      weekday: "long",
                      year: "numeric",
                      locale: "es",
                      minute: "2-digit",
                      hour: "numeric",
                    })
                  : "-"}
              </td>
            </tr>
            {duration && (
              <tr>
                <td align={"right"}>
                  <b>Duración</b>
                </td>
                <td>{duration} </td>
              </tr>
            )}
            <tr>
              <td align={"right"}>
                <b>Odómetro:</b>
              </td>
              <td>{formatNumber(entity.meterEntry.value, 0)} km</td>
            </tr>
          </tbody>
        </table>
      </div>

      <Row>
        <Col span={12} className={"pr-1"}>
          <Card
            style={{ height: "100%" }}
            title={
              <div>
                <InfoCircleOutlined className={"mr-1"} />
                Trabajos realizados
                <Badge
                  showZero
                  count={entity.tasks.length}
                  style={{ backgroundColor: "#636f73", marginLeft: 5 }}
                />
              </div>
            }
          >
            <ul>
              {entity.tasks.map((x) => (
                <li key={x.taskType.name}>{x.taskType.name}</li>
              ))}
            </ul>
          </Card>
        </Col>
        <Col span={12} className={"pl-1"}>
          <Card
            style={{ height: "100%" }}
            title={
              <div>
                <SettingOutlined className={"mr-1"} />
                Repuestos Utilizados
                <Badge
                  showZero
                  count={entity.parts.length}
                  style={{ backgroundColor: "#636f73", marginLeft: 5 }}
                />
              </div>
            }
          >
            <ul>
              {entity.parts.map((x) => (
                <li key={x.partType.name}>
                  {x.partType.name} ({formatNumber(x.qty, 0)})
                </li>
              ))}
            </ul>
          </Card>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={24} className={"section-title-bis mt-2"}>
          <ProfileOutlined />
          Detalles adicionales
        </Col>
        <Col span={12} className={"pr-1"}>
          <CommentCard
            commentableId={serviceId}
            commentableType={"serviceId"}
          />
        </Col>

        <Col span={12} className={"pl-1"}>
          <DocumentsCard fillableId={serviceId} fillableType={"serviceId"} />
        </Col>
        <Col span={24} className={"pl-1"}>
          <FilesCard fillableId={serviceId} fillableType={"serviceId"} />
        </Col>
      </Row>
      <small className={"muted"}>
        Creado por {user.firstName} {user.lastName}
      </small>
      <small className={"muted"}> día {formatDate(entity.createdAt)}</small>
    </div>
  );
}
