import { post } from "../../api";
import { post as postV2 } from "../../helpers/v2api";
import { segurosBulk } from "./segurosBulk";
import { vendorsBulk } from "./vendorsBulk";

export default {
  vehicles: {
    fields: {
      name: "nombre",
      license_plate: "patente",
      year: "año",
      brand: "marca",
      model: "modelo",
      vin: "Chasis",
      owner: "titular",
      ownerCUIT: "CUIT",
      imei: "Imei",
      taxiLicense: "Licencia",
      engineNumber: "Numero Motor",
      km: "Kilometraje",
      comment: "Comentario",
    },
    fnCreate: (payload) => post("vehicles", payload),
  },
  contacts: {
    fields: {
      firstName: "nombre",
      lastName: "apellido",
    },
    fnCreate: (payload) => post("contacts", payload),
  },
  devices: {
    fields: {
      vehicleName: "Nombre del vehículo",
      model: "Modelo del dispositivo",
      imei: "IMEI del dispositivo",
      phoneNumber: "Número de teléfono",
    },
    fnCreate: (payload) => postV2("devices", payload),
  },
  seguros: segurosBulk,
  proveedores: vendorsBulk,
};
