import { Alert, Button, Card, Col, Form, Input, Row, Select } from "antd";
import React from "react";
import { FileTextOutlined } from "@ant-design/icons";
import { defaultLayout } from "../../constants";

export function VendorsForm({
  error,
  form,
  loading,
  onCancel,
  onSubmit,
  title,
}) {
  const cancel = () => {
    onCancel();
  };

  return (
    <Row>
      <Col {...defaultLayout}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h2>{title}</h2>
          <h3>
            <FileTextOutlined className={"muted"} /> Detalles
          </h3>
          <Form
            form={form}
            onFinish={onSubmit}
            layout={"vertical"}
            style={{ minWidth: 800 }}
          >
            <Card>
              {error && (
                <Alert message={error} className={"mb-3"} type="error" />
              )}
              <Form.Item label="Nombre corto" name="short_name">
                <Input placeholder={"Nombre corto"} />
              </Form.Item>
              <Form.Item label="Razon social" name="legal_name">
                <Input placeholder={"Razon social"} />
              </Form.Item>
              <Form.Item label="CUIT" name="tax_id">
                <Input placeholder={"CUIT"} />
              </Form.Item>
              <Form.Item name={"tax_category"} label={"Categoria impositiva"}>
                <Select
                  placeholder={"Categoria impositiva"}
                  options={["RI", "MO"].map((x) => ({
                    value: x,
                    label: `${x}`,
                  }))}
                />
              </Form.Item>
              <Form.Item>
                <Button loading={loading} onClick={cancel} className={"mr-2"}>
                  Cancelar
                </Button>
                <Button loading={loading} type={"primary"} htmlType={"submit"}>
                  Guardar
                </Button>
              </Form.Item>
            </Card>
          </Form>
        </div>
      </Col>
    </Row>
  );
}
