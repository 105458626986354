import React from "react";
import { Badge, message, Table } from "antd";
import { renderDateRangeAndDuration, renderDistance } from "../../utils";
import { useSWRFetch } from "../../helpers";

import { remove } from "../../api";
import { Link } from "react-router-dom";
import { CommentOutlined } from "@ant-design/icons";
import ActionsMenu from "../../components/ActionsMenu";
import dottie from "dottie";
import CarSVG from "../../components/CarSVG";

const noPropagation = (e) => e.stopPropagation();
const LinkNoPropagate = (props) => <Link {...props} onClick={noPropagation} />;

const renderVehicle = (text, record) => {
  const vehicle = dottie.transform(record).vehicle;
  const noPropagation = (e) => e.stopPropagation();

  return (
    <div style={{ display: "flex", alignItems: "center", paddingLeft: "10" }}>
      <CarSVG type={"Auto"} width={40} vehicle={vehicle} />
      <div className={"ml-1"}>
        <LinkNoPropagate
          onClick={noPropagation}
          to={`/${record.vehicle.accountId}/vehicles/${record.vehicleId}`}
        >
          <b>{vehicle.name}</b>
        </LinkNoPropagate>
        <p>
          <small>
            <Badge style={{ marginRight: -5 }} color={vehicle.status.color} />
            {vehicle.status.name} • {vehicle.year} {vehicle.brand}{" "}
            {vehicle.model}
          </small>
        </p>
      </div>
    </div>
  );
};

export default function AssignmentsTable({ match, history }) {
  const { entityId } = match.params;
  let key = `contactId`;
  const { data, mutate } = useSWRFetch(
    `vehicle-assignments?page=1&q[s]=name+asc&q[${key}_in][]=${entityId}`
  );

  const onDelete = async (record) => {
    try {
      await remove("vehicle-assignments", record.id);
      message.success(`El registro removido`);
      await mutate();
    } catch (err) {
      console.log(err);
    }
  };

  if (!data) {
    return null;
  }

  let columns = [
    { title: "", dataIndex: "vehicleId", render: renderVehicle },

    {
      title: "",
      dataIndex: "startDate",
      render: renderDateRangeAndDuration,
    },
    {
      title: "",
      dataIndex: "startDate",
      render: renderDistance,
    },

    {
      title: "",
      dataIndex: "comm",
      render: (text, record) => (
        <span>
          <CommentOutlined /> {record.comments && record.comments.length}{" "}
          comentarios
        </span>
      ),
    },

    {
      title: "",
      dataIndex: "id",
      render: (text, record) => (
        <ActionsMenu record={record} onDelete={onDelete} />
      ),
    },
  ];

  if (!data) {
    return null;
  }

  if (data.length === 0) {
    return null;
    // return <ServiceEmtpyView accountId={accountId} />;
  }

  const onRow = (record) => {
    return {
      onClick: () => {
        console.log(record.id);
        history.push(
          `/${record.vehicle.accountId}/vehicles/${record.vehicle.id}/asignaciones/${record.id}`
        );
      },
    };
  };

  return (
    <div>
      <Table
        onRow={onRow}
        dataSource={data}
        rowClassName={"show-pointer"}
        columns={columns}
        pagination={false}
        size={"small"}
        rowKey={(record) => record.id}
      />
    </div>
  );
}
