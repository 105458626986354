import { get } from "../../helpers/v2api";
import useSWR from "swr";

export const useSWRFetch = (url) => {
  const fetcher = async () => {
    return (await get(url)).data;
  };

  return useSWR(url, fetcher, {
    initialData: null,
    revalidateOnMount: true,
  });
};
