import React from 'react';

export default function Inicio() {
    return (
        <div id="inicio" style={{ borderBottom: "1px solid grey" }}>
            <div style={{ display: "flex" }}>
                <h1 style={{ fontSize: 40, color: "#0066b3" }}>Inicio</h1>
            </div>

            <div style={{ margin: "0px 0px 30px 0px" }}>
                <h3 style={{ fontSize: 24 }}>¿Qué muestra cada panel?</h3>
                <p style={{ fontSize: 16 }}>
                    El Inicio está organizado en paneles con detalles principales y actividades recientes de tu flota.
                    También funciona como acceso rápido a otros sectores de la aplicación.
                </p>
            </div>

            <div>
                <div style={{ margin: "20px 0px" }}>
                    <h4 style={{ fontSize: 18 }}><b>Cantidad de vehículos por estado</b></h4>
                    <p style={{ fontSize: 16 }}>Clickear en uno de los estados te redirigirá al listado de vehículos con el estado seleccionado como filtro</p>
                </div>
                <div style={{ margin: "20px 0px" }}>
                    <h4 style={{ fontSize: 18 }}><b>Últimos comentarios</b></h4>
                    <p style={{ fontSize: 16 }}>Seleccionar una palabra en azul te redirigirá al respectivo elemento (servicio, conductor, vehículo, etc)</p>
                </div>
                <div style={{ margin: "20px 0px" }}>
                    <h4 style={{ fontSize: 18 }}><b>Últimas ubicaciones - Sólo visible con servicio de telemática</b></h4>
                    <p style={{ fontSize: 16 }}>Seleccionar el elemento azulado te llevará al último viaje, en proceso o finalizado</p>
                </div>
                <div style={{ margin: "20px 0px" }}>
                    <h4 style={{ fontSize: 18 }}><b>Vehículos asignados a un conductor</b></h4>
                    <p style={{ fontSize: 16 }}>Clickear en un número te llevará a la lista de vehículos con la asignación seleccionada como filtro</p>
                </div>
                <div style={{ margin: "20px 0px" }}>
                    <h4 style={{ fontSize: 18 }}><b>Vehículos encendidos</b></h4>
                    <p style={{ fontSize: 16 }}>Clickear en un número te llevará a la lista de vehículos con la asignación seleccionada como filtro</p>
                </div>
            </div>
        </div>
    )
}
