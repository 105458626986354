import React from "react";
import ReactDOM from "react-dom";
import "./style/index.less";
import App from "./App";
import { getCookie } from "./helpers";
import { MobileApp } from "./MobileApp/MobileApp";

if (!getCookie("token")) {
  window.location.replace("https://auth.inmatics.io");
}

function iOS() {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}

const isIOS = iOS();

ReactDOM.render(
  <React.StrictMode>{isIOS ? <MobileApp /> : <App />}</React.StrictMode>,
  document.getElementById("root")
);
