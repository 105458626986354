import React, { useContext, useEffect, useState } from "react";
import { parse, stringify } from "query-string";
import moment from "moment-timezone";
import { Alert, Button, Card, Col, DatePicker, Divider, Form, Row } from "antd";
import { DollarOutlined } from "@ant-design/icons";
import locale from "antd/es/date-picker/locale/es_ES";
import Breadcrumb from "../../components/Breadcrumb";
import { defaultLayout } from "../../constants";
import useForm from "../../hooks/useForm";
import { post } from "../../api";
import ConceptSelector from "../../components/ConceptSelector";
import { TextInput } from "../../components/Inputs";
import { useDataApi } from "../../hooks/useDataApi";
import Comment from "../../components/Comment";
import ShiftPicker from "./ShiftPicker";
import { AccountContext } from "../../hooks/useAccount";
import ContactSelect from "../../components/DriverSelector/ContactSelect";
import { pick } from "lodash";
import { showNotification } from "../../utils";

export default function CollectionsCreate({ match, location, history }) {
  const { accountId } = match.params;
  const [pickDriver, setPickDriver] = useState(false);
  const [state] = useDataApi("vehicles");
  const account = useContext(AccountContext);
  const pathname = location.pathname;
  const { vehicleId, date, transactionTypeId } = parse(location.search);
  const vehicles = state.data;

  const {
    formState,
    onChange,
    handleSubmit,
    submitting,
    errors,
    setEntity,
  } = useForm(
    {
      vehicleId: vehicleId ? Number(vehicleId) : undefined,
      transactionTypeId: transactionTypeId || account.transactionTypeId,
      date: date ? moment(date) : moment(),
    },
    [
      { name: "amount", required: true },
      { name: "vehicleId", required: true },
    ]
  );

  useEffect(() => {
    onChange(
      "transactionTypeId",
      transactionTypeId ||
        (account.transactionTypeId && account.transactionTypeId.toString())
    );
  }, [account, onChange, transactionTypeId]);

  const { entity, asyncError, submitted } = formState;

  const onSubmit = async (createOneMore) => {
    //Prevent double submission
    if (submitting) {
      return;
    }

    let contactId = vehicles.find(
      (x) => x.id.toString() === entity.vehicleId.toString()
    )["vehicleAssignments.contact.id"];

    const res = await post("transactions", {
      ...entity,
      date: entity.date.format("YYYY-MM-DD"),
      ...(pickDriver ? {} : { contactId }),
    });

    if (createOneMore) {
      setEntity(pick(entity, ["date", "transactionTypeId"]));
    } else {
      history.push(
        `/${accountId}/vehicles/${entity.vehicleId}/collections/${res.id}`
      );
    }
    showNotification(accountId, res);
  };

  const routes = [
    { to: "/" + accountId + "/collections", label: "Cobranza" },
    { label: "Cargar cobranza" },
  ];

  return (
    <div className={"mb-5"}>
      <div className="entity-header mb-3">
        <Breadcrumb routes={routes} />
      </div>
      <Row>
        <Col {...defaultLayout}>
          <Card
            title={
              <div className="card-title">
                <div>
                  <DollarOutlined style={{ color: "#AAAAAA" }} />
                  {" Cargar cobranza"}
                </div>
              </div>
            }
            className={"mb-4"}
          >
            <Form layout={"vertical"} onFinish={handleSubmit(onSubmit)}>
              {asyncError && (
                <Alert
                  className={"mb-4"}
                  description={
                    <ul>
                      <li>{asyncError.message}</li>
                    </ul>
                  }
                  message={"Solucione los siguientes errores"}
                  type="error"
                  showIcon
                />
              )}

              <Form.Item label={"Fecha"}>
                <DatePicker
                  locale={locale}
                  autoFocus
                  style={{ width: "100%" }}
                  format="DD/MM/YYYY"
                  onChange={(e) => {
                    onChange("date", e);
                    history.push({
                      pathname,
                      search: stringify({
                        date: e.format("YYYY-MM-DD"),
                        transactionTypeId: entity["transactionTypeId"],
                      }),
                    });
                  }}
                  value={entity["date"] ? moment(entity["date"]) : undefined}
                />
              </Form.Item>

              <ShiftPicker
                submitted={submitted}
                error={errors["vehicleId"]}
                onChange={onChange}
                setPickDriver={setPickDriver}
                pickDriver={pickDriver}
                entity={entity}
              />

              {pickDriver && (
                <ContactSelect
                  submitted={submitted}
                  entity={entity}
                  onChange={onChange}
                  errors={errors}
                />
              )}

              <ConceptSelector
                onChange={(key, value) => {
                  onChange(key, value);
                  history.push({
                    pathname,
                    search: stringify({
                      transactionTypeId: value,
                      date: moment(entity["date"]).format("YYYY-MM-DD"),
                    }),
                  });
                }}
                entity={entity}
              />

              <TextInput
                onChange={onChange}
                placeholder={"Ingrese el monto percibido (Ej. $1.000)"}
                entity={entity}
                error={errors["amount"]}
                name={"amount"}
                submitted={submitted}
                label={"Monto"}
              />
              <TextInput
                onChange={onChange}
                placeholder={"Ej: Numero de transferencia"}
                entity={entity}
                error={errors["reference"]}
                name={"reference"}
                submitted={submitted}
                label={"Referencia"}
              />

              <Divider />

              <div className={"justify-flex-end"}>
                <Button
                  className="mr-2"
                  style={{ width: 150 }}
                  type="secondary"
                  onClick={() => history.goBack()}
                >
                  CANCELAR
                </Button>
                <Button
                  type={"primary"}
                  htmlType={"submit"}
                  disabled={submitting}
                  style={{ minWidth: 200 }}
                >
                  GUARDAR
                </Button>
              </div>
            </Form>
          </Card>
          <Comment onChange={onChange} entity={entity} />
        </Col>
      </Row>
    </div>
  );
}
