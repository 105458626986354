import React from "react";
import GoogleMapReact from "google-map-react";
import { Tooltip } from "antd";
import { Link } from "react-router-dom";
import { GOOGLE_MAPS_KEY } from "../../constants";

const Marker = ({ children }) => children;

function getIcon(x) {
  let src = "https://static.inmatics.io/icons/icoGeo10.svg";
  if (x.ignition === 2) {
    src = "https://static.inmatics.io/icons/icoGeo06_danger.svg";
  } else if (x.ignition === 0) {
    src = "https://static.inmatics.io/icons/icoGeo06_mute.svg";
  }
  return src;
}

export default function PlaceVehiclesMapView({ place }) {
  const point = { lat: place.lat, lng: place.lng };
  const data = place.vehicles;
  if (!data) {
    return null;
  }

  return (
    <div style={{ height: "600px", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: GOOGLE_MAPS_KEY }}
        defaultCenter={point}
        center={point}
        defaultZoom={17}
      >
        {data.map((vehicle) => {
          const x = vehicle.last_location_entry || {};
          return (
            <Marker lat={x.lat} lng={x.lng} text="x.vehicleId">
              <Tooltip
                key={vehicle.id}
                color={"white"}
                // trigger={"click"}
                overlay={
                  <div style={{ flexDirection: "column" }}>
                    <Link to={`/${vehicle.account_id}/vehicles/${vehicle.id}`}>
                      <div>
                        <strong>
                          {vehicle.name} | {vehicle.license_plate}
                        </strong>
                      </div>
                    </Link>
                    <div style={{ color: "black" }}>
                      Vel: {x.speed}{" "}
                      <small
                        style={{
                          color: "black",
                          fontStyle: "italic",
                          fontWeight: "bold",
                        }}
                      >
                        km/h
                      </small>
                    </div>
                  </div>
                }
              >
                <img src={getIcon(x)} width={32} alt="" />
              </Tooltip>
            </Marker>
          );
        })}
      </GoogleMapReact>
    </div>
  );
}
