//npm
import moment from "moment-timezone";
import React from "react";
import { Alert, Button, Col, Form, notification, Row } from "antd";
import { parse } from "query-string";

//inmatics
import Breadcrumb from "../../components/Breadcrumb";
import Comment from "../../components/Comment";
import PartsSelector from "../../components/PartsSelector";
import ServiceDetails from "../../components/ServiceDetails";
import TaskSelector from "../../components/TaskSelector";
import useForm from "../../hooks/useForm";
import { defaultLayout, timezone } from "../../constants";
import { post } from "../../api";

export default function ServiceCreate({ match, history, location }) {
  const { accountId } = match.params;

  const { formState, onChange, handleSubmit } = useForm(
    {
      tasks: [],
      parts: [],
      files: [],
      vehicleId: parse(location.search).vehicleId
        ? Number(parse(location.search).vehicleId)
        : undefined,
      finishDate: moment().tz(timezone).format("YYYY-MM-DD HH:mm"),
    },
    []
  );
  const { entity, asyncError } = formState;

  const onSubmit = async () => {
    const service = await post("services", entity);
    history.push(
      `/${accountId}/vehicles/${entity.vehicleId}/services/${service.id}`
    );
  };

  const onAddTask = (e) => {
    if (entity.tasks.find((task) => task.taskTypeId === e)) {
      notification.error({ message: "Tarea repetida, no se agregó." });
      return;
    }

    onChange("tasks", [...entity.tasks, { taskTypeId: e }]);
    notification.success({ message: "Se agregó una tarea al servicio." });
  };

  const onAddPart = (e) => {
    const message = "Autoparte repetida, no se agregó.";
    const message2 = "Se agregó una tarea al servicio.";

    if (entity.parts.find((part) => part["partTypeId"] === e)) {
      notification.error({ message: message });
      return;
    }

    onChange("parts", [...entity["parts"], { partTypeId: e, qty: 1 }]);
    notification.success({ message: message2 });
  };

  const onRowChange = (key, value, record) => {
    const id = "partTypeId";

    onChange("parts", [
      ...entity["parts"].filter((x) => x[id] !== record[id]),
      { [id]: record[id], [key]: value },
    ]);
  };

  const onRemoveTask = (e) => {
    onChange("tasks", [...entity.tasks.filter((x) => e !== x)]);
    notification.success({ message: "Se eliminó la tarea." });
  };

  const onRemovePart = (e) => {
    onChange("parts", [...entity.tasks.filter((x) => e !== x)]);
    notification.success({ message: "Se eliminó la autoparte." });
  };

  return (
    <div className={"mb-5"}>
      <div className="entity-header mb-3">
        <Breadcrumb
          routes={[
            { to: "/" + accountId + "/services", label: "Servicios" },
            { label: "Crear Servicio" },
          ]}
        />

        <div className="entity-header__buttons">
          <Button
            className="mr-2"
            style={{ width: 150 }}
            type="secondary"
            onClick={() => history.goBack()}
          >
            CANCELAR
          </Button>
          <Button type="primary" onClick={handleSubmit(onSubmit)}>
            GUARDAR SERVICIO
          </Button>
        </div>
      </div>
      <Row>
        <Col {...defaultLayout}>
          <Form layout={"vertical"} onFinish={handleSubmit(onSubmit)}>
            {asyncError && (
              <Alert
                className={"mb-4"}
                description={
                  <ul>
                    <li>{asyncError.message}</li>
                  </ul>
                }
                message={"Solucione los siguientes errores."}
                type="error"
                showIcon
              />
            )}
            <ServiceDetails onChange={onChange} entity={entity} />
            <TaskSelector
              onSelect={onAddTask}
              entity={entity}
              onRemoveTask={onRemoveTask}
            />
            <PartsSelector
              onSelect={onAddPart}
              entity={entity}
              onRowChange={onRowChange}
              onRemove={onRemovePart}
            />
            <Comment onChange={onChange} entity={entity} />
            <div className={"form-buttons-container"}>
              <Button
                className="mr-2"
                style={{ width: 150 }}
                type="secondary"
                onClick={() => history.goBack()}
              >
                Cancelar
              </Button>
              <Button type={"primary"} htmlType={"submit"}>
                Guardar Servicio.
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </div>
  );
}
