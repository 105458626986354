import React from "react"
import {DatePicker, Form} from "antd";
import locale from "antd/es/date-picker/locale/es_ES";
import {dateRanges} from "../../utils";
import {IGridColumn} from "../../types";

// Date range filter component
export function DateRange(props: {column: IGridColumn, onChange: any, entity: any}) {
    const {column, onChange, entity} = props;

    const entityElement = entity[column.dataIndex];

    return (
        <Form.Item label={column.title} >
            <DatePicker.RangePicker
                style={{ width: "100%" }}
                value={(entityElement?.operand) ? entityElement.value : entityElement}
                locale={locale}
                // @ts-ignore
                ranges={dateRanges}
                onChange={onChange}
            />
        </Form.Item>
    );
}
