import { Table } from "antd";
import React from "react";
import { useHistory } from "react-router";

import { getOnDelete, getOnTableChange } from "../../helpers";
import { getURI } from "../../helpers/GetURI";
import { useQuery } from "../../hooks/useQuery";
import { TableFilters } from "../../routes/Vehicles/VehicleList/VehiclesTableFilters";
import { TableHeader } from "../../routes/Vehicles/VehicleList/VehiclesTableHeader";
import { download } from "../../utils/download";
import { useSWRFetch } from "../../utils/rest";
import { getColumns } from "./columns";

const endPoint = "admin/devices";

export default function AdminDevicesList() {
  const { state, setSort, setPage, clearFilters, onChange } = useQuery({
    filters: {},
    sort: "id",
    page: 1,
  });

  const { error, data, mutate } = useSWRFetch(getURI(endPoint, state));
  const history = useHistory();

  const onRow = (record: any) => {
    return {
      onClick: () => history.push(`/admin/devices/${record.id}`),
    };
  };

  const columns = getColumns(getOnDelete(endPoint, mutate));

  if (error) {
    return <div>{JSON.stringify(error)}</div>;
  }

  return (
    <div>
      <TableHeader
        addLink={`/admin/devices/create`}
        addText={"Nuevo dispositivo"}
        iconURL={"https://static.inmatics.io/icons/icoSeguridad.svg"}
        onDownload={() =>
          download("admin/devices/download", `listado_dispositivos`)
        }
        title={"Listado de dispositivos"}
      />
      <TableFilters
        clearFilters={clearFilters}
        columns={columns}
        onChange={onChange}
        queryState={state}
        setPage={setPage}
        totalCount={data?.count}
      />
      <Table
        dataSource={data?.data}
        rowClassName={"show-pointer"}
        onChange={getOnTableChange(setSort)}
        columns={columns}
        onRow={onRow}
        pagination={false}
        size={"small"}
        rowKey={(record) => record.id}
      />
    </div>
  );
}
