import { Form } from "antd";
import moment from "moment";
import React, { useContext } from "react";

import { AccountContext } from "../../hooks/useAccount";
import { useFetchAndSubmit } from "../../hooks/useFetchAndSubmit";
import { IAccount, IWithAccountId } from "../../types";
import { InvoiceForm } from "./InvoicesForm";

const endPoint = `invoices`;
const path = `facturas`;

export default function VendorsEdit({ match, history }: IWithAccountId) {
  const [form] = Form.useForm();
  const account: IAccount = useContext(AccountContext);
  const entityId = match.params.entityId;
  const { setFieldsValue } = form;
  const title = `Actualizar factura #${entityId}`;

  const onSuccess = () => history.push(`/${account.id}/${path}`);

  const { onSubmit, submitting, error } = useFetchAndSubmit({
    apiVersion: "v2",
    endPoint,
    entityId: String(entityId),
    onSuccess,
    setFieldsValue,
    transform: (x: any) => ({ ...x, date: moment(x.date) }),
  });

  return (
    <div style={{ height: "100vh" }}>
      <InvoiceForm
        error={error}
        form={form}
        loading={submitting}
        onCancel={() => history.goBack()}
        onFinish={onSubmit}
        title={title}
      />
    </div>
  );
}
