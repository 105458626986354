import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { TripsList } from "./TripsList";

export function Trips(props: { match: any }) {
  const { match } = props;

  return (
    <Switch>
      <Route component={TripsList} path={match.path + "/"} exact />
      <Redirect to={match.url} />
    </Switch>
  );
}
