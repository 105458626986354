import React from "react";
import { patch } from "../../api";
import { useEntity } from "../../hooks/useEntity";
import Breadcrumb from "../../components/Breadcrumb";
import { Alert, Button, Form, Layout } from "antd";
import { TextInput } from "../../components/Inputs";

const text = { title: "Editar usuario" };
const fontSize = `${30 / text.title.length}rem`;
const END_POINT = `roles`;

const formFields = [];

export default function Entity({ match, history }) {
  const { entityId, accountId } = match.params;

  const { state, errors, onChange, handleSubmit } = useEntity(
    entityId,
    END_POINT,
    formFields
  );

  const { entity, submitted, submitting, asyncError, fetching } = state;

  const onSubmit = async () => {
    await patch(END_POINT, entity, entityId);
    history.push(`/${accountId}/roles/${entityId}`);
  };

  if (fetching) {
    return <p>loading...</p>;
  }

  const routes = [
    { to: "/" + accountId + "/roles", label: "Usuarios" },
    { label: "Editar usuario" },
  ];

  return (
    <div className={"mb-5"}>
      <div className="entity-header mb-3">
        <Breadcrumb routes={routes} />
        <div className="entity-header__buttons">
          <Button
            className="mr-2"
            style={{ width: 150 }}
            type="secondary"
            onClick={() => history.goBack()}
          >
            CANCELAR
          </Button>
          <Button type="primary" onClick={onSubmit}>
            GUARDAR CAMBIOS
          </Button>
        </div>
      </div>
      <Layout className={"small-form-container"} id={"root"}>
        <Form layout={"vertical"} className={"login-form card"}>
          <h1 style={{ fontSize: fontSize }}>{text.title}</h1>

          {asyncError && <Alert message={asyncError.message} type="error" />}

          <TextInput
            error={errors["firstName"]}
            submitted={submitted}
            label={"Nombre"}
            onChange={onChange}
            placeholder={"Ingresa el nombre"}
            entity={entity}
            name={["user", "firstName"]}
          />
          <TextInput
            error={errors["lastName"]}
            submitted={submitted}
            label={"Apellido"}
            onChange={onChange}
            placeholder={"Ingresa el apellido"}
            entity={entity}
            name={["user", "lastName"]}
          />
          <div className={"login-form-buttons"}>
            <Button type="secondary" onClick={() => history.goBack()}>
              CANCELAR
            </Button>

            <Button
              type="primary"
              disabled={submitting}
              loading={submitting}
              onClick={handleSubmit(onSubmit)}
            >
              GUARDAR CAMBIOS
            </Button>
          </div>
        </Form>
      </Layout>
    </div>
  );
}
