import React from 'react';
import { Card, Image } from "antd";
import Meta from "antd/es/card/Meta";

export default function MapaGeneral() {
    return (
        <div id="mapa-general" style={{ borderBottom: "1px solid grey", }}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                <h1 style={{ fontSize: 40, color: "#0066b3" }}>Mapa General</h1>
                <p style={{ fontSize: 16 }}>Brindamos la ubicación manera remota y en tiempo real de cada unidad</p>
            </div>

            <div style={{ display: "flex", marginBottom: 20 }}>
                <Card
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "60%",
                        textAlign: "center",
                        marginRight: 20
                    }}
                    cover={<Image alt="mapa" src="https://static.inmatics.io/images/telematica/mapa.png" />}
                >
                    <Meta title="Mapa General" description="El mapa la permite ver la ubicación e información de toda tu flota o grupos de  vehículos" />
                </Card>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start" }}>
                    <div>
                        <h4 style={{ fontSize: 18 }}><b>Detalle de cada vehículo</b></h4>
                        <ul>
                            <li style={{ fontSize: 16 }}>Modelo del vehículo</li>
                            <li style={{ fontSize: 16 }}>Dirección</li>
                            <li style={{ fontSize: 16 }}>Conductor</li>
                            <li style={{ fontSize: 16 }}>Velocidad actual</li>
                        </ul>
                    </div>
                    <div>
                        <ul style={{ listStyle: "none" }}>
                            <li style={{ marginBottom: 10 }}>
                                <img style={{ width: 30, marginRight: 5 }} alt="ico_geo" src="https://static.inmatics.io/icons/icoGeo10.svg" />
                                Encendido
                            </li>
                            <li style={{ marginBottom: 10 }}>
                                <img style={{ width: 30, marginRight: 5 }} alt="ico_geo" src="https://static.inmatics.io/icons/icoGeo06_mute.svg" />
                                Apagado
                            </li>
                            <li style={{ marginBottom: 10 }}>
                                <img style={{ width: 30, marginRight: 5 }} alt="ico_geo" src="https://static.inmatics.io/icons/icoGeo_warning.svg" />
                                Posible desconexión
                            </li>
                            <li style={{ marginBottom: 10 }}>
                                <img style={{ width: 30, marginRight: 5 }} alt="ico_geo" src="https://static.inmatics.io/icons/icoGeo06_danger.svg" />
                                Desconectado
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
