import { FileTextOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  InputNumber,
  Row,
} from "antd";
import locale from "antd/es/date-picker/locale/es_ES";
import moment from "moment/moment";
import React from "react";

import { VendorSelect } from "../../components/VendorSelect";
import { defaultLayout } from "../../constants";
import { FormParams } from "../../types";

export function InvoiceForm({
  error,
  form,
  loading,
  onCancel,
  onFinish,
  title,
}: FormParams) {
  moment.locale("es");

  const cancel = () => {
    onCancel();
  };

  return (
    <Row>
      <Col {...defaultLayout}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h2>{title}</h2>
          <h3>
            <FileTextOutlined className={"muted"} /> Detalles
          </h3>
          <Form
            form={form}
            onFinish={onFinish}
            layout={"vertical"}
            style={{ minWidth: 800 }}
          >
            <Card>
              {error && (
                <Alert message={error} className={"mb-3"} type="error" />
              )}
              <VendorSelect />
              <Form.Item label="Fecha" name="date">
                <DatePicker
                  showTime={false}
                  locale={locale}
                  // locale={locale}
                  style={{ width: "100%" }}
                />
              </Form.Item>

              <Form.Item label="IVA 10.5%" name="iva_105">
                <InputNumber placeholder={"IVA 10.5%"} />
              </Form.Item>
              <Form.Item label="IVA 21%" name="iva_21">
                <InputNumber placeholder={"IVA 21%"} />
              </Form.Item>
              <Form.Item label="IVA 27%" name="iva_27">
                <InputNumber placeholder={"IVA 27%"} />
              </Form.Item>
              <Form.Item label="IVA 5%" name="iva_05">
                <InputNumber placeholder={"IVA 5%"} />
              </Form.Item>
              <Form.Item label="IVA 2.5%" name="iva_025">
                <InputNumber placeholder={"IVA 2.5%"} />
              </Form.Item>
              <Form.Item label="Percepción Buenos Aires" name="ibba">
                <InputNumber placeholder={"Percepción Buenos Aires"} />
              </Form.Item>
              <Form.Item
                label="Percepción Ganancias"
                name="percepcion_ganancias"
              >
                <InputNumber placeholder={"Percepción Ganancias"} />
              </Form.Item>
              <Form.Item label="Percepción CABA" name="ibcf">
                <InputNumber placeholder={"Percepción CABA"} />
              </Form.Item>

              <Form.Item>
                <Button loading={loading} onClick={cancel} className={"mr-2"}>
                  Cancelar
                </Button>
                <Button loading={loading} type={"primary"} htmlType={"submit"}>
                  Guardar
                </Button>
              </Form.Item>
            </Card>
          </Form>
        </div>
      </Col>
    </Row>
  );
}
