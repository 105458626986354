import React, { useEffect, useState } from "react";
import { useDataApi } from "../hooks/useDataApi";

const createAccount = "/create-account";

export const MobileApp = () => {
  const [state] = useDataApi("auth/user");

  let redirectURL;

  const { data, isLoading } = state;

  const [capabilities, setCapabilities] = useState({});

  useEffect(() => {
    // setCapabilities(navigator.platform)
    // setCapabilities(navigator.appVersion)
    const onSuccess = (succ: any) => {
      setCapabilities(succ);
    };
    // const onSuccess = (succ: any) => setCapabilities(succ);
    navigator.geolocation.getCurrentPosition(onSuccess, onSuccess);
  }, []);

  console.log(capabilities);

  if (isLoading) {
    return null;
  }

  const pathname = window.location.pathname;

  //User has no role in any account
  if (data.roles.length === 0 && pathname !== createAccount) {
    redirectURL = createAccount;
  }

  //User has a role in any account, so should be redirected to that account
  if (
    (data.roles.length === 1 && pathname === createAccount) ||
    (data.roles.length === 1 && pathname === "/")
  ) {
    redirectURL = "/" + data.roles[0].account.id;
  }

  if (redirectURL && pathname !== "/me") {
    window.location.replace(redirectURL);
  }

  return (
    <div>
      <h1>Hello iOS</h1>
    </div>
  );
};
