import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import AdminDevicesList from "./AdminDevicesList";
import DevicesCreate from "./DevicesCreate";
import DevicesEdit from "./DevicesEdit";
import DevicesView from "./DevicesView";

export function Devices({ match }: any) {
  return (
    <Switch>
      <Route component={AdminDevicesList} path={match.path + "/"} exact />
      <Route component={DevicesCreate} path={match.url + "/create"} exact />

      <Route
        component={DevicesEdit}
        path={match.url + "/edit/:entityId"}
        exact
      />
      <Route component={DevicesView} path={match.url + "/:entityId"} exact />
      <Redirect to={match.url} />
    </Switch>
  );
}
