import { Badge, Button, Card, Image, Row, Upload, notification } from "antd";
import React from "react";
import useSWR from "swr";

import { get } from "../../api";
import { post } from "../../helpers/api";
import { IFile } from "../../types";

export default function FilesCard(props: {
  fillableId: string;
  fillableType: string;
}) {
  const { fillableId, fillableType } = props;

  let url = `files?${fillableType}=${fillableId}&type=image`;
  const { data, mutate } = useSWR(url, async () => await get(url));

  if (!data) {
    return null;
  }

  const uploadImage = async (options: any) => {
    const { onError, file } = options;

    const fmData = new FormData();
    fmData.append("file", file);
    fmData.append(fillableType, fillableId);
    const value = "image";
    fmData.append("type", value);

    try {
      await post("files/i", fmData, {
        headers: { "content-type": "multipart/form-data" },
      });
      notification.success({
        message: "El documento fue subido correctamente.",
      });
      await mutate();
    } catch (err) {
      onError({ err });
    }
  };

  return (
    <Card
      title={
        <div className="card-title">
          <div>
            Imágenes
            <Badge showZero count={data.length} />
          </div>
          <div>
            <Upload
              accept="image/*"
              fileList={[]}
              customRequest={uploadImage}
              className="image-upload-grid"
            >
              <Button type={"link"}>+ Agregar nueva imagen</Button>
            </Upload>
          </div>
        </div>
      }
    >
      <Row>
        {data.map((x: IFile, idx: number) => {
          const policy = `?policy=eyAiZXhwaXJ5IjogMTY2ODUzMTgxNyB9&signature=b7edc9e1c9e36c3d95886530ccc12205f330e80f4230e57d01122f0ade1933bb`;
          let src = x.url;

          if (src.includes("filestackcontent")) {
            src = src + policy;
          }

          return (
            <Image
              key={idx}
              className={"p-2"}
              src={src}
              style={{ width: 200, height: 150 }}
            />
          );
        })}
      </Row>
    </Card>
  );
}
