import React from "react";
import { Button, Dropdown, Menu, Tooltip } from "antd";
import { Link } from "react-router-dom";
import { DeleteOutlined, EditOutlined, MoreOutlined } from "@ant-design/icons";
import { formatMoney } from "../../utils/format";
import { filterTypes } from "../../constants";
import { useVendors } from "../../hooks";

const path = `facturas`;

function ActionsMenu({ record, onDelete }) {
  function onMouseEnter(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  return (
    <Dropdown
      trigger={"click"}
      onClick={onMouseEnter}
      overlay={
        <Menu>
          <Menu.Item key="0">
            <Link to={`/${record.vendor.account_id}/${path}/${record.id}`}>
              <Button type={"link"}>
                <EditOutlined style={{ color: "#AAAAAA" }} /> Editar
              </Button>
            </Link>
          </Menu.Item>
          <Menu.Item>
            <Button
              type={"link"}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onDelete(record);
              }}
            >
              <DeleteOutlined style={{ color: "#AAAAAA" }} /> Remover
            </Button>
          </Menu.Item>
        </Menu>
      }
    >
      <Tooltip title={"Acciones"} placement={"left"}>
        <Button icon={<MoreOutlined />} />
      </Tooltip>
    </Dropdown>
  );
}

const render = (text) => formatMoney(text, 2);

export const getColumns = (onDelete) => [
  {
    dataIndex: "vendor_id",
    filterPlaceHolder: "Filtrar por proveedor",
    filterType: filterTypes.asyncTag,
    formatter: (x) => x.legal_name,
    render: (text, record) => record.vendor.legal_name,
    sorter: true,
    sortIndex: "Vendor.legal_name",
    title: "Razon Social",
    useFetcher: useVendors,
  },

  {
    title: "CUIT",
    dataIndex: ["vendor", "tax_id"],
    sorter: true,
  },
  {
    title: "Imp. Neto Grav.",
    dataIndex: "taxed_total_net",
    sorter: true,
    render,
  },
  {
    title: "Importe Total",
    dataIndex: "total",
    sorter: true,
    render,
  },

  {
    title: "",
    dataIndex: "",
    align: "right",
    render: (text, record) => (
      <ActionsMenu record={record} onDelete={onDelete} />
    ),
  },
];
