import { useEffect, useState } from "react";

import { strokeColor } from "../constants";

export const Poly = (options: google.maps.PolylineOptions) => {
  const [poly, setPoly] = useState<any>();
  const path = options.path;

  useEffect(() => {
    const lineSymbol: google.maps.Symbol = {
      path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
      scale: 1,
    };

    if (!poly) {
      const icons = [{ icon: lineSymbol, offset: "100%" }];

      setPoly(
        new window.google.maps.Polyline({
          path: path || [],
          strokeColor: strokeColor,
          strokeOpacity: 1.0,
          strokeWeight: 3,
          icons,
        })
      );
    }

    return () => {
      if (poly) {
        poly.setMap(null);
      }
    };
  }, [poly, path]);

  useEffect(() => {
    if (poly) {
      poly.setOptions(options);
    }
  }, [poly, options]);

  return null;
};
