import CollectionsListQuery from "./CollectionsListQuery";
import React from "react";
import ReportSummary from "../../components/ReportSummary";
import { calculateTotals, textFilter } from "../../utils";
import { getColumns } from "./helper";
import { Table } from "antd";
import { useFetchData } from "../../hooks/useFetchData";

export default function FinancialReport({
  accountId,
  conf,
  tempGroupLabel,
  tempGroup,
  title,
  ranges,
  transform,
  iterator,
}) {
  const [page, setPage] = React.useState(1);

  const [state, onChange] = useFetchData({
    url: "reports/collections",
    initialQuery: { ...conf, accountId, groupBy: "contactId" },
    transform: transform,
  });

  const { data, query } = state;
  const { search } = query;

  const dataSource = textFilter(search, data, ["name", "contact"]);

  return (
    <div>
      <div className="grid-header">
        <h1>{title}</h1>
      </div>
      <CollectionsListQuery
        ranges={ranges}
        tempGroup={tempGroup}
        label={tempGroupLabel}
        onChange={onChange}
        query={query}
      />

      <Table
        columns={getColumns(accountId, query, iterator, page)}
        size={"small"}
        pagination={{
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} de ${total} registros`,
          position: ["topRight"],
          onChange(current) {
            setPage(current);
          },
          pageSize: 15,
        }}
        dataSource={dataSource}
        summary={() => (
          <ReportSummary
            iterator={iterator}
            row={calculateTotals(dataSource, iterator)}
          />
        )}
      />
    </div>
  );
}
