import React from "react";
import { Select } from "antd";
import { IGridColumn } from "../types";

interface FilterTagSelectorParams {
  entity: any;
  onChange: any;
  column: IGridColumn;
}

export default function FilterTagSelector(props: FilterTagSelectorParams) {
  const { entity, onChange, column } = props;
  const { data } = column.useFetcher();
  if (!data) {
    return null;
  }

  const entityElement = entity[column.dataIndex];
  return (
    <Select
      allowClear
      mode={"multiple"}
      onChange={(value) => onChange(value)}
      optionFilterProp={"label"}
      options={data.map((item: any) => ({
        label: column.formatter ? column.formatter(item) : item.name,
        value: item.id.toString(),
      }))}
      placeholder={column.filterPlaceHolder}
      showSearch
      style={{ width: "100%" }}
      value={(entityElement && entityElement.value) || entityElement}
    />
  );
}
