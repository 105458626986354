import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import InvoicesList from "./InvoicesList";
import InvoicesEdit from "./InvoicesEdit";
import InvoicesCreate from "./InvoicesCreate";

export function Invoices({ match }) {
  return (
    <Switch>
      <Route component={InvoicesList} path={match.path + "/"} exact />
      <Route component={InvoicesCreate} path={match.url + "/crear"} exact />
      <Route component={InvoicesEdit} path={match.url + "/:id"} exact />
      <Redirect to={match.url} />
    </Switch>
  );
}
