import { Dropdown, Menu } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { getByKey } from "../utils";
import React from "react";

function DropdownMenu({ options, onChange, value, label }) {
  let small = <>{label}</>;

  return (
    <Dropdown.Button
      overlay={
        <Menu>
          {options.map((x) => (
            <Menu.Item onClick={() => onChange(x.value)} key={x.label}>
              {x.label}
            </Menu.Item>
          ))}
        </Menu>
      }
      className={"muted"}
      placement="bottomCenter"
      icon={<DownOutlined />}
    >
      <small>{small} </small>
      {getByKey(options, value, "value").label}
    </Dropdown.Button>
  );
}

export default DropdownMenu;
