import {Card, Col, Row, Statistic} from "antd";
import React from "react";

import {useDataApi} from "../../../hooks/useDataApi";
import {nFormatter} from "../../../utils";

export default function Entity({vehicleId}: any) {
    const [state] = useDataApi(`transactions/summary?vehicleId=${vehicleId}`);

    const {data} = state;

    return (
        <Card title={"Cobranza"}>
            <Row>
                <Col>
                    {" "}
                    <Statistic
                        style={{minWidth: "80px"}}
                        title="7 días"

                        value={data["seven"]}
                        formatter={(value) => nFormatter(Math.abs(Number(value)), 2)}
                    />
                </Col>
                <Col>
                    {" "}
                    <Statistic
                        title="30 días"
                        style={{minWidth: "80px"}}
                        value={data["thirty"]}
                        formatter={(value) => nFormatter(Math.abs(Number(value)), 2)}
                    />
                </Col>
                <Col>
                    {" "}
                    <Statistic
                        title="90 días"
                        style={{minWidth: "80px"}}
                        value={data["ninety"]}
                        formatter={(value) => nFormatter(Math.abs(Number(value)), 2)}
                    />
                </Col>

            </Row>
        </Card>
    );
}
