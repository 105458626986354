import axios from "axios";
import { v2dataServer } from "../constants/server";
import useSWR from "swr";

export const get = (endPoint: string, config?: object) =>
  axios
    .get(`${v2dataServer}/${endPoint}`, {
      ...config,
      withCredentials: true,
    })
    .then((response) => response);

export const post = (endPoint: string, payload: object, config?: object) =>
  axios
    .post(`${v2dataServer}/${endPoint}`, payload, {
      ...config,
      withCredentials: true,
    })
    .then((response) => response);

export const patch = (endPoint: string, payload = {}, id: string) =>
  axios
    .patch(`${v2dataServer}/${endPoint}/${id}`, payload, {
      withCredentials: true,
    })
    .then((response) => response);

export const remove = (endPoint: string, id: string) =>
  axios
    .delete(`${v2dataServer}/${endPoint}/${id}`, { withCredentials: true })
    .then((response) => response.data);

export const useSWRFetch = (url: string) => {
  const fetcher = async () => {
    return (await get(url)).data;
  };

  return useSWR(url, fetcher, {
    initialData: null,
    revalidateOnMount: true,
  });
};
