import React, { useEffect, useState } from "react";
import { useContacts } from "../../hooks/useContacts";
import {
  Button,
  Card,
  DatePicker,
  Form,
  Select,
  Row,
  Col,
  InputNumber,
  Alert,
} from "antd";
import { get, patch } from "../../api";
import moment from "moment";
import { useLegacyVehicles } from "../../hooks/useLegacyVehicles";
import { parseError } from "../../helpers";
import locale from "antd/es/date-picker/locale/es_ES";

const endPoint = `vehicle-assignments`;

const transform = (entity) => {
  return {
    ...entity,
    startDate: moment(entity["startDate"]),
    finishDate: entity["finishDate"] ? moment(entity["finishDate"]) : undefined,
  };
};

export default function VehicleAssignmentEdit({ match, history }) {
  const { data } = useContacts();
  const vehicles = useLegacyVehicles();
  const [submitting, setSubmitting] = useState(false);
  const [form] = Form.useForm();
  const { setFieldsValue } = form;
  const entityId = match.params.entityId;
  const [error, setError] = useState("");

  const onFinish = async (values) => {
    try {
      setSubmitting(true);
      await patch(endPoint, values, entityId);
      history.goBack();

      // onSuccess();
      setSubmitting(false);
    } catch (err) {
      setSubmitting(false);
      setError(parseError(err));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (entityId) {
        const res = await get(`${endPoint}/${entityId}`);
        setFieldsValue(transform(res));
      } else {
        setFieldsValue({});
      }
    };
    fetchData();
  }, [entityId, setFieldsValue]);

  return (
    <div>
      <Form form={form} onFinish={onFinish} layout={"vertical"}>
        <Card>
          {error && <Alert message={error} className={"mb-3"} type="error" />}
          <Form.Item
            style={{ display: "none" }}
            name={"vehicleId"}
            label={"Vehículo"}
          >
            <Select
              options={vehicles.data.map((x) => ({
                value: x.id,
                label: `${x.name}`,
              }))}
            />
          </Form.Item>
          <Form.Item name={"contactId"} label={"Conductor asignado"}>
            <Select
              options={data.map((x) => ({
                value: x.id,
                label: `${x.firstName} ${x.lastName}`,
              }))}
            />
          </Form.Item>
          <Row>
            <Col span={12}>
              <Form.Item label="Fecha inicio" name="startDate">
                <DatePicker locale={locale} showTime style={{ width: 300 }} />
              </Form.Item>
            </Col>

            <Form.Item
              label="Kilometraje inicial"
              name={["startingMeterEntry", "value"]}
            >
              <InputNumber
                style={{ width: 300 }}
                placeholder={"Kilometraje inicial"}
              />
            </Form.Item>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item label="Fecha fin" name="finishDate">
                <DatePicker locale={locale} showTime style={{ width: 300 }} />
              </Form.Item>
            </Col>

            <Form.Item
              label="Kilometraje final"
              name={["endingMeterEntry", "value"]}
            >
              <InputNumber
                style={{ width: 300 }}
                placeholder={"Kilometraje final"}
              />
            </Form.Item>
          </Row>
          <Form.Item>
            <Button loading={submitting} type={"primary"} htmlType={"submit"}>
              Guardar
            </Button>
          </Form.Item>
        </Card>
      </Form>
    </div>
  );
}
