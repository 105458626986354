import { FileTextOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
} from "antd";
import locale from "antd/es/date-picker/locale/es_ES";
import * as PropTypes from "prop-types";
import React from "react";

import { defaultLayout } from "../../constants";
import { FormParams, Vehicle } from "../../types";

interface InsuranceFormParams extends FormParams {
  vehicles: any;
}

export function InsuranceForm({
  form,
  error,
  onCancel,
  onFinish,
  vehicles,
  loading,
  title,
}: InsuranceFormParams) {
  const cancel = () => {
    onCancel();
  };

  const insuranceTypes = [
    "Garage",
    "Todo Riesgo",
    "Responsabilidad civil",
    "Terceros completo",
  ];

  const strings = [
    "Allianz",
    "La Caja",
    "La meridional",
    "La Nueva",
    "Provincia Seguros",
    "Prudencia Seguros",
    "Sancor Seguros",
    "SMG",
    "Sura",
    "Zurich",
  ];

  return (
    <Row>
      <Col {...defaultLayout}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h2>{title}</h2>
          <h3>
            <FileTextOutlined className={"muted"} /> Detalles
          </h3>
          <Form
            form={form}
            onFinish={onFinish}
            layout={"vertical"}
            style={{ minWidth: 800 }}
          >
            <Card>
              {error && (
                <Alert message={error} className={"mb-3"} type="error" />
              )}
              <Form.Item name={"vehicle_id"} label={"Vehículo"}>
                <Select
                  optionFilterProp={"label"}
                  options={vehicles.data.map((item: Vehicle) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                  placeholder={"Seleccione el vehículo"}
                  allowClear
                  showSearch
                />
              </Form.Item>
              <Form.Item name={"type"} label={"Cobertura"}>
                <Select
                  placeholder={"Cobertura"}
                  options={insuranceTypes.map((x) => ({
                    value: x,
                    label: `${x}`,
                  }))}
                />
              </Form.Item>
              <Form.Item label="Costo" name="cost">
                <Input
                  type="number"
                  placeholder={"Costo mensual de la poliza"}
                />
              </Form.Item>
              <Form.Item name={"vendor_name"} label={"Compañia"}>
                <Select
                  placeholder={"Cobertura"}
                  options={strings.map((x) => ({
                    value: x,
                    label: `${x}`,
                  }))}
                />
              </Form.Item>

              <Form.Item label="Inicio cobertura" name="valid_from">
                <DatePicker
                  format="DD/MM/YYYY HH:mm"
                  locale={locale}
                  style={{ width: 300 }}
                />
              </Form.Item>

              <Form.Item label="Fin cobertura" name="valid_to">
                <DatePicker
                  format="DD/MM/YYYY HH:mm"
                  locale={locale}
                  style={{ width: 300 }}
                />
              </Form.Item>

              <Form.Item>
                <Button loading={loading} onClick={cancel} className={"mr-2"}>
                  Cancelar
                </Button>
                <Button loading={loading} type={"primary"} htmlType={"submit"}>
                  Guardar
                </Button>
              </Form.Item>
            </Card>
          </Form>
        </div>
      </Col>
    </Row>
  );
}

InsuranceForm.propTypes = {
  form: PropTypes.any,
  onFinish: PropTypes.func,
  error: PropTypes.string,
  vehicles: PropTypes.any,
  loading: PropTypes.bool,
};
