import { ClockCircleOutlined } from "@ant-design/icons";
import React from "react";
import { calculateDuration } from "../utils";

export default function Duration(props: {
  startDate: string;
  finishDate: string;
}) {
  const { startDate, finishDate } = props;

  return calculateDuration(startDate, finishDate) ? (
    <small>
      <ClockCircleOutlined /> {calculateDuration(startDate, finishDate)}
    </small>
  ) : null;
}
