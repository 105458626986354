import { DeleteOutlined, EditOutlined, MoreOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, Tooltip } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { filterTypes } from "src/constants";
import { dashWhenEmpty } from "src/helpers";
import { useLegacyVehicles } from "src/hooks/useLegacyVehicles";

const render = (text: string, record: any) => {
  if (!text) {
    return "N/A";
  }
  return (
    <Link to={`/${record.account_id}/vehicles/${record.vehicle_id}`}>
      {record.vehicle_name} ({record.vehicle_account_name}){" "}
    </Link>
  );
};

export const getColumns = (onDelete: any) => [
  {
    dataIndex: "account_name",
    sorter: true,
    title: "Cuenta",
  },
  {
    dataIndex: "vehicle_id",
    filterPlaceHolder: "Filtrar por vehículo",
    filterType: filterTypes.asyncTag,
    render: render,
    sorter: true,
    title: "Vehículo asigando",
    useFetcher: useLegacyVehicles,
  },
  {
    dataIndex: "device_model",
    filterPlaceHolder: "Modelo del dispositivo",
    filterType: filterTypes.text,
    title: "Modelo",
    sorter: true,
  },
  {
    dataIndex: "device_imei",
    filterType: filterTypes.text,
    filterPlaceHolder: "IMEI del dispositivo",
    title: "IMEI",
    render: dashWhenEmpty,
  },
  {
    dataIndex: "phone_number",
    filterType: filterTypes.text,
    title: "Número de telefono",
    filterPlaceHolder: "Número de telefono",
    render: dashWhenEmpty,
  },
  {
    dataIndex: "id",
    title: "",
    render: (_: string, record: any) => {
      return (
        <Dropdown
          trigger={undefined}
          // @ts-ignore
          onClick={(e: any) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          overlay={
            <Menu>
              <Menu.Item key="0">
                <Link to={`/admin/devices/edit/${record.id}`}>
                  <Button type={"link"}>
                    <EditOutlined style={{ color: "#AAAAAA" }} /> Editar
                  </Button>
                </Link>
              </Menu.Item>
              <Menu.Item key="1">
                <Button onClick={() => onDelete(record)} type={"link"}>
                  <DeleteOutlined style={{ color: "#AAAAAA" }} /> Remover
                  dispositivo
                </Button>
              </Menu.Item>
            </Menu>
          }
        >
          <Tooltip title={"Acciones"} placement={"left"}>
            <Button icon={<MoreOutlined />} />
          </Tooltip>
        </Dropdown>
      );
    },
  },
];
