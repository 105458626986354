import React from "react";
import { Form } from "antd";
import { useLegacyVehicles } from "../../hooks/useLegacyVehicles";
import { VendorsForm } from "./VendorsForm";
import { useFetchAndSubmit } from "../../hooks/useFetchAndSubmit";

const endPoint = `vendors`;

export default function VendorsEdit({ match, history }) {
  const vehicles = useLegacyVehicles();
  const [form] = Form.useForm();
  const entityId = match.params.id;
  const { setFieldsValue } = form;
  const title = `Actualizar proveedor #${entityId}`;

  const onSuccess = (data) => history.push(`/${data.account_id}/proveedores`);

  const [onSubmit, submitting, error] = useFetchAndSubmit({
    endPoint,
    entityId,
    setFieldsValue,
    onSuccess,
  });

  return (
    <div style={{ height: "100vh" }}>
      <VendorsForm
        error={error}
        form={form}
        loading={submitting}
        onCancel={() => history.goBack()}
        onSubmit={onSubmit}
        title={title}
        vehicles={vehicles}
      />
    </div>
  );
}
