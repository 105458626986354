import React from "react";
import { get } from "../helpers/v2api";
import useSWR from "swr";
import { useQuery } from "../hooks/useQuery";
import { getURI } from "../helpers/GetURI";
import FiltersInfo from "../components/FiltersInfo";
import { Filters } from "../components/Filters";
import { Table } from "antd";
import Paginator from "../organisms/Paginator";
import { FIELD_TYPES, GRID_PAGE_SIZE } from "../constants";
import format from "../utils/format";

const columns = [
  {
    title: "Nombre",
    dataIndex: "first_name",
  },
  {
    title: "Apellido",
    dataIndex: "last_name",
  },
  {
    title: "Email",
    dataIndex: "email",
  },
  {
    title: "Última visita",
    dataIndex: "last_visit",
    sorter: true,
    render: (date: any) => {
      if (!date) return "N/A";
      return format(date, FIELD_TYPES.RELATIVE_TIME);
    },
  },
];

const useSWRFetch = (url: string) => {
  const fetcher = async () => {
    const axiosResponse = await get(url);

    return {
      data: axiosResponse.data,
      count: axiosResponse.headers["x-total-count"],
    };
  };

  return useSWR(url, fetcher, {
    initialData: null,
    revalidateOnMount: true,
  });
};

export default function UsersList() {
  const initializerArg = {
    filters: {},
    sort: "lastVisit desc",
    page: 1,
  };
  const { state, onChange, clearFilters, setPage } = useQuery(initializerArg);
  const { page, filters } = state;

  const { data } = useSWRFetch(getURI("admin/users", state));

  return (
    <div>
      <div className={"justify-flex-end p-3"}>
        <div className={"mr-2"}>
          <FiltersInfo
            filters={filters}
            columns={columns}
            clearFilters={clearFilters}
          />
        </div>
        <Filters columns={columns} filters={filters} onChange={onChange} />
        <Paginator
          current={page}
          onChange={setPage}
          total={data?.count}
          pageSize={GRID_PAGE_SIZE}
        />
      </div>
      <Table
        dataSource={data?.data}
        rowClassName={"show-pointer"}
        onChange={onChange}
        columns={columns}
        pagination={false}
        size={"small"}
        rowKey={(record) => record.id}
      />
    </div>
  );
}
