import React from "react";
import { List } from "antd";
import { RightOutlined } from "@ant-design/icons";

export default function Mantenimiento() {
    const data = [
        {
            title: 'El vehículo al cual referencia la orden de servicio de trabajo',
        },
        {
            title: 'Fecha/Hora inicio y  de finalización: En el caso de haber una fecha de inicio, se mostrará en pantalla la duración del servicio',
        },
        {
            title: 'Tareas realizadas: Cada tarea referencia un tipo de tarea',
        },
        {
            title: 'Repuestos e insumos utilizados. Por cada ítem se registra cantidad utilizada',
        },
        {
            title: 'Imágenes y videos relacionadas a la orden de trabajo (Archivos multimedia)',
        },
        {
            title: 'Documentos relacionados (Archivos, excel, PDF, word, etc)',
        },
    ];

    return (
        <div id="mantenimiento" style={{ borderBottom: "1px solid grey" }}>
            <div style={{ display: "flex" }}>
                <h1 style={{ fontSize: 40, color: "#0066b3" }}>Mantenimiento</h1>
            </div>

            <div style={{ padding: 10 }}>
                <h3>El componente principal del mantenimiento es la <b>orden de trabajo</b>, en la que se registra:</h3>
                <List
                    itemLayout="horizontal"
                    dataSource={data}
                    renderItem={(item) => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={<RightOutlined />}
                                title={item.title}
                            />
                        </List.Item>
                    )}
                />
            </div>
        </div>
    )
}
