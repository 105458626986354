import React from "react";
import { Row, Col, Card } from "antd";
import { Link } from "react-router-dom";

export default function Reports({ match }) {
  let accountId = match.params.accountId;

  const FIN_REPORTS = [
    {
      to: `/${accountId}/reports/collections-annual`,
      title: "Historico Anual",
      description: "Reporte anual agrupado por meses.",
    },
    {
      to: `/${accountId}/reports/collections-weekly`,
      title: "Cobranza semanal",
      description: "Reporte semanal agrupado por días.",
    },
  ];

  const SER_REPORTS = [
    {
      to: `/${accountId}/reports/services-summary`,
      title: "Servicios por vehículo",
      description: "Reporte de sumarizado de servicios agrupados por vehículo.",
    },
  ];

  return (
    <div>
      <div className={"grid-header"}>
        <h1>Reportes</h1>
      </div>
      <Row>
        <Col span={12} className={"p-2"}>
          <Card title={"Cobranzas"}>
            {FIN_REPORTS.map((x) => {
              return (
                <div>
                  <Link to={x.to}>{x.title}</Link>
                  <p>{x.description}</p>
                </div>
              );
            })}
          </Card>
        </Col>
        <Col span={12} className={"p-2"}>
          <Card title={"Servicios"}>
            {SER_REPORTS.map((x) => {
              return (
                <div>
                  <Link to={x.to}>{x.title}</Link>
                  <p>Reporte anual agrupado por meses</p>
                </div>
              );
            })}
          </Card>
        </Col>
      </Row>
    </div>
  );
}
