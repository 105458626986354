import { message } from "antd";
import { FilterValue, TablePaginationConfig } from "antd/lib/table/interface";
import { isEmpty } from "lodash";
import useSWR from "swr";

import { get } from "../api";
import { GetOnDelete } from "../types";
import { newApi } from "../utils/rest";

export function getCookie(name: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    // @ts-ignore
    return parts.pop().split(";").shift();
  }
}

export const getOnDelete: GetOnDelete = (endpoint: string, mutate: any) => (
  id: string,
  name: any,
  isArchival?: boolean
) => {
  newApi()
    .v3.delete(endpoint + "/" + id)
    .then(() => {
      mutate();
      message.success(
        `El registro #${name} ha sido ${
          isArchival ? "archivado" : "eliminado"
        }.`
      );
    })
    .catch((err) => message.error(JSON.stringify(err)));
};

export const getOnTableChange = (setSort: (sort: string) => void) => (
  pagination: TablePaginationConfig,
  filters: Record<string, FilterValue | null>,
  sorter: any
) => {
  if (sorter.order) {
    let sort = sorter.order === "ascend" ? `ASC` : "DESC";
    setSort(`${sorter.field}+${sort}`);
  } else {
    setSort("");
  }
};

export function shouldRenderEmptyView(
  data: any,
  isValidating: boolean,
  filters: any
) {
  return (
    Number(data?.count) === 0 &&
    !isValidating &&
    isEmpty(JSON.parse(JSON.stringify(filters)))
  );
}

export function calculateAge(date: Date): number {
  const diffHours =
    Math.abs(new Date().getTime() - date.getTime()) / (1000 * 60 * 60);
  if (diffHours < 2) {
    return 0;
  } else if (diffHours < 6) {
    return 1;
  } else {
    return 2;
  }
}

export const parseError = (error: any) => {
  if (error) {
    const { message, response } = error;

    if (response && response.data) {
      return response.data;
    } else if (message) {
      return message;
    } else {
      return JSON.stringify(error);
    }
  }
};

export const getByKey = (collection: any[], value: string, key = "id") => {
  if (!collection) return null;

  const item = collection.filter(
    (item) => item[key]?.toString() === value.toString()
  );
  if (item) return item[0];
  return null;
};

export const useSWRFetch = (url: string) =>
  useSWR(url, () => get(url), { initialData: [], revalidateOnMount: true });

export const dashWhenEmpty = (x: string) => x || "-";

export function getLastNYears(n: number, currentYear: number) {
  const years = [];
  for (let i = 0; i < n; i++) {
    const items = currentYear - i;
    years.push(items.toString());
  }
  return years;
}

export function calculateDurationBis(
  startDate: string,
  finishDate?: string
): string {
  let duration: string;

  const startDateTime = new Date(startDate);
  const finishDateTime = finishDate ? new Date(finishDate) : new Date();

  if (startDateTime <= finishDateTime) {
    const diffMilliseconds = finishDateTime.getTime() - startDateTime.getTime();
    const diffMinutes = Math.floor(diffMilliseconds / (1000 * 60));
    const diffHours = Math.floor(diffMinutes / 60);
    const diffDays = Math.floor(diffHours / 24);

    if (diffDays > 0) {
      duration = `${diffDays ? diffDays + " días." : ""}`;
    } else if (diffHours > 0) {
      duration = `${diffHours ? diffHours + " horas." : ""}`;
    } else {
      duration = `${diffMinutes ? diffMinutes + " minutos." : ""}`;
    }
  } else {
    duration =
      "Error: La fecha y hora de inicio no puede ser posterior a la de inicio. Por favor, corrija la fecha de inicio.";
  }

  return duration;
}
