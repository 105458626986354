import React from "react";

import { logos } from "../constants";
import { Vehicle } from "../types";

type CarSVGProps = { type?: any; vehicle: Vehicle; width?: number };

export default (props: CarSVGProps) => {
  const { type, vehicle, width } = props;
  if (!vehicle.brand) return null;

  let logo = logos[vehicle.brand] || logos[vehicle.brand.toUpperCase()];
  if (logo) {
    return (
      <img
        width={width || 64}
        src={`https://static.inmatics.io/car-brand-logos/ico-${logo}.svg`}
        alt=""
      />
    );
  }

  return (
    <svg
      aria-hidden="true"
      width={width || 64}
      height={width || 64}
      viewBox={`0 0 ${width || 64} ${width || 64}`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="AD216SG"
    >
      <rect width="64" height="64" ry="4" fill="#78858A" />
      <text
        x="25"
        y="25"
        fill="#ffffff"
        fontSize="16"
        textAnchor="middle"
        dominantBaseline="central"
      >
        {(type || "AUTO").substring(0, 3).toUpperCase()}
      </text>
    </svg>
  );
};
