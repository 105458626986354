import { FileTextOutlined } from "@ant-design/icons";
import { Alert, Button, Card, Col, Form, Input, Row } from "antd";
import React from "react";

import { AccountSelect } from "../../components/AccountSelect";
import { VehicleSelectByAccount } from "../../components/VehicleSelectByAccount";
import { defaultLayout } from "../../constants";
import { FormParams } from "../../types";

function DeviceForm(props: FormParams) {
  const { error, form, loading, onCancel, onFinish, title } = props;
  const cancel = () => {
    onCancel();
  };

  return (
    <Row>
      <Col {...defaultLayout}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h2>{""}</h2>
          <h3>
            <FileTextOutlined className={"muted"} /> {title}
          </h3>
          <Form
            form={form}
            onFinish={onFinish}
            layout={"vertical"}
            style={{ minWidth: 800 }}
          >
            <Card>
              {error && (
                <Alert message={error} className={"mb-3"} type="error" />
              )}
              <AccountSelect form={form} />
              <VehicleSelectByAccount form={form} />
              <Form.Item label="Modelo" name="device_model">
                <Input placeholder={"Modelo del dispositivo"} />
              </Form.Item>

              <Form.Item label="IMEI" name="device_imei">
                <Input placeholder={"Codigo IMEI del dispositivo"} />
              </Form.Item>

              <Form.Item label="Numero de telefono" name="phone_number">
                <Input
                  minLength={9}
                  type={"tel"}
                  placeholder={"Ej: 541187654321"}
                />
              </Form.Item>

              <Form.Item>
                <Button loading={loading} onClick={cancel} className={"mr-2"}>
                  Cancelar
                </Button>
                <Button loading={loading} type={"primary"} htmlType={"submit"}>
                  Guardar
                </Button>
              </Form.Item>
            </Card>
          </Form>
        </div>
      </Col>
    </Row>
  );
}

export default DeviceForm;
