import { Status, Wrapper } from "@googlemaps/react-wrapper";
import React, { useState } from "react";
import { GOOGLE_MAPS_KEY } from "src/constants";
import { MapWithCluster } from "src/organisms/Maps/MapWithCluster";
import { ILocation } from "src/types";

import { hoursSinceLastPosition } from "../../../components/Marker_helpers";

const renderStatus = (status: Status) => {
  if (status === "LOADING") return <h3>{status} ..</h3>;
  if (status === "FAILURE") return <h3>{status} ...</h3>;
  return <h3>{status} ...</h3>;
};

type MapParams = {
  entries: ILocation[];
};
export const Map = (props: MapParams) => {
  const [showNotReportingVehicles, setShowNotReportingVehicles] = useState(
    false
  );
  const { entries } = props;

  if (entries.length === 0) {
    return null;
  }

  return (
    <Wrapper
      apiKey={GOOGLE_MAPS_KEY}
      render={renderStatus}
      version={"beta"}
      //@ts-ignore
      libraries={["marker"]}
    >
      <MapWithCluster
        onChangeShowNotReportingVehicles={() =>
          setShowNotReportingVehicles((prevState) => !prevState)
        }
        showNotReportingVehicles={showNotReportingVehicles}
        entries={entries.filter(
          (x) =>
            showNotReportingVehicles || hoursSinceLastPosition(x.timestamp) < 6
        )}
      />
    </Wrapper>
  );
};
