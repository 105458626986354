import DropdownMenu from "./DropdownMenu";
import Paginator from "./Paginator";
import React from "react";

const pageSize = 50;

function SortPaginate({
  onSortChange,
  sortOptions,
  sortValue,
  current,
  total,
  onPageChange,
}) {
  return (
    <div className={"sort-paginate"}>
      <DropdownMenu
        onChange={onSortChange}
        options={sortOptions}
        value={sortValue}
        label={"Orden"}
      />
      <Paginator
        current={current}
        total={total}
        onChange={onPageChange}
        pageSize={pageSize}
      />
    </div>
  );
}

export default SortPaginate;
