import React from "react";
import { Table } from "antd";
import { getURI } from "../helpers/GetURI";
import { get } from "../helpers/v2api";
import useSWR from "swr";
import { Filters } from "../components/Filters";
import { useQuery } from "../hooks/useQuery";
import { filterTypes } from "../constants";
import FiltersInfo from "../components/FiltersInfo";
import {
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from "antd/lib/table/interface";

const columns = [
  {
    title: "ID",
    dataIndex: "id",
  },
  {
    title: "Nombre",
    dataIndex: "name",
    filterType: filterTypes.text,
  },
  {
    title: "Creada",
    dataIndex: "created_at",
  },
  {
    title: "Cantidad vehículos",
    dataIndex: "vehicle_count",
    sorter: true,
  },
  {
    title: "Cantidad usuarios",
    dataIndex: "user_count",
    sorter: true,
  },
];

const useSWRFetch = (url: string) => {
  const fetcher = async () => {
    const axiosResponse = await get(url);

    return {
      data: axiosResponse.data,
      count: axiosResponse.headers["X-Total-Count"],
    };
  };

  return useSWR(url, fetcher, {
    initialData: null,
    revalidateOnMount: true,
  });
};

export default function AccountsList() {
  const initializerArg = {
    filters: {},
    sort: "name",
    page: 1,
  };

  const { state, onChange, clearFilters, setSort } = useQuery(initializerArg);

  const { filters } = state;

  const { data } = useSWRFetch(getURI("accounts", state));

  const onTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any>
  ) => {
    if (sorter.order) {
      let sort = sorter.order === "ascend" ? `ASC` : "DESC";
      setSort(`${sorter.field}+${sort}`);
    } else {
      setSort("");
    }
  };

  return (
    <div>
      <div className={"justify-flex-end p-3"}>
        <div className={"mr-2"}>
          <FiltersInfo
            filters={filters}
            columns={columns}
            clearFilters={clearFilters}
          />
        </div>
        <Filters columns={columns} filters={filters} onChange={onChange} />
      </div>
      <Table
        dataSource={data?.data}
        rowClassName={"show-pointer"}
        // @ts-ignore
        onChange={onTableChange}
        columns={columns}
        pagination={false}
        size={"small"}
        rowKey={(record) => record.id}
      />
    </div>
  );
}
