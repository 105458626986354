import React from "react";
import { Button, InputNumber, Popconfirm, Table } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

const compareFn = (a, b) => {
  if (a.partTypeId < b.partTypeId) return -1;
  if (a.partTypeId > b.partTypeId) return 1;
  return 0;
};

export default function TaskList({
  entity,
  collection,
  onRemove,
  onRowChange,
}) {
  const renderTaskType = (text) => {
    let find = collection.find((x) => x.id === text);
    return find && find.name;
  };

  const renderDelete = (text, record) => {
    return (
      <div>
        <Popconfirm
          title="¿Esta seguro que desea eliminar esta parte?"
          onConfirm={() => onRemove(record)}
          okText="Sí"
          cancelText="No"
        >
          <Button
            type="ghost"
            size={"small"}
            shape="circle"
            danger
            icon={<DeleteOutlined />}
          />
        </Popconfirm>
      </div>
    );
  };

  return (
    <div>
      <Table
        columns={[
          {
            title: "Parte",
            dataIndex: "partTypeId",
            key: "partTypeId",
            render: renderTaskType,
          },
          {
            title: "Cantidad",
            dataIndex: "qty",
            key: "qty",
            render: (text, record) => (
              <InputNumber
                decimalSeparator={","}
                precision={2}
                value={text}
                onChange={(value) => onRowChange("qty", value, record)}
              />
            ),
          },
          {
            title: "",
            dataIndex: "createdAt",
            key: "createdAt",
            render: renderDelete,
          },
        ]}
        dataSource={entity["parts"].sort(compareFn)}
        size={"small"}
        pagination={false}
        rowKey={(record) => record["partTypeId"]}
      />
    </div>
  );
}
