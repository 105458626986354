import mqtt from "mqtt";
import { useEffect, useState } from "react";

import { ILocation, LocationEntry } from "../types";

export const useMQTT = (topic: string) => {
  const [mqttMessages, setMqttMessages] = useState<ILocation[]>([]);

  useEffect(() => {
    const client = mqtt.connect("wss://mqtt.inmatics.io", { port: 443 });
    console.log(`Connecting to listen to ${topic}`);

    client.on("connect", () => {
      console.log("Connected to MQTT broker");
      client.subscribe(topic);
    });

    client.on("message", (top: string, message) => {
      if (topic === top) {
        const parse: LocationEntry = JSON.parse(message.toString());
        setMqttMessages((prevState: any) => {
          return [parse, ...prevState];
        });
      }
    });

    return () => {
      console.log("Disconnecting from MQTT broker");
      client.end();
    };
  }, [topic]);

  return mqttMessages;
};
