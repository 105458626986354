import React, { useState } from "react";
import { parse } from "papaparse";
import Breadcrumb from "../../components/Breadcrumb";
import { Button, Select, Table } from "antd";
import { useRecursivePosts } from "../../hooks/useRecursivePosts";
import config from "./config";

const options = [
  { value: "vehicles", label: "Vehículos" },
  { value: "contacts", label: "Contactos" },
  { value: "seguros", label: "Seguros" },
  { value: "proveedores", label: "Proveedores" },
  { value: "devices", label: "Dispositivos" },
];

function BulkInsert({ match, history }) {
  const { accountId } = match;
  const [mappingColumns, setMappingColumns] = useState([]);
  const [insertData, setInsertData] = useState([]);
  const [mapping, setMapping] = useState({});
  const [importType, setImportType] = useState("vehicles");

  const configElement = config[importType];
  const onFinish = () => history.push(`/${accountId}/${importType}`);

  const { postRecursively, inserts, errors } = useRecursivePosts(
    onFinish,
    configElement.fnCreate
  );

  const selectCell = (text, record) => {
    let onChange = (value) => {
      return setMapping((prevState) => {
        return { ...prevState, [record.column]: value };
      });
    };

    return (
      <Select
        allowClear
        onChange={onChange}
        options={Object.keys(configElement.fields)
          .map((x) => ({
            label: configElement.fields[x],
            disabled: Object.values(mapping).includes(x),
            value: x,
          }))
          .sort((a, b) => (a.label > b.label ? 1 : -1))}
        placeholder={"Elija la opcion o deja en blanco para saltear"}
        style={{ width: "100%" }}
        value={mapping[record.column]}
      />
    );
  };

  const submitImport = () => {
    postRecursively([...insertData], mapping);
  };

  const columns = [
    {
      title: "Columna",
      dataIndex: "column",
      key: "date",
    },
    {
      title: "Ejemplo",
      dataIndex: "example",
      key: "example",
    },
    {
      title: "Columna",
      dataIndex: "example",
      key: "example",
      render: selectCell,
    },
  ];

  const handleChangeFile = (e) => {
    let reader = new FileReader();
    const files = e.target.files;
    if (files.length > 0) {
      reader.onload = (_event) => {
        setMappingColumns(
          parse(reader.result, {})
            .data[0].sort()
            .map((x) => {
              return {
                column: x,
                example:
                  parse(reader.result, { header: true })?.data[0][x] ?? "",
              };
            })
        );
        let newVar = parse(reader.result, { header: true })?.data ?? [];
        //@todo bug que me agrega un elemento con la primera columna. Buscar mejor solución.
        setInsertData(newVar.filter((x) => Object.keys(x).length > 1));
      };

      reader.readAsText(files[0], "UTF-8");
    }
  };

  const routes = [
    { to: "/" + accountId + "imports", label: "Importaciones" },
    { label: "Importar datos" },
  ];

  return (
    <div>
      <div className="p-3">
        <Breadcrumb routes={routes} />
      </div>
      <div className={"p-3"}>
        <h1>Paso 1: Seleccione un archivo para importar</h1>
        <div>
          <input
            accept=".csv, text/csv"
            type="file"
            style={{ marginBottom: 20 }}
            onChange={(e) => handleChangeFile(e)}
          />
          <Select
            options={options}
            value={importType}
            onChange={(e) => setImportType(e)}
            style={{ width: 300 }}
          />
        </div>

        {mappingColumns.length > 0 && (
          <div>
            <Table
              dataSource={mappingColumns}
              tableLayout={"fixed"}
              className={"mb-3"}
              bordered={true}
              pagination={false}
              columns={columns}
              rowKey={(record) => record.id}
            />
            <Button type="primary" onClick={submitImport}>
              {`IMPORTAR ${insertData.length} REGISTRO/S`}
            </Button>
          </div>
        )}
        <p>{inserts.length > 0 && "Registros insertados: " + inserts.length}</p>
        <p>{errors.length > 0 && "Registros fallados: " + errors.length}</p>
      </div>
    </div>
  );
}

export default BulkInsert;
