import React from "react";

import { FIELD_TYPES } from "../../../constants";
import { ILocation } from "../../../types";
import format from "../../../utils/format";
import IgnitionStatus from "../RenderIgnition";

export const Title = (props: { location: ILocation }) => {
  const { location } = props;

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <IgnitionStatus status={location.ignition} />
      {location.address && <small>{location.address}</small>}{" "}
      <small>
        {" . "}
        {location.speed} {"km/h .   ("}
        {format(location.timestamp, FIELD_TYPES.RELATIVE_TIME)}
      </small>
      )
    </div>
  );
};
