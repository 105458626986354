import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { IWithAccountId } from "../../types";
import EditInsurance from "./EditDevice";
import ListDevices from "./ListDevices";

export default function Devices({ match }: IWithAccountId) {
  return (
    <Switch>
      <Route render={() => <ListDevices />} path={match.path + "/"} exact />
      <Route component={EditInsurance} path={match.url + "/:entityId"} exact />
      <Redirect to={match.url} />
    </Switch>
  );
}
