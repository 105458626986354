import React, { useState } from "react";
import { Alert, Button, Form, Input, Modal } from "antd";
import useForm from "../hooks/useForm";
import { FIELD_TYPES } from "../constants";

const formFields = [
  {
    name: "text",
    label: "Comentario",
    type: FIELD_TYPES.TEXT,
    required: true,
    placeholder: "Ingresa el kilometraje",
  },
];

export default ({ onModalSubmit }) => {
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const { formState, handleSubmit, onChange } = useForm({}, formFields);
  const { entity, submitting, asyncError } = formState;

  const onSubmit = async () => {
    await onModalSubmit(entity.text);
    setModalIsVisible(false);
  };

  const edit = () => setModalIsVisible(true);

  return (
    <div>
      <Modal
        title="Agregar comentario"
        visible={modalIsVisible}
        onOk={handleSubmit(onSubmit)}
        okText={"Agregar"}
        cancelText={"Cancelar"}
        confirmLoading={submitting}
        onCancel={() => setModalIsVisible(false)}
      >
        <Form layout={"vertical"}>
          {asyncError && <Alert message={asyncError.message} type="error" />}
          <Input.TextArea
            onChange={(e) => onChange("text", e.target.value)}
            label={"Comentario"}
            placeholder={"Comentar algo constructivo"}
          />
        </Form>
      </Modal>
      <Button onClick={edit} type={"link"}>
        + Agregar un comentario
      </Button>
    </div>
  );
};
