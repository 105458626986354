import React from "react";
import { Avatar } from "antd";
import { Link } from "react-router-dom";
import { FIELD_TYPES } from "../constants";
import format from "../utils/format";

let Comment = ({ comment }) => {
  let user = comment.role.user;
  const fullName = user.firstName + " " + user.lastName;
  return (
    <div className="comment">
      <div className={"p-1 mr-3"}>
        <Avatar>{(user.firstName[0] + user.lastName[0]).toUpperCase()}</Avatar>
      </div>
      <div>
        <Link to={"/1"}>{fullName}</Link> (
        {format(comment.createdAt, FIELD_TYPES.RELATIVE_TIME)})
        <i>{comment.text}</i>
      </div>
    </div>
  );
};
export default Comment;
