import React, { useState } from "react";
import {
  Button,
  Card,
  Dropdown,
  Menu,
  message,
  Modal,
  Table,
  Tooltip,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import Breadcrumb from "../../components/Breadcrumb";
import { remove } from "../../api";
import TaskTypeForm from "./TaskTypeForm";
import { Link } from "react-router-dom";
import { useSWRFetch } from "../../helpers";

function EntityModal({ trigger, entityId, mutate }) {
  const [visible, setVisible] = useState(false);
  const title = entityId
    ? "Editar tipo de tarea."
    : "Agregar un tipo de tarea nueva.";

  const close = () => {
    mutate();
    setVisible(false);
  };

  return (
    <div>
      <div onClick={() => setVisible(true)}>{trigger}</div>
      <Modal
        visible={visible}
        title={title}
        forceRender
        footer={null}
        onCancel={close}
      >
        <TaskTypeForm onSuccess={close} entityId={entityId} />
      </Modal>
    </div>
  );
}

function PreferencesExpenseType({ match }) {
  const endPoint = "task-types";
  const [sort, setSort] = useState("name+asc");
  const { data, error, mutate } = useSWRFetch(`${endPoint}?sort=${sort}`);
  const deleteWarning =
    "Para poder borrar este tipo de tarea, no puede haber tareas asociadas con este tipo de tarea.";
  const addButtonLabel = "Agregar un tipo de tarea nuevo";

  const onTableChange = (pagination, filters, sorter) => {
    if (sorter.order) {
      let sort = sorter.order === "ascend" ? `ASC` : "DESC";
      setSort(`${sorter.field}+${sort}`);
    } else {
      setSort("");
    }
  };

  const onDelete = async (record) => {
    try {
      await remove(endPoint, record.id);
      mutate();
      message.success(`${record.name} removido`);
    } catch (err) {
      console.log(err);
    }
  };

  const menu = (record) => (
    <Menu>
      <Menu.Item>
        <EntityModal
          entityId={record.id}
          mutate={mutate}
          trigger={
            <Button
              type={"link"}
              icon={<EditOutlined style={{ color: "#AAAAAA" }} />}
            >
              Editar
            </Button>
          }
        />
      </Menu.Item>
      <Menu.Item>
        {record.tasksCount !== 0 ? (
          <Tooltip title={deleteWarning}>
            <Button type={"link"} disabled>
              <DeleteOutlined style={{ color: "#AAAAAA" }} /> Remover
            </Button>
          </Tooltip>
        ) : (
          <Button type={"link"} onClick={() => onDelete(record)}>
            <DeleteOutlined style={{ color: "#AAAAAA" }} /> Remover
          </Button>
        )}
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: "Nombre",
      dataIndex: "name",
      sorter: "true",
    },
    {
      title: "Uso",
      dataIndex: "tasksCount",
      sorter: "true",
      render: (text, record) => (
        <Link
          to={`/${match.params.accountId}/services?taskTypeId=${record.id}`}
        >
          {text} servicio/s contien esta tarea.
        </Link>
      ),
    },
    {
      title: "Acciones",
      dataIndex: "id",
      width: 90,
      render: (id, record) => (
        <div className="justify-center" key={id}>
          <Tooltip title="Acciones" placement="left">
            <Dropdown
              trigger={"click"}
              overlay={menu(record)}
              placement="bottomLeft"
            >
              <Button
                icon={
                  <EllipsisOutlined style={{ transform: "rotate(90deg)" }} />
                }
              />
            </Dropdown>
          </Tooltip>
        </div>
      ),
    },
  ];

  const routes = [
    { to: "/preferencias", label: "Preferencias" },
    { label: "Tipos de tareas" },
  ];

  return (
    <Card
      title={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Breadcrumb routes={routes} />
          <EntityModal
            mutate={mutate}
            trigger={
              <Button icon={<PlusOutlined />} type={"primary"}>
                {addButtonLabel}
              </Button>
            }
          />
        </div>
      }
    >
      {JSON.stringify(error)}
      <Table
        dataSource={data}
        rowKey={(record) => record.id}
        columns={columns}
        pagination={false}
        onChange={onTableChange}
        size={"small"}
        className="table-striped-rows"
        bordered
      />
    </Card>
  );
}

export default PreferencesExpenseType;
