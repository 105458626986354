import React from "react";
import { Card, Button, Form, notification } from "antd";
import Breadcrumb from "../../components/Breadcrumb";
import { patch } from "../../api";
import { useEntity } from "../../hooks/useEntity";
import { TextInput } from "../../components/Inputs";

const END_POINT = `accounts`;
const formFields = [{ name: "name", required: true }];

function PreferencesGeneral({ match }) {
  const { accountId } = match.params;
  const { state, errors, onChange, handleSubmit } = useEntity(
    accountId,
    END_POINT,
    formFields
  );

  const { entity, submitted, submitting, fetching } = state;

  const onSubmit = async () => {
    await patch(END_POINT, entity, accountId);
    notification.success({
      message: "Los cambios fueron guardados correctamente",
    });
  };

  if (fetching) {
    return <p>loading...</p>;
  }

  const routes = [
    { to: "/preferencias", label: "Preferencias" },
    { label: "General" },
  ];

  return (
    <Card title={<Breadcrumb routes={routes} />}>
      <Form layout={"vertical"} onFinish={handleSubmit(onSubmit)}>
        <TextInput
          error={errors["name"]}
          submitted={submitted}
          label={"Nombre"}
          required
          onChange={onChange}
          placeholder={"Ingresa el nombre"}
          entity={entity}
          name={"name"}
        />

        <Form.Item style={{ display: "flex" }}>
          <Button
            type="primary"
            htmlType="submit"
            disabled={submitting}
            loading={submitting}
          >
            Guardar cambios
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}

export default PreferencesGeneral;
