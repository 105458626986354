import { Avatar, Table } from "antd";
import React from "react";

import { getOnTableChange, shouldRenderEmptyView } from "../../helpers";
import { getURI } from "../../helpers/GetURI";
import { useQuery } from "../../hooks/useQuery";
import { TelematicsEmptyView } from "../../organisms/TelematicsEmptyView";
import { useSWRFetch } from "../../utils/rest";
import { TableFilters } from "../Vehicles/VehicleList/VehiclesTableFilters";
import { getColumns } from "./getColumns";

const endPoint = "devices";

export default function ListDevices() {
  const { state, setSort, setPage, clearFilters, onChange } = useQuery({
    filters: {},
    sort: "id",
    page: 1,
  });

  const { isValidating, error, data } = useSWRFetch(getURI(endPoint, state));

  const columns = getColumns();

  if (error) {
    return <div>{JSON.stringify(error)}</div>;
  }

  if (data) {
    if (shouldRenderEmptyView(data, isValidating, state.filters)) {
      return <TelematicsEmptyView />;
    }
  }

  return (
    <div>
      <div
        style={{
          background: "#FAFAFA",
          color: "#397872",
        }}
        className={"justify-flex-between p-3"}
      >
        <div>
          {" "}
          <Avatar
            size={32}
            src={"https://static.inmatics.io/icons/icoSeguridad.svg"}
          />{" "}
          <span style={{ paddingLeft: 8, fontSize: 20 }}>
            {"Listado de dispositvos"}
          </span>
        </div>
      </div>
      <TableFilters
        clearFilters={clearFilters}
        columns={columns}
        onChange={onChange}
        queryState={state}
        setPage={setPage}
        totalCount={data?.count}
      />
      <Table
        dataSource={data?.data}
        onChange={getOnTableChange(setSort)}
        columns={columns}
        pagination={false}
        size={"small"}
        rowKey={(record) => record.id}
      />
    </div>
  );
}
