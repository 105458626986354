import { Button, Drawer } from "antd";
import React, { useEffect, useState } from "react";
import { FilterOutlined } from "@ant-design/icons";
import { FiltersParams } from "../types";
import { FiltersForm } from "./FiltersForm";

export function Filters(props: FiltersParams) {
  const { columns } = props;
  const myObj: { [index: string]: any } = {};
  const [entity, setEntity] = useState(myObj);
  const [visible, setVisible] = useState(false);

  const onSubmit = () => {
    props.onChange(entity);
    setVisible(false);
  };

  useEffect(() => {
    setEntity(props.filters);
  }, [props.filters]);

  const onChange = (key: string, value: any) =>
    setEntity((prevState) => ({ ...prevState, [key]: value }));

  return (
    <>
      <Button
        type={"primary"}
        // size={"small"}
        className={"ml-2"}
        onClick={() => setVisible(true)}
        icon={<FilterOutlined />}
      />
      <Drawer
        title={
          <div style={{ backgroundColor: "2c3357", color: "white" }}>
            <h3 style={{ margin: 0, color: "white" }}>Filtrar resultados</h3>
          </div>
        }
        width={500}
        placement="right"
        headerStyle={{ backgroundColor: "#018da2", color: "white" }}
        closable={false}
        onClose={() => setVisible(false)}
        visible={visible}
      >
        <FiltersForm
          onChange={onChange}
          onSubmit={onSubmit}
          entity={entity}
          columns={columns}
        />
      </Drawer>
    </>
  );
}
