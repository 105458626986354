import { Form } from "antd";
import React from "react";

import Breadcrumb from "../../components/Breadcrumb";
import { useFetchAndSubmit } from "../../hooks/useFetchAndSubmit";
import { IWithAccountId } from "../../types";
import { VehicleForm } from "./VehicleForm";

const endPoint = `vehicles`;

export default function VehicleEdit({ match, history }: IWithAccountId) {
  const [form] = Form.useForm();
  const { setFieldsValue } = form;
  const entityId = match.params.entityId;
  const onSuccess = () => history.push("admin/devices");
  const routes = [
    { to: "/" + match.params.accountId + "/vehicles", label: "Vehículos" },
    { label: "Editar vehículo" },
  ];

  const { onSubmit, submitting, error } = useFetchAndSubmit({
    apiVersion: "v3",
    endPoint,
    entityId: String(entityId),
    onSuccess,
    setFieldsValue,
    transform: "",
  });

  return (
    <div className={"mb-5"}>
      <div className="entity-header mb-3">
        <Breadcrumb routes={routes} />
      </div>

      <VehicleForm
        form={form}
        error={error}
        onFinish={onSubmit}
        loading={submitting}
        onCancel={() => history.goBack()}
        title={`Actualizar el vehículo #${entityId}`}
      />
    </div>
  );
}
