import React from "react";
import { Avatar } from "antd";
import { Link } from "react-router-dom";
import { FIELD_TYPES } from "../constants";
import format from "../utils/format";
import {Comment} from "../types";

interface CommentProps {
  comment: Comment;
  accountId: string;
}

const DashboardCommentView: React.FC<CommentProps> = ({ comment, accountId }) => {
  const {
    author_first_name,
    author_last_name,
    role_id,
    link_to,
    link_text,
    text,
    created_at
  } = comment;

  return (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex" }}>
          <div className="p-1 mr-3">
            <Avatar>
              {author_first_name[0] + author_last_name[0]}
            </Avatar>
          </div>
          <div style={{ marginBottom: 16 }}>
            <p style={{ marginBottom: 0 }}>
              <Link to={`/${accountId}/roles/${role_id}`}>
                {author_first_name} {author_last_name}{" "}
              </Link>
              comentó en <Link to={`/${accountId}${link_to}`}>{link_text}</Link>
            </p>
            <i>{text}</i>
          </div>
        </div>
        <div style={{ minWidth: 80 }}>
          <small>({format(created_at, FIELD_TYPES.RELATIVE_TIME)})</small>
        </div>
      </div>
  );
};

export default DashboardCommentView;
