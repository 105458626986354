import React from "react";
import { List } from "antd";
import { RightOutlined } from "@ant-design/icons";

export default function Seguros() {
    const data = [
        {
            title: "Registrar",
               description: "una póliza de seguro nueva"
        },
        {
            title: "Actualizar",
            description: "una póliza de seguro existente"
        },
        {
            title: "Listar",
            description: "pólizas y ver pólizas individuales"
        },
        {
            title: "Ver",
            description: "pólizas pertenecientes a vehículos"
        }
    ]

    return (
        <div id="seguros" style={{ borderBottom: "1px solid grey" }}>
            <div style={{ display: "flex" }}>
                <h1 style={{ fontSize: 40, color: "#0066b3" }}>Seguros</h1>
            </div>

            <div>
                <h3>Las acciones posibles en el sector de Seguros son:</h3>
                <List
                    itemLayout="horizontal"
                    dataSource={data}
                    renderItem={(item) => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={<RightOutlined />}
                                title={item.title}
                                description={item.description}
                            />
                        </List.Item>
                    )}
                />
            </div>
        </div>
    )
}
