export const strokeColor = "rgba(0,102,255,0.8)";

export const DEVICE_ACTIONS = {
  setDigitalOutput_0: "set_digital_output_0",
  setDigitalOutput_1: "set_digital_output_1",
};

export const FIELD_TYPES = {
  CAR_PICKER: "carPicker",
  CHECKBOX: "checkbox",
  CURRENCY: "currency",
  DATE: "date",
  DATETIME: "datetime",
  EMAIL: "email",
  HOUR: "time",
  INTEGER: "integer",
  MILEAGE: "mileage",
  NUMBER: "number",
  PASSWORD: "password",
  PERCENTAGE: "percentage",
  RADIO_BUTTON: "radioButton",
  RELATIVE_TIME: "relative_time",
  SELECT: "select",
  TEXT: "text",
  TIME: "time",
};

export enum filterTypes {
  dateRange = "dateRange",
  range = "range",
  text = "text",
  asyncTagLegacy = "asyncTagLegacy",
  asyncTag = "asyncTag",
}

export const GRID_PAGE_SIZE = 100;

export const logos: { [key: string]: string } = {
  AGRALE: "agrale",
  "ALFA ROMEO": "alfaromeo",
  AUDI: "audi",
  BAIC: "baic",
  BMW: "bmw",
  CHERY: "chery",
  CHEVROLET: "chevrolet",
  CHRYSLER: "chrysler",
  CITROEN: "citroen",
  DAEWOO: "daewoo",
  DAHIATSU: "dahiatsu",
  DFM: "dfm",
  DFSK: "dfsk",
  DODGE: "dodge",
  "DS AUTOMOBILES": "dsautomobiles",
  FERRARI: "ferrari",
  FIAT: "fiat",
  FORD: "ford",
  "FORD CAMION": "ford",
  FOTON: "foton",
  GEELY: "geely",
  "GREAT WALL": "greatwall",
  HAVAL: "haval",
  HINO: "hino",
  HONDA: "honda",
  HUMMER: "hummer",
  HYUNDAI: "hyundai",
  ISUZU: "isuzu",
  IVECO: "iveco",
  JAGUAR: "jaguar",
  JEEP: "jeep",
  JMC: "jmc",
  KIA: "kia",
  "KIA CAMION": "kia",
  LADA: "lada",
  LIFAN: "lifan",
  MACK: "mack",
  MAZDA: "mazda",
  "MERCEDES BENZ": "mercedesBenz",
  "MERCEDES BENZ C": "mercedesBenz",
  "MINI COOPER": "minicooper",
  MITSUBISHI: "mitsubishi",
  NISSAN: "nissan",
  PEUGEOT: "peugeot",
  PORSCHE: "porsche",
  RAM: "ram",
  RENAULT: "renault",
  ROVER: "rover",
  "ROVER-LAND ROV.": "landrover",
  SCANIA: "scania",
  SHINERAY: "shineray",
  SMART: "smart",
  SUBARU: "subaru",
  SUZUKI: "suzuki",
  TATA: "tata",
  TOYOTA: "toyota",
  VOLKSWAGEN: "volkswagen",
  "VOLKSWAGEN CAM.": "volkswagen",
  VOLVO: "volvo",
  "VOLVO CAMION": "volvo",
};

export const months = [
  "Ene",
  "Feb",
  "Mar",
  "Abr",
  "May",
  "Jun",
  "Jul",
  "Ago",
  "Sep",
  "Oct",
  "Nov",
  "Dic",
];
export const days = ["Lun", "Mar", "Mie", "Jue", "Vie", "Sab", "Dom"];

export const MONTH_SHORT_NAMES = [
  "",
  "Ene",
  "Feb",
  "Mar",
  "Abr",
  "May",
  "Jun",
  "Jul",
  "Ago",
  "Sep",
  "Oct",
  "Nov",
  "Dic",
];
export const defaultLayout = {
  sm: { span: 24 },
  md: { span: 12, offset: 6 },
  xl: { span: 12, offset: 6 },
};

export const timezone = "AMERICA/ARGENTINA/BUENOS_AIRES";
export const OPERANDS = {
  substring: "substring",
  eq: "eq",
  in: "in",
  range: "range",
};
// export const GOOGLE_MAPS_KEY = "AIzaSyCKwmY_-MUcmUwd0Ag6SGdxVR4zmf--nAU";
export const GOOGLE_MAPS_KEY = "AIzaSyB0jdQ-eoAtaVqxaK7K_zIcgGk_wqeSzPY";
export const STATIC_SERVER = "https://static.inmatics.io";

export const GEO_POLYGON_SET = {
  POLYGON_FILL_COLOR: "#0000FF",
  POLYGON_BORDER_COLOR: "#0000FF",
};

export const carBrands = [
  "AGRALE",
  "ALFA ROMEO",
  "AUDI",
  "BAIC",
  "BMW",
  "CHANGAN",
  "CHERY",
  "CHEVROLET",
  "CHRYSLER",
  "CITROEN",
  "DAEWOO",
  "DFM",
  "DFSK",
  "DS AUTOMOBILES",
  "FERRARI",
  "FIAT",
  "FORD",
  "FORD CAMION",
  "FOTON",
  "GEELY",
  "GREAT WALL",
  "HAVAL",
  "HINO",
  "HONDA",
  "HUMMER",
  "HYUNDAI",
  "ISUZU",
  "IVECO",
  "JAC MOTORS",
  "JAGUAR",
  "JMC",
  "KIA",
  "KIA CAMION",
  "LIFAN",
  "MACK",
  "MASERATI",
  "MCLAREN",
  "MERCEDES BENZ",
  "MERCEDES BENZ C",
  "MINI COOPER",
  "MITSUBISHI",
  "NISSAN",
  "PEUGEOT",
  "PORSCHE",
  "RENAULT",
  "RENAULT CAMION",
  "ROLLS ROYCE",
  "ROVER-LAND ROV.",
  "SCANIA",
  "SEAT",
  "SERO ELECTRIC",
  "SHINERAY",
  "SMART",
  "SOUEAST",
  "SUBARU",
  "SUZUKI",
  "TOYOTA",
  "VOLKSWAGEN",
  "VOLKSWAGEN CAM.",
  "VOLVO",
  "VOLVO CAMION",
  "ZANELLA",
];
