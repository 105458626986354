import React from "react";
import { Dropdown, Input, Menu } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { getByKey, groupByItems } from "../../utils";

const grey = "#858484";

export default function CollectionsListQuery({
  query,
  onChange,
  ranges,
  label,
  tempGroup,
}) {
  const { groupBy, search } = query;

  return (
    <div className={"grid-filters"}>
      <Input
        placeholder="Buscar ..."
        allowClear
        onChange={(e) => onChange("search", e.target.value)}
        value={search}
        style={{ width: 300 }}
      />
      <Dropdown.Button
        overlay={
          <Menu>
            {groupByItems.map((x) => (
              <Menu.Item
                onClick={() => onChange("groupBy", x.key)}
                key={x.key}
                icon={x.icon}
              >
                {x.label}
              </Menu.Item>
            ))}
          </Menu>
        }
        className={"ml-3"}
        placement="bottomCenter"
        icon={<DownOutlined />}
      >
        <small style={{ color: grey }}>Agrupar por: </small>
        {groupByItems.filter((x) => x.key === groupBy)[0].label}
      </Dropdown.Button>
      <Dropdown.Button
        overlay={
          <Menu>
            {ranges.map((x) => (
              <Menu.Item
                onClick={() => onChange(tempGroup, x.value)}
                key={x.value}
              >
                {x.label}
              </Menu.Item>
            ))}
          </Menu>
        }
        className={"ml-3"}
        placement="bottomCenter"
        icon={<DownOutlined />}
      >
        <small style={{ color: grey }}>{label} </small>
        {getByKey(ranges, query[tempGroup], "value").label}
      </Dropdown.Button>
    </div>
  );
}
