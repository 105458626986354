import React from "react";
import { Card, Statistic } from "antd";
import { useDataApi } from "../../hooks/useDataApi";
import {
  UserOutlined,
  UserAddOutlined,
  UserDeleteOutlined,
} from "@ant-design/icons";

export default function Entity() {
  const [state] = useDataApi("reports/assigned-vehicles");

  let { data } = state;

  const title = (
    <div>
      <UserOutlined className={"muted mr-2"} />
      Vehículos asignados a un conductor
    </div>
  );

  const title2 = (
    <div>
      <UserAddOutlined className={"muted mr-2"} />
      Asignados
    </div>
  );

  const title3 = (
    <div>
      <UserDeleteOutlined className={"muted mr-2"} />
      Sin Asignar
    </div>
  );
  return (
    <Card title={title} className={"mb-2"}>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <Statistic
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          title={title2}
          value={data[0] && data[0].countDriver}
          valueStyle={{ color: "#3f8600" }}
        />
        <Statistic
          title={title3}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          value={data[0] && data[0].driverLess}
          valueStyle={{ color: "#e69120" }}
        />
      </div>
    </Card>
  );
}
