import React from "react";
import { Card, Form } from "antd";
import { PlaceForm } from "./PlaceForm";
import { useFetchAndSubmit } from "./useFetchAndSubmit";
import { useHistory } from "react-router";
import { FileTextOutlined } from "@ant-design/icons";
import { VehiclesTable } from "./VehiclesTable";

interface EditPlaceParams {
  match: { params: { id: string } };
}

const endPoint = `places`;

export default function EditPlace(props: EditPlaceParams) {
  const { match } = props;
  const history = useHistory();

  const [form] = Form.useForm();

  const [onFinish, submitting, error] = useFetchAndSubmit(
    endPoint,
    match.params.id,
    form.setFieldsValue
  );

  return (
    <Card style={{ height: "100vh" }}>
      <div>
        <h3>
          <FileTextOutlined className={"muted"} /> Lugares{" "}
          <small>/ Editar {form.getFieldsValue().name}</small>
        </h3>
      </div>
      <PlaceForm
        // @ts-ignore
        error={error}
        form={form}
        // @ts-ignore
        loading={submitting}
        onCancel={() => history.goBack()}
        // @ts-ignore
        onFinish={onFinish}
      />
      <VehiclesTable placeId={match.params.id} />
    </Card>
  );
}
