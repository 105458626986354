import { Wrapper } from "@googlemaps/react-wrapper";
import { Status } from "@googlemaps/react-wrapper/src";
import React from "react";

import { Marker } from "../../../components/Marker";
import { Poly } from "../../../components/Polyline";
import { GOOGLE_MAPS_KEY } from "../../../constants";
import { ILocation } from "../../../types";
import { MyMapComponent } from "../../Trips/MyMapComponent";

const renderStatus = (status: Status) => {
  if (status === "LOADING") return <h3>{status} ..</h3>;
  if (status === "FAILURE") return <h3>{status} ...</h3>;
  return <h3>{status} ...</h3>;
};

export const Map = (props: { entries: ILocation[] }) => {
  const { entries } = props;
  const [zoom, setZoom] = React.useState(3); // initial zoom

  return (
    <Wrapper
      apiKey={GOOGLE_MAPS_KEY}
      render={renderStatus}
      version={"beta"}
      //@ts-ignore
      libraries={["marker"]}
    >
      <MyMapComponent
        locationEntries={entries}
        onClick={undefined}
        onIdle={(m: google.maps.Map) => setZoom(m.getZoom()!)}
        style={{ height: "400px" }}
      >
        {entries.map((x: ILocation) => (
          <Marker position={x} key={x.id} zoom={zoom} />
        ))}
        <Poly path={entries.reverse()} />
      </MyMapComponent>
    </Wrapper>
  );
};
