import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import VendorsList from "./VendorsList";
import VendorsEdit from "./VendorsEdit";
import VendorsCreate from "./VendorsCreate";

export function Vendors({ match }) {
  return (
    <Switch>
      <Route component={VendorsList} path={match.path + "/"} exact />
      <Route component={VendorsCreate} path={match.url + "/crear"} exact />
      <Route component={VendorsEdit} path={match.url + "/:id"} exact />
      <Redirect to={match.url} />
    </Switch>
  );
}
