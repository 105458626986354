import { Table } from "antd";
import {
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from "antd/lib/table/interface";
import React from "react";
import useSWR from "swr";

import Paginator from "../components/Paginator";
import { GRID_PAGE_SIZE } from "../constants";
import { getURI } from "../helpers/GetURI";
import { get } from "../helpers/v2api";
import { useQuery } from "../hooks/useQuery";

const renderStatus = (text: string, record: any) => {
  if (!record["status_name"]) return <div>N/A</div>;

  return (
    <div>
      <span style={{ color: record["status_color"] }}>&#9673; </span>
      {record["status_name"]}
    </div>
  );
};

const columns = [
  {
    title: "Nombre",
    dataIndex: "name",
    sorter: true,
  },
  {
    title: "Cuenta",
    dataIndex: "account_name",
    sorter: true,
  },
  {
    title: "Status",
    dataIndex: "status_name",
    render: renderStatus,
    sorter: true,
  },
];

const useSWRFetch = (url: string) => {
  const fetcher = async () => {
    const axiosResponse = await get(url);

    return {
      data: axiosResponse.data,
      count: axiosResponse.headers["x-total-count"],
    };
  };

  return useSWR(url, fetcher, {
    initialData: null,
    revalidateOnMount: true,
  });
};

export default function VehiclesList() {
  const initializerArg = {
    filters: {},
    sort: "name",
    page: 1,
  };

  const { state, setSort, setPage } = useQuery(initializerArg);
  const { page } = state;

  // @ts-ignore
  const { data, error } = useSWRFetch(getURI("admin/vehicles/all", state));

  const onTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any>
  ) => {
    if (sorter.order) {
      let sort = sorter.order === "ascend" ? `ASC` : "DESC";
      setSort(`${sorter.field}+${sort}`);
    } else {
      setSort("");
    }
  };

  if (error) {
    return <div>{JSON.stringify(error)}</div>;
  }
  return (
    <div>
      <div className={"justify-flex-end p-3"}>
        <div className={"mr-2"}></div>
        <Paginator
          current={page}
          onChange={setPage}
          total={data?.count}
          pageSize={GRID_PAGE_SIZE}
        />
      </div>

      <Table
        dataSource={data?.data}
        rowClassName={"show-pointer"}
        // @ts-ignore
        onChange={onTableChange}
        columns={columns}
        pagination={false}
        size={"small"}
        rowKey={(record) => record.id}
      />
    </div>
  );
}
