import {useCallback, useReducer} from "react";
import validator from "../utils/validator";
import {isEmpty} from "lodash";

// Define action types
const actionTypes = {
    SUBMIT_INIT: "SUBMIT_INIT",
    SUBMIT_ATTEMPTED: "SUBMIT_ATTEMPTED",
    SUBMIT_SUCCESS: "SUBMIT_SUCCESS",
    SUBMIT_FAILURE: "SUBMIT_FAILURE",
    ENTITY_CHANGED: "ENTITY_CHANGED",
    MERGE_ENTITY: "MERGE_ENTITY",
    SET_ENTITY: "SET_ENTITY",
};

// Define state and action types
interface FormState {
    submitting: boolean;
    submitted: boolean;
    entity: Record<string, any>;
    asyncError?: { code?: number; message: string };
}

interface Action {
    type: string;
    payload?: any;
}

// Form reducer function
const formReducer = (state: FormState, action: Action): FormState => {
    switch (action.type) {
        case actionTypes.SUBMIT_INIT:
            return {
                ...state,
                submitting: true,
            };
        case actionTypes.SUBMIT_ATTEMPTED:
            return {
                ...state,
                submitted: true,
            };
        case actionTypes.SUBMIT_SUCCESS:
            return {
                ...state,
                submitting: false,
            };
        case actionTypes.SUBMIT_FAILURE:
            return {
                ...state,
                submitting: false,
                asyncError: action.payload.error,
            };
        case actionTypes.ENTITY_CHANGED:
            return {
                ...state,
                entity: {...state.entity, [action.payload.key]: action.payload.value},
            };
        case actionTypes.MERGE_ENTITY:
            return {
                ...state,
                entity: {...state.entity, ...action.payload.entity},
            };
        case actionTypes.SET_ENTITY:
            return {
                ...state,
                entity: action.payload.entity,
                submitted: false,
            };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};

// Custom hook for form handling
const useForm = (initialValues: Record<string, any> = {}, fields: any[] = []) => {
    const [state, dispatch] = useReducer(formReducer, {
        submitting: false,
        submitted: false,
        entity: initialValues,
    });

    const {entity} = state;

    const handleSubmit = useCallback(
        (callback: () => Promise<void>) => async (e?: React.FormEvent) => {
            if (e && e.preventDefault) {
                e.preventDefault();
                e.persist();
            }

            dispatch({type: actionTypes.SUBMIT_ATTEMPTED});
            try {
                if (isEmpty(validator(entity, fields))) {
                    dispatch({type: actionTypes.SUBMIT_INIT});
                    await callback();
                    dispatch({type: actionTypes.SUBMIT_SUCCESS});
                }
            } catch (error) {
                console.error(error);
                const errorMessage =
                    error.response && error.response.status
                        ? {
                            code: error.response.status,
                            message: error.response.data,
                        }
                        : {message: "Lo lamentamos mucho, algo salió mal."};
                dispatch({
                    type: actionTypes.SUBMIT_FAILURE,
                    payload: {error: errorMessage},
                });
            }
        },
        [entity, fields]
    );

    const handleChange = useCallback(
        (key: string, value: any) =>
            dispatch({type: actionTypes.ENTITY_CHANGED, payload: {key, value}}),
        []
    );

    const mergeEntity = (entity: Record<string, any>) =>
        dispatch({type: actionTypes.MERGE_ENTITY, payload: {entity}});

    const setEntity = (entity: Record<string, any>) =>
        dispatch({type: actionTypes.SET_ENTITY, payload: {entity}});

    return {
        formState: state,
        onChange: handleChange,
        setEntity,
        handleSubmit,
        mergeEntity,
        errors: validator(entity, fields),
    };
};

export default useForm;
