import React, { useContext } from "react";
import { Alert, Button, Form, Layout } from "antd";
import { TextInput } from "../../components/Inputs";
import Header from "../../components/Header";
import useForm from "../../hooks/useForm";
import { post } from "../../api";
import { AuthContext } from "../../hooks/useAuth";
import {User} from "../../types";

const text = { title: "¿Cuál es el nombre de tu flota de transporte?" };

const formFields = [
  {
    name: "accountName",
    label: "Nombre de la flota",
    required: true,
    type: "password",
    prefixIcon: "lock",
  },
];

const CreateAccount = () => {
  const { formState, onChange, handleSubmit, errors } = useForm({}, formFields);
  const { entity, submitted, submitting, asyncError } = formState;
  const user = useContext<User>(AuthContext);

  const onSubmit = async () => {
    await post("accounts", entity);
    window.location.reload();
  };

  return (
    <div>
      <Header user={user} />
      <Layout className={"login-container"}>
        <Form layout={"vertical"} className={"login-form card"}>
          <h1>{text.title}</h1>

          {asyncError && <Alert message={asyncError.message} type="error" />}

          {formFields.map((x) => (
            <TextInput
              key={x.name}
              error={errors[x.name]}
              // @ts-ignore
              onChange={onChange}
              submitted={submitted}
              label={x.label}
              placeholder={`Ej. Flota de ${user.first_name}`}
              entity={entity}
              name={x.name}
            />
          ))}

          <div className={"login-form-buttons"}>
            <small>
              <a href="https://app.inmatics.io/help">¿Necesitas ayuda?</a>
            </small>
            <Button
              type="primary"
              disabled={submitting}
              loading={submitting}
              onClick={handleSubmit(onSubmit)}
            >
              Crear flota
            </Button>
          </div>
        </Form>
      </Layout>
    </div>
  );
};
export default CreateAccount;
