import React from "react";
import { Form, Input } from "antd";
import { formatNumber } from "../utils/format";
import { calculateTimeSince } from "../utils";
import { useDataApi } from "../hooks/useDataApi";

export default function OdometerUpdateField({ entity, onChange, optional }) {
  const { vehicleId } = entity;
  const [state] = useDataApi("vehicles");
  const vehicleList = state.data;

  let help = "";
  if (vehicleId) {
    console.log(vehicleId);
    const vehicle = vehicleList.find(
      (x) => x.id.toString() === vehicleId.toString()
    );
    let vehicleElement = vehicle && vehicle["meterEntries.max_reading"];

    if (vehicleElement) {
      help = `Última actualización: ${formatNumber(
        vehicleElement,
        0
      )}km (${calculateTimeSince(vehicle["meterEntries.max_date"])})`;
    }
  }

  return (
    <Form.Item
      label={
        optional ? (
          <div>
            Kilometraje <small>(opcional)</small>
          </div>
        ) : (
          "Kilometraje"
        )
      }
      help={help}
    >
      <Input
        placeholder={"Indique el kilometraje actual del vehículo"}
        value={entity["meterEntryValue"] || ""}
        onChange={(e) => onChange("meterEntryValue", e.target.value)}
      />
    </Form.Item>
  );
}
