import { Card, Col, Row } from "antd";
import React from "react";

export function TelematicsEmptyView() {
  return (
    <div style={{ minHeight: 1000, paddingTop: 150 }}>
      <Row>
        <Col span={12} offset={6}>
          <Card>
            <div
              style={{
                minHeight: 300,
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src={"https://static.inmatics.io/icons/icoTelematica.svg"}
                alt={"Telematica"}
              />
              <h2>{"No tiene el servicio de telemática contratado"}</h2>
              <p style={{ maxWidth: "80%" }}>
                {
                  "Consulte por el servicio de telemática con su ejecutivo de cuenta"
                }
              </p>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
