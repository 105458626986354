import React from "react";
import { getByKey } from "../../utils";
import { useLegacyVehicles } from "../../hooks/useLegacyVehicles";

export default function FiltersInfo({ values }) {
  const ve = useLegacyVehicles();
  if (!values.map) {
    return null;
  }
  return (
    <>
      {values
        .map((y) => {
          let byKey = getByKey(ve.data, y);
          return (byKey || "").name;
        })
        .join(", ")}
    </>
  );
}
