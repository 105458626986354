import { LoadingOutlined } from "@ant-design/icons";
import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";

import { get } from "../api";
import { HighlightedText } from "./HighlightedText";

const { Option } = Select;

const Omnisearch = (props: { accountId: string }) => {
  const history = useHistory();
  const { accountId } = props;
  const [q, setQ] = useState("");
  const [data, setData] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      if (q.length > 0) {
        try {
          setFetching(true);
          const data = await get("omnisearch?q=" + q);
          setFetching(false);
          setData(data);
        } catch (e) {
          setFetching(false);
          setError("Error");
        }
      } else {
        setData([]);
      }
    };
    fetchData();
  }, [q]);

  const debounced = useDebouncedCallback((value) => setQ(value), 500);

  const handleChange = (value: any) => {
    const split = value.split("-");

    history.push(`/${accountId}/${split[0]}/${split[1]}`);
    setData([]);
  };

  const options = data.map((d: any) => (
    <Option key={d.id} value={`${d.type}-${d.id}`}>
      <Link to={`/${accountId}/${d.type}/${d.id}`}>
        <div>
          <div>
            <h3>
              <HighlightedText haystack={d.name} needle={q} />{" "}
              <small>
                {" "}
                ({d.type === "vehicles" ? "Vehículo" : "Conductor"})
              </small>
            </h3>
          </div>
        </div>
      </Link>
    </Option>
  ));

  const fetchingOptions = ["buscando"].map((d: any) => (
    <Option key={"Searching"} value={d.id}>
      <div className="search-dropdown--opened">
        <div className="search-dropdown--searching">
          <LoadingOutlined />
          <p>Buscando...</p>
        </div>
      </div>
    </Option>
  ));

  const errorOptions = ["error"].map((d) => (
    <Option key={d} value={d}>
      <div className="search-dropdown--opened">
        <p className="error">{"error"}</p>
      </div>
    </Option>
  ));

  return (
    <Select
      showSearch
      value={""}
      placeholder={"Búsqueda rápida"}
      style={{ minWidth: 500 }}
      className="searchbar"
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onSearch={debounced}
      onChange={handleChange}
      notFoundContent={null}
    >
      {fetching ? fetchingOptions : error ? errorOptions : options}
    </Select>
  );
};
export default Omnisearch;
