import React from "react";
import { Table, Popconfirm, Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

export default function TaskList({ entity, taskList, onRemoveTask }) {
  const renderTaskType = (text) => {
    let find = taskList.find((x) => x.id === text);
    return find && find.name;
  };

  const renderDelete = (text, record) => {
    return (
      <div>
        <Popconfirm
          title="¿Esta seguro que desea eliminar este trabajo?"
          onConfirm={() => onRemoveTask(record)}
          okText="Sí"
          cancelText="No"
        >
          <Button
            type="ghost"
            size={"small"}
            shape="circle"
            danger
            icon={<DeleteOutlined />}
          />
        </Popconfirm>
      </div>
    );
  };

  let columns = [
    {
      title: "Tarea",
      dataIndex: "taskTypeId",
      key: "taskTypeId",
      render: renderTaskType,
    },
    {
      title: "",
      dataIndex: "createdAt",
      key: "createdAt",
      render: renderDelete,
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        dataSource={entity.tasks}
        size={"small"}
        rowKey={(record) => record.taskTypeId}
      />
    </div>
  );
}
