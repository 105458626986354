import React from "react";
import { Button, Table } from "antd";
import { useFetchData } from "../../hooks/useFetchData";
import { Link } from "react-router-dom";
import { PlusCircleOutlined } from "@ant-design/icons";

const columns = [
  {
    title: "Nombre",
    dataIndex: "name",
    render: (text, record) => (
      <Link to={`/${record.accountId}/vehicles/${record.id}`}>
        {record.name}
      </Link>
    ),
  },
  { title: "# servicios", dataIndex: "services.service_count" },
  { title: "# tareas", dataIndex: "services.tasks.task_count" },
  { title: "Kilometraje", dataIndex: "meterEntries.max_reading" },
  { title: "Fecha ultimo km", dataIndex: "meterEntries.max_date" },
];

export default function ServicesSummary({ match }) {
  let accountId = match.params.accountId;

  const [state] = useFetchData({
    url: "reports/services-summary",
    initialQuery: {},
  });

  const { data } = state;

  return (
    <div>
      <div className="grid-header">
        <h1>{"Resumen de servicios"}</h1>
        <div>
          <Link to={"/" + accountId + "/collections/create"}>
            <Button
              size={"large"}
              type={"primary"}
              className={"ml-1"}
              shape={"circle"}
              icon={<PlusCircleOutlined />}
            />
          </Link>
        </div>
      </div>

      <Table
        columns={columns}
        size={"small"}
        className={"reports"}
        dataSource={data}
        pagination={{ pageSize: 15 }}
      />
    </div>
  );
}
