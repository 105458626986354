import { Card, Col } from "antd";
import React from "react";
import useSWR from "swr";

import { get } from "../../../helpers/v2api";
import { useMQTT } from "../../../hooks/useMQTT";
import { Vehicle } from "../../../types";
import { Map } from "./Map";
import { Title } from "./Title";

function useLastLocations(device_imei: string | undefined, vehicleID: string) {
  const messages = useMQTT(`devices/${device_imei}`);
  const url = `location-entries?filters[vehicle_id]=${vehicleID}`;

  const { data } = useSWR(url, async () => (await get(url)).data, {
    refreshInterval: 5000,
  });

  if (!data || !data[0]) {
    return null;
  }

  const position = {
    ...data[0],
    lat: Number(data[0].lat),
    lng: Number(data[0].lng),
  };

  const location = messages[0] || position;
  return { data, location };
}

export default function SummaryMap(props: { vehicle: Vehicle }) {
  const { id, device_imei } = props.vehicle;

  const useLastLocations1 = useLastLocations(device_imei, id);
  if (useLastLocations1 === null) {
    return null;
  }

  const { data, location } = useLastLocations1;

  return (
    <Col span={15} className={"pb-1 pr-1"}>
      <Card title={<Title location={location} />} style={{ maxHeight: 528 }}>
        <div
          style={{ height: "58vh", width: "100%", padding: 0 }}
          className={"in-section"}
        >
          <Map entries={data || []} />
        </div>
      </Card>
    </Col>
  );
}
