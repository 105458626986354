import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "antd";

export default ({ routes }) => {
  return (
    <Breadcrumb>
      {routes.map((r) => {
        if (r.to) {
          return (
            <Breadcrumb.Item key={r.label}>
              <Link to={r.to}>{r.label}</Link>
            </Breadcrumb.Item>
          );
        } else {
          return <Breadcrumb.Item key={r.label}>{r.label}</Breadcrumb.Item>;
        }
      })}
    </Breadcrumb>
  );
};
