import React, { useContext } from "react";
import { Form } from "antd";
import { AccountContext } from "../../hooks/useAccount";
import { useLegacyVehicles } from "../../hooks/useLegacyVehicles";
import { InvoiceForm } from "./InvoicesForm";
import { VendorBreadcrumb } from "./VendorsBreadcrumb";
import { useOnSubmit } from "../../hooks/useOnSubmit";

const path = `facturas`;
const endPoint = "invoices";
const title = `Registrar una factura nueva`;

export default function VendorsCreate({ history }) {
  const vehicles = useLegacyVehicles();
  const account = useContext(AccountContext);
  const [form] = Form.useForm();

  const urlAfterSubmit = () => {
    return `/${account.id}/${path}`;
  };
  const [onSubmit, submitting, error] = useOnSubmit(endPoint, urlAfterSubmit);

  return (
    <div style={{ height: "100vh" }}>
      <div className="entity-header mb-3">
        <VendorBreadcrumb account={account} label={"Registrar"} />
      </div>

      <InvoiceForm
        error={error}
        form={form}
        loading={submitting}
        onCancel={() => history.goBack()}
        onSubmit={onSubmit}
        title={title}
        vehicles={vehicles}
      />
    </div>
  );
}
