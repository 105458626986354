import React, { useReducer, useState } from "react";
import moment from "moment-timezone";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Alert, DatePicker, Form, Input, Modal } from "antd";
import useForm from "../../hooks/useForm";
import ContactSelect from "./ContactOrCreate";
import locale from "antd/es/date-picker/locale/es_ES";
import { post } from "../../api";
import { FIELD_TYPES } from "../../constants";
import { odomoterUpdateHelp } from "../../utils";

const reducer = (state, action) => {
  console.log(action);
  switch (action.type) {
    case "ADD_NEW_CHANGED":
      return {
        ...state,
        addNew: action.payload,
      };

    default:
      throw new Error();
  }
};

const initialState = {
  addNew: "selectExisting",
};

export default ({ vehicle }) => {
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { addNew } = state;

  let fields =
    addNew === "selectExisting"
      ? [
          {
            name: "contactId",
            required: true,
          },
          {
            name: "startDate",
            required: true,
          },
          {
            name: "meterEntryValue",
            type: FIELD_TYPES.INTEGER,
          },
        ]
      : [
          {
            name: "firstName",
            required: true,
          },
          {
            name: "meterEntryValue",
            type: FIELD_TYPES.INTEGER,
          },
          {
            name: "lastName",
            required: true,
          },
          {
            name: "startDate",
            required: true,
          },
        ];

  const { formState, handleSubmit, errors, onChange } = useForm(
    {
      startDate: moment(),
      vehicleId: vehicle.id,
    },
    fields
  );

  const { entity, submitted, submitting, asyncError } = formState;

  const onSubmit = async () => {
    await post("vehicle-assignments", entity);
    document.body.style.overflow = "initial";
    window.location.reload();
  };

  let showModal = () => {
    document.body.style.overflow = "hidden";
    setModalIsVisible(true);
  };

  let closeModal = () => {
    document.body.style.overflow = "initial ";
    setModalIsVisible(false);
  };

  return (
    <div>
      <Modal
        title={`Asignar ${vehicle.name}`}
        visible={modalIsVisible}
        onOk={handleSubmit(onSubmit)}
        okText={"Asignar"}
        cancelText={"Cancelar"}
        confirmLoading={submitting}
        onCancel={closeModal}
      >
        {asyncError && <Alert message={asyncError.message} type="error" />}
        <Form layout={"vertical"}>
          <ContactSelect
            onChange={onChange}
            entity={entity}
            addNew={addNew}
            dispatch={dispatch}
            submitted={submitted}
            errors={errors}
          />

          <Form.Item label={"Fecha y hora de inicio"} required>
            <DatePicker
              showTime
              locale={locale}
              style={{ width: "100%" }}
              placeholder={"Fecha inicio"}
              format="DD/MM/YYYY HH:mm"
              value={
                entity["startDate"] ? moment(entity["startDate"]) : undefined
              }
              onChange={(e) => onChange("startDate", e)}
            />
          </Form.Item>
          <Form.Item
            label={
              <div>
                Kilometraje inicio <small>(opcional)</small>
              </div>
            }
            help={odomoterUpdateHelp(vehicle)}
          >
            {/*{JSON.stringify(vehicle.meterCurrentValue)}*/}
            <Input
              placeholder={"Ingresa el kilometraje"}
              onChange={(e) =>
                onChange(
                  "startingMeterEntryValue",
                  e.target.value.replace(/\D/g, "").replace(/\./gi, "")
                )
              }
              value={entity["meterEntryValue"]}
            />
          </Form.Item>
          <Form.Item label={"Comentario"}>
            <Input.TextArea
              value={entity["comment"]}
              onChange={(e) => onChange("comment", e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>
      <span>Sin asignar</span>
      {<PlusCircleOutlined className={"ml-1"} onClick={showModal} />}
    </div>
  );
};
