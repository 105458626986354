import React, { useRef, useState } from "react";
import { Button, Form, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useDataApi } from "../hooks/useDataApi";
import { post } from "../api";

const endPoint = "transaction-types?sort=name+asc&category=collection";

export default function ConceptSelector({ onChange, entity, label }) {
  const NAME = "transactionTypeId";
  const [name, setName] = useState("");
  const foo = useDataApi(endPoint);
  const state = foo[0];
  const addItem = foo[2];
  const inputRef = useRef(null);

  const onAddItemClick = async () => {
    const response = await post(endPoint, { name });
    addItem(response);
    setName("");
    onChange(NAME, response.id);
    inputRef.current.blur();
  };

  const onSelectChange = (e) => onChange(NAME, e);

  const onInputChange = (e) => setName(e);

  const entityElement = entity["transactionTypeId"];
  return (
    <Form.Item label={label || "Tipo cobranza"} required>
      <Select
        allowClear
        ref={inputRef}
        showSearch
        notFoundContent={
          name.length > 3 ? (
            <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
              <Button
                style={{
                  flex: "none",
                  display: "block",
                  cursor: "pointer",
                }}
                type="primary"
                onClick={onAddItemClick}
              >
                <PlusOutlined /> {`Agregar ${name}`}
              </Button>
            </div>
          ) : (
            ""
          )
        }
        onChange={onSelectChange}
        onSearch={onInputChange}
        optionFilterProp={"label"}
        options={state.data.map((item) => ({
          label: item.name,
          value: item.id.toString(),
        }))}
        placeholder="Tipo cobranza (Ej. Efectivo)"
        value={entityElement ? entityElement.toString() : ""}
      />
    </Form.Item>
  );
}
