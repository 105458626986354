import React from "react";

export default function Finanzas() {
    return (
        <div id="finanzas" style={{ borderBottom: "1px solid grey" }}>
            <div style={{ display: "flex" }}>
                <h1 style={{ fontSize: 40, color: "#0066b3" }}>Finanzas</h1>
            </div>

            <div>
                <p style={{ fontSize: 18 }}>
                    Facilitamos reportes periódicos para un control diario de los gastos y cobranzas.
                </p>
            </div>

            <div>
                <h3><b>Cobranzas</b></h3>
                <p style={{ fontSize: 18 }}>
                    Los registros deben obligatoriamente vincularse a un <b>vehículo</b> y establecer un <b>monto</b>.
                </p>
            </div>

            <div>
                <h3><b>Facturas</b></h3>
                <p style={{ fontSize: 18 }}>
                    Las facturas están siempre relacionadas con un proveedor.
                </p>
            </div>
        </div>
    )
}
