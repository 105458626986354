import React from "react";
import { Row, Menu, Col, Card } from "antd";
import { NavLink, Redirect, Route, Switch } from "react-router-dom";
import PreferencesStatus from "./PreferencesStatus";
import PreferencesGeneral from "./PreferencesGeneral";
import PreferencesUsers from "./PreferencesUsers";
import PreferencesTransactionsTypes from "./PreferencesTransactionsTypes";
import PreferencesTaskTypes from "./PreferencesTaskTypes";
import PreferencesPartTypes from "./PreferencesPartTypes";

function Preferences({ history, match }) {
  const items = [
    { path: "/general", component: PreferencesGeneral, label: "General" },
    { path: "/estados", component: PreferencesStatus, label: "Estados" },
    {
      path: "/tipo-tareas",
      component: PreferencesTaskTypes,
      label: "Tipo de tareas",
    },

    {
      path: "/tipo-cobranzas",
      component: PreferencesTransactionsTypes,
      label: "Tipo de cobranzas",
    },
    {
      path: "/repuestos",
      component: PreferencesPartTypes,
      label: "Autopartes e insumos",
    },
    { path: "/usuarios", component: PreferencesUsers, label: "Usuarios" },
  ];

  return (
    <div style={{ padding: 10 }}>
      <Row>
        <Col span={4}>
          <Card>
            <Menu selectedKeys={[history.location.pathname]}>
              {items.map((x) => (
                <Menu.Item key={`${match.url}${x.path}`}>
                  <NavLink to={`${match.url}${x.path}`}>{x.label}</NavLink>
                </Menu.Item>
              ))}
            </Menu>
          </Card>
        </Col>
        <Col span={20} style={{ paddingLeft: 10 }}>
          <Switch>
            {items.map((x) => (
              <Route
                key={x.path}
                path={match.path + x.path}
                exact
                component={x.component}
              />
            ))}
            <Redirect to={match.url + "/general"} />
          </Switch>
        </Col>
      </Row>
    </div>
  );
}

export default Preferences;
