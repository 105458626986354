import React from "react";
import { useEntity } from "../../hooks/useEntity";
import Breadcrumb from "../../components/Breadcrumb";
import { Alert, Avatar, Button } from "antd";
import { EditOutlined, MailOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

export default function RoleView({ match }) {
  const { roleId, accountId } = match.params;
  const { state } = useEntity(roleId, `roles`);
  const { fetching, entity, asyncError } = state;

  if (fetching) {
    return "";
  }
  if (asyncError) {
    return (
      <Alert
        className={"mb-4"}
        description={
          <ul>
            <li>{asyncError.message}</li>
          </ul>
        }
        message={"Solucione los siguientes errores"}
        type="error"
        showIcon
      />
    );
  }

  const { user } = entity;
  const fullName = user.firstName + " " + user.lastName;

  const routes = [
    { to: `/${accountId}/roles`, label: "Usuarios de la cuenta" },
    { label: fullName },
  ];

  return (
    <div>
      <div className="entity-header">
        <Breadcrumb routes={routes} />

        <div className="entity-header__buttons">
          <Link to={`/${accountId}/roles/${entity.id}/edit`}>
            <Button type={"dashed"}>
              <EditOutlined /> EDITAR USUARIO
            </Button>
          </Link>
        </div>
      </div>
      <div className="vehicle-header">
        <div style={{ marginRight: 20, display: "flex", alignItems: "center" }}>
          <Avatar size={90} src={entity.profileImageUrl}>
            {(user.firstName[0] + user.lastName[0]).toUpperCase()}
          </Avatar>
        </div>
        <div className={"vehicle-info"}>
          <h1>{fullName}</h1>
          <div className={"vehicle-info-status"}>
            <div>
              <p>
                <span>
                  <a
                    rel="noopener noreferrer"
                    target={"_blank"}
                    href={`mailto=${user.phoneNumber}`}
                  >
                    <MailOutlined /> {user.email}
                  </a>
                </span>
              </p>

              <p>DNI: {entity.documentNumber || "-"}</p>
              <p>Rol: {entity.roleType.name}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
