import { useEffect, useReducer } from "react";
import axios from "axios";
import { dataServer } from "../constants/server";

const dataFetchReducer = (state, action) => {
  switch (action.type) {
    case "FETCH_INIT":
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case "FETCH_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload.data,
      };

    case "ADD_ITEM":
      return {
        ...state,
        data: [...state.data, action.payload],
      };

    case "QUERY_CHANGED":
      return {
        ...state,
        query: { ...state.query, [action.payload.key]: action.payload.value },
      };

    case "CLEAR_QUERY":
      return {
        ...state,
        query: {},
      };

    case "FETCH_FAILURE":
      return {
        ...state,
        isLoading: false,
        isError: true,
        asyncError: action.payload.error,
      };
    default:
      throw new Error();
  }
};

export const useFetchData = ({ url, initialQuery, transform }) => {
  const [state, dispatch] = useReducer(dataFetchReducer, {
    isLoading: true,
    isError: false,
    data: [],
    query: initialQuery,
  });

  const { query } = state;

  const onChange = (key, value) => {
    dispatch({ type: "QUERY_CHANGED", payload: { key, value } });
  };

  const clearQuery = () => {
    dispatch({ type: "CLEAR_QUERY" });
  };

  const addItem = (item) => {
    dispatch({ type: "ADD_ITEM", payload: item });
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: "FETCH_INIT" });

      try {
        let data = transform ? transform(query) : query;
        const result = await axios.post(`${dataServer}/${url}`, data, {
          withCredentials: true,
        });
        dispatch({ type: "FETCH_SUCCESS", payload: result });
      } catch (e) {
        console.log(e);
        if (e.response && e.response.status) {
          dispatch({
            type: "FETCH_FAILURE",
            payload: {
              error: {
                code: e.response.status,
                message: e.response.data,
              },
            },
          });
        } else {
          dispatch({
            type: "FETCH_FAILURE",
            payload: {
              error: { message: "Lo lamentamos mucho, algo salio mal." },
            },
          });
        }
      }
    };

    fetchData();
  }, [url, query, transform]);

  return [state, onChange, addItem, clearQuery];
};
