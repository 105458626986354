let _dataServer;

if (process.env.NODE_ENV === "production") {
  _dataServer =
      process.env.REACT_APP_VER_ENV === "test"
          ? "https://api-test.inmatics.io"
          : "https://api.inmatics.io";
} else {
  _dataServer = "http://localapi.inmatics.io:3030";
}

// let io = "http://v2api.inmatics.io";
export const dataServer = _dataServer;

let _v2dataServer;

if (process.env.NODE_ENV === "production") {
  _v2dataServer =
      process.env.REACT_APP_VER_ENV === "test"
          ? "https://api-test.inmatics.io"
          : "https://v2api.inmatics.io";
} else {
  _v2dataServer = "http://localapi.inmatics.io:8080";
}
export const v2dataServer = _v2dataServer;