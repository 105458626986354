import { Table } from "antd";
import React from "react";
import { Link } from "react-router-dom";

import { useDataApi } from "../../hooks/useDataApi";
import { renderTime } from "../../utils";
import { formatMoney } from "../../utils/format";
import ContactSummaryCollections from "./ContactSummaryCollections";

export default function CollectionsTable({ match }) {
  const { entityId, accountId } = match.params;
  const [collectionsState] = useDataApi(`transactions?contactId=${entityId}`);

  return (
    <div>
      <ContactSummaryCollections contactId={entityId} />
      <Table
        columns={[
          {
            title: "ID",
            dataIndex: "id",
            render: (text, record) => (
              <Link
                to={{
                  pathname: `/${accountId}/vehicles/${record.vehicleId}/collections/${record.id}`,
                  state: { menuKey: ["vehiculos", "collections-vehicle"] },
                }}
              >
                {text}
              </Link>
            ),
          },
          {
            title: "Fecha",
            key: "date",
            dataIndex: "date",
            render: renderTime,
          },
          {
            title: "Tipo",
            key: ["transactionType.name"],
            dataIndex: ["transactionType.name"],
          },
          {
            title: "Vehículo",
            key: ["vehicle", "name"],
            dataIndex: ["vehicle", "name"],
            render: (text, record) => (
              <Link to={`/${accountId}/vehicles/${record["vehicle.id"]}`}>
                {record["vehicle.name"]}
              </Link>
            ),
          },
          {
            title: "Referencia",
            dataIndex: ["reference"],
            render: (text) => {
              return text || "-";
            },
          },
          {
            title: "Monto",
            key: "amount",
            dataIndex: "amount",
            align: "right",
            render: (text) => formatMoney(text, 2),
          },
        ]}
        loading={collectionsState.isLoading}
        size={"small"}
        className={"reports"}
        dataSource={collectionsState.data}
        pagination={{ pageSize: 15 }}
      />
    </div>
  );
}
