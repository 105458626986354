import React from "react";
import { Form, Select } from "antd";
import { useDataApi } from "../../hooks/useDataApi";

export default function ContactSelect({ entity, onChange, submitted, errors }) {
  const { contactId } = entity;
  const [state] = useDataApi("contacts");

  return (
    <Form.Item
      label={"Seleccione un conductor"}
      validateStatus={submitted && errors["contactId"] ? "error" : "success"}
      hasFeedback={submitted}
      help={submitted && errors["contactId"]}
    >
      <Select
        allowClear
        autoFocus
        onChange={(value) => onChange("contactId", value)}
        optionFilterProp={"label"}
        options={state.data.map((item) => ({
          label: `${item.firstName} ${item.lastName}`,
          value: item.id.toString(),
        }))}
        placeholder="Buscar conductor"
        showSearch
        value={contactId}
      />
    </Form.Item>
  );
}
