import React from "react";
import { getRangeFromWeek } from "../../utils";
import moment from "moment";
import FinancialReport from "./FinancialReport";
import { days } from "../../constants";

const transform = (x) => {
  const { week, groupBy, accountID } = x;
  return {
    range: getRangeFromWeek(week),
    accountID,
    groupBy,
    tempGroup: "week",
  };
};

export default ({ match }) => {
  let accountId = match.params.accountId;

  const getWeeks = () => {
    const weeks = [];
    const isoWeek = moment().isoWeek();

    for (let i = 0; i < 7; i++) {
      let number = isoWeek - i;
      let moment1 = moment().isoWeek(number).startOf("week").add(1, "day");
      let moment2 = moment().isoWeek(number).endOf("week").add(1, "day");
      weeks.push({
        value: number,
        label: `${moment1.format("DD/MM")} - ${moment2.format("DD/MM")}`,
      });
    }
    return weeks;
  };

  return (
    <FinancialReport
      tempGroup={"week"}
      ranges={getWeeks()}
      tempGroupLabel={"Semana"}
      iterator={[...days, "Total Mes"]}
      transform={transform}
      title={"Cobranza semanal"}
      conf={{
        week: moment().isoWeek(),
      }}
      accountId={accountId}
    />
  );
};
