import { ArrowDownOutlined } from "@ant-design/icons";
import { Button, Table } from "antd";
import { get } from "lodash";
import { parse } from "query-string";
import React from "react";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";

import DropdownMenu from "../../components/DropdownMenu";
import { Filters } from "../../components/Filters";
import FiltersInfo from "../../components/FiltersInfo";
import Paginator from "../../components/Paginator";
import { GRID_PAGE_SIZE, filterTypes } from "../../constants";
import { parseError } from "../../helpers";
import { useContacts } from "../../hooks/useContacts";
import { useLegacyVehicles } from "../../hooks/useLegacyVehicles";
import { useQuery } from "../../hooks/useQuery";
import { useSWRSearch } from "../../hooks/useSWRSearch";
import { useTransactionTypes } from "../../hooks/useTransactionTypes";
import { IGridColumn, IWithAccountId, User } from "../../types";
import { formatDate } from "../../utils";
import { formatMoney } from "../../utils/format";
import CollectionsCreateModal from "./CollectionsCreateModal";

// @ts-ignore
const formatForExport = (data, columns) =>
  data.map((x: any) => {
    let row = {};
    // @ts-ignore
    columns.forEach(
      (col: {
        title: string | number;
        renderCSV: (arg0: any) => any;
        dataIndex: any;
      }) =>
        // @ts-ignore
        (row[col.title] = col.renderCSV
          ? col.renderCSV(x)
          : get(x, col.dataIndex))
    );
    return row;
  });

const renderContact = (text: string, record: any) =>
  record.contactId ? (
    <Link to={`/${record["contact.accountId"]}/contacts/${record.contactId}`}>
      {record["contact.firstName"] + " " + record["contact.lastName"]}
    </Link>
  ) : (
    "-"
  );

const renderVehicle = (text: string, record: any) => (
  <Link to={`/${record["vehicle.accountId"]}/vehicles/${record.vehicleId}`}>
    {record["vehicle.name"]}
  </Link>
);

const sortOptions = [
  { label: "Fecha - Más recientes", value: "date+DESC" },
  { label: "Fecha - Más antiguos", value: "date+ASC" },
  { label: "Monto - Mayor a menor", value: "amount+DESC" },
  { label: "Monto - Menor a mayor", value: "amount+ASC" },
];

const getColumns = (accountId: string): IGridColumn[] => [
  {
    title: "ID",
    dataIndex: "id",
    render: (text: string, record: any) => (
      <Link
        to={{
          pathname: `/${accountId}/vehicles/${record.vehicleId}/collections/${record.id}`,
          state: { menuKey: ["vehiculos", "collections-vehicle"] },
        }}
      >
        {text}
      </Link>
    ),
  },
  {
    title: "Conductor",
    dataIndex: "contactId",
    filterType: filterTypes.asyncTagLegacy,
    filterPlaceHolder: "Filtrar conductores",
    render: renderContact,
    useFetcher: useContacts,
    formatter: (x: User) => (x ? x.firstName + " " + x.lastName : "-"),
    renderCSV: (x: any) =>
      x ? `${x["contact.firstName"]}  ${x["contact.lastName"]}` : "-",
  },
  {
    title: "Vehículo",
    dataIndex: "vehicleId",
    filterType: filterTypes.asyncTagLegacy,
    filterPlaceHolder: "Filtrar vehículos",
    useFetcher: useLegacyVehicles,
    render: renderVehicle,
  },
  {
    title: "Concepto",
    dataIndex: "transactionTypeId",
    filterType: filterTypes.asyncTagLegacy,
    filterPlaceHolder: "Filtrar conceptos",
    useFetcher: useTransactionTypes,
    render: (text: string, record: any) => record["transactionType.name"],
  },
  {
    title: "Referencia",
    dataIndex: "reference",
    filterType: filterTypes.text,
    filterPlaceHolder: "Filtrar Referencias",
    render: (text: string) => text || "-",
  },
  {
    title: "Fecha",
    filterType: filterTypes.dateRange,
    dataIndex: "date",
    render: (text: string) => formatDate(text),
  },
  {
    title: "Monto",
    dataIndex: "amount",
    align: "right",
    render: (text: string) => formatMoney(text, 2),
  },
];

export default function TransactionsList(props: IWithAccountId) {
  const { match, location } = props;
  const accountId = match.params.accountId;
  const title = "Cobranzas";

  let transactionTypeId = parse(location.search).transactionTypeId;
  const initializerArg = {
    filters: transactionTypeId
      ? { transactionTypeId: [transactionTypeId] }
      : {},
    sort: sortOptions[0].value,
    page: 1,
  };
  const { state, onChange, clearFilters, setSort, setPage } = useQuery(
    initializerArg
  );
  const { data, error, mutate } = useSWRSearch(
    { ...state },
    `transactions/search`
  );
  const { filters, page, sort } = state;

  const columns = getColumns(accountId);

  return (
    <div>
      <div className="grid-header">
        <h1>{title}</h1>
        <div className={"justify-flex-end"}>
          <CSVLink
            data={formatForExport(data.rows || [], getColumns(accountId))}
            filename={`export-${Date.now()}.csv`}
          >
            <Button icon={<ArrowDownOutlined />}>Descargar</Button>
          </CSVLink>

          <div>
            <CollectionsCreateModal mutate={mutate} accountId={accountId} />
          </div>
        </div>
      </div>
      <div>{parseError(error)}</div>

      <div className={"justify-flex-end p-3"}>
        <div className={"mr-2"}>
          <FiltersInfo
            filters={filters}
            columns={columns}
            clearFilters={clearFilters}
          />
        </div>
        <DropdownMenu
          onChange={setSort}
          options={sortOptions}
          value={sort}
          label={"Orden"}
        />
        <Paginator
          total={data.count}
          current={page}
          pageSize={GRID_PAGE_SIZE}
          onChange={setPage}
        />
        <Filters columns={columns} filters={filters} onChange={onChange} />
      </div>
      <Table
        // @ts-ignore
        columns={columns}
        size={"small"}
        pagination={false}
        className="table-striped-rows"
        dataSource={data.rows}
        rowKey={(record) => record.id}
      />
    </div>
  );
}
