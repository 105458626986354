import { Layout } from "antd";
import axios from "axios";
import React, { useContext, useLayoutEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { AccountContext } from "../../hooks/useAccount";
import { AuthContext } from "../../hooks/useAuth";
import { useDataApi } from "../../hooks/useDataApi";
import { IWithAccountId, User } from "../../types";
import { routes } from "../routes";

const { Content } = Layout;

export default function Account({ match }: IWithAccountId) {
  const user = useContext<User>(AuthContext);
  let accountId = match.params.accountId;
  const [state] = useDataApi("accounts/" + accountId);
  const { data } = state;

  useLayoutEffect(() => {
    axios.defaults.headers.common["Account-Token"] = accountId;
    //If user does not have a role in these account, redirect home. Doing hard redirect because React Router is not reloading right.
    if (
      !user.roles?.map((x) => x.account_id).includes(Number(accountId)) &&
      accountId !== "me"
    ) {
      window.location.replace("/");
    }
  }, [accountId, user.roles]);

  return (
    <AccountContext.Provider value={data}>
      <Layout id={"root"}>
        <Header user={user} accountId={accountId} />
        <Layout className="body">
          <Sidebar accountId={accountId} />
          <Content className="main-content">
            <Switch>
              {routes.map((x) => {
                return (
                  <Route
                    {...x}
                    path={x.path}
                    exact={x.exact}
                    // @ts-ignore
                    component={x.c}
                    key={x.path}
                  />
                );
              })}
              <Redirect
                to={{
                  pathname: `/${match.params.accountId}/dashboard`,
                  state: { menuKey: ["inicio"] },
                }}
              />
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </AccountContext.Provider>
  );
}
