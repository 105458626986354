import React from "react";
import { patch } from "../../api";
import { useEntity } from "../../hooks/useEntity";
import { Alert, Button, Form, Layout } from "antd";
import { TextInput } from "../../components/Inputs";
import ConceptSelector from "../../components/ConceptSelector";

const text = { title: "Editar cuenta" };
const fontSize = `${30 / text.title.length}rem`;
const END_POINT = `accounts`;

const formFields = [];

export default function Entity({ match, history }) {
  const { accountId } = match.params;

  const { state, errors, onChange, handleSubmit } = useEntity(
    accountId,
    END_POINT,
    formFields
  );

  const { entity, submitted, submitting, asyncError, fetching } = state;

  const onSubmit = async () => {
    await patch(END_POINT, entity, accountId);
    history.push(`/${accountId}`);
  };

  if (fetching) {
    return <p>loading...</p>;
  }

  return (
    <div className={"mb-5"}>
      <h1>Editar cuenta</h1>
      <Layout className={"small-form-container"} id={"root"}>
        <Form layout={"vertical"} className={"login-form card"}>
          <h1 style={{ fontSize: fontSize }}>{text.title}</h1>

          {asyncError && <Alert message={asyncError.message} type="error" />}

          <TextInput
            error={errors["name"]}
            submitted={submitted}
            label={"Nombre"}
            onChange={onChange}
            placeholder={"Ingresa el nombre"}
            entity={entity}
            name={"name"}
          />
          <ConceptSelector
            label={"Tipo cobranza por defecto"}
            entity={entity}
            onChange={onChange}
          />
          <div className={"login-form-buttons"}>
            <Button type="secondary" onClick={() => history.goBack()}>
              CANCELAR
            </Button>

            <Button
              type="primary"
              disabled={submitting}
              loading={submitting}
              onClick={handleSubmit(onSubmit)}
            >
              GUARDAR CAMBIOS
            </Button>
          </div>
        </Form>
      </Layout>
    </div>
  );
}
