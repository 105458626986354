import {
  BranchesOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  MoreOutlined,
  ScissorOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Menu, Popconfirm, Tooltip } from "antd";
import React from "react";
import { Link } from "react-router-dom";

import { DEVICE_ACTIONS, FIELD_TYPES, filterTypes } from "../../constants";
import { calculateAge, dashWhenEmpty } from "../../helpers";
import { IGridColumn, Vehicle } from "../../types";
import format, { formatNumber } from "../../utils/format";
import { newApi } from "../../utils/rest";

export const renderLastConnection = (lastConnectionEntry: string) => {
  if (lastConnectionEntry) {
    const dateTime = new Date(lastConnectionEntry);
    const age = calculateAge(dateTime);
    let icon;
    if (age === 0) {
      icon = (
        <CheckCircleOutlined style={{ paddingRight: 8, color: "green" }} />
      );
    } else if (age === 1) {
      icon = (
        <ExclamationCircleOutlined
          style={{ paddingRight: 8, color: "yellow" }}
        />
      );
    } else {
      icon = <ExclamationCircleOutlined style={{ paddingRight: 8 }} />;
    }
    return (
      <span>
        {icon}
        {format(lastConnectionEntry, FIELD_TYPES.RELATIVE_TIME)}
      </span>
    );
  } else {
    return "-";
  }
};

function sendDeviceAction(id: string, action: string) {
  newApi()
    .v3.post(`devices/${id}/actions`, { action })
    .then((_) => alert("Action OK"));
}

export const getColumns = (): IGridColumn[] => [
  {
    dataIndex: "id",
    title: "ID",
    render: dashWhenEmpty,
    sorter: true,
  },
  {
    dataIndex: "device_model",
    filterType: filterTypes.text,
    title: "Modelo",
    render: dashWhenEmpty,
    sorter: true,
  },
  {
    dataIndex: "device_imei",
    filterType: filterTypes.text,
    title: "IMEI",
    render: dashWhenEmpty,
    sorter: true,
  },
  {
    dataIndex: "vehicle_name",
    filterType: filterTypes.text,
    title: "Vehículo",
    render: (text, record) =>
      text ? (
        <Link to={`/${record.account_id}/vehicles/${record.vehicle_id}`}>
          {text}
        </Link>
      ) : (
        "-"
      ),
    sorter: true,
  },
  {
    dataIndex: "phone_number",
    title: "Número de telefono",
    render: dashWhenEmpty,
  },
  {
    dataIndex: "lle_odometer",
    title: "KM",
    render: (x) => formatNumber(x / 1000, 0) + "km.",
  },
  {
    dataIndex: "lle_timestamp",
    filterType: filterTypes.text,
    title: "Ultima conexión",
    render: renderLastConnection,
    sorter: true,
  },
  {
    dataIndex: "id",
    title: "",
    render: (_, record: Vehicle) => {
      return (
        <Dropdown
          trigger={undefined}
          // @ts-ignore
          onClick={(e: any) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          overlay={
            <Menu>
              <Menu.Item key="0">
                <Button
                  onClick={() =>
                    sendDeviceAction(
                      record.id,
                      DEVICE_ACTIONS.setDigitalOutput_0
                    )
                  }
                  type={"link"}
                >
                  <BranchesOutlined style={{ color: "#AAAAAA" }} /> Desactivar
                  salida
                </Button>
              </Menu.Item>

              <Menu.Item key="1">
                <Popconfirm
                  title="¿Esta seguro que desea activar la salida?"
                  onConfirm={() =>
                    sendDeviceAction(
                      record.id,
                      DEVICE_ACTIONS.setDigitalOutput_1
                    )
                  }
                  okText="Sí"
                  cancelText="No"
                >
                  <Button type={"link"}>
                    <ScissorOutlined style={{ color: "#AAAAAA" }} /> Activar
                    salida
                  </Button>
                </Popconfirm>
              </Menu.Item>
            </Menu>
          }
        >
          <Tooltip title={"Acciones"} placement={"left"}>
            <Button icon={<MoreOutlined />} />
          </Tooltip>
        </Dropdown>
      );
    },
  },
];
