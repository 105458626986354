import { post as v2api } from "../../helpers/v2api";

export const vendorsBulk = {
  fields: {
    legal_name: "Razon Social",
    short_name: "Nombre corto",
    tax_id: "CUIT",
    tax_category: "Categoria fiscal",
  },
  fnCreate: async (payload) => {
    return await v2api("vendors", { ...payload });
  },
};
