import { Form } from "antd";
import React, { useContext } from "react";

import { AccountContext } from "../../hooks/useAccount";
import { useFetchAndSubmit } from "../../hooks/useFetchAndSubmit";
import { useLegacyVehicles } from "../../hooks/useLegacyVehicles";
import { IWithAccountId } from "../../types";
import { InsuranceForm } from "./InsuranceForm";
import { transform } from "./transform";

const endPoint = `insurances`;

export default function EditInsurance({ match, history }: IWithAccountId) {
  const vehicles = useLegacyVehicles();
  const [form] = Form.useForm();
  const entityId = match.params.entityId;
  const { setFieldsValue } = form;
  useContext(AccountContext);
  const title = `Actualizar póliza de seguro #${match.params.entityId}`;

  const onSuccess = () => {
    history.goBack();
  };

  const { onSubmit, submitting, error } = useFetchAndSubmit({
    endPoint,
    entityId: String(entityId),
    setFieldsValue,
    onSuccess,
    apiVersion: "v3",
    transform,
  });

  return (
    <div style={{ height: "100vh" }}>
      <InsuranceForm
        error={error}
        form={form}
        loading={submitting}
        onCancel={() => history.goBack()}
        onFinish={onSubmit}
        title={title}
        vehicles={vehicles}
      />
    </div>
  );
}
