import React from "react";
import { getByKey } from "../../utils";
import { useContacts } from "../../hooks/useContacts";

export default function FiltersInfo({ values }) {
  const ve = useContacts();
  if (!values.map) {
    return null;
  }
  return (
    <>
      {values
        .map((y) => {
          const byKey = getByKey(ve.data, y);

          return `${byKey.firstName} ${byKey.lastName}`;
        })
        .join(", ")}
    </>
  );
}
