import React from "react";
import { Button, DatePicker, Form, Select } from "antd";
import locale from "antd/es/date-picker/locale/es_ES";
import moment from "moment";
import { useLegacyVehicles } from "../../hooks/useLegacyVehicles";

type QueryParams = {
  onSubmit: any;
  entity: any;
  onChange: any;
  submitted: boolean;
  errors: any;
};

export function Query(props: QueryParams) {
  const { onSubmit, entity, onChange, submitted, errors } = props;

  const { data } = useLegacyVehicles();

  return (
    <Form
      layout={"vertical"}
      className={"p-3"}
      size={"small"}
      onFinish={onSubmit}
    >
      <Form.Item
        label={"Vehículo"}
        help={submitted && errors["vehicle_id"]}
        validateStatus={errors["vehicle_id"] && submitted ? "error" : "success"}
      >
        <Select
          allowClear
          onChange={(value) => onChange("vehicle_id", value)}
          optionFilterProp={"label"}
          options={data.map((item: any) => ({
            label: item.name,
            value: item.id.toString(),
          }))}
          placeholder="Elija el vehículo"
          showSearch
          value={entity["vehicle_id"] && entity["vehicle_id"].toString()}
        />
      </Form.Item>
      <Form.Item
        hasFeedback={submitted}
        label={"Rango de fechas"}
        help={submitted && errors["timestamp"]}
        validateStatus={errors["timestamp"] && submitted ? "error" : "success"}
      >
        <DatePicker.RangePicker
          style={{ width: "100%" }}
          locale={locale}
          ranges={{
            Hoy: [moment(), moment()],
            Ayer: [moment().subtract(1, "day"), moment().subtract(1, "day")],
            "Este mes": [moment().startOf("month"), moment().endOf("month")],
            "Mes Pasado": [
              moment().subtract(1, "month").startOf("month"),
              moment().subtract(1, "month").endOf("month"),
            ],
          }}
          onChange={(range) => onChange("timestamp", range)}
        />
      </Form.Item>
      <Button type={"primary"} htmlType={"submit"}>
        Enviar
      </Button>
    </Form>
  );
}
