import { useEffect, useState } from "react";

import { ILocation } from "../types";
import { getInfoWindow, getMarkerOptions } from "./Marker_helpers";

export const Marker = (props: {
  position: ILocation;
  zoom: number;
  mapType?: string;
}) => {
  const [marker, setMarker] = useState<google.maps.Marker>();
  const [infoWindow, setInfoWindow] = useState<google.maps.InfoWindow>();

  const { position, mapType, zoom } = props;

  useEffect(() => {
    if (!marker) {
      setMarker(new window.google.maps.Marker());
    }

    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker, position.direction, zoom]);

  useEffect(() => {
    if (marker) {
      const markerOptions = getMarkerOptions(position, mapType, zoom);
      marker.setOptions({ ...props, ...markerOptions });
    }
  }, [zoom, marker, position, mapType, props]);

  useEffect(() => {
    if (!infoWindow) {
      setInfoWindow(
        new window.google.maps.InfoWindow({
          content: getInfoWindow(position),
        })
      );
    }
  }, [infoWindow, position]);

  // Render Info Window onCLick
  useEffect(() => {
    if (marker && infoWindow) {
      marker.addListener("click", () => {
        infoWindow.open({
          anchor: marker,
          shouldFocus: false,
        });
      });
    }
  }, [marker, infoWindow]);

  return null;
};
