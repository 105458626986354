import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { Button } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { ILocation } from "src/types";

import { getCenter, getInfoWindow, getPin } from "./utils";

export function MapWithCluster({
  entries,
  onChangeShowNotReportingVehicles,
  showNotReportingVehicles,
}: {
  entries: ILocation[];
  onChangeShowNotReportingVehicles: any;
  showNotReportingVehicles: any;
}) {
  const [showInfoWindows, setShowInfoWindows] = useState(true);

  const mapDivRef = useRef<any>();

  const markersByVehicleID = useRef<{
    //@ts-ignore
    [vehicleID: string]: google.maps.marker.AdvancedMarkerView;
  }>({});

  const infoWindows = useRef<google.maps.InfoWindow[]>([]);

  const [map, setMap] = useState<google.maps.Map>();
  const cluster = useRef<MarkerClusterer>();

  //handle close all info windows
  useEffect(() => {
    if (!showInfoWindows && infoWindows.current) {
      infoWindows.current.forEach((x) => {
        x.close();
      });
    }
  }, [showInfoWindows]);

  //Init map
  useEffect(() => {
    if (mapDivRef.current && !map) {
      const { bound, boundCenter } = getCenter(entries);

      const map = new google.maps.Map(mapDivRef.current, {
        center: boundCenter.toJSON(),
        mapId: "63d68809f95fbc19",
      });

      map.fitBounds(bound);
      setMap(map);
    }
    // It should only set the position for the first time.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  //Init cluster
  useEffect(() => {
    if (!cluster.current && map) {
      cluster.current = new MarkerClusterer({
        map,
      });
    }
  }, [map]);

  //Initialize and update markers
  useEffect(() => {
    if (map && cluster.current) {
      entries.forEach((location) => {
        if (markersByVehicleID.current[location.vehicle_id]) {
          markersByVehicleID.current[location.vehicle_id].content = getPin(
            location
          ).element;
          markersByVehicleID.current[location.vehicle_id].position = location;
        } else {
          // @ts-ignore
          const advancedMarkerView = new google.maps.marker.AdvancedMarkerView({
            position: location,
            title: location.vehicle_name,
            content: getPin(location).element,
          });
          markersByVehicleID.current[location.vehicle_id] = advancedMarkerView;

          const infoWindow = new google.maps.InfoWindow({
            disableAutoPan: true,
          });
          infoWindow.setContent(getInfoWindow(location));

          advancedMarkerView.addListener("click", (e: any) => {
            map.panTo(e.latLng?.toJSON());

            setShowInfoWindows(true);

            infoWindow.open(
              { map: advancedMarkerView.map, shouldFocus: false },
              advancedMarkerView
            );
          });
          infoWindow.open(
            { map: advancedMarkerView.map, shouldFocus: false },
            advancedMarkerView
          );

          infoWindows.current.push(infoWindow);
          cluster.current?.addMarker(advancedMarkerView);
        }
      });
    }
  }, [map, entries, cluster]);

  return (
    <div className={"p-1"}>
      <div
        className={"p-3"}
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <Button
          onClick={() => {
            showNotReportingVehicles && window.location.reload();
            onChangeShowNotReportingVehicles();
          }}
        >
          {showNotReportingVehicles
            ? "OCULTAR VEHICULOS SIN REPORTAR"
            : "VER VEHICULOS SIN REPORTAR"}
        </Button>
        <Button
          disabled={!setShowInfoWindows}
          onClick={() => setShowInfoWindows(false)}
        >
          OCULTAR MARCADORES
        </Button>
      </div>
      <div style={{ height: "80vh" }} ref={mapDivRef} id="map" />
    </div>
  );
}
