import React from "react";
import { Card, Col, Row } from "antd";

export default function EmptyView({ accountId }) {
  let style = {
    minHeight: 300,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  };

  let p = `Registre rápidamente el mantenimiento preventivo y las reparaciones. Mantenga registros de servicio detallados por vehículo.`;

  return (
    <Row style={{ minHeight: 1000, paddingTop: 150 }}>
      <Col span={12} offset={6}>
        <Card>
          <div style={style}>
            <img
              src="https://static.inmatics.io/icons/service-history.png"
              alt="Empty service icon"
              height={100}
              width={100}
            />
            <h2>Historial del odómetro</h2>
            <p style={{ maxWidth: "80%" }}>{p}</p>
            Todavia no tiene entradas
          </div>
        </Card>
      </Col>
    </Row>
  );
}
