import React from "react";
import useForm from "../../hooks/useForm";
import { Alert, Button, Form, Layout } from "antd";
import { TextInput } from "../../components/Inputs";
import { post } from "../../api";

const text = { title: "Agregar conductor" };
const fontSize = `2rem`;

const formFields = [
  {
    name: "firstName",
    label: "Nombre",
    required: true,
    placeholder: "Ingrese el nombre",
  },
  {
    name: "lastName",
    label: "Apellido",
    required: true,
    placeholder: "Ingrese el apellido",
  },
  {
    name: "email",
    label: "Correo",
    placeholder: "Ingrese el correo electrónico",
  },
  {
    name: "documentNumber",
    label: "DNI",
    placeholder: "Ingrese el DNI",
  },
  {
    name: "phoneNumber",
    label: "Telefono",
    placeholder: "Ingrese el telefono",
  },
];

export default function Home({ history, match }) {
  const { formState, handleSubmit, errors, onChange } = useForm(
    { firstName: "", lastName: "", email: "" },
    formFields
  );

  const { entity, submitted, submitting, asyncError } = formState;

  let { accountId } = match.params;

  const onSubmit = async () => {
    await post("contacts", entity);
    history.push("/" + accountId + "/contacts");
  };

  return (
    <Layout className={"login-container"} id={"root"}>
      <Form layout={"vertical"} className={"login-form card"}>
        <h1 style={{ fontSize: fontSize }}>{text.title}</h1>

        {asyncError && <Alert message={asyncError.message} type="error" />}

        {formFields.map((x) => (
          <TextInput
            key={x.name}
            error={errors[x.name]}
            onChange={onChange}
            submitted={submitted}
            label={x.label}
            placeholder={x.placeholder}
            entity={entity}
            name={x.name}
          />
        ))}

        <div className={"login-form-buttons"}>
          <Button type="secondary" onClick={() => history.goBack()}>
            CANCELAR
          </Button>

          <Button
            type="primary"
            disabled={submitting}
            loading={submitting}
            onClick={handleSubmit(onSubmit)}
          >
            AGREGAR CONDUCTOR
          </Button>
        </div>
      </Form>
    </Layout>
  );
}
