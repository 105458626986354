import React, { useEffect, useState } from "react";
import { FileTextOutlined } from "@ant-design/icons";
import { Divider } from "antd";
import { get } from "../../helpers/v2api";
import { parseError } from "../../helpers";
import { GOOGLE_MAPS_KEY } from "../../constants";
import { Wrapper } from "@googlemaps/react-wrapper";
import TripDetails from "./TripDetails";
import { MyMapComponent } from "./MyMapComponent";
import { Marker } from "../../components/Marker";
import { Polygon } from "../../components/Polygon";
import TripPointsList from "./TripPointsList";

const endPoint = "trips";

export default function ViewTrip({ match }) {
  const [entity, setEntity] = useState(null);
  const [geos, setGeos] = useState(null);
  const [error, setError] = useState();
  const entityId = match.params.id;
  const locationEntries = entity?.location_entries;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const axiosResponse = await get(`${endPoint}/${entityId}`);
        setEntity(axiosResponse.data);
      } catch (e) {
        setError(parseError(e));
      }
    };
    fetchData();

    const fetchGeo = async () => {
      try {
        const axiosResponse = await get(`places`);
        setGeos(axiosResponse.data?.rows);
      } catch (e) {
        setGeos([]);
      }
    };
    fetchGeo();
  }, [entityId]);

  if (!entity) {
    return null;
  }

  if (error) {
    return null;
  }

  return (
    <div>
      <Wrapper
        apiKey={GOOGLE_MAPS_KEY}
        render={(status) => {
          if (status === "LOADING") return <h3>{status} ..</h3>;
          if (status === "FAILURE") return <h3>{status} ...</h3>;
          return null;
        }}
      >
        <MyMapComponent
          locationEntries={locationEntries}
          zoom={14}
          style={{ height: "500px" }}
        >
          {(locationEntries || []).map((x) => (
            <Marker position={x} rotation={x.direction - 180} key={x.id} />
          ))}
          {(geos || []).map(({ name, geo_polygon }, idx) => {
            if (geo_polygon) {
              return (
                <Polygon
                  coordinates={geo_polygon[0]}
                  name={name}
                  key={"polygon" + idx}
                />
              );
            }
            return null;
          })}
        </MyMapComponent>
      </Wrapper>
      <Divider />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className={"section-title-bis"}>
          <FileTextOutlined />
          Detalles
        </div>
      </div>
      <div className={"section-container"}>
        {/*<Header entity={entity} />*/}
        <TripDetails entity={entity} />
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className={"section-title-bis"}>
          <FileTextOutlined />
          Puntos del recorrido
        </div>
      </div>
      <div className={"section-container"}>
        <TripPointsList entity={entity} />
      </div>
    </div>
  );
}
