import {
  CommentOutlined,
  PaperClipOutlined,
  PlusCircleOutlined,
  ToolOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Table } from "antd";
import { stringify } from "qs";
import React from "react";
import { Link } from "react-router-dom";

import { useDataApi } from "../hooks/useDataApi";
import { renderTimeAndMileage } from "../utils";
import ServiceEmtpyView from "./ServiceEmtpyView";

const renderTasks = (text, record) => {
  return (
    <div>
      <p>
        <ToolOutlined /> {record.tasks.map((x) => x.taskType.name).join(" · ")}
      </p>
      {record.comments[0] && (
        <p>
          <CommentOutlined /> <i>{record.comments[0].text}</i>
        </p>
      )}
      <p>
        <PaperClipOutlined />{" "}
        {record.files[0] ? (
          <Button type={"link"} href={"#"}>
            {record.files.length}
          </Button>
        ) : (
          <i>{record.files.length}</i>
        )}
      </p>
    </div>
  );
};

const columns = () => [
  {
    title: "Fecha finalización / Odómetro",
    dataIndex: "finishDate",
    key: "finishDate",
    render: renderTimeAndMileage,
  },
  {
    title: "Detalles",
    dataIndex: "finishDate",
    key: "finishDate",
    render: renderTasks,
  },
];

export default function ServicesTable({ match, history }) {
  const { vehicleId, accountId } = match.params;
  const query = stringify({ vehicleId: [vehicleId] });

  const [state] = useDataApi(`services?${query}`);
  let { data, isLoading } = state;

  if (data.length === 0 && !isLoading) {
    return <ServiceEmtpyView accountId={accountId} vehicleId={vehicleId} />;
  }

  return (
    <div>
      <div className="grid-header">
        <h1 style={{ color: "#397872" }}>
          {" "}
          <Avatar
            size={40}
            src={"https://static.inmatics.io/icons/maintenance.svg"}
          />{" "}
          {"Historial de mantenimiento"}
        </h1>

        <div className={"justify-flex-end"}>
          <div className={"ml-2"}>
            <Link
              to={{
                pathname: "/" + accountId + "/services/create",
                state: { menuKey: ["mantenimiento"] },
              }}
            >
              <Button type={"primary"} icon={<PlusCircleOutlined />}>
                Nuevo servicio
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <Table
        dataSource={data}
        onRow={(record) => {
          return {
            onClick: () =>
              history.push({
                pathname: `/${accountId}/vehicles/${record.vehicleId}/services/${record.id}`,
                state: { menuKey: ["vehiculos", "services-vehicle"] },
              }),
          };
        }}
        rowClassName={"show-pointer"}
        loading={isLoading}
        columns={columns()}
        size={"small"}
        rowKey={(record) => record.id}
      />
    </div>
  );
}
