import { get } from "../../api";
import { post as v2api } from "../../helpers/v2api";

export const segurosBulk = {
  fields: {
    license_plate: "Patente",
    cost: "Costo",
    type: "Cobertura",
  },
  fnCreate: async (payload) => {
    const vehicle = await get(
      `vehicles?license_plate=${payload.license_plate}`
    );
    if (vehicle[0]) {
      const cost = Number(payload.cost.replace(/\D/g, ""));
      console.log(payload);
      const type =
        cost < 1000
          ? "Garage"
          : cost < 2000
          ? "Responsabilidad civil"
          : "Terceros completo";

      await v2api("insurances", {
        cost: cost.toString(),
        vehicle_id: vehicle[0].id,
        vendor_name: "AMCA",
        type: type,
        valid_from: new Date(2021, 9, 1, 4, 0, 0),
        valid_to: new Date(2021, 9, 31, 4, 0, 0),
      });
    }
  },
};
