import React from "react";

import { Filters } from "../../../components/Filters";
import FiltersInfo from "../../../components/FiltersInfo";
import Paginator from "../../../components/Paginator";
import { GRID_PAGE_SIZE } from "../../../constants";
import { IGridColumn, QueryState } from "../../../types";

type TableFiltersParams = {
  queryState: QueryState;
  setPage: (page: number) => void;
  totalCount: number;
  clearFilters: () => void;
  onChange: (x: object) => void;
  columns: IGridColumn[];
};

export function TableFilters(props: TableFiltersParams) {
  const {
    clearFilters,
    columns,
    onChange,
    queryState,
    setPage,
    totalCount,
  } = props;
  const { page, filters } = queryState;

  return (
    <div className={"justify-flex-between p-3"}>
      <div>
        <Paginator
          current={page}
          onChange={setPage}
          total={totalCount}
          pageSize={GRID_PAGE_SIZE}
        />
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <div className={"justify-flex-end"}>
            <div className={"mr-2"}>
              <FiltersInfo
                filters={filters}
                columns={columns}
                clearFilters={clearFilters}
              />
            </div>
            <Filters columns={columns} filters={filters} onChange={onChange} />
          </div>
        </div>
      </div>
    </div>
  );
}
