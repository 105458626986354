import React from "react";
import { Select } from "antd";

export default function FilterTagSelector({
                                              entity,
                                              onChange,
                                              style,
                                              useFetcher,
                                              name,
                                              placeholder,
                                              formatter,
                                          }) {
    const ve = useFetcher();

    return (
        <Select
            allowClear
            mode={"multiple"}
            onChange={(value) => onChange(name, value)}
            optionFilterProp={"label"}
            options={ve.data.map((item) => ({
                label: formatter ? formatter(item) : item.name,
                value: item.id.toString(),
            }))}
            placeholder={placeholder}
            showSearch
            style={style}
            value={entity[name]}
        />
    );
}
