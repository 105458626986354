import React from "react";
import { RightOutlined } from "@ant-design/icons";
import { List } from "antd";

export default function Ajustes() {
    const data = [
        {
            title: 'Estados',
            description: 'Los estados del vehículo definen la situación particular de un vehículo en un determinado momento en el tiempo. Esta configuración permite crear y modificar estados'
        },
        {
            title: 'Tipos de tareas',
            description: 'Las tareas son los procesos individuales que recibe un vehículo durante un servicio',
        },
        {
            title: 'Autopartes e insumos',
            description: 'En esta sección gestionamos el alta, baja y modificación de tipo de SKU que mantendremos dentro de nuestro inventario y que utilizaremos durante los servicios de mantenimiento',
        },
        {
            title: 'Gestión de usuarios',
            description: 'Dentro de esta sección administramos a los usuarios y le podremos asignar roles y permisos a las diferentes partes del sistema',
        },
    ];

    return (
        <div id="ajustes" style={{ borderBottom: "1px solid grey" }}>
            <div style={{ display: "flex" }}>
                <h1 style={{ fontSize: 40, color: "#0066b3" }}>Ajustes de Cuenta</h1>
            </div>
            <div>
                <p style={{ fontSize: 18 }}>Inmatics se ajusta a las necesidades particulares de cada cuenta mediante una serie de ajustes que permiten amoldar el sistema.</p>
                <List
                    itemLayout="horizontal"
                    dataSource={data}
                    renderItem={(item) => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={<RightOutlined />}
                                title={item.title}
                                description={item.description}
                            />
                        </List.Item>
                    )}
                />
            </div>
        </div>
    )
}
