import { stringify } from "qs";
import { Filters, QueryState } from "../types";

function isObject(val: any) {
  if (val === null) {
    return false;
  }
  return typeof val === "function" || typeof val === "object";
}

export function applyFilters(filters: Filters) {
  let fil: any = {};

  for (const filter in filters) {
    if (filters.hasOwnProperty(filter)) {
      const f = filters[filter];



      if (f) {
        if (isObject(f)) {
          let value

          //If value is an array of moment objects, stringify them.
          if (Array.isArray(f.value) && f.value[0] && f.value[0]._isAMomentObject ) {
            value = f.value.map((x:any) => x.toDate());
          } else {
            value = f.value;
          }

          fil[`${filter}_${f.operand}`] = value;
        } else {
          fil[`${filter}`] = f;
        }
      }
    }
  }
  return fil;
}

export function getURI(resource: string, state: QueryState) {
  const filters = state.filters;

  let fil = applyFilters(filters);
  const query = stringify(
    { ...state, filters: fil },
    { encode: false, arrayFormat: "comma" }
  );
  return `${resource}?${query}`;
}
