import { Table } from "antd";
import React from "react";
import { useHistory } from "react-router";

import { getOnDelete, getOnTableChange } from "../../helpers";
import { getURI } from "../../helpers/GetURI";
import { useQuery } from "../../hooks/useQuery";
import { IInsurance } from "../../types";
import { download } from "../../utils/download";
import { useSWRFetch } from "../../utils/rest";
import { TableFilters } from "../Vehicles/VehicleList/VehiclesTableFilters";
import { TableHeader } from "../Vehicles/VehicleList/VehiclesTableHeader";
import { getColumns } from "./getColumns";

const endPoint = "insurances";

interface ListInsurancesParams {
  vehicleId?: string;
  hideFilters?: boolean;
  accountId: string;
}

export default function ListInsurances(props: ListInsurancesParams) {
  const { vehicleId, accountId } = props;
  const history = useHistory();
  const { state, setSort, setPage, clearFilters, onChange } = useQuery({
    filters: {},
    sort: "name",
    page: 1,
  });

  const { filters } = state;
  const { data, error, mutate } = useSWRFetch(
    getURI(endPoint, {
      ...state,
      filters: { ...{ vehicle_id: vehicleId }, ...filters },
    })
  );

  const onRow = (record: IInsurance) => {
    return {
      onClick: () => {
        return history.push({
          pathname: `/${accountId}/vehicles/${record.vehicle_id}/seguros/${record.id}`,
          state: { menuKey: ["vehiculos", "seguros-vehicle"] },
        });
      },
    };
  };

  const columns = getColumns(getOnDelete(endPoint, mutate));

  if (error) return <div>{JSON.stringify(error)}</div>;
  if (!data) return null;
  return (
    <div>
      <TableHeader
        addLink={{
          pathname: `/${accountId}/seguros/crear`,
          state: { menuKey: ["seguros"] },
        }}
        addText={"Nueva póliza"}
        iconURL={"https://static.inmatics.io/icons/icoSeguridad.svg"}
        onDownload={() => download("insurances/download", `listado_seguros`)}
        title={"Listado de pólizas de seguro"}
      />
      <TableFilters
        clearFilters={clearFilters}
        columns={columns}
        onChange={onChange}
        queryState={state}
        setPage={setPage}
        totalCount={data?.count}
      />
      <Table
        columns={columns}
        onRow={onRow}
        dataSource={data?.data}
        onChange={getOnTableChange(setSort)}
        pagination={false}
        rowClassName={"show-pointer"}
        rowKey={(record) => record.id}
        size={"small"}
      />
    </div>
  );
}
