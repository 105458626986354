import { CommentOutlined } from "@ant-design/icons";
import { Table, Tag, message } from "antd";
import React from "react";

import { remove } from "../api";
import { useSWRFetch } from "../helpers";
import { renderDateRangeAndDuration, renderDistance } from "../utils";
import ActionsMenu from "./ActionsMenu";
import { LinkNoPropagate } from "./LinkNoPropagate";

const renderContact = (text, record) =>
  record.contactId ? (
    <div>
      <LinkNoPropagate
        to={`/${record.contact.accountId}/contacts/${record.contactId}`}
      >
        {record.contact.firstName + " " + record.contact.lastName}
      </LinkNoPropagate>{" "}
      {!record.finishDate && <Tag color="#87d068">Conductor Actual</Tag>}
    </div>
  ) : (
    "-"
  );

export default function AssignmentsTable({ match, history }) {
  const { vehicleId } = match.params;
  let key = `vehicleId`;
  const { data, mutate } = useSWRFetch(
    `vehicle-assignments?page=1&q[s]=name+asc&q[${key}_in][]=${vehicleId}`
  );

  const onDelete = async (record) => {
    try {
      await remove("vehicle-assignments", record.id);
      mutate();
      message.success(`Registro removido`);
    } catch (err) {
      console.log(err);
    }
  };

  if (!data) {
    return null;
  }

  let columns = [
    { title: "", dataIndex: "contact", render: renderContact },

    {
      title: "",
      dataIndex: "startDate",
      render: renderDateRangeAndDuration,
    },
    {
      title: "",
      dataIndex: "startDate",
      render: renderDistance,
    },

    {
      title: "",
      dataIndex: "comm",
      render: (text, record) => (
        <span>
          <CommentOutlined /> {record.comments && record.comments.length}{" "}
          comentarios
        </span>
      ),
    },

    {
      title: "",
      dataIndex: "id",
      render: (text, record) => (
        <ActionsMenu record={record} onDelete={onDelete} />
      ),
    },
  ];

  if (!data) {
    return null;
  }

  const onRow = (record) => {
    return {
      onClick: () => {
        history.push({
          pathname: `/${record.vehicle.accountId}/vehicles/${record.vehicle.id}/asignaciones/${record.id}`,
          state: { menuKey: ["vehiculos", "asignaciones-vehicle"] },
        });
      },
    };
  };

  return (
    <div>
      <Table
        onRow={onRow}
        dataSource={data}
        rowClassName={"show-pointer"}
        columns={columns}
        pagination={false}
        size={"small"}
        rowKey={(record) => record.id}
      />
    </div>
  );
}
