import { Wrapper } from "@googlemaps/react-wrapper";
import { Descriptions, Table, Tooltip } from "antd";
import React from "react";

import { Marker } from "../../components/Marker";
import { Poly } from "../../components/Polyline";
import { FIELD_TYPES, GOOGLE_MAPS_KEY, filterTypes } from "../../constants";
import { IGridColumn, ILocation } from "../../types";
import format, { formatNumber } from "../../utils/format";
import { MyMapComponent } from "../Trips/MyMapComponent";
import NoLocationEntryView from "./NoLocationEntryView";

const columns: IGridColumn[] = [
  {
    dataIndex: "vehicle_name",
    sorter: true,
    title: "Vehículo",
  },
  {
    dataIndex: "timestamp",
    sorter: true,
    title: "Fecha",
    filterType: filterTypes.dateRange,
    render: (text: string) => (
      <Tooltip
        placement="topLeft"
        title={format(text, FIELD_TYPES.RELATIVE_TIME)}
      >
        {format(text, FIELD_TYPES.DATETIME)}
      </Tooltip>
    ),
  },
  {
    dataIndex: "ignition",
    sorter: true,
    title: "Motor",
    render: (text: string) => (text ? "Encendido" : "Apagado"),
  },
  {
    dataIndex: "battery",
    sorter: true,
    title: "Batería",
    render: (text: string) => formatNumber(text, 1) + " V",
  },
  {
    dataIndex: "speed",
    sorter: true,
    title: "Velocidad",
    align: "right",
    render: (text: string) => text + " km/h",
  },

  {
    dataIndex: "odometer",
    sorter: true,
    title: "Odometro",
    align: "right",
    render: (text: number) => formatNumber(text / 1000, 1) + " km",
  },
];

export function Results(props: { data: any }) {
  const { data } = props;

  const [zoom, setZoom] = React.useState(3); // initial zoom

  if (!data) {
    return null;
  }

  const { location_entry_aggregated, records } = data;

  if (records.length === 0) {
    return <NoLocationEntryView />;
  }

  const onIdle = (m: google.maps.Map) => {
    setZoom(m.getZoom()!);
  };

  return (
    <div>
      <Descriptions size={"small"} title="Datos agregados" className={"p-2"}>
        <Descriptions.Item label="Velocidad máxima">
          {formatNumber(location_entry_aggregated.speed_max, 0)} km/h
        </Descriptions.Item>
        <Descriptions.Item label="Velocidad promedio">
          {formatNumber(location_entry_aggregated.speed_avg, 0)} km/h
        </Descriptions.Item>
        <Descriptions.Item label="Distancia">
          {formatNumber(location_entry_aggregated.distance / 1000, 1)} km
        </Descriptions.Item>
        <Descriptions.Item label="Registros">
          {formatNumber(location_entry_aggregated.record_count, 0)}
        </Descriptions.Item>
      </Descriptions>
      <div
        style={{
          height: "600px",
          width: "100%",
          border: "1px solid grey",
          marginBottom: 10,
        }}
        className={"in-section"}
      >
        <Wrapper apiKey={GOOGLE_MAPS_KEY}>
          <MyMapComponent
            locationEntries={records}
            onClick={undefined}
            onIdle={onIdle}
            style={{ height: "600px" }}
          >
            {records.map((x: ILocation) => {
              return <Marker position={x} key={x.id} zoom={zoom} />;
            })}
            <Poly path={records.reverse()} />
          </MyMapComponent>
        </Wrapper>
      </div>
      <Table
        className="table-striped-rows"
        columns={columns}
        dataSource={records}
        rowKey={(record) => record.id}
        size={"small"}
      />
    </div>
  );
}
