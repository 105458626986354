import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import AssignmentsTable from "../../../components/AssignmentsTable";
import MeterReadingsTable from "../../../components/MeterReadingsTable";
import ServicesTable from "../../../components/ServicesTable";
import { Vehicle } from "../../../types";
import VehicleAssignmentEdit from "../../Assignments/VehicleAssignmentEdit";
import CollectionView from "../../Collections/CollectionView";
import EditInsurance from "../../Insurance/EditInsurance";
import ListInsurances from "../../Insurance/ListInsurances";
import ViewInsurance from "../../Insurance/ViewInsurance";
import ServiceView from "../../Services/ServiceView";
import VehicleAssignmentView from "../../Services/VehicleAssignmentView";
import { TripsList } from "../../Trips/TripsList";
import ViewTrip from "../../Trips/ViewTrip";
import CollectionsTable from "./CollectionsTable";
import ExpensesTable from "./ExpensesTable";
import VehicleSummary from "./VehicleSummary";

export default function VehicleViewRoutes({
  path,
  vehicleId,
  vehicle,
  accountId,
  url,
}: {
  url: string;
  path: string;
  vehicleId: string;
  vehicle: Vehicle;
  accountId: string;
}) {
  return (
    <Switch>
      <Route
        exact
        render={() => <VehicleSummary vehicle={vehicle} />}
        path={path + "/resumen"}
      />
      <Route exact component={CollectionsTable} path={path + "/collections"} />
      <Route exact component={ExpensesTable} path={path + "/gastos"} />
      <Route component={MeterReadingsTable} path={path + "/readings"} />
      <Route exact component={ServicesTable} path={path + "/services"} />
      <Route exact component={VehicleSummary} path={path + "/resumen"} />
      <Route exact component={AssignmentsTable} path={path + "/asignaciones"} />
      <Route
        exact
        render={() => (
          <ListInsurances
            hideFilters
            vehicleId={vehicleId}
            accountId={accountId}
          />
        )}
        path={path + "/seguros"}
      />
      <Route
        exact
        render={() => <TripsList vehicleId={vehicleId} />}
        path={path + "/viajes"}
      />
      <Route exact component={ViewTrip} path={path + "/viajes/:id"} />
      <Route exact component={ViewInsurance} path={path + "/seguros/:id"} />
      <Route
        exact
        component={EditInsurance}
        path={path + "/seguros/:entityId/editar"}
      />
      <Route
        exact
        component={ServiceView}
        path={path + "/services/:serviceId"}
      />
      <Route
        exact
        component={VehicleAssignmentEdit}
        path={path + "/asignaciones/:entityId/editar"}
      />
      <Route
        exact
        component={VehicleAssignmentView}
        path={path + "/asignaciones/:vehicleAssignmentId"}
      />
      <Route
        exact
        component={CollectionView}
        path={path + "/collections/:id"}
      />
      <Route exact component={CollectionsTable} path={path + "/collections"} />
      <Redirect
        to={{
          pathname: url + "/resumen",
          state: { menuKey: ["vehiculos", "summary-vehicle"] },
        }}
      />
    </Switch>
  );
}
