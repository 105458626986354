import { Table } from "antd";
import React, { useEffect, useState } from "react";

import { FIELD_TYPES } from "../../constants";
import { ILocation } from "../../types";
import format from "../../utils/format";

export default function TripPointsList(props: any) {
  const { entity } = props;
  let locationEntries = entity?.location_entries;

  const [dataSource, setDataSource] = useState();

  const columns = [
    {
      title: "Fecha",
      dataIndex: "date",
      render: (text: string) => format(text, FIELD_TYPES.DATETIME),
    },
    {
      title: "Batería",
      dataIndex: "battery",
      align: "right",
    },
    {
      title: "Velocidad",
      dataIndex: "speed",
      align: "right",
    },
  ];

  useEffect(() => {
    const locationsInOlderDateFirstOrder = locationEntries.reverse();
    let dataSource: any = [];

    locationsInOlderDateFirstOrder.map((location: ILocation) => {
      dataSource.push({
        id: location.id,
        date: location.created_at,
        battery: location.battery,
        speed: location.speed,
      });
      return location;
    });

    setDataSource(dataSource);
  }, [locationEntries]);

  return (
    <div>
      <Table
        dataSource={dataSource}
        rowClassName={"show-pointer"}
        // @ts-ignore
        columns={columns}
        pagination={false}
        size={"small"}
        rowKey={(record) => record.id}
      />
    </div>
  );
}
