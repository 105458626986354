import React, {useEffect, useState} from "react";
import {Alert, Button, Form, Input} from "antd";
import {ColorResult, SwatchesPicker} from "react-color";
import {newApi} from "../../utils/rest";

const endPoint = `statuses`;

interface StatusFormProps {
    entityId?: string;
    onSuccess: () => void;
}

interface FormValues {
    name: string;
    color: string;
}

const StatusForm: React.FC<StatusFormProps> = ({ entityId, onSuccess }) => {
    const [submitting, setSubmitting] = useState(false);
    const [color, setColor] = useState("#2196f3");
    const [error, setError] = useState<string>("");
    const [form] = Form.useForm<FormValues>();

    useEffect(() => {
        const fetchData = async () => {
            if (entityId) {
                try {
                    const res = await newApi().v3.get(`${endPoint}/${entityId}`);
                    form.setFieldsValue({
                        name: res.data.name,
                        color: res.data.color,
                    });
                    setColor(res.data.color);
                } catch (err) {
                    setError(err.message || JSON.stringify(err));
                }
            } else {
                form.setFieldsValue({ name: "", color: "#2196f3" });
            }
        };

        fetchData();
    }, [entityId, form]);

    const handleFinish = async (values: FormValues) => {
        setSubmitting(true);
        setError("");

        try {
            const payload = {
                name: values.name,
                color,
            };
            if (entityId) {
                await newApi().v3.patch(`${endPoint}/${entityId}`, payload);
            } else {
                await newApi().v3.post(endPoint, payload);
            }
            onSuccess();
        } catch (err) {
            setError(err.message || JSON.stringify(err));
        } finally {
            setSubmitting(false);
        }
    };

    const handleColorChange = (colorResult: ColorResult) => {
        setColor(colorResult.hex);
        form.setFieldsValue({ color: colorResult.hex });
    };

    return (
        <Form layout="vertical" form={form} onFinish={handleFinish}>
            {error && <Alert message={error} type="error" />}
            <Form.Item
                label="Nombre del estado"
                name="name"
                rules={[{ required: true, message: "Por favor ingrese el nombre" }]}
            >
                <Input />
            </Form.Item>
            <Form.Item label="Color">
                <div style={{ marginBottom: 20 }}>
                    <span style={{ color, margin: 5 }}>&#9673;</span>
                </div>
                <SwatchesPicker color={color} onChangeComplete={handleColorChange} />
            </Form.Item>
            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    disabled={submitting}
                    loading={submitting}
                >
                    GUARDAR
                </Button>
            </Form.Item>
        </Form>
    );
};

export default StatusForm;
