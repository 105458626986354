import {
  CommentOutlined,
  PaperClipOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Avatar, Badge, Button, Table } from "antd";
import dottie from "dottie";
import { parse } from "query-string";
import React from "react";
import { Link } from "react-router-dom";

import CarSVG from "../../components/CarSVG";
import DropdownMenu from "../../components/DropdownMenu";
import { Filters } from "../../components/Filters";
import FiltersInfo from "../../components/FiltersInfo";
import Paginator from "../../components/Paginator";
import { GRID_PAGE_SIZE, filterTypes } from "../../constants";
import { parseError } from "../../helpers";
import { useLegacyVehicles } from "../../hooks/useLegacyVehicles";
import { usePartTypes } from "../../hooks/usePartTypes";
import { useQuery } from "../../hooks/useQuery";
import { useSWRSearch } from "../../hooks/useSWRSearch";
import { useTasks } from "../../hooks/useTasks";
import { IGridColumn, IService, IWithAccountId } from "../../types";
import { renderTimeAndMileage } from "../../utils";

const sortOptions = [
  { label: "Fecha - Más recientes", value: "finishDate+DESC" },
  { label: "Fecha - Más antiguos", value: "finishDate+ASC" },
];

const getColumns = (): IGridColumn[] => {
  const renderVehicle = (text: string, record: object) => {
    const vehicle = dottie.transform(record).vehicle;

    return (
      <div style={{ display: "flex", alignItems: "center", paddingLeft: "10" }}>
        <CarSVG type={"Auto"} width={40} vehicle={vehicle} />
        <div className={"ml-1"}>
          <b>{vehicle.name}</b>
          <p>
            <small>
              <Badge style={{ marginRight: -5 }} color={vehicle.status.color} />
              {vehicle.status.name} • {vehicle.year} {vehicle.brand}{" "}
              {vehicle.model}
            </small>
          </p>
        </div>
      </div>
    );
  };

  const renderTasks = (text: string, record: IService) => {
    return (
      <div>
        {record.tasks.map((x) => x.taskType.name).join(" · ")}
        <div>{record.parts.map((x) => x.partType.name).join(" · ")}</div>
        {record.comments[0] && (
          <p style={{ marginBottom: 0 }}>
            <CommentOutlined /> <i>{record.comments[0].text}</i>
          </p>
        )}
        <p>
          <PaperClipOutlined />{" "}
          {record.files[0] ? (
            <Button type={"link"}>{record.files.length}</Button>
          ) : (
            <i>{record.files.length}</i>
          )}
        </p>
      </div>
    );
  };

  return [
    {
      title: "Vehículo",
      dataIndex: "vehicleId",
      filterType: filterTypes.asyncTagLegacy,
      filterPlaceHolder: "Filtrar vehículos",
      useFetcher: useLegacyVehicles,
      render: renderVehicle,
    },
    {
      title: "Trabajos",
      dataIndex: "taskTypeId",
      useFetcher: useTasks,
      filterType: filterTypes.asyncTagLegacy,
      render: renderTasks,
      filterPlaceHolder: "Filtrar tareas realizadas",
    },
    {
      title: "Repuestos",
      hidden: true,
      dataIndex: "partTypeId",
      useFetcher: usePartTypes,
      filterType: filterTypes.asyncTagLegacy,
      filterPlaceHolder: "Filtrar partes utilizadas",
    },
    {
      title: "Fecha finalización / Odómetro",
      dataIndex: "finishDate",

      render: renderTimeAndMileage,
      align: "right",
    },
  ];
};

export default function ServicesList(props: IWithAccountId) {
  const { match, location, history } = props;
  const { accountId } = match.params;
  let stringParsedQuery = parse(location.search);
  let taskTypeId = stringParsedQuery.taskTypeId;
  let partTypeId = stringParsedQuery.partTypeId;

  const onRow = (record: IService) => {
    return {
      onClick: () =>
        history.push({
          pathname: `/${accountId}/vehicles/${record.vehicleId}/services/${record.id}`,
          state: { menuKey: ["vehiculos", "services-vehicle"] },
        }),
    };
  };

  let initialFilter = {};
  if (taskTypeId) {
    // @ts-ignore
    initialFilter.taskTypeId = [taskTypeId];
  }
  if (partTypeId) {
    // @ts-ignore
    initialFilter.partTypeId = [partTypeId];
  }

  const initializerArg = {
    filters: initialFilter,
    sort: sortOptions[0].value,
    page: 1,
  };
  const { state, onChange, clearFilters, setSort, setPage } = useQuery(
    initializerArg
  );
  const { data, error } = useSWRSearch(state, `services/search`);
  const { filters, page, sort } = state;
  const columns = getColumns();

  return (
    <div>
      <div className="grid-header">
        <h1 style={{ color: "#397872" }}>
          {" "}
          <Avatar
            size={40}
            src={"https://static.inmatics.io/icons/maintenance.svg"}
          />{" "}
          {"Historial de mantenimiento"}
        </h1>

        <div className={"justify-flex-end"}>
          <div className={"ml-2"}>
            <Link
              to={{
                pathname: "/" + accountId + "/services/create",
                state: { menuKey: ["mantenimiento"] },
              }}
            >
              <Button type={"primary"} icon={<PlusCircleOutlined />}>
                Nuevo servicio
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <div>{parseError(error)}</div>

      <div className={"justify-flex-end p-3"}>
        <div className={"mr-2"}>
          <FiltersInfo
            filters={filters}
            columns={columns}
            clearFilters={clearFilters}
          />
        </div>
        <DropdownMenu
          onChange={setSort}
          options={sortOptions}
          value={sort}
          label={"Orden"}
        />
        <Paginator
          total={data.count}
          current={page}
          pageSize={GRID_PAGE_SIZE}
          onChange={setPage}
        />
        <Filters columns={columns} filters={filters} onChange={onChange} />
      </div>
      <Table
        // @ts-ignore
        columns={columns.filter((x) => !x.hidden)}
        onRow={onRow}
        // @ts-ignore
        rowSelection={"checkbox"}
        rowClassName={"show-pointer"}
        size={"small"}
        pagination={false}
        className="table-striped-rows"
        dataSource={data.rows}
        rowKey={(record) => record.id}
      />
    </div>
  );
}
