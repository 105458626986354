import React, { useEffect, useState } from "react";
import { Card } from "antd";
import { FileTextOutlined } from "@ant-design/icons";
import { get } from "../../helpers/v2api";
import { parseError } from "../../helpers";
import PlaceVehiclesMapView from "./PlaceVehiclesMapView";
import { Link } from "react-router-dom";

const endPoint = `places`;

export default function ViewPlace(props: any) {
  const [entity, setEntity] = useState({
    name: "",
    formatted_address: "",
    description: "",
  });
  const [error, setError] = useState(null);

  const entityId = props.match.params.id;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const axiosResponse = await get(`${endPoint}/${entityId}`);
        setEntity(axiosResponse.data);
      } catch (e) {
        setError(parseError(e));
      }
    };
    fetchData();
  }, [entityId]);

  if (!entity.name) {
    return null;
  }

  return (
    <Card style={{ height: "100vh" }}>
      <div>
        <h3>
          <FileTextOutlined className={"muted"} /> Lugares{" "}
        </h3>
      </div>
      {error && JSON.stringify(error)}
      <h2>{entity.name}</h2>
      <h2>{entity.formatted_address}</h2>
      <h2>{entity.description}</h2>
      <Link to={props.match.url + "/editar"}>Editar</Link>

      <PlaceVehiclesMapView place={entity} />
    </Card>
  );
}
