import Breadcrumb from "../../components/Breadcrumb";
import React from "react";
import * as PropTypes from "prop-types";

export function VendorBreadcrumb(props) {
  return (
    <div className="entity-header mb-3">
      <Breadcrumb
        routes={[
          {
            to: "/" + props.account.id + "/proveedores",
            label: "Proveedores",
          },
          { label: props.label },
        ]}
      />
    </div>
  );
}

VendorBreadcrumb.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.string,
  }),
  label: PropTypes.string,
};
