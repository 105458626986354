import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ListPlaces from "./ListPlaces";
import EditPlace from "./EditPlace";
import CreatePlace from "./CreatePlace";
import { IWithAccountId } from "../../types";
import ViewPlace from "./ViewPlace";

export default function Places(props: IWithAccountId) {
  const { match } = props;

  return (
    <div>
      <Switch>
        <Route component={ListPlaces} path={match.path + "/"} exact />
        <Route component={CreatePlace} path={match.url + "/crear"} exact />
        <Route component={EditPlace} path={match.url + "/:id/editar"} exact />
        <Route component={ViewPlace} path={match.url + "/:id"} exact />
        <Redirect to={match.url} />
      </Switch>
    </div>
  );
}
