import { Form, Select } from "antd";
import React from "react";
import { IVendor } from "../types";
import { useVendors } from "../hooks";

export function VendorSelect() {
  const { data, error } = useVendors();

  if (error) {
    alert("Error");
  }

  return (
    <Form.Item name={"vendor_id"} label={"Proveedor"}>
      <Select
        placeholder={"Proveedor"}
        allowClear
        optionFilterProp={"label"}
        showSearch
        options={(data || []).map((vendor: IVendor) => ({
          value: vendor.id,
          label: `${vendor.legal_name} (${vendor.tax_id})`,
        }))}
      />
    </Form.Item>
  );
}
