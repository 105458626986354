import {
  BankOutlined,
  CalendarOutlined,
  DollarOutlined,
  EditOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { Button, Divider } from "antd";
import { DateTime } from "luxon";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { calculateDurationBis } from "../../helpers";
import { IInsurance } from "../../types";
import { formatMoney, formatNumber } from "../../utils/format";
import { newApi } from "../../utils/rest";

function Header(props: {
  vendor_name: string;
  cost: number;
  duration: string;
}) {
  return (
    <div className={"vehicle-assignment-view-header"}>
      <div className={"vehicle-assignment-view-header-item"}>
        <div>
          <BankOutlined />
        </div>
        <div className={" ml-2"}>
          <div className={"muted"}> Compañia</div>
          <div>{props.vendor_name}</div>
        </div>
      </div>
      <div className={"vehicle-assignment-view-header-item"}>
        <div>
          <CalendarOutlined />
        </div>
        <div className={" ml-2"}>
          <div className={"muted"}> DURACIÓN</div>
          <b>{props.duration ? props.duration : "N/A"}</b>
        </div>
      </div>

      <div className={"vehicle-assignment-view-header-item"}>
        <div>
          <DollarOutlined />
        </div>
        <div className={" ml-2"}>
          <div className={"muted"}> Costo</div>
          <div>
            <div>
              {props.cost ? (
                <span>
                  <small>$</small> <b>{formatNumber(props.cost, 0)}</b>
                </span>
              ) : (
                "N/A"
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Header.propTypes = { entity: PropTypes.any };
const endPoint = "insurances";

function getIsoDate(dateString: string) {
  return dateString
    ? DateTime.fromISO(dateString).toLocaleString({
        month: "short",
        day: "numeric",
        weekday: "long",
        year: "numeric",
      })
    : "-";
}

export default function ViewInsurance({ match }: any) {
  const [entity, setEntity] = useState<IInsurance>();

  const entityId = match.params.id;

  useEffect(() => {
    const fetchData = async () => {
      const axiosResponse = await newApi().v3.get(`${endPoint}/${entityId}`);
      setEntity(axiosResponse.data);
    };
    fetchData().then((x) => x);
  }, [entityId]);

  if (!entity) {
    return null;
  }

  const accountId = "35";
  const duration = calculateDurationBis(entity.valid_from, entity.valid_to);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className={"section-title-bis"}>
          <FileTextOutlined />
          Detalles
        </div>

        <Link
          to={{
            pathname: `/${accountId}/vehicles/${entity.vehicle_id}/seguros/${entity.id}/editar`,
            state: { menuKey: ["vehiculos", "seguros-vehicle"] },
          }}
        >
          <Button type={"dashed"}>
            <EditOutlined /> Editar póliza de seguro
          </Button>
        </Link>
      </div>

      <div className={"section-container"}>
        <Header
          cost={entity.cost}
          vendor_name={entity.vendor_name}
          duration={duration}
        />
        <Divider />
        <table className="service-view-table">
          <tbody>
            <tr>
              <td align={"right"}>
                <b>Fecha inicio:</b>
              </td>
              <td>{getIsoDate(entity.valid_from)}</td>
            </tr>
            <tr>
              <td align={"right"}>
                <b>Fecha fin:</b>
              </td>
              <td>{getIsoDate(entity.valid_to)}</td>
            </tr>
            <tr>
              <td align={"right"}>
                <b>Tipo de cobertura:</b>
              </td>
              <td>{entity.type}</td>
            </tr>
            <tr>
              <td align={"right"}>
                <b>Costo:</b>
              </td>
              <td>{formatMoney(entity.cost)}</td>
            </tr>
          </tbody>
        </table>
        <small className={"muted"}>{entity.created_by_name}</small>
      </div>
      <Divider />
    </div>
  );
}
