import { Alert, Button, Form, Layout } from "antd";
import React, { useContext } from "react";

import { patch } from "../../api";
import Header from "../../components/Header";
import { TextInput } from "../../components/Inputs";
import { AuthContext } from "../../hooks/useAuth";
import { useEntity } from "../../hooks/useEntity";

const { Content } = Layout;

const text = { title: "Editar usuario" };
const fontSize = `${30 / text.title.length}rem`;

export default function Me({ history }) {
  const user = useContext(AuthContext);
  const { state, errors, onChange, handleSubmit } = useEntity(1, "me", []);
  const { entity, submitted, submitting, asyncError, fetching } = state;

  const onSubmit = async () => {
    await patch(`me`, entity, entity.id);
    window.location.replace("/");
  };

  if (fetching) return null;

  return (
    <Layout id={"root"}>
      <Header user={user} />
      <Layout className="body">
        <Content className="main-content">
          <div className={"mb-5"}>
            <div className="entity-header mb-3">
              <div className="entity-header__buttons">
                <Button
                  className="mr-2"
                  style={{ width: 150 }}
                  type="secondary"
                  onClick={() => history.goBack()}
                >
                  CANCELAR
                </Button>
                <Button type="primary" onClick={onSubmit}>
                  GUARDAR CAMBIOS
                </Button>
              </div>
            </div>
            <Layout className={"small-form-container"} id={"root"}>
              <Form layout={"vertical"} className={"login-form card"}>
                <h1 style={{ fontSize: fontSize }}>{text.title}</h1>

                {asyncError && (
                  <Alert message={asyncError.message} type="error" />
                )}

                <TextInput
                  error={errors["firstName"]}
                  submitted={submitted}
                  label={"Nombre"}
                  onChange={onChange}
                  placeholder={"Ingresa el nombre"}
                  entity={entity}
                  name={"firstName"}
                />
                <TextInput
                  error={errors["lastName"]}
                  submitted={submitted}
                  label={"Apellido"}
                  onChange={onChange}
                  placeholder={"Ingresa el apellido"}
                  entity={entity}
                  name={"lastName"}
                />
                <div className={"login-form-buttons"}>
                  <Button type="secondary" onClick={() => history.goBack()}>
                    CANCELAR
                  </Button>

                  <Button
                    type="primary"
                    disabled={submitting}
                    loading={submitting}
                    onClick={handleSubmit(onSubmit)}
                  >
                    GUARDAR CAMBIOS
                  </Button>
                </div>
              </Form>
            </Layout>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}
