import { get } from "./v2api";
import useSWR from "swr";

export const useFetch = (url: string) => {
  let fetcher;

  fetcher = async () => {
    return (await get(url)).data;
  };

  return useSWR(url, fetcher, {
    initialData: null,
    revalidateOnMount: true,
  });
};
