import { Table } from "antd";
import React from "react";

import { FIELD_TYPES } from "../constants";
import { useMQTT } from "../hooks/useMQTT";
import format from "../utils/format";

export default function Mqtt() {
  const topic = "devices/new";
  const strings = useMQTT(topic);

  const columns = [
    {
      title: "Vehiculo",
      dataIndex: "vehicle_name",
    },
    {
      title: "Cuenta",
      dataIndex: "account_name",
    },
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      render: (x: any) => (x ? format(x, FIELD_TYPES.RELATIVE_TIME) : "N/A"),
    },
    {
      title: "Odometro",
      dataIndex: "odometer",
      render: (x: any) => format(x / 1000, FIELD_TYPES.INTEGER),
    },
    {
      title: "Ignicion",
      dataIndex: "ignition",
    },
  ];

  return (
    <div>
      <div className={"p-3"}>
        <h3>Connected to topic: {topic}</h3>
      </div>
      <div>
        <Table
          dataSource={strings}
          rowClassName={"show-pointer"}
          columns={columns}
          pagination={false}
          size={"small"}
        />
      </div>
    </div>
  );
}
