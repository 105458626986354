import { QueryState } from "../types";

export const ACTIONS = {
  mergeFilters: "MERGE_FILTERS",
  clearFilters: "CLEAR_FILTERS",
  setPage: "SET_PAGE",
  setSort: "SET_SORT",
};

export function queryReducer(
  state: QueryState,
  action: { type: string; payload?: any }
) {
  const payload = action.payload;

  switch (action.type) {
    case ACTIONS.mergeFilters:
      return { ...state, filters: { ...state.filters, ...payload } };

    case ACTIONS.clearFilters:
      return { ...state, filters: {} };

    case ACTIONS.setPage:
      return { ...state, page: payload };

    case ACTIONS.setSort:
      return { ...state, sort: payload };

    default:
      throw new Error();
  }
}
