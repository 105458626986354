import { Alert, Button, Form, Input } from "antd";
import React, { useEffect, useState } from "react";

import { newApi } from "../utils/rest";

function SendSMS() {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const { setFieldsValue } = form;
  const apiEndpoint = "sms";

  const onFinish = async (values: {
    phone_number: string;
    message: string;
  }) => {
    try {
      setSubmitting(true);
      setError("");
      await newApi().v3.post(apiEndpoint, values);
      // await post(apiEndpoint, values);
      setFieldsValue({});
      setSubmitting(false);
    } catch (err) {
      setSubmitting(false);
      setError(err.message || "An error occurred while sending the SMS.");
    }
  };

  useEffect(() => {
    setFieldsValue({});
  }, [setFieldsValue]);

  const phoneRegex = /^\+?[1-9]\d{1,14}$/;

  return (
    <Form layout="vertical" form={form} onFinish={onFinish}>
      {error && <Alert message={error} type="error" />}
      <Form.Item
        label="Phone number"
        name="phone_number"
        rules={[
          { required: true, message: "Please input a phone number" },
          { pattern: phoneRegex, message: "Invalid phone number format" },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Message"
        name="message"
        rules={[{ required: true, message: "Please input a message" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item style={{ display: "flex" }}>
        <Button
          type="primary"
          htmlType="submit"
          disabled={submitting}
          loading={submitting}
        >
          SEND
        </Button>
      </Form.Item>
    </Form>
  );
}

export default SendSMS;
