import React from "react";

import { FIELD_TYPES } from "../../constants";
import { IDevice, IWithAccountId } from "../../types";
import format from "../../utils/format";
import { useSimpleSWRFetch } from "../../utils/rest";

type SimConnectivity = {
  subscriber_info: {
    success: boolean;
    state: string;
    location: {
      current_location_retrieved: boolean;
      age_of_location: number;
      cell_global_id: {
        mcc: string;
        mnc: string;
        lac: number;
        cid: number;
      };
    };
  };
  request_timestamp: string;
  reply_timestamp: string;
};

type DeviceFullResponse = IDevice & SimConnectivity;

function DevicesView({ match }: IWithAccountId) {
  const { data } = useSimpleSWRFetch(
    `admin/devices/${match.params.entityId}?full=true`,
    {
      refreshInterval: 60000,
    }
  );

  if (!data) {
    return null;
  }
  const response: DeviceFullResponse = data;

  return (
    <div>
      <h2>Device Info</h2>
      <ul>
        <li>Account ID: {response.account_id}</li>
        <li>Account Name: {response.account_name}</li>
        <li>Device IMEI: {response.device_imei}</li>
        <li>Device Model: {response.device_model}</li>
        <li>IMSI: {response.phone_number}</li>
        <li>Vehicle Name: {response.vehicle_name}</li>
        <li>Vehicle ID: {response.vehicle_id}</li>
        <li>Battery: {response.lle_battery}%</li>
      </ul>

      <h2>Subscriber Info</h2>
      <ul>
        <li>Success: {response.subscriber_info?.success ? "Yes" : "No"}</li>
        <li>State: {response.subscriber_info?.state}</li>
        <li>
          Location Retrieved:{" "}
          {response.subscriber_info?.location.current_location_retrieved
            ? "Yes"
            : "No"}
        </li>
        <li>
          Age of Location: {response.subscriber_info?.location.age_of_location}
        </li>
        <li>MCC: {response.subscriber_info?.location.cell_global_id.mcc}</li>
        <li>MNC: {response.subscriber_info?.location.cell_global_id.mnc}</li>
      </ul>

      <h2>Timestamps</h2>
      <ul>
        <li>Request: {response.request_timestamp}</li>
        <li>
          Reply: {format(response.reply_timestamp, FIELD_TYPES.RELATIVE_TIME)}
        </li>
      </ul>
    </div>
  );
}

export default DevicesView;
