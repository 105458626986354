import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { IWithAccountId } from "../../types";
import CreateInsurance from "./CreateInsurance";
import EditInsurance from "./EditInsurance";
import ListInsurances from "./ListInsurances";

export default function Insurances({ match }: IWithAccountId) {
  return (
    <Switch>
      <Route
        render={() => <ListInsurances accountId={match.params.accountId} />}
        path={match.path + "/"}
        exact
      />
      <Route component={CreateInsurance} path={match.url + "/crear"} exact />
      <Route component={EditInsurance} path={match.url + "/:entityId"} exact />
      <Redirect to={match.url} />
    </Switch>
  );
}
