import { Form, Input, DatePicker } from "antd";
import React from "react";
import moment from "moment";
import locale from "antd/es/date-picker/locale/es_ES";
import { get } from "lodash";

// Define interfaces for props
interface TextInputProps {
  label: string;
  required?: boolean;
  placeholder?: string;
  transform?: (value: string) => string;
  entity: Record<string, any>;
  name: string | string[];
  help?: string;
  onChange: (name: string | string[], value: any) => void;
  autoFocus?: boolean;
  style?: React.CSSProperties;
  error?: string;
  submitted?: boolean;
}

interface DateInputProps {
  label: string;
  placeholder?: string;
  entity: Record<string, any>;
  name: string;
  onChange: (name: string, value: any) => void;
  error?: string;
  submitted?: boolean;
}

interface DateTimeInputProps extends DateInputProps {}

// TextInput component
export const TextInput: React.FC<TextInputProps> = ({
                                                      autoFocus = false,
                                                      entity,
                                                      error,
                                                      help,
                                                      label,
                                                      name,
                                                      onChange,
                                                      placeholder = "",
                                                      required = false,
                                                      style,
                                                      submitted,
                                                      transform,
                                                    }) => {
  const entityElement = get(entity, Array.isArray(name) ? name.join(".") : name);

  return (
      <Form.Item
          label={label}
          required={required}
          validateStatus={error && submitted ? "error" : "success"}
          help={help || (submitted && error)}
      >
        <Input
            style={style}
            autoFocus={autoFocus}
            placeholder={placeholder}
            value={entityElement || ""}
            onChange={(e) => onChange(name, transform ? transform(e.target.value) : e.target.value)}
        />
      </Form.Item>
  );
};

// DateInput component
export const DateInput: React.FC<DateInputProps> = ({
                                                      label,
                                                      placeholder,
                                                      entity,
                                                      name,
                                                      onChange,
                                                      error,
                                                      submitted,
                                                    }) => (
    <Form.Item
        label={label}
        validateStatus={error && submitted ? "error" : "success"}
        help={submitted && error}
    >
      <DatePicker
          placeholder={placeholder}
          style={{ width: "100%" }}
          value={entity[name] ? moment(entity[name]) : undefined}
          format="DD/MM/YYYY"
          onChange={(date) => onChange(name, date?.format("YYYY-MM-DD"))}
      />
    </Form.Item>
);

// DateTimeInput component
export const DateTimeInput: React.FC<DateTimeInputProps> = ({
                                                              label,
                                                              placeholder,
                                                              entity,
                                                              name,
                                                              onChange,
                                                              error,
                                                              submitted,
                                                            }) => (
    <Form.Item
        label={label}
        validateStatus={error && submitted ? "error" : "success"}
        help={submitted && error}
    >
      <DatePicker
          showTime
          locale={locale}
          style={{ width: "100%" }}
          placeholder={placeholder}
          format="DD/MM/YYYY HH:mm"
          value={entity[name] ? moment(entity[name]) : undefined}
          onChange={(date) => onChange(name, date?.format("YYYY-MM-DD HH:mm"))}
      />
    </Form.Item>
);
