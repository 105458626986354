import { Alert, Avatar, Button, Input, Table } from "antd";
import React from "react";
import { Link } from "react-router-dom";

import FilterTagSelector from "../../components/AsyncMultipleSelect";
import CreateLink from "../../components/CreateLink";
import MultipleSelect from "../../components/MultipleSelect";
import SortPaginate from "../../components/SortPAginate";
import { useFetchData } from "../../hooks/useFetchDataBis";

const sortOptions = [
  { label: "Nombre", value: "firstName+ASC" },
  { label: "Apellido", value: "lastName+ASC" },
];

const getColumns = (accountId) => {
  const renderVehicle = (text, record) => {
    return record.vehicleAssignments.length > 0
      ? record.vehicleAssignments.map((x) => (
          <span>
            <Link
              to={{
                pathname: `/${accountId}/vehicles/${x.vehicle.id}`,
                state: { menuKey: ["vehiculos"] },
              }}
            >
              {x.vehicle.name}
            </Link>{" "}
          </span>
        ))
      : "-";
  };

  return [
    {
      title: "Conductor",
      dataIndex: "license_plate",
      key: "license_plate",
      render: (text, record) => {
        const fullName = record.firstName + " " + record.lastName;

        return (
          <div className="p-1">
            <Avatar className={"mr-3"} src={record.profileImageUrl}>
              {(record.firstName[0] + record.lastName[0]).toUpperCase()}
            </Avatar>
            <Link
              to={{
                pathname: `/${accountId}/contacts/${record.id}`,
                state: { menuKey: ["conductores"] },
              }}
            >
              {fullName}
            </Link>
          </div>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (text) => text || "-",
    },
    {
      title: "Telefono",
      dataIndex: "phoneNumber",
      render: (text) => text || "-",
    },
    {
      title: "DNI",
      dataIndex: "documentNumber",
      render: (text) => text || "-",
    },
    {
      title: "Vehículo",
      dataIndex: "vehicleName",
      render: renderVehicle,
    },
  ];
};

const filters = [
  { label: "Nombre", name: "q" },
  { label: "Asignado", name: "assigned" },
];

export default function ContactsList({ match }) {
  const accountId = match.params.accountId;

  const [state, onChange, addItem, clearQuery, setPage, setSort] = useFetchData(
    {
      url: "contacts/search",
      initialSort: sortOptions[0].value,
      initialQuery: {},
      collectionName: "services",
    }
  );

  //Should fix right away. Dirty fix for passing lint.
  if (!addItem) {
    console.log(addItem);
  }

  const { data, query, asyncError, count, page, isLoading, s } = state;
  const length = Object.keys(query).length;
  const dataSource = data.rows;

  return (
    <div>
      <div className="grid-header">
        <h1>
          <Avatar
            src={
              "https://static.inmatics.io/images/conductores/conductor_avatar.png"
            }
            size={"large"}
            style={{ height: 50, width: 50 }}
          />{" "}
          {"Conductores"}
        </h1>
        <CreateLink
          to={{
            pathname: "/" + accountId + "/contacts/create",
            state: { menuKey: ["conductores"] },
          }}
          label={" Nuevo conductor"}
        />
      </div>

      <div className={"grid-filters"}>
        <Input.Search
          placeholder="Buscar por nombre, apellido o DNI"
          enterButton
          allowClear
          onSearch={(value) => onChange("q", value)}
          value={query["q"]}
          style={{ width: 300, marginRight: 10 }}
        />
        {filters
          .filter((x) => x.type === "tag")
          .map((x) => (
            <FilterTagSelector
              entity={query}
              name={x.name}
              formatter={x.formatter}
              onChange={onChange}
              placeholder={x.placeholder}
              style={{ width: 300, marginRight: 10 }}
              useFetcher={x.useFetcher}
            />
          ))}
        <MultipleSelect
          entity={query}
          onChange={onChange}
          placeholder={"Filtar por asignación"}
          style={{ width: 200, marginRight: 10 }}
          name={"assigned"}
          options={[
            { label: "Asignados", value: "assigned" },
            { label: "Sin asignar", value: "unassigned" },
          ]}
        />
        {length > 0 && (
          <Button type={"link"} onClick={clearQuery}>
            {" "}
            Borrar filtros
          </Button>
        )}
      </div>
      {asyncError && <Alert message={asyncError.message} type="error" />}

      <div>
        {count > 0 && (
          <SortPaginate
            current={page}
            onPageChange={setPage}
            onSortChange={setSort}
            sortOptions={sortOptions}
            sortValue={s}
            total={count}
          />
        )}
        <Table
          columns={getColumns(accountId)}
          rowSelection={"checkbox"}
          loading={isLoading}
          size={"small"}
          pagination={false}
          className="table-striped-rows"
          dataSource={dataSource}
          rowKey={(record) => record.id}
        />
      </div>
    </div>
  );
}
