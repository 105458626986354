import { Button, Dropdown, Menu } from "antd";
import React from "react";
import { Link } from "react-router-dom";

export default function QuickAddButton({ accountId, vehicleId }: any) {
  return (
    <Dropdown
      overlay={
        <Menu>
          <Menu.Item key="0">
            <Link to={`/${accountId}/services/create?vehicleId=${vehicleId}`}>
              Servicio
            </Link>
          </Menu.Item>
          <Menu.Item key="1">
            <Link
              to={`/${accountId}/collections/create?vehicleId=${vehicleId}`}
            >
              Cobranza
            </Link>
          </Menu.Item>
          {/*<Menu.Divider />*/}
          {/*<Menu.Item key="3">Foto</Menu.Item>*/}
        </Menu>
      }
      trigger={["click"]}
    >
      <Button style={{ width: "100%", marginBottom: "10px" }} type="primary">
        + Agregar
      </Button>
    </Dropdown>
  );
}
