import React, { useContext } from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";
import { AccountContext } from "../hooks/useAccount";

const LinkButton = ({ path, label, icon }) => {
  const account = useContext(AccountContext);

  return (
    <Link to={"/" + account.id + path}>
      <Button type={"primary"} icon={icon}>
        {label}
      </Button>
    </Link>
  );
};

export default LinkButton;
