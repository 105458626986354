import axios from "axios";
import { dataServer } from "../constants/server";

export const get = (endPoint) =>
  axios
    .get(`${dataServer}/${endPoint}`, { withCredentials: true })
    .then((response) => response.data);

export const post = (endPoint, payload) =>
  axios
    .post(`${dataServer}/${endPoint}`, payload, { withCredentials: true })
    .then((response) => response.data);

export const put = (endPoint, payload, id) =>
  axios
    .put(`${dataServer}/${endPoint}/${id}`, payload)
    .then((response) => response.data);

export const patch = (endPoint, payload, id) =>
  axios
    .patch(`${dataServer}/${endPoint}/${id}`, payload, {
      withCredentials: true,
    })
    .then((response) => response.data);

export const remove = (endPoint, id) =>
  axios
    .delete(`${dataServer}/${endPoint}/${id}`, { withCredentials: true })
    .then((response) => response.data);
