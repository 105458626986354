import React from "react";
import { getByKey } from "../../utils";
import { usePartTypes } from "../../hooks/usePartTypes";

export default function PartTypesFilterInfo({ values }) {
  const ve = usePartTypes();
  if (!values.map) {
    return null;
  }
  return (
    <>
      {values
        .map((y) => {
          const byKey = getByKey(ve.data, y);
          return byKey ? `${byKey.name}` : "";
        })
        .join(", ")}
    </>
  );
}
