import React from "react";
import { ArrowDownOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Avatar, Button, message, Table } from "antd";
import { parseError } from "../../helpers";
import { get, remove } from "../../helpers/v2api";
import moment from "moment";
import { useHistory } from "react-router";
import { getColumns } from "./getColumns";
import { useSWRFetch } from "./useSWRFetch";
import { useQuery } from "../../hooks/useQuery";
import { Filters } from "../../components/Filters";
import FiltersInfo from "../../components/FiltersInfo";
import { getURI } from "../../helpers/GetURI";
import LinkButton from "../../components/LinkButton";

const endPoint = "invoices";
const path = `facturas`;

export default function InvoicesList(props) {
  const { hideFilters } = props;
  const history = useHistory();
  const initialFilter = {};
  const { state, setSort, onChange, clearFilters } = useQuery({
    filters: initialFilter,
    page: 1,
  });
  const { filters } = state;
  const { data, error, mutate } = useSWRFetch(getURI(endPoint, state));

  const onDelete = async (record) => {
    try {
      await remove(endPoint, record.id);
      mutate();
      message.success(`La factura #${record.id} ha sido removida.`);
    } catch (err) {
      console.log(err);
    }
  };

  if (error) {
    return <div>{JSON.stringify(error)}</div>;
  }

  if (!data) {
    return null;
  }

  if (error) {
    return null;
  }

  async function download() {
    try {
      let response = await get(endPoint + "/download");
      const data = response.data;

      const fileName = `${path}_${moment().format("YYYY-MM-DD")}.csv`;

      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      alert("Error downloading");
    }
  }

  const onTableChange = (pagination, filters, sorter) => {
    if (sorter.order) {
      let sort = sorter.order === "ascend" ? `ASC` : "DESC";
      setSort(`${sorter.column.sortIndex || sorter.field}+${sort}`);
    } else {
      setSort("");
    }
  };

  const onRow = (record) => {
    return {
      onClick: () =>
        history.push(`/${record.vendor.account_id}/${path}/${record.id}`),
    };
  };

  return (
    <div>
      <div className="grid-header">
        <h1 style={{ color: "#397872" }}>
          {" "}
          <Avatar
            size={40}
            src={"https://static.inmatics.io/icons/icoSeguridad.svg"}
          />{" "}
          {"Facturas"}
        </h1>
        <div className={"justify-flex-end"}>
          <Button onClick={download} icon={<ArrowDownOutlined />}>
            Descargar
          </Button>
          <div className={"ml-2"}>
            <LinkButton
              path={`/${path}/crear`}
              label={"Registrar nueva factura"}
              icon={<PlusCircleOutlined />}
            />
          </div>
        </div>
      </div>
      <div>{parseError(error)}</div>
      {!hideFilters && (
        <div className={"justify-flex-end"}>
          <div className={"mr-2"}>
            <FiltersInfo
              filters={filters}
              columns={getColumns(onDelete)}
              clearFilters={clearFilters}
            />
          </div>
          <Filters
            columns={getColumns(onDelete)}
            filters={filters}
            onChange={onChange}
          />
        </div>
      )}

      <Table
        className="table-striped-rows"
        columns={getColumns(onDelete)}
        dataSource={data}
        onChange={onTableChange}
        onRow={onRow}
        pagination={false}
        rowClassName={"show-pointer"}
        rowKey={(record) => record.id}
        size={"small"}
      />
    </div>
  );
}
