import React, { useState, ReactNode } from "react";
import {
    Card,
    Table,
    Button,
    Modal,
    message,
    Tooltip,
    Dropdown,
    Menu,
} from "antd";
import {
    EditOutlined,
    DeleteOutlined,
    EllipsisOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import Breadcrumb from "../../components/Breadcrumb";
import StatusForm from "./StatusForm";
import {newApi, useSimpleSWRFetch} from "../../utils/rest";

interface Status {
    id: number;
    name: string;
    color: string;
    count: number;
}

interface StatusModalProps {
    trigger: ReactNode;
    entityId?: number;
    mutate: () => void;
}

const StatusModal: React.FC<StatusModalProps> = ({ trigger, entityId, mutate }) => {
    const [visible, setVisible] = useState(false);

    const close = () => {
        mutate();
        setVisible(false);
    };

    return (
        <div>
            <div onClick={() => setVisible(true)}>{trigger}</div>
            <Modal
                visible={visible}
                title={entityId ? "Editar estado" : "Agregar un estado nuevo"}
                forceRender
                footer={null}
                onCancel={close}
            >
                <StatusForm onSuccess={close} entityId={entityId?.toString()} />
            </Modal>
        </div>
    );
};

const PreferencesStatus: React.FC = () => {
    const {error, data, mutate} = useSimpleSWRFetch("reports?sort=count+desc");

    if (error) {
        return null;
    }

    if (!data) {
        return null
    }




    const confirm = async (record: Status) => {
        try {
            await newApi().v3.delete(`statuses/${record.id}`)
            mutate();
            message.success(`${record.name} removido`);
        } catch (err) {
            console.error(err);
        }
    };

    const menu = (record: Status) => (
        <Menu>
            <Menu.Item>
                <StatusModal
                    entityId={record.id}
                    mutate={mutate}
                    trigger={
                        <Button type="link" icon={<EditOutlined style={{ color: "#AAAAAA" }} />}>
                            Editar
                        </Button>
                    }
                />
            </Menu.Item>
            <Menu.Item>
                {record.count !== 0 ? (
                    <Tooltip title="Para borrar este estado, no puede haber ningun vehículo en este estado.">
                        <Button type="link" disabled>
                            <DeleteOutlined style={{ color: "#AAAAAA" }} /> Remover
                        </Button>
                    </Tooltip>
                ) : (
                    <Button type="link" onClick={() => confirm(record)}>
                        <DeleteOutlined style={{ color: "#AAAAAA" }} /> Remover
                    </Button>
                )}
            </Menu.Item>
        </Menu>
    );

    const columns = [
        {
            title: "Nombre del estado",
            dataIndex: "color",
            render: (_: string, record: Status) => (
                <div>
                    <b>{record.name}</b> <span style={{ color: record.color, marginRight: 5 }}>&#9673; </span>
                </div>
            ),
        },
        {
            title: "Cantidad de vehículos",
            dataIndex: "count",
        },
        {
            title: "Acciones",
            dataIndex: "id",
            width: 90,
            render: (_: number, record: Status) => (
                <div className="justify-center">
                    <Tooltip title="Acciones" placement="left">
                        <Dropdown trigger={["click"]} overlay={menu(record)} placement="bottomLeft">
                            <Button icon={<EllipsisOutlined style={{ transform: "rotate(90deg)" }} />} />
                        </Dropdown>
                    </Tooltip>
                </div>
            ),
        },
    ];

    const routes = [
        { to: "/preferencias", label: "Preferencias" },
        { label: "Status" },
    ];

    return (
        <Card
            title={
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Breadcrumb routes={routes} />
                    <StatusModal
                        mutate={mutate}
                        trigger={
                            <Button icon={<PlusOutlined />} type="primary">
                                Agregar un estado nuevo
                            </Button>
                        }
                    />
                </div>
            }
        >
            <Table
                dataSource={data}
                rowKey={(record) => record.id.toString()}
                columns={columns}
                pagination={false}
                size="small"
                bordered
            />
        </Card>
    );
};

export default PreferencesStatus;
