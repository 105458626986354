import { Button, Dropdown, Menu, Tooltip } from "antd";
import { Link } from "react-router-dom";
import { EditOutlined, MoreOutlined } from "@ant-design/icons";
import React from "react";

function ActionsMenu({ record }) {
  function onMouseEnter(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  return (
    <Dropdown
      trigger={"click"}
      onClick={onMouseEnter}
      overlay={
        <Menu>
          <Menu.Item key="0">
            <Link to={`/inventario/${record.id}`}>
              <Button type={"link"}>
                <EditOutlined style={{ color: "#AAAAAA" }} /> Editar
              </Button>
            </Link>
          </Menu.Item>
        </Menu>
      }
    >
      <Tooltip title={"Acciones"} placement={"left"}>
        <Button icon={<MoreOutlined />} />
      </Tooltip>
    </Dropdown>
  );
}

export default ActionsMenu;
