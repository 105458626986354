import React from "react";
import { Card, Spin } from "antd";
import { CommentOutlined, LineOutlined } from "@ant-design/icons";

import CommentView from "src/components/DashboardCommentView";
import { useSimpleSWRFetch } from "src/utils/rest";
import { Comment } from "src/types";

interface DashboardCommentsProps {
    accountId: string;
}

export default function DashboardComments({ accountId }: DashboardCommentsProps) {
    const { data, error } = useSimpleSWRFetch("comments");

    if (error) {
        return (
            <div style={{ textAlign: "center", padding: "20px" }}>
                <p className="error-message">Error fetching comments. Please try again later.</p>
            </div>
        );
    }

    if (!data) {
        return (
            <div style={{ textAlign: "center", padding: "20px" }}>
                <Spin size="large" />
            </div>
        );
    }

    const emptyState = (
        <div style={{ textAlign: "center" }}>
            <LineOutlined className="muted" style={{ fontSize: 30 }} />
            <p className="muted">Todavía no hay comentarios</p>
        </div>
    );

    const title = (
        <div>
            <CommentOutlined className="muted mr-2" />
            Últimos Comentarios
        </div>
    );

    return (
        <Card bodyStyle={{ maxHeight: 300, overflowY: "auto" }} title={title}>
            {data.length === 0 ? emptyState : data.map((comment: Comment) => (
                <CommentView accountId={accountId} key={comment.id} comment={comment} />
            ))}
        </Card>
    );
}
